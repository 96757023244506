<template>
<div class="om_header_view">
    <div class="om_logo_region">
        <div class="om_logo_img">
            <img width="32" height="32" :src="logoTitle.icon" alt=""/>
        </div>
        <div class="om_logo_name">{{title}}</div>
    </div>
    <div class="om_menu_region">
        <div class="om_menu_nav">
            <el-menu
            mode="horizontal"
            :default-active="active"
            unique-opened
            router
            >
                <template
                    v-for="(menuitem, index) in menuitems"
                    >
                    <el-sub-menu
                        :key="index"
                        v-if="menuitem && menuitem.chlidren && menuitem.chlidren.length"
                        :index="menuitem.url"
                    >
                        <template #title>{{menuitem.name}}</template>
                        <el-menu-item
                            v-for="(itm, idx) in menuitem.chlidren"
                            :key="index + idx"
                            :index="itm.url"
                            @click="selectitem(itm)"
                        >
                            <span>{{itm.name}}</span>
                        </el-menu-item>
                    </el-sub-menu >
                    <el-menu-item
                        :key="index + 'a'"
                        :index="menuitem.url"
                        v-else
                    >
                        <span>{{menuitem.name}}</span>
                    </el-menu-item>
                </template>
            </el-menu>
        </div>
    </div>
    <div class="om_user_region">
        <el-dropdown @command="handleCommand" @visible-change="handleVisible" :hide-on-click="false">
            <span class="el-dropdown-link">
                <i class="icon"><img src="/images/user.png" alt=""></i>
                <span class="title">{{username}}</span>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item command="change_language" split-button="true">{{dyy}}
                    </el-dropdown-item>
                    <el-dropdown-item v-if="change" class="down_lang">
                        <el-dropdown-item command="zhCn" v-if="!( (lang === 'zhCn') || (lang === null))">{{zhCn}}</el-dropdown-item>
                        <el-dropdown-item command="en" v-if="!(lang === 'en')">{{en}}</el-dropdown-item>
                        <el-dropdown-item command="ru" v-if="!(lang === 'ru')">{{ru}}</el-dropdown-item>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="quanxian>0" command="om_web_config_dialog">{{xtsz}}</el-dropdown-item>
                    <el-dropdown-item v-if="quanxian>0" command="handle_svg_edit_click">{{dtbj}}</el-dropdown-item>
                    <el-dropdown-item command="handle_data_overview_click">{{sjzl}}</el-dropdown-item>
                    <el-dropdown-item command="edit_password">{{xgmm}}</el-dropdown-item>
                    <el-dropdown-item command="yz_calibrate">{{yzbd}}</el-dropdown-item>
                    <el-dropdown-item command="logout">{{tcdl}}</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
    <!-- 全屏显示 -->
    <div class="fullscreen" @click="handleFullScreen">
        <el-tooltip effect="dark" :content="qxqp" placement="bottom">
            <close v-show="fullscreen" style="width: 24px; height: 24px; border:none; outline: none;"/>
        </el-tooltip>
        <el-tooltip effect="dark" :content="qp" placement="bottom">
            <rank v-show="!fullscreen" style="width: 24px; height: 24px; border:none; outline: none;"/>
        </el-tooltip>
    </div>
    <om_calibrate_dialog ref="om_calibrate_dialog" />
    <om_calibrate_zj_dialog ref="om_calibrate_zj_dialog" />
    <om_reset_pwd_dialog ref="om_reset_pwd_dialog" />
    <om_data_overview_dialog ref="om_data_overview_dialog" />
    <om_svg_edit_dialog ref="om_svg_edit_dialog" />
    <om_web_config_dialog ref="om_web_config_dialog" />
</div>
</template>
<script>
import { ref } from 'vue'
import { FullScreen as rank, CloseBold as close } from '@element-plus/icons-vue'
import om_calibrate_dialog from './components/om_calibrate_dialog'
import om_calibrate_zj_dialog from './components/om_calibrate_dialog/om_calibrate_zj'
import om_reset_pwd_dialog from './components/om_reset_pwd_dialog'
import om_data_overview_dialog from './components/om_data_overview_dialog'
import om_svg_edit_dialog from './components/om_svg_edit_dialog'
import om_web_config_dialog from './components/om_web_config_dialog'
import { logoTitle, loginOut } from '@/utils'
export default {
    components:{
        rank,
        close,
        om_calibrate_dialog,
        om_calibrate_zj_dialog,
        om_reset_pwd_dialog,
        om_data_overview_dialog,
        om_svg_edit_dialog,
        om_web_config_dialog
    },
    setup() {
        const om_calibrate_dialog = ref(null)
        const om_calibrate_zj_dialog = ref(null)
        const om_web_config_dialog = ref(null)
        const om_reset_pwd_dialog = ref(null)
        const om_data_overview_dialog = ref(null)
        return {
            om_calibrate_dialog,
            om_calibrate_zj_dialog,
            om_web_config_dialog,
            om_reset_pwd_dialog,
            om_data_overview_dialog
        }
    },
    data() {
        const systemLang = this.$Lang
        return {
            menuitems:
            [
                {
                    // 系统首页
                    name: systemLang.xtsy.name,
                    url: '/home',
                    icon: 'icon-home'
                }, {
                    // 数据管理
                    name: systemLang.sjgl.name,
                    url: '/report',
                    icon: 'icon-report'
                }, {
                    // 系统日志
                    name: systemLang.rzgl.name,
                    url: '/sys_log',
                    icon: 'icon-gragh',
                    chlidren: [
                        {
                            // 系统日志
                            name: systemLang.sysLog.name,
                            url: '/sys_log',
                            icon: 'icon-gragh'
                        }, {
                            // 每日报告
                            name: systemLang.Dailyeport.name,
                            url: '/Dailyeport',
                            icon: 'icon-event'
                        }, {
                            // 自检功能
                            name: systemLang.header.zjgn.name,
                            url: '/self-inspection',
                            icon: 'icon-event'
                        }
                    ]
                }, {
                    // 缺陷管理
                    name: systemLang.qxgl.name,
                    url: '/event',
                    icon: 'icon-event'
                }, {
                    // 工况管理
                    name: systemLang.gkgl.name,
                    url: '/condition',
                    icon: 'icon-condition'
                }, {
                    // 典型谱图
                    name: systemLang.dxpt.name,
                    url: '/gragh',
                    icon: 'icon-gragh'
                }
            ],
            active: '/home',
            userName: '管理员账号',
            logoTitle,
            flag: false,
            change: false,
            fullscreen: false,
            dialogVisible: false
        }
    },
    computed: {
        quanxian() {
            return sessionStorage.getItem('quanxian')
        },
        username() {
            const username = sessionStorage.getItem('username')
            return username || this.userName
        },
        lang() {
            return localStorage.getItem('lang')
        },
        title() {
            const os = navigator.userAgent.toLowerCase()
            return os.indexOf('linux') >= 0 ? this.$Lang.title : this.$Lang.oldtitle
        },
        dtbj() {
            return this.$Lang.header.dtbj.name
        },
        sjzl() {
            return this.$Lang.header.sjzl.name
        },
        zjgn() {
            return this.$Lang.header.zjgn.name
        },
        xtsz() {
            if (this.$Lang.header.xtsz.name === null || this.$Lang.header.xtsz.name === undefined) {
                return ''
            }
            return this.$Lang.header.xtsz.name
        },
        xgmm() {
            return this.$Lang.header.xgmm.name
        },
        yzbd() {
            return this.$Lang.header.yzbd.name
        },
        dyy() {
            return this.$Lang.header.dyy.name
        },
        zhCn() {
            return this.$Lang.header.dyy.zhCn
        },
        en() {
            return this.$Lang.header.dyy.en
        },
        ru() {
            return this.$Lang.header.dyy.ru
        },
        tcdl() {
            return this.$Lang.header.tcdl
        },
        qp() {
            return this.$Lang.header.qp
        },
        qxqp() {
            return this.$Lang.header.qxqp
        },
        rzgl() {
            return this.$Lang.rzgl.name
        }
    },
    watch: {
        $route: {
            deep: false,
            imidiate: false,
            handler: function(val, oldVal) {
                this.active = val.path
            }
        }
    },
    methods: {
        handleVisible(visible) {
            if ( !visible ) {
                this.change = false
            }
        },
        selectitem(item) {
            if (item === undefined || item === null) {
                return
            }
            if (item.url === '/self-inspection') {
                if (this.om_calibrate_zj_dialog) {
                    this.om_calibrate_zj_dialog.showDialog()
                }
            }
        },
        async handleCommand(command) {
            if (command === 'logout') {
                loginOut()
            }
            if (command === 'yz_calibrate') {
                if (this.om_calibrate_dialog) {
                    this.om_calibrate_dialog.showDialog()
                }
            }
            if (command === 'edit_password') {
                if (this.om_reset_pwd_dialog) {
                    this.om_reset_pwd_dialog.showDialog()
                }
            }
            if (command === 'change_language') {
                const change = !(this.change)
                this.change = change
            }
            if (command === 'om_web_config_dialog') {
                if (this.om_web_config_dialog) {
                    this.om_web_config_dialog.showDialog()
                }
            }
            if (command === 'handle_svg_edit_click') {
                this.handle_svg_edit_click()
            }
            if (command === 'handle_data_overview_click') {
                this.handle_data_overview_click()
            }
            if (command === 'zhCn' || command === 'en' || command === 'ru') {
                const change = !(this.change)
                this.change = change
                const paramset = {
                    lang: command
                }
                try {
                    const result = await this.$ajax.p_init_jczd_data(paramset)
                    if ( result[0][0].code === 0) {
                        this.$message.success(`${result[0][0].text}`)
                    }
                } catch (error) {
                    this.$message.warning(`p_init_jczd_data 异常,错误原因:${error}`)
                    return
                }
                localStorage.setItem('lang', command)
                this.$router.go(0)
            }
        },
        handleFullScreen() {
            if (
                document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msExitFullscreenElement
            ) {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen()
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen()
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen()
                }
                this.fullscreen = false
                return
            }
            const element = document.documentElement
            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen()
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
            }
            this.fullscreen = true
        },
        handle_data_overview_click() {
            if (om_data_overview_dialog) {
                this.$refs.om_data_overview_dialog.showDialog()
            }
        },
        handle_svg_edit_click() {
            if (om_svg_edit_dialog) {
                this.$refs.om_svg_edit_dialog.showDialog()
            }
        }
    }
}
</script>

<style scoped lang="scss">
$height: 58px;
.om_header_view {
    user-select: none;
    font-family: monospace;
    width: 100%;
    height: $height;
    display: flex;
    background: url(/images/banner.png) no-repeat;
    background-size:100% 100%;
    color: #fff;
    .om_logo_region {
        flex: 0;
        height: 100%;
        min-width: 450px;
        max-width: 450px;
        line-height: $height;
        font-family: monospace;
        display: flex;
        justify-content: space-around;
        .om_logo_img {
            flex: 0;
            width: $height;
            min-width: $height;
            text-align: center;
            box-sizing: border-box;
            padding: 9px;
        }
        .om_logo_name {
            flex: 1;
            font-size: 16px;
            line-height: 30px;
            font-weight: bold;
            vertical-align: middle;
            color: whitesmoke;
            font-family: 'monospace';
            word-wrap: break-word;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items:center;
        }
    }
    .om_menu_region {
        flex: 1;
        min-width: 100px;
        height:100%;
        display: flex;
        justify-content: space-between;
        .om_menu_nav {
            height: 100%;
            width: 100%;
            padding: 0px;
            margin: 0px;
            overflow: hidden;
            box-sizing: border-box;
            .el-menu, .el-menu--horizontal {
                box-sizing: border-box !important;
                height: 100%;
                width: 100%;
                background-color: rgba(0,0,0,0) !important;
                border: none !important;
            }
            .el-menu-item {
                box-sizing: border-box !important;
                height: 100% !important;
                font-size: 18px !important;
                font-family: 'monospace' !important;
                color: #fff !important;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0,0,0,0) !important;
            }
            .el-menu-item>span {
                height: 100% !important;
                box-sizing: border-box !important;
            }
            .el-menu-item.is-active
            .el-menu-item.is-active>.iconfont,
            .el-menu-item.is-active>span {
                height: 100% !important;
                color: #ead023 !important;
                box-sizing: border-box !important;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0,0,0,0) !important;
                font-weight: 600;
            }
        }
    }
    .om_dialog_region {
        flex: 0;
        min-width: 200px;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .om_user_region {
        flex: 0;
        width: 90px;
        min-width: 90px;
        height:100%;
        padding-right: 10px;
        .el-dropdown {
            width: 100% !important;
            height: 100% !important;
            line-height: $height !important;
            outline: none;
            text-align: center;
            cursor: pointer;
            // .down_lang{
            //     width: 100%;
            //     height: 100%;
            //     display: flex;
            //     flex-direction: column;
            // }
            .el-dropdown-link {
                width: 100%;
                line-height: $height;
                height: 100%;;
                display: flex;
                cursor: pointer;
                .icon {
                    flex: 0;
                    min-width: 32px;
                    width: 32px;
                }
                .title {
                    flex: 1;
                    color: #fff !important;
                    font-size: 16px !important;
                    font-family: 'monospace' !important;
                    overflow:hidden;
                    text-overflow: ellipsis;
                    white-space:nowrap;
                    width:100%;
                }
            }
        }
    }
    .fullscreen {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0;
        min-width: $height;
        height: 100%;
    }
}
::v-deep .el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
    font-size: 20px !important;
    color: #fff !important;
    font-weight: 700 !important;
}
::v-deep .el-menu--horizontal>.el-sub-menu .el-sub-menu__title:hover {
   background: transparent !important;
}
::v-deep .el-menu--horizontal>.el-sub-menu:hover .el-sub-menu__title {
    font-size: 20px !important;
    // color: #000 !important;
    font-weight: 700 !important;
}
</style>
