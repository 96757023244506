import Router from '../router'
import { ElMessage } from 'element-plus'

const systemLang = localStorage.getItem('lang')
let Lang = null
if (systemLang === 'zhCn') {
    Lang = window.zhCn
} else if (systemLang === 'en') {
    Lang = window.en
} else if (systemLang === 'ru') {
    Lang = window.ru
} else {
    Lang = window.zhCn
}

export const logoTitle = {
    title: document.title,
    icon:'/favicon.ico'
}

export function loginOut() {
    // window.clearVuexAlong()
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('x-access-token')
    Router.replace('/login')
    ElMessage({
        type:'success',
        message: Lang.utils.login.tccg
    })
}
// eslint-disable-next-line
Date.prototype.format = function(fmt) {
    const o = {
        'M+' : this.getMonth() + 1, // 月份
        'd+' : this.getDate(), // 日
        'h+' : this.getHours(), // 小时
        'm+' : this.getMinutes(), // 分
        's+' : this.getSeconds(), // 秒
        'q+' : Math.floor((this.getMonth() + 3 ) / 3), // 季度
        'S'  : this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
}

export const sleep = (msec) => {
    return new Promise(
        (resolve, reject) => {
            setTimeout(
                () => {
                    resolve()
                },
                msec
            )
        }
    )
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
export function sheet2blob(XLSX, sheet, sheetName) {
    sheetName = sheetName || 'sheet1'
    const workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    }
    workbook.Sheets[sheetName] = sheet // 生成excel的配置项
    const wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    }
    const wbout = XLSX.write(workbook, wopts)
    const blob = new Blob(
        [s2ab(wbout)],
        {
            type: 'application/octet-stream'
        }
    )
    // 字符串转ArrayBuffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (var i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF
        }
        return buf
    }
    return blob
}

export function openDownloadDialog(url, saveName) {
    if (typeof url === 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url) // 创建blob地址
    }
    var aLink = document.createElement('a')
    aLink.href = url
    aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event
    if (window.MouseEvent) {
        event = new MouseEvent('click')
    } else {
        event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    }
    aLink.dispatchEvent(event)
}

export function toDecimal(x, fixed) {
    if (x === undefined || x === null) {
        return ''
    }
    let f = parseFloat(x)
    if (isNaN(f)) {
        return 9999
    }
    const scale = Math.pow(10, fixed)
    f = Math.round( x * scale ) / scale
    return f
}

export function toDateTime(sec) {
    const secs = parseInt(sec)
    const date = new Date(secs * 1000)
    const fmt = date.format('yyyy-MM-dd hh:mm:ss')
    return fmt
}

export function contain_device_flag(data_flag) {
    const bitmask = 0x00ff0000
    if ( (data_flag & bitmask) > 0 ) {
        return true
    }
    return false
}

export function device_flag_object(data_flag) {
    const bitmask = 0x00ff0000
    const zzzt = Lang.utils.zzzt
    const status = {
        data_flag: data_flag,
        mingzi: zzzt.zzzc,
        src: '/images/device_flag/normal.gif'
    }
    if (!data_flag) {
        return status
    }
    if ( (data_flag & bitmask) > 0 ) {
        status.mingzi = zzzt.zzgz
        status.src = '/images/device_flag/unnormal.gif'
        return status
    }
    return status
}

export function contain_equip_flag(data_flag) {
    const attenmask = 0x01000000
    const alarmmask = 0x02000000
    if ( (data_flag & alarmmask) > 0 ) {
        return true
    }
    if ( (data_flag & attenmask) > 0 ) {
        return true
    }
    return false
}

export function contain_equip_alarm_flag(data_flag) {
    const alarmmask = 0x02000000
    if ((data_flag & alarmmask) > 0 ) {
        return true
    }
    return false
}

export function contain_equip_atten_flag(data_flag) {
    const attenmask = 0x01000000
    const alarmmask = 0x02000000
    if ((data_flag & alarmmask) > 0 ) {
        return false
    }
    if ((data_flag & attenmask) > 0 ) {
        return true
    }
    return false
}

export function equip_flag_object(data_flag) {
    const attenmask = 0x01000000
    const alarmmask = 0x02000000
    const sjzt = Lang.utils.sjzt
    const status = {
        data_flag: data_flag,
        mingzi: sjzt.sjzc,
        src: '/images/data_flag/normal.gif'
    }
    if (!data_flag) {
        return status
    }
    if ( (data_flag & alarmmask) > 0 ) {
        status.mingzi = sjzt.sjbj
        status.src = '/images/equip_flag/alarm.gif'
        return status
    }
    if ( (data_flag & attenmask) > 0 ) {
        status.mingzi = sjzt.sjyj
        status.src = '/images/equip_flag/atten.gif'
        return status
    }
    return status
}

export function canshu_flag_object(data_flag) {
    const DataNullF_Mask = 0x00010000
    const DataValidF_Mask = 0x00020000
    const DataBreakF_Mask = 0x00040000
    const DataDieF_Mask = 0x00080000
    const DataErrorF_Mask = 0x00800000
    const DataAttenF_Mask = 0x01000000
    const DataAlarmF_Mask = 0x02000000
    const sjzt = Lang.utils.sjzt
    const status = {
        data_flag: data_flag,
        mingzi: sjzt.sjzc,
        src: '/images/data_flag/normal.gif'
    }
    if (!data_flag) {
        return status
    }
    if ((data_flag & DataNullF_Mask) > 0) {
        status.mingzi = sjzt.ksj
        status.src = '/images/data_flag/datanullf.gif'
        return status
    }
    if ((data_flag & DataValidF_Mask) > 0) {
        status.mingzi = sjzt.wxsj
        status.src = '/images/data_flag/datavalidf.gif'
        return status
    }
    if ((data_flag & DataBreakF_Mask) > 0) {
        status.mingzi = sjzt.sjzd
        status.src = '/images/data_flag/databreakf.gif'
        return status
    }
    if ((data_flag & DataDieF_Mask) > 0) {
        status.mingzi = sjzt.sjst
        status.src = '/images/data_flag/datadief.gif'
        return status
    }
    if ((data_flag & DataErrorF_Mask) > 0) {
        status.mingzi = sjzt.sjyw
        status.src = '/images/data_flag/dataerrorf.gif'
        return status
    }
    if ((data_flag & DataAttenF_Mask) > 0) {
        status.mingzi = sjzt.sjyj
        status.src = '/images/data_flag/dataattenf.gif'
        return status
    }
    if ((data_flag & DataAlarmF_Mask) > 0) {
        status.mingzi = sjzt.sjbj
        status.src = '/images/data_flag/dataalarmf.gif'
        return status
    }
    return status
}

export const randPrps = () => {
    let prps = []
    for (let y = 0; y < 50; y++) {
        for (let x = 0; x < 360; x += 1.8) {
            const min_dbm = -70
            const max_dbm = 0
            const value = parseFloat((Math.random() * Math.random() * (max_dbm - min_dbm) + min_dbm).toFixed(2))
            const phase = toDecimal(x, 1)
            prps.push([phase, y, value])
        }
    }
    prps = prps.filter(
        (value) => {
            return value[2] > -40
        }
    )
    prps = prps.sort(
        (value1, value2) => {
            if (value1[2] < value2[2]) return 1
            return -1
        }
    )
    prps = prps.splice(0, 100)
    return prps
}

export const randPrp = () => {
    let prp = []
    for (let i = 0; i < 650; i++) {
        const min_dbm = 10000
        const max_dbm = 0
        const value = parseFloat((Math.random() * Math.random() * (max_dbm - min_dbm) + min_dbm).toFixed(2))
        prp.push([i, value])
    }
    prp = prp.filter(
        (value) => {
            return value[1] > 5
        }
    )
    prp = prp.sort(
        (value1, value2) => {
            if (value1[1] < value2[1]) return 1
            return -1
        }
    )
    prp = prp.splice(0, 100)
    return prp
}

export const randData = () => {
    const zxdata = {}
    for (let shebei_id = 1; shebei_id <= 30; shebei_id++) {
        for (let canshu_id = 1; canshu_id <= 10; canshu_id++) {
            const key = `1_4_${shebei_id}_${canshu_id}`
            //
            const key_data_flag = `zx_data_calc.${key}.data_flag`
            const data_flag = parseInt(Math.random() * 0xFFFFFFFF)
            zxdata[key_data_flag] = data_flag
            //
            const key_data = `zx_data_src.${key}.data`
            const data = Math.random() * 100
            zxdata[key_data] = data
        }
    }
    for (let shebei_id = 1; shebei_id <= 1; shebei_id++) {
        for (let canshu_id = 1; canshu_id <= 10; canshu_id++) {
            const key = `1_34_${shebei_id}_${canshu_id}`
            //
            if (canshu_id === 7) {
                const key_data = `zx_data_src.${key}.rtime`
                const rand = parseInt(Date.now() / 1000)
                const data = rand
                zxdata[key_data] = data
                continue
            }
            //
            const key_data_flag = `zx_data_calc.${key}.data_flag`
            const data_flag = parseInt(Math.random() * 0xFFFFFFFF)
            zxdata[key_data_flag] = data_flag
            //
            const key_data = `zx_data_src.${key}.data`
            const data = Math.random() * 100
            zxdata[key_data] = data
        }
    }
    for (let shebei_id = 1; shebei_id <= 12; shebei_id++) {
        for (let canshu_id = 1; canshu_id <= 24; canshu_id++) {
            const key = `1_18_${shebei_id}_${canshu_id}`
            //
            const key_data_flag = `zx_data_calc.${key}.data_flag`
            const data_flag = parseInt(Math.random() * 0xFFFFFFFF)
            zxdata[key_data_flag] = data_flag
            //
            if (canshu_id === 1) {
                const key_data = `zx_data_src.${key}.rtime`
                const rand = parseInt(Date.now() / 1000)
                const data = rand
                zxdata[key_data] = data
                continue
            }
            // 放电次数
            if (canshu_id === 10) {
                const key_data = `zx_data_src.${key}.data`
                const rand = parseInt(Math.random() * 100)
                const data = rand
                zxdata[key_data] = data
                continue
            }
            // 放电类型
            if (canshu_id === 17) {
                const key_data = `zx_data_src.${key}.data`
                let rand = Math.random() * 6
                if (rand > 5) {
                    rand = 99
                }
                rand = parseInt(rand)
                const data = rand
                zxdata[key_data] = data
                continue
            }
            // PRPS
            if (canshu_id === 21) {
                const key = `1_18_${shebei_id}_${canshu_id}`
                const key_data = `zx_data_src.${key}.data`
                const data = randPrps()
                zxdata[key_data] = data
                continue
            }
            //
            if (canshu_id === 20 || canshu_id === 22) {
                continue
            }
            // 放电概率
            if (canshu_id === 24) {
                const key_data = `zx_data_src.${key}.data`
                const rand = Math.random()
                const data = rand
                zxdata[key_data] = data
                continue
            }
            const key_data = `zx_data_src.${key}.data`
            const data = Math.random() * 100
            zxdata[key_data] = data
        }
    }
    for (let shebei_id = 1; shebei_id <= 6; shebei_id++) {
        for (let canshu_id = 1; canshu_id <= 24; canshu_id++) {
            const key = `1_38_${shebei_id}_${canshu_id}`
            //
            const key_data_flag = `zx_data_calc.${key}.data_flag`
            const data_flag = parseInt(Math.random() * 0xFFFFFFFF)
            zxdata[key_data_flag] = data_flag
            //
            if (canshu_id === 1) {
                const key_data = `zx_data_src.${key}.rtime`
                const rand = parseInt(Date.now() / 1000)
                const data = rand
                zxdata[key_data] = data
                continue
            }
            // 放电次数
            if (canshu_id === 10) {
                const key_data = `zx_data_src.${key}.data`
                const rand = parseInt(Math.random() * 100)
                const data = rand
                zxdata[key_data] = data
                continue
            }
            // 放电类型
            if (canshu_id === 17) {
                const key_data = `zx_data_src.${key}.data`
                let rand = Math.random() * 6
                if (rand > 5) {
                    rand = 99
                }
                rand = parseInt(rand)
                const data = rand
                zxdata[key_data] = data
                continue
            }
            //
            if (canshu_id === 20) {
                const key = `1_38_${shebei_id}_${canshu_id}`
                //
                const key_data = `zx_data_src.${key}.data`
                const data = randPrp()
                zxdata[key_data] = data
                continue
            }
            // PRPS
            if (canshu_id === 21) {
                const key = `1_38_${shebei_id}_${canshu_id}`
                //
                const key_data = `zx_data_src.${key}.data`
                const data = randPrps()
                zxdata[key_data] = data
                continue
            }
            // 放电概率
            if (canshu_id === 24) {
                const key_data = `zx_data_src.${key}.data`
                const rand = Math.random()
                const data = rand
                zxdata[key_data] = data
                continue
            }
            const key_data = `zx_data_src.${key}.data`
            const data = Math.random() * 100
            zxdata[key_data] = data
        }
    }
    return zxdata
}

export function multisheet2blob(XLSX, sheets, sheetNames) {
    const sheetlist = {}
    const sheetnamelist = []
    for (var j = 0; j < sheets.length; j++) {
        const defaultsheet = 'sheet${j+1}'
        const sname = sheetNames[j] || defaultsheet
        sheetnamelist.push(sname)
        sheetlist[sname] = sheets[j] // 生成excel的配置项
    }
    const workbook = {
        SheetNames: sheetnamelist,
        Sheets: sheetlist
    }
    const wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    }
    const wbout = XLSX.write(workbook, wopts)
    const blob = new Blob(
        [s2ab(wbout)],
        {
            type: 'application/octet-stream'
        }
    )
    // 字符串转ArrayBuffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (var i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF
        }
        return buf
    }
    return blob
}
