<template>
<div class="om_shebei_event_table">
    <el-table
    :data="shebei_fault_event"
    :show-overflow-tooltip="true"
    height="100%"
    max-height= "calc(100vh - 30px)"
    border
    :stripe="true">
        <el-table-column
        align="center"
        prop="mingzi"
        :label="jczz"
        width="auto">
            <template #default="scope">
                <div class="name"><el-tag>{{scope.row.mingzi}}</el-tag></div>
            </template>
        </el-table-column>
        <el-table-column
        align="center"
        prop="ctime"
        :label="zdsj"
        width="160">
            <template #default="scope">
                <div class="name"><el-tag>{{ctime(scope.row)}}</el-tag></div>
            </template>
        </el-table-column>
        <el-table-column
        align="center"
        prop="data_flag"
        :label="sjzt"
        width="150">
            <template #default="scope">
                <img :src="equip_flag_object(scope.row).src" alt=""/>
                <span>{{equip_flag_object(scope.row).mingzi}}</span>
            </template>
        </el-table-column>
        <el-table-column
        align="center"
        :label="ckxq"
        width="100">
            <template #default="scope">
                <div style="display: flex; justify-content: center; align-items: center;">
                    <el-button class="more" type="warning" size="small" round @click="handle_click_event(scope.row)">{{ck}}</el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
import { toDecimal, equip_flag_object } from '@/utils'
export default {
    name: 'om_shebei_event_table',
    computed: {
        shebei_fault_event() {
            const shebei_fault_event = this.$store.getters.shebei_fault_event
            return shebei_fault_event
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        shebei_alarm_event_total() {
            const shebei_alarm_event_total = this.$store.getters.shebei_alarm_event_total
            return shebei_alarm_event_total
        },
        shebei_atten_event_total() {
            const shebei_atten_event_total = this.$store.getters.shebei_atten_event_total
            return shebei_atten_event_total
        },
        shebei_event_pie() {
            const shebei_total = this.shebei_total
            const shebei_alarm_event_total = this.shebei_alarm_event_total
            const shebei_atten_event_total = this.shebei_atten_event_total
            const shebei_normal_total = shebei_total - shebei_alarm_event_total - shebei_atten_event_total
            const shebei_event_pie = [
                { value: shebei_normal_total, name:'数据正常' },
                { value: shebei_atten_event_total, name:'数据预警' },
                { value: shebei_alarm_event_total, name:'数据报警' }
            ]
            return shebei_event_pie
        },
        shebei_normal_percent() {
            return toDecimal((this.shebei_total - this.shebei_alarm_event_total - this.shebei_atten_event_total) * 100 / this.shebei_total, 2)
        },
        shebei_alarm_percent() {
            return toDecimal(this.shebei_alarm_event_total * 100 / this.shebei_total, 2)
        },
        shebei_atten_percent() {
            return toDecimal(this.shebei_atten_event_total * 100 / this.shebei_total, 2)
        },
        jczz() {
            return this.$Lang.xtsy.sjbjxq.jczz
        },
        zdsj() {
            return this.$Lang.xtsy.sjbjxq.zdsj
        },
        sjzt() {
            return this.$Lang.xtsy.sjbjxq.sjzt
        },
        ckxq() {
            return this.$Lang.xtsy.sjbjxq.ckxq
        },
        ck() {
            return this.$Lang.xtsy.sjbjxq.ck
        },
        option() {
            const serie = {
                name: '数据状态统计',
                type:'pie',
                radius : '60%',
                center: ['50%', '50%'],
                data: this.shebei_event_pie
            }
            const option = {
                backgroundColor:'#b0d5df',
                title : {
                    text: '数据状态统计',
                    x:'center'
                },
                tooltip : {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                color:['#9AFF9A', '#ffcc66', '#ff3366'],
                toolbox: {
                    show : true,
                    feature : {
                        mark : {
                            show: true
                        },
                        saveAsImage : {
                            show: true
                        }
                    }
                },
                calculable : true,
                series: [
                    serie
                ]
            }
            return option
        }
    },
    methods: {
        canshu(object) {
            if (object === undefined || object === null) return null
            const obj_id = object.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        ctime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const ctime = canshu.ctime
            if (ctime === undefined || ctime === null) {
                return ''
            }
            return ctime
        },
        rtime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) {
                return ''
            }
            return rtime
        },
        equip_flag_object(object) {
            const data_flag = this.data_flag(object)
            return equip_flag_object(data_flag)
        },
        handle_click_event(shebei) {
            const leixing_id = shebei.leixing_id
            if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) {
                this.$router.push('/condition')
                return
            }
            this.$router.push('/report')
            this.$nextTick(
                () => {
                    this.$store.commit('setSelectedShebeiObject', shebei)
                }
            )
        },
        resize() {
            const ref_echarts = this.ref_echarts
            if (ref_echarts === undefined || ref_echarts === null) return
            ref_echarts.resize()
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang='scss'>
.om_shebei_event_table {
    width: 100%;
    height: 100%;
    background: white;
    box-sizing: border-box;
    overflow: hidden;
    .el-table {
        width: 100%;
        font-size: 12px !important;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .el-table__header-wrapper {
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            flex: 0 auto;
            min-height: 0px;
        }
        .el-table__body-wrapper {
            width: 100%;
            box-sizing: border-box;
            overflow-y: auto;
            flex: 1;
        }
        td, th {
            padding: 0px;
        }
        .more {
            width: 50px;
            height: 100%;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px !important;
        }
    }
}
</style>