<!--
 * @Author: wugs@weijie.com w19920622
 * @Date: 2022-08-02 22:05:19
 * @LastEditors: wugs@weijie.com w19920622
 * @LastEditTime: 2022-09-12 15:46:07
 * @FilePath: \omWork\om\web\om_a300_view\src\views\om_main_view\om_body_view\Dailyeport\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
<div class="layout">
    <div class="content_form">
        <div class="time">
            <span>{{rq}}: </span>
            <el-date-picker
                v-model="date_time"
                type="date"
                :clearable="false"
                placeholder="Pick a day"
                :size="size"
            />
        </div>
        <div class="query">
            <el-button type="primary" round @click="getList" :loading="fullscreenLoading">{{cx}}</el-button>
            <el-button type="primary" round @click="exportHandler" :loading="exportStutus">{{dc}}</el-button>
        </div>
    </div>
    <div
        class="om_event_view"
        v-loading.lock="fullscreenLoading"
    >
        <div class="top">
            <om_shebei_event_view
                class="om_shebei_event_view"
                :shebeiData="shebeiData"
                :detailData="detailData"
            />
        </div>
        <div class="bottom">
            <om_device_event_view
                :shebeiData="shebeiData"
                :detailData="detailData"
                class="om_device_event_view"
            />
        </div>
    </div>
</div>
</template>

<script>
import om_shebei_event_view from './om_shebei_event_view'
import om_device_event_view from './om_device_event_view'
// import {
//     onActivated
// } from 'vue'
import moment from 'moment'
export default {
    name: 'om_event_view',
    components: {
        om_shebei_event_view,
        om_device_event_view
    },
    data() {
        return {
            shebeiData: [],
            detailData: [],
            fullscreenLoading: false,
            date_time: moment(),
            exportStutus: false
        }
    },
    computed: {
        cx() {
            return this.$Lang.Dailyeport.home.cx
        },
        dc() {
            return this.$Lang.Dailyeport.home.dc
        },
        rq() {
            return this.$Lang.Dailyeport.home.rq
        }
    },
    activated() {
        this.getList()
    },
    methods: {
        async getList() {
            this.fullscreenLoading = true
            try {
                const data = await this.$ajax.Dailyeport_query({
                    date_time: this.date_time ? moment(this.date_time).format('yyyy-MM-DD 00:00:00') : ''
                })
                if (data[0][0].code === 0) {
                    this.shebeiData = data[1]
                    this.detailData = data[2]
                }
                // console.log('this.shebeiData', this.shebeiData)
                // console.log('this.detailData', this.detailData)
                this.fullscreenLoading = false
            } catch (err) {
                this.fullscreenLoading = false
                console.log('error', err)
            }
        },
        async exportHandler() {
            this.exportStutus = true
            const fileName = this.$Lang.Dailyeport.name
            // window.open(`/export_log?timeFrom=${timeFrom}&timeTo=${timeTo}&type=${data_flag_selected}`)
            try {
                const params = {
                    date_time: this.date_time ? moment(this.date_time).format('yyyy-MM-DD 00:00:00') : '',
                    type: this.data_flag_selected,
                    lang: localStorage.getItem('lang')
                }
                const resultset = await this.$ajax.export_log(params, 'Dailyeport')
                console.log('resultset', resultset)
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(resultset)
                let datename = ''
                if (this.date_time !== null) {
                    datename = moment(this.date_time).format('yyyyMMDD')
                }
                link.download = `${fileName}${datename}.xlsx`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                this.exportStutus = false
            } catch (error) {
                this.exportStutus = false
                this.$Message({
                    type:'warning',
                    message:`p_query_lx_data异常, 错误原因：${error}`
                })
            }
        }
    }
}
</script>

<style lang='scss' scoped>
.layout{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .content_form {
        width: 100%;
        min-height: 40px;
        flex: 0;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .time {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 320px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .query {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            margin: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .om_event_view {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        background: white;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 4px;
        .top {
            flex: 1;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding-bottom: 2px;
        }
        .bottom {
            flex: 1;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding-top: 2px;
        }
    }
}
</style>