<template>
<div class="om_typical_gragh_view">
    <div class="leixings">
        <el-radio-group v-model="selected_leixing_id">
            <el-radio-button v-for="(leixing, index) in leixings" :key="index" :label="leixing.leixing_id">
                {{leixing.mingzi}}
            </el-radio-button>
        </el-radio-group>
    </div>
    <om_typical_file
    v-if="show"
    class="om_typical_file"
    ref="ref_typical_file"
    :leixing_id="selected_leixing_id"
    @current-change="handle_current_change">
    </om_typical_file>
</div>
</template>

<script>
import { ref } from 'vue'
import om_typical_file from './om_typical_file'
export default {
    name:'om_typical_gragh_view',
    components: {
        om_typical_file
    },
    setup() {
        const ref_typical_file = ref(null)
        return {
            ref_typical_file
        }
    },
    data() {
        return {
            show: true,
            selected_leixing_id: null,
            selectedFileName: null
        }
    },
    computed: {
        leixings() {
            const leixings = this.$store.getters.leixings
            const leixings_exclude_condition = leixings.filter(
                (leixing) => {
                    if (leixing.leixing_id === 4) {
                        return true
                    }
                    if (leixing.leixing_id === 10) {
                        return true
                    }
                    if (leixing.leixing_id === 18) {
                        return true
                    }
                    if (leixing.leixing_id === 38) {
                        return false
                    }
                    return false
                }
            )
            return leixings_exclude_condition
        }
    },
    watch: {
        'selectedFileName': {
            immidiate: true,
            deep: true,
            handler() {
                this.setSelectedFileName()
            }
        }
    },
    methods: {
        setSelectedFileName() {
            const ref_typical_file = this.ref_typical_file
            if (ref_typical_file === undefined || ref_typical_file === null) return
            const selectedFileName = this.selectedFileName
            if ( selectedFileName === undefined || selectedFileName === null) return
            ref_typical_file.setSelectedFileName(selectedFileName)
        },
        handle_current_change(selectedFileName) {
            this.selectedFileName = selectedFileName
        }
    },
    activated() {
        this.show = true
        this.$nextTick(
            () => {
                this.setSelectedFileName()
            }
        )
    },
    deactivated() {
        this.show = false
    },
    mounted() {
        const leixings = this.leixings
        if (leixings === undefined || leixings === null) return
        if (leixings.length <= 0) return
        this.selected_leixing_id = leixings[0].leixing_id
        this.show = true
    }
}
</script>

<style lang='scss' scoped>
.om_typical_gragh_view {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .leixings {
        flex: 0;
        width: 100%;
        height: 40px;
        min-height: 40px;
        box-sizing: border-box;
        overflow: hidden;
        background: lightskyblue;
        padding-left: 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #E4E7ED;
    }
    .om_typical_file {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
    }
}
</style>
