<template>
<div class="om_device_event_table">
    <el-table
    :data="device_fault_event"
    :show-overflow-tooltip="true"
    height="100%"
    max-height= "calc(100vh - 30px)"
    border
    :stripe="true">
        <el-table-column
        align="center"
        prop="mingzi"
        :label="jczz"
        width="auto">
            <template #default="scope">
                <div class="name"><el-tag>{{scope.row.mingzi}}</el-tag></div>
            </template>
        </el-table-column>
        <el-table-column
        align="center"
        prop="ctime"
        :label="zdsj"
        width="160">
            <template #default="scope">
                <div class="name"><el-tag>{{ctime(scope.row)}}</el-tag></div>
            </template>
        </el-table-column>
        <el-table-column
        align="center"
        prop="data_flag"
        :label="zzzt"
        width="120">
            <template #default="scope">
                <div style="display: flex; justify-content: center; align-items: center;">
                    <img :src="device_flag_object(scope.row).src"/>
                    <span>{{device_flag_object(scope.row).mingzi}}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
        align="center"
        :label="ckxq"
        width="120">
            <template #default="scope">
                <div style="display: flex; justify-content: center; align-items: center;">
                    <el-button class="more" type="warning" size="small" round @click="handle_click_event(scope.row)">{{ck}}</el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
import { toDecimal, device_flag_object } from '@/utils'
export default {
    name: 'om_device_event_table',
    computed: {
        device_fault_event() {
            const device_fault_event = this.$store.getters.device_fault_event
            return device_fault_event
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        device_fault_event_total() {
            const device_fault_event_total = this.$store.getters.device_fault_event_total
            return device_fault_event_total
        },
        device_normal_percent() {
            return toDecimal((this.shebei_total - this.device_fault_event_total) * 100 / this.shebei_total, 2)
        },
        device_unnormal_percent() {
            return toDecimal(this.device_fault_event_total * 100 / this.shebei_total, 2)
        },
        device_event_pie() {
            const shebei_total = this.shebei_total
            const device_fault_event_total = this.device_fault_event_total
            const device_normal_total = shebei_total - device_fault_event_total
            const shebei_event_pie = [
                { value: device_normal_total, name:'装置正常' },
                { value: device_fault_event_total, name:'装置故障' }
            ]
            return shebei_event_pie
        },
        jczz() {
            return this.$Lang.xtsy.zzgzxq.jczz
        },
        zdsj() {
            return this.$Lang.xtsy.zzgzxq.zdsj
        },
        zzzt() {
            return this.$Lang.xtsy.zzgzxq.zzzt
        },
        ckxq() {
            return this.$Lang.xtsy.zzgzxq.ckxq
        },
        ck() {
            return this.$Lang.xtsy.zzgzxq.ck
        }
    },
    methods: {
        canshu(object) {
            if (object === undefined || object === null) return null
            const obj_id = object.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        ctime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const ctime = canshu.ctime
            if (ctime === undefined || ctime === null) {
                return ''
            }
            return ctime
        },
        rtime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) {
                return ''
            }
            return rtime
        },
        device_flag_object(object) {
            const data_flag = this.data_flag(object)
            return device_flag_object(data_flag)
        },
        handle_click_event(shebei) {
            const leixing_id = shebei.leixing_id
            if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) {
                this.$router.push('/condition')
                return
            }
            this.$router.push('/report')
            this.$nextTick(
                () => {
                    this.$store.commit('setSelectedShebeiObject', shebei)
                }
            )
        }
    }
}
</script>

<style lang='scss'>
.om_device_event_table {
    width: 100%;
    background: white;
    box-sizing: border-box;
    overflow: hidden;
    .el-table {
        width: 100%;
        height: 100%;
        font-size: 12px !important;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .el-table__header-wrapper {
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            flex: 0 auto;
            min-height: 0px;
        }
        .el-table__body-wrapper {
            width: 100%;
            box-sizing: border-box;
            overflow-y: auto;
            flex: 1;
        }
        td, th {
            padding: 0px;
        }
        .more {
            width: 50px;
            height: 100%;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px !important;
        }
    }
}
</style>
