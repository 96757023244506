<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="95%"
height="95%"
:title="title">
    <div class="gragh">
        <om_gispd_prp_view class="om_gispd_prp_view" ref="om_gispd_prp_view" :object="object"/>
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog/index.vue'
import om_gispd_prp_view from '../components/om_gispd_prp_view'
export default {
    name: 'om_real_prp_gragh_dialog',
    components:{
        om_dialog,
        om_gispd_prp_view
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const om_gispd_prp_view = ref(null)
        return {
            om_gispd_prp_view
        }
    },
    computed: {
        title() {
            const defaultName = this.ssfdlptTitle
            const object = this.object
            if (object === undefined || object === null) return defaultName
            const mingzi = object.mingzi
            if (mingzi === undefined || mingzi === null || mingzi === '') return defaultName
            const title = `${mingzi}${defaultName}`
            return title
        },
        ssfdlptTitle() {
            return this.$Lang.jcsj.sssj.ssfdlpt.title
        }
    },

    methods: {
        showDialog() {
            this.$refs.om_dialog.showDialog()
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        resize() {
            if (!this.om_gispd_prps_view) return
            this.om_gispd_prps_view.resize()
            if (!this.om_gispd_prp_view) return
            this.om_gispd_prp_view.resize()
        }
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .gragh {
        width: 100%;
        height: 100%;
        margin: 0px;
        box-sizing: border-box;
        overflow: hidden;
        padding: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .toolbar {
            width: 100%;
            min-height: 40px;
            flex: 0;
            overflow: hidden;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            background: lightskyblue;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .el-select {
                flex: 0;
                min-width: 600px;
                overflow: hidden;
                box-sizing: border-box;
                padding: 4px;
                margin: 0px;
            }
        }
        .prp {
            flex: 0;
            height: 300px;
            min-height: 300px;
            width: 100%;
            padding: 2px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .prps {
            flex: 1;
            width: 100%;
            padding: 0px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .item {
                padding: 4px;
                height: 100%;
                flex: 1;
                box-sizing: border-box;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
            .item2 {
                padding: 4px;
                height: 50%;
                min-width: 30%;
                flex: 1;
                box-sizing: border-box;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }
}
</style>