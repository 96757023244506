<template>
<div class="om_echarts_list_prps">
    <div class="toolbar">
        <el-slider :min="0" :max="max" :step="1" v-model="value" @input="handle_slider_input">
        </el-slider>
        <el-button-group>
            <el-button type="success" :disabled="button_forward_diasbled" @click="handle_button_forward_click">{{kj}}</el-button>
            <el-button type="success" :disabled="button_backward_diasbled" @click="handle_button_backward_click">{{zcbf}}</el-button>
            <el-button type="success" :disabled="button_play_diasbled" @click="handle_button_play_click">{{bf}}</el-button>
            <el-button type="success" :disabled="button_pause_diasbled" @click="handle_button_pause_click">{{zt}}</el-button>
            <el-button type="success" :disabled="button_stop_diasbled" @click="handle_button_stop_click">{{tz}}</el-button>
        </el-button-group>
    </div>
    <div class="content">
        <om_prps_view :prps="prps"/>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import { sleep } from '@/utils'
import om_prps_view from '@/components/om_prps_view'
export default {
    name: 'om_echarts_list_prps',
    components: {
        om_prps_view
    },
    props: {
        list_prps: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            prps:[],
            max: 0,
            value: 0,
            button_play_diasbled: true,
            button_pause_diasbled: false,
            button_stop_diasbled: false,
            button_forward_diasbled: false,
            button_backward_diasbled: false,
            isPause: false,
            isStop: false,
            isDrag: false,
            dragValue: 0,
            speed: 1
        }
    },
    watch: {
        'list_prps': {
            immidiate: true,
            deep: true,
            async handler() {
                await this.replay()
            }
        }
    },
    setup() {
        const ref_dialog = ref(null)
        return {
            ref_dialog
        }
    },
    computed: {
        kj() {
            return this.$Lang.jcsj.sssj.sspthf.kj
        },
        zcbf() {
            return this.$Lang.jcsj.sssj.sspthf.zcbf
        },
        bf() {
            return this.$Lang.jcsj.sssj.sspthf.bf
        },
        zt() {
            return this.$Lang.jcsj.sssj.sspthf.zt
        },
        tz() {
            return this.$Lang.jcsj.sssj.sspthf.tz
        }
    },
    methods: {
        handle_slider_input(value) {
            this.dragValue = value
            this.isDrag = true
        },
        handle_button_forward_click() {
            if (this.speed > 16) return
            this.speed = this.speed * 2.0
        },
        handle_button_backward_click() {
            this.speed = 1
        },
        async handle_button_play_click() {
            this.button_forward_diasbled = false
            this.button_backward_diasbled = false
            this.button_pause_diasbled = false
            this.button_stop_diasbled = false
            this.button_play_diasbled = true
            if (this.isPause) {
                if (this.isStop) {
                    this.isPause = false
                    this.isStop = false
                    await this.replay()
                    return
                }
                this.isPause = false
                return
            }
            this.isPause = false
            this.isStop = false
            await this.replay()
        },
        handle_button_pause_click() {
            this.isPause = true
            this.button_pause_diasbled = true
            this.button_play_diasbled = false
            this.button_forward_diasbled = true
            this.button_backward_diasbled = true
        },
        handle_button_stop_click() {
            this.isStop = true
            this.button_stop_diasbled = true
            this.button_pause_diasbled = true
            this.button_play_diasbled = false
            this.button_forward_diasbled = true
            this.button_backward_diasbled = true
        },
        async replay() {
            const list_prps = this.list_prps
            if (list_prps === undefined || list_prps === null) return
            if (!Array.isArray(list_prps)) return
            const length = list_prps.length
            this.max = length - 1
            if (length <= 0) return
            for (let i = 0; i < length; i++) {
                if (this.isDrag) {
                    this.value = this.dragValue
                    i = this.dragValue
                    this.isDrag = false
                    continue
                }
                this.value = i
                const prps = list_prps[i]
                await this.$nextTick()
                this.prps = prps
                while (this.isPause) {
                    if (this.isStop) {
                        break
                    }
                    await sleep(100)
                }
                if (this.isStop) {
                    await this.$nextTick()
                    this.value = 0
                    break
                }
                await sleep(500.0 / this.speed)
            }
            this.isPause = false
            this.isStop = false
            this.value = 0
            this.button_play_diasbled = false
            this.button_pause_diasbled = true
            this.button_stop_diasbled = true
            this.button_backward_diasbled = true
            this.button_forward_diasbled = true
        }
    },
    mounted() {
        this.isPause = false
        this.replay()
    }
}
</script>

<style lang="scss" scoped>
.om_echarts_list_prps {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .toolbar {
        width: 100%;
        min-height: 40px;
        flex: 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 2px solid #2376bf;
        padding-left: 4px;
        .el-slider {
            flex: 1;
            overflow: hidden;
            box-sizing: border-box;
            padding: 4px;
            margin: 4px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
        }
        .el-button-group {
            flex: 0;
            overflow: hidden;
            box-sizing: border-box;
            padding: 4px;
            margin: 4px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            min-width: 280px;
        }
    }
    .content {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px;
        padding-left: 0px;
        box-sizing: border-box;
        overflow: hidden;
    }
}
</style>