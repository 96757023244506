<template>
<div class="om_report_tree">
    <el-tree
    ref="om_report_tree"
    :props="defaultProps"
    :data="jianges"
    :current-node-key="current_node_key"
    node-key="obj_id"
    :default-expand-all="true"
    :expand-on-click-node="false"
    :highlight-current="true"
    @node-click="handle_node_click"
    :empty-text="zwsj">
        <template #default="{ data }">
            <div class="custom_tree_node" >
                <img class="device" :src="device_flag_object(data.obj_id).src"/>
                <img class="equip" :src="equip_flag_object(data.obj_id).src"/>
                <span class="mingzi">{{data.mingzi}}</span>
            </div>
        </template>
    </el-tree>
</div>
</template>

<script>
import { ref } from 'vue'
import { device_flag_object, equip_flag_object } from '@/utils'
export default {
    name:'om_report_tree',
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            defaultProps: {
                children: 'shebeis',
                label: 'mingzi'
            },
            curbdzId: null,
            curLeixingId: null,
            curShebeiId: null
        }
    },
    setup() {
        const om_report_tree = ref(null)
        return {
            om_report_tree
        }
    },
    computed: {
        current_node_key() {
            return this.object.obj_id
        },
        jianges() {
            const jianges = this.$store.getters.jianges
            const jianges_exclude_condition = jianges.filter(
                (jiange) => {
                    if (jiange.jg_id === 1031) {
                        return false
                    }
                    if (jiange.jg_id === 1034) {
                        return false
                    }
                    if (jiange.jg_id === 1035) {
                        return false
                    }
                    return true
                }
            )
            return jianges_exclude_condition
        },
        zwsj() {
            return this.$Lang.sjgl.zwsj
        }
    },
    watch: {
        'current_node_key': {
            immidiate: true,
            deep: true,
            handler() {
                const om_report_tree = this.om_report_tree
                if (om_report_tree === undefined || om_report_tree === null) {
                    return
                }
                const object = this.object
                if (object === undefined || object === null) return
                const obj_id = object.obj_id
                if (obj_id === undefined || obj_id === null) return
                om_report_tree.setCurrentKey(obj_id)
                // const newShebei = this.object.shebei_id
                // const newbdz = this.object.bdz_id
                // const newLeixing = this.object.leixing_id
                // if (newLeixing !== 18) return
                // this.$Message.warning(newLeixing + ',' + newShebei)
                // if (newShebei !== this.curShebeiId || newbdz !== this.curbdzId || newLeixing !== this.curLeixingId) {
                //     if (this.curLeixingId !== null && this.curShebeiId !== null) {
                //         this.resetChannel(this.curbdzId, this.curLeixingId, this.curShebeiId, 0)
                //     }
                //     this.curbdzId = newbdz
                //     this.curLeixingId = newLeixing
                //     this.curShebeiId = newShebei
                //     this.resetChannel(this.curbdzId, this.curLeixingId, this.curShebeiId, 1)
                // }
            }
        }
    },
    methods: {
        handle_node_click(object) {
            const leixing_id = object.leixing_id
            // this.$Message.warning(leixing_id + ',' + object.shebei_id)
            if (leixing_id === 1 || leixing_id === 24 || leixing_id === 2 || leixing_id === 21) {
                return
            }
            this.$store.commit('setSelectedShebeiObject', object)
            // if (leixing_id !== 18) return
            // const newShebei = object.shebei_id
            // const newbdz = object.bdz_id
            // const newLeixing = object.leixing_id
            // if (newShebei !== this.curShebeiId || newbdz !== this.curbdzId || newLeixing !== this.curLeixingId) {
            //     if (this.curLeixingId !== null && this.curShebeiId !== null) {
            //         this.resetChannel(this.curbdzId, this.curLeixingId, this.curShebeiId, 0)
            //     }
            //     this.curbdzId = newbdz
            //     this.curLeixingId = newLeixing
            //     this.curShebeiId = newShebei
            //     this.resetChannel(this.curbdzId, this.curLeixingId, this.curShebeiId, 1)
            // }
            return
        },
        sum_flag(obj_id) {
            const canshu = this.$store.getters.canshu(obj_id, 1)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const sum_flag = canshu.data_flag
            if (sum_flag === undefined || sum_flag === null) {
                return 0
            }
            return sum_flag
        },
        device_flag_object(obj_id) {
            const sum_flag = this.sum_flag(obj_id)
            const device_flag = device_flag_object(sum_flag)
            return device_flag
        },
        equip_flag_object(obj_id) {
            const sum_flag = this.sum_flag(obj_id)
            const equip_flag = equip_flag_object(sum_flag)
            return equip_flag
        },
        resetChannel(bdz, leixing, shebei, mode) {
            if (bdz === null || leixing === null || shebei === null) return false
            try {
                this.$ajax.reset_channel_mode(bdz, leixing, shebei, mode)
                return true
            } catch (error) {
                this.$Message.warning(error.toString())
            }
            return false
        }
    }
}
</script>

<style scoped lang="scss">
.om_report_tree {
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 14px;
    .custom_tree_node {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .device {
            flex: 0;
        }
        .equip {
            flex: 0;
        }
        .mingzi {
            flex: 1;
        }
    }
}
</style>