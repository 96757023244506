<template>
    <div stlye="display:none;">
    </div>
</template>

<script>
// import { randData } from '@/utils'
export default {
    name : 'om_init_data',
    data() {
        return {
            timer: null,
            period: 0
        }
    },
    beforeCreate() {
        //
        // setInterval(
        //     () => {
        //         const zxdata = randData()
        //         this.$store.commit('set_zx_data', zxdata)
        //     },
        //     1000
        // )
        // 更新数据
        this.period = 100
        const period = this.period
        this.timer = setInterval(
            () => {
                this.$store.commit('update_zx_data')
            },
            period
        )
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
