<template>
<div class="om_prps_view">
    <div class="toolbar">
        <el-tag type="success" :hit="true" effect="dark" size="default">{{sj}}:{{rtime}}</el-tag>
        <el-tag type="success" :hit="true" effect="dark" size="default">{{fz}}:{{max}}</el-tag>
        <el-tag type="success" :hit="true" effect="dark" size="default">{{jz}}:{{ave}}</el-tag>
        <el-tag type="success" :hit="true" effect="dark" size="default">{{pc}}:{{fdcount}}</el-tag>
        <el-tag type="success" :hit="true" effect="dark" size="default">{{gl}}:{{probability}}%</el-tag>
        <el-tag :type="warning" :hit="true" effect="dark" size="default">{{lx}}:{{fdtype}}</el-tag>
        <div class="el-tool">
            <el-button type="primary" style="float: right" @click="saveAsImage">save</el-button>
        </div>
    </div>
    <div class="prps">
       <om_echarts_prps ref="ref_echarts_prps" class="om_echart_view" :prps="rdata" />
    </div>
</div>
</template>

<script>
import { toDecimal } from '@/utils'
import { ref } from 'vue'
import om_echarts_prps from '@/components/om_echarts_prps'
export default {
    name:'om_prps_view',
    components: {
        om_echarts_prps
    },
    props: {
        prps: {
            type: Object,
            default: () => {
                return null
            }
        },
        prname: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const ref_echarts_prps = ref(null)
        return {
            ref_echarts_prps
        }
    },
    computed: {
        rdata() {
            const prps = this.prps
            if (prps === undefined || prps === null) return null
            const rdata = prps.rdata
            if (rdata === undefined || rdata === null) return null
            return rdata
        },
        rtime() {
            const prps = this.prps
            if (prps === undefined || prps === null) return ''
            const rtime = prps.rtime
            if (rtime === undefined || rtime === null) return ''
            return rtime.split(' ')[1]
        },
        max() {
            const prps = this.prps
            if (prps === undefined || prps === null) return ''
            const max = prps.max
            if (max === undefined || max === null) return ''
            return toDecimal(max, 1)
        },
        ave() {
            const prps = this.prps
            if (prps === undefined || prps === null) return ''
            const ave = prps.ave
            if (ave === undefined || ave === null) return ''
            return toDecimal(ave, 1)
        },
        fdcount() {
            const prps = this.prps
            if (prps === undefined || prps === null) return ''
            const fdcount = prps.fdcount
            if (fdcount === undefined || fdcount === null) return ''
            return toDecimal(fdcount, 0)
        },
        fdtype() {
            const prps = this.prps
            if (prps === undefined || prps === null) return this.zc
            const fdtype = prps.fdtype
            if (fdtype === undefined || fdtype === null) return this.zc
            const ifdtype = toDecimal(fdtype, 0)
            return this.yxbianma(18, 17, ifdtype)
        },
        probability() {
            const prps = this.prps
            if (prps === undefined || prps === null) return ''
            const probability = prps.probability
            if (probability === undefined || probability === null) return ''
            return toDecimal(probability, 1)
        },
        warning() {
            const prps = this.prps
            if (prps === undefined || prps === null) return 'success'
            const fdtype = prps.fdtype
            if (fdtype === 0) {
                return 'success'
            }
            return 'danger'
        },
        zc() {
            return this.$Lang.jcsj.sssj.ssptsj.zc
        },
        sj() {
            return this.$Lang.jcsj.sssj.ssptsj.sj
        },
        fz() {
            return this.$Lang.jcsj.sssj.ssptsj.fz
        },
        jz() {
            return this.$Lang.jcsj.sssj.ssptsj.jz
        },
        pc() {
            return this.$Lang.jcsj.sssj.ssptsj.pc
        },
        gl() {
            return this.$Lang.jcsj.sssj.ssptsj.gl
        },
        lx() {
            return this.$Lang.jcsj.sssj.ssptsj.lx
        }
    },
    methods: {
        yxbianma(leixing_id, canshu_id, data_int) {
            if (leixing_id === undefined || leixing_id === null) {
                return data_int
            }
            if (canshu_id === undefined || canshu_id === null) {
                return data_int
            }
            const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
            const bianma = this.$store.getters.getYxbianma(obj_id)
            if (bianma === undefined || bianma === null) {
                return data_int
            }
            return `${data_int}(${bianma.shuoming})`
        },
        resize() {
            const ref_echarts_prps = this.ref_echarts_prps
            if (ref_echarts_prps === undefined || ref_echarts_prps === null) return
            ref_echarts_prps.resizeChart()
            window.console.log('sss','sd')
        },
        saveAsImage() {
            const ref_echarts_prps = this.ref_echarts_prps
            if (ref_echarts_prps === undefined || ref_echarts_prps === null) return
            const curtm = new Date()
            const tm = curtm.format('yyyyMMddhhmm')
            let imagename = ''
            const prname = this.prname
            if (prname !== undefined && prname !== null) {
                imagename = prname
            }
            imagename = imagename + tm
            ref_echarts_prps.saveAsImage(imagename)
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.om_prps_view {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .toolbar {
        // flex: 0;
        min-height: 40px;
        width: 100%;
        margin: 0px;
        padding: 0px;
        border-bottom: 2px solid #2376bf;
        overflow: hidden;
        box-sizing: border-box;
        background: skyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .el-tag {
            padding: 4px;
            margin: 4px;
            min-width: 80px;
            font-weight: 200;
            color:whitesmoke;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .el-tool {
        flex: auto;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 3px;
    }
    .prps {
        flex: 1;
        width: 100%;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        box-sizing: border-box;
        .om_echart_view {
            width: 100%;
            height:100%;
        }
    }
}
</style>