<template>
<div class="om_echarts_time">
    <om_echarts class="om_echarts" :option="option" ref="ref_echarts"/>
</div>
</template>

<script>
import om_echarts from '@/components/om_echarts'
import { ref } from 'vue'
export default {
    name:'om_echarts_time',
    components: {
        om_echarts
    },
    props: {
        time: {
            default: () => {
                const date = new Date()
                return date
            }
        },
        title: {
            type: String,
            default: () => {
                return '当前时间'
            }
        }
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    computed: {
        option() {
            const systime = this.time
            if (systime === null) return null
            const second = systime.getSeconds()
            const minute = systime.getMinutes() + second / 60
            const hour = (systime.getHours() % 12) + minute / 60
            const option = {
                grid: {
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0
                },
                center: ['50%', '50%'],
                radius: '100%',
                series: [
                    {
                        center: ['50%', '50%'],
                        radius: '100%',
                        name: 'hour',
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        min: 0,
                        max: 12,
                        splitNumber: 12,
                        clockwise: true,
                        axisLine: {
                            lineStyle: {
                                width: 5,
                                color: [[1, 'rgba(0,0,0,0.7)']],
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                                shadowBlur: 0
                            }
                        },
                        splitLine: {
                            distance: 0,
                            lineStyle: {
                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                shadowBlur: 3,
                                shadowOffsetX: 1,
                                shadowOffsetY: 2
                            }
                        },
                        axisTick: {
                            distance: 0
                        },
                        axisLabel: {
                            show: false,
                            fontSize: 14,
                            distance: 10,
                            formatter: function(value) {
                                if (value === 0) {
                                    return ''
                                }
                                return value + ''
                            }
                        },
                        pointer: {
                            icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                            width: 4,
                            length: '55%',
                            offsetCenter: [0, '8%'],
                            itemStyle: {
                                color: '#C0911F',
                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                shadowBlur: 8,
                                shadowOffsetX: 2,
                                shadowOffsetY: 4
                            }
                        },
                        detail: {
                            show: false
                        },
                        title: {
                            fontSize: 12,
                            color: 'black',
                            border: 1,
                            fontWeight: 'bold',
                            offsetCenter: ['0%', '40%']
                        },
                        animation: hour !== 0,
                        data: [
                            {
                                name: this.title,
                                value: hour
                            }
                        ]
                    },
                    {
                        center: ['50%', '50%'],
                        radius: '100%',
                        name: 'minute',
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        min: 0,
                        max: 60,
                        clockwise: true,
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        pointer: {
                            icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                            width: 2,
                            length: '70%',
                            offsetCenter: [0, '8%'],
                            itemStyle: {
                                color: '#C0911F',
                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                shadowBlur: 8,
                                shadowOffsetX: 2,
                                shadowOffsetY: 4
                            }
                        },
                        detail: {
                            show: false
                        },
                        title: {
                            offsetCenter: ['0%', '-40%']
                        },
                        animation: minute !== 0,
                        data: [{ value: minute }]
                    },
                    {
                        center: ['50%', '50%'],
                        radius: '100%',
                        name: 'second',
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        min: 0,
                        max: 60,
                        animationEasingUpdate: 'bounceOut',
                        clockwise: true,
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        pointer: {
                            icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                            width: 2,
                            length: '85%',
                            offsetCenter: [0, '8%'],
                            itemStyle: {
                                color: '#C0911F',
                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                shadowBlur: 8,
                                shadowOffsetX: 2,
                                shadowOffsetY: 4
                            }
                        },
                        detail: {
                            show: false
                        },
                        title: {
                            offsetCenter: ['0%', '-40%']
                        },
                        animation: second !== 0,
                        data: [{ value: second }]
                    }
                ]
            }
            return option
        }
    },
    methods: {
        resize() {
            if (this.ref_echarts) {
                this.ref_echarts.resize()
            }
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.om_echarts_time {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .om_echarts {
        width: 100%;
        height: 100%;
    }
}
</style>
