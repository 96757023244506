<template>
<div class="om_svg_view">
    <om_scene ref="ref_scene" :detail="detail" @mousedown="onMouseDown"/>
</div>
</template>

<script>
import { ref } from 'vue'
import om_scene from '@/components/om_canvas/om_scene'
export default {
    name: 'om_svg_view',
    components: {
        om_scene
    },
    data() {
        return {
            detail: null
        }
    },
    setup() {
        const ref_scene = ref(null)
        return {
            ref_scene
        }
    },
    methods: {
        // -----------------------------------------------------------------
        async getImageSize(src) {
            return new Promise(
                (resolve, reject) => {
                    const image = new Image()
                    image.onload = () => {
                        const size = {
                            width: image.width,
                            height: image.height
                        }
                        resolve(size)
                    }
                    image.src = src
                }
            )
        },
        // -----------------------------------------------------------------
        async p_query_ms_svg() {
            const detail = {
                scene: {
                    background: '/images/svg/station.jpg',
                    viewBox : {
                        x: 0,
                        y: 0,
                        width: 1666,
                        height: 831
                    }
                },
                items: []
            }
            try {
                const resultset = await this.$ajax.p_query_ms_svg('{}')
                if (resultset[1] === undefined || resultset[1] === null) return
                if (resultset[1].length === 0) {
                    this.detail = detail
                    return
                }
                const record = resultset[1][0]
                if (record === undefined || record === null) {
                    this.detail = detail
                    return
                }
                detail.scene.viewBox.x = record.x
                detail.scene.viewBox.y = record.y
                detail.scene.viewBox.width = record.width
                detail.scene.viewBox.height = record.height
                detail.items = record.items
                const src = '/images/svg/station.jpg'
                const size = await this.getImageSize(src)
                if (size === undefined || size === null) return
                detail.scene.background = `${src}?${Math.random()}`
                const viewBox = {
                    x: 0,
                    y: 0,
                    width: size.width,
                    height: size.height
                }
                detail.scene.viewBox = viewBox
                this.$nextTick(
                    () => {
                        this.detail = detail
                    }
                )
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_ms_svg异常,错误原因:${error}`
                })
            }
        },
        object(obj_id) {
            if (obj_id === undefined || obj_id === null) return null
            const map_object = this.$store.state.station.map_object
            if (map_object === undefined || map_object === null) return []
            const object = map_object[obj_id]
            return object
        },
        onMouseDown(e) {
            const target = e.target
            if (target === undefined || target === null) return
            const item = target.item
            if (item === undefined || item === null) return
            const obj_id = item.obj_id
            if (obj_id === undefined || obj_id === null || obj_id === '') return
            const object = this.object(obj_id)
            if (object === undefined || object === null) return
            this.$router.push('/report')
            this.$nextTick(
                () => {
                    this.$store.commit('setSelectedShebeiObject', object)
                }
            )
        }
    },
    async mounted() {
        await this.p_query_ms_svg()
    }
}
</script>

<style lang="scss" scoped>
.om_svg_view {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>