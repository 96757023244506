<template>
<div class="om_footer_view">
    <div class="status">
        <div class="status_mingzi">{{zzzt}}</div>
        <div class="items">
            <div class="item">
                <div class="label">{{zzzc}}</div>
                <div class="value"><img src="/images/device_flag/normal.gif" alt=""></div>
            </div>
            <div class="item">
                <div class="label">{{zzgz}}</div>
                <div class="value"><img src="/images/device_flag/unnormal.gif" alt=""></div>
            </div>
        </div>
    </div>
    <div class="status">
        <div class="status_mingzi">{{sjzt}}</div>
        <div class="items">
            <div class="item">
                <div class="label">{{sjzc}}</div>
                <div class="value"><img src="/images/equip_flag/normal.gif" alt=""></div>
            </div>
            <div class="item">
                <div class="label">{{sjbj}}</div>
                <div class="value"><img src="/images/equip_flag/alarm.gif" alt=""></div>
            </div>
            <div class="item">
                <div class="label">{{sjyj}}</div>
                <div class="value"><img src="/images/equip_flag/atten.gif" alt=""></div>
            </div>
        </div>
    </div>
    <div class="right">
        {{bqsy}}
    </div>
</div>
</template>

<script>
export default {
    computed: {
        zzzt() {
            return this.$Lang.footer.zzzt
        },
        zzzc() {
            return this.$Lang.footer.zzzc
        },
        zzgz() {
            return this.$Lang.footer.zzgz
        },
        sjzt() {
            return this.$Lang.footer.sjzt
        },
        sjzc() {
            return this.$Lang.footer.sjzc
        },
        sjbj() {
            return this.$Lang.footer.sjbj
        },
        sjyj() {
            return this.$Lang.footer.sjyj
        },
        bqsy() {
            return this.$Lang.footer.bqsy
        }
    }
}
</script>

<style scoped lang="scss">
.om_footer_view {
    user-select: none;
    width:100%;
    height: 24px;
    overflow: hidden;
    font-family: monospace;
    font-size: 14px;
    font-weight: bold;
    color: lightgray;
    background: #2376b7;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    .status {
        height: 100%;
        flex: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .status_mingzi {
            height: 100%;
            flex: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px 0 8px;
        }
        .items {
            height: 100%;
            flex: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            .item {
                padding: 0 4px 0 4px;
                height: 100%;
                flex: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                .label {
                    height: 100%;
                    flex: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .value {
                    height: 100%;
                    flex: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
    .right {
        height: 100%;
        flex: 0 auto;
        min-width: 400px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 8px;
    }
}
</style>