<template>
    <om_dialog
    ref="om_dialog"
    class="om_dialog"
    width="65%"
    height="85%"
    :title="setrate"
    >
        <div class="om_dialog__header">
            <el-select
            placeholder="请选择对应类型"
            v-model="leixing_selected_"
            @change="select_leixing"
            style="margin: 8px"
            >
                <el-option
                v-for="(leixing, index) in leixings"
                :label="leixing.mingzi"
                :value="leixing.leixing_id"
                :key="index"
                >
                </el-option>
            </el-select>
            <el-select
            placeholder="请选择对应参数"
            v-model="canshu_selected"
            @change="select_canshu"
            style="margin: 8px"
            >
                <el-option
                v-for="(canshu, index) in canshu_option"
                :label="canshu.shuoming"
                :value="canshu.obj_id"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
        <div class="om_dialog__body">
            <el-table
            :showHeader="true"
            :data="rate_data"
            border
            stripe
            >
                <el-table-column
                :label="typename"
                align="center"
                prop="mingzi"
                width="150"
                show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                :label="paramname"
                align="center"
                prop="shuoming"
                width="150"
                show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="yxsx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.youxiaoup" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="yxxx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.youxiaodn" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="`${bjsx}(%)`"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.alarmup" size="small" :precision="2" :step="1" :min="-100" :max="100" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="`${bjxx}(%)`"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.alarmdn" size="small" :precision="2" :step="1" :min="-100" :max="0" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="`${yjsx}(%)`"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.attenup" size="small" :precision="2" :step="1" :min="-100" :max="100" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="`${yjxx}(%)`"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.attendn" size="small" :precision="2" :step="1" :min="-100" :max="0" />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template #footer>
            <div  style="padding: 8px; width: 100%; height: 100%; display: flex; flex-direction:row; justify-content: flex-end; align-items: center;">
                <el-button @click="handle_cancel_click">{{qx}}</el-button>
                <el-button type="primary" @click="handle_commit_click">{{qd}}</el-button>
            </div>
        </template>
    </om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
export default {
    name: 'om_rate_dialog',
    components: {
        om_dialog
    },
    setup() {
        const om_dialog = ref(null)
        const ref_upload = ref(null)
        return {
            om_dialog,
            ref_upload
        }
    },
    props: {
        leixing_selected: {
            type: Number,
            default: () => {
                return 0
            }
        }
    },
    data() {
        return {
            leixing_selected_: '',
            canshu_selected: '',
            canshu_option: [],
            rate_data: []
        }
    },
    computed: {
        quanxian() {
            return sessionStorage.getItem('quanxian')
        },
        leixings() {
            const leixings = this.$store.getters.leixings
            const leixings_exclude_condition = leixings.filter(
                (leixing) => {
                    if (leixing.leixing_id === 31) {
                        return false
                    }
                    if (leixing.leixing_id === 34) {
                        return false
                    }
                    if (leixing.leixing_id === 35) {
                        return false
                    }
                    return true
                }
            )
            return leixings_exclude_condition
        },
        qx() {
            return this.$Lang.header.xgmm.qx
        },
        qd() {
            return this.$Lang.header.dtbj.scbjt.qd
        },
        yxsx() {
            return this.$Lang.jcsj.sssj.sjxq.yxsx
        },
        yxxx() {
            return this.$Lang.jcsj.sssj.sjxq.yxxx
        },
        bjsx() {
            return this.$Lang.jcsj.sssj.sjxq.bjsx
        },
        bjxx() {
            return this.$Lang.jcsj.sssj.sjxq.bjxx
        },
        yjsx() {
            return this.$Lang.jcsj.sssj.sjxq.yjsx
        },
        yjxx() {
            return this.$Lang.jcsj.sssj.sjxq.yjxx
        },
        setrate() {
            return this.$Lang.jcsj.sssj.sjxq.setrate
        },
        allcanshu() {
            return this.$Lang.jcsj.sssj.sjxq.allcanshu
        },
        typename() {
            return this.$Lang.jcsj.sssj.sjxq.typename
        },
        paramname() {
            return this.$Lang.jcsj.sssj.sjxq.paramname
        }
    },
    methods: {
        showDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            om_dialog.showDialog()
            this.$nextTick(
                () => {
                    const leixing_selected = this.leixing_selected
                    this.leixing_selected_ = JSON.parse(JSON.stringify(leixing_selected))
                    this.select_leixing(this.leixing_selected)
                }
            )
        },
        select_leixing(leixing_id) {
            const canshu_all = {
                obj_id: '',
                shuoming: this.allcanshu
            }
            this.canshu_option = []
            this.leixings.forEach(
                (value, index) => {
                    if (value.leixing_id === leixing_id) {
                        // 获取类型下的所有参数
                        const obj_id = this.leixings[index].shebeis[0].obj_id
                        const canshus = this.$store.getters.canshus(obj_id)
                        for (const index in canshus) {
                            const canshu = JSON.parse(JSON.stringify(canshus[index]))
                            if (canshu.canshu_id === 1) {
                                continue
                            }
                            if (canshu.datatype === 3) {
                                continue
                            }
                            if (canshu.datatype === 4) {
                                continue
                            }
                            if (canshu.datatype === 5) {
                                continue
                            }
                            if (value.leixing_id === 18 && canshu.canshu_id === 17 ) {
                                continue
                            }
                            if (value.leixing_id === 38 && canshu.canshu_id === 17) {
                                continue
                            }
                            canshu.obj_id = canshu.obj_id.split('_')[3]
                            this.canshu_option.push(canshu)
                        }
                        this.canshu_option.splice( 0, 0, canshu_all)
                        this.canshu_selected = JSON.parse(JSON.stringify(this.canshu_option[0].obj_id))
                        this.handle_query_yuzhi()
                    }
                }
            )
        },
        select_canshu() {
            this.handle_query_yuzhi()
        },
        async handle_query_yuzhi() {
            try {
                let canshu_set = ''
                const canshu_ids = []
                const leixing_id = this.leixing_selected_
                const canshu_selected = this.canshu_selected
                const canshu_option = this.canshu_option
                if (canshu_selected !== '') {
                    canshu_set = canshu_selected
                } else {
                    canshu_option.forEach(
                        (canshu) => {
                            if (canshu.obj_id !== '') {
                                canshu_ids.push(canshu.canshu_id)
                            }
                        }
                    )
                    canshu_set = canshu_ids.join(',')
                }
                const paramset = {
                    leixing_id: leixing_id,
                    canshu_id: canshu_set
                }
                const resultset = await this.$ajax.p_query_yuzhi_rate(paramset)
                const code = resultset[0][0].code
                const length = resultset[1].length
                if (code !== 0) {
                    this.$Message.warning(`p_update_yuzhi_rate,错误原因：${resultset[0][0].text}`)
                }
                if (length !== 0) {
                    this.rate_data = resultset[1]
                }
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_update_yuzhi_rate,错误原因：${error}`
                })
            }
        },
        handle_commit_click() {
            try {
                const om_dialog = this.om_dialog
                const rate_data = this.rate_data
                if (om_dialog === undefined || om_dialog === null) return
                this.dialogVisible = false
                rate_data.forEach(
                    async(item) => {
                        const paramset = {
                            leixing_id: item.leixing_id,
                            canshu_id: item.canshu_id,
                            attenup: item.attenup,
                            attendn: item.attendn,
                            alarmup: item.alarmup,
                            alarmdn: item.alarmdn,
                            youxiaoup: item.youxiaoup,
                            youxiaodn: item.youxiaodn
                        }
                        const resultset = await this.$ajax.p_update_yuzhi_rate(paramset)
                        const code = resultset[0][0].code
                        if (code !== 0) {
                            this.$Message.warning(`p_update_yuzhi_rate,错误原因：${resultset[0][0].text}`)
                        }
                    }
                )
                this.$Message.success('阈值比率修改成功！')
                om_dialog.closeDialog()
                this.$router.go(0)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_update_yuzhi_rate,错误原因：${error}`
                })
            }
        },
        handle_cancel_click() {
            this.om_dialog.closeDialog()
        }
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .om_dialog__header {
        width: 100%;
        height: 100%;
        max-height: 40px;
        position: relative;
        box-sizing: border-box;
    }
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .om_dialog__footer {
        box-sizing: border-box;
        padding: 4px;
    }
}
</style>
