<template>
<div class="om_history_file_data">
    <om_echarts ref="ref_echarts" class="om_echart_view" :option="option" :merge="false"></om_echarts>
</div>
</template>
<script>
import X2js from 'x2js'
import om_echarts from '@/components/om_echarts'
import { ref } from 'vue'
export default {
    name:'om_history_file_data',
    components: {
        om_echarts
    },
    data() {
        return {
            option: null
        }
    },
    props: {
        selectedFileName: {
            type: String,
            default: () => {
                return null
            }
        },
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    watch: {
        selectedFileName: {
            immidiate: true,
            deep: false,
            async handler() {
                this.clear()
                await this.reload()
            }
        }
    },
    computed: {
        Axdl() {
            return this.$Lang.dxpt.Axdl
        },
        Bxdl() {
            return this.$Lang.dxpt.Bxdl
        },
        Cxdl() {
            return this.$Lang.dxpt.Cxdl
        },
        xw() {
            return this.$Lang.jcsj.sssj.ssptsj.xw
        },
        zq() {
            return this.$Lang.jcsj.sssj.ssptsj.zq
        },
        fu() {
            return this.$Lang.jcsj.sssj.ssptsj.fu
        },
        zqdw() {
            return this.$Lang.jcsj.sssj.ssptsj.zqdw
        }
    },
    methods:{
        async reload() {
            try {
                if (this.object === undefined || this.object === null) return
                if (this.object.bdz_id === undefined || this.object.bdz_id === null) return
                if (this.object.leixing_id === undefined || this.object.leixing_id === null) return
                if (this.object.leixing_id !== 4 && this.object.leixing_id !== 10 && this.object.leixing_id !== 18 && this.object.leixing_id !== 38) return
                if (this.object.shebei_id === undefined || this.object.shebei_id === null) return
                if (this.selectedFileName === undefined || this.selectedFileName === null || this.selectedFileName === '') return
                const bdz_id = this.object.bdz_id
                const leixing_id = this.object.leixing_id
                const shebei_id = this.object.shebei_id
                const selectedFileName = this.selectedFileName
                const paramset = {
                    bdz_id: bdz_id,
                    leixing_id: leixing_id,
                    shebei_id: shebei_id,
                    filename: selectedFileName
                }
                const resultset = await this.$ajax.getFileData(paramset)
                if (resultset[1] === undefined || resultset[1] === null) return
                if (resultset[1].length === 0) return
                this.showChart(resultset[1][0])
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`getFileData异常,错误原因:${error}`
                })
            }
        },
        clear() {
            this.option = null
            return
        },
        getLangTitle(name) {
            if (name === 'DischargeType') {
                return this.$Lang.ptlb.fdlx
            }
            if (name === 'Normal') {
                return this.$Lang.ptlb.normal
            }
            if (name === 'Peak') {
                return this.$Lang.ptlb.fdPeak
            }
            if (name === 'Noise') {
                return this.$Lang.ptlb.fdNoise
            }
            if (name === 'Surface') {
                return this.$Lang.ptlb.fdSurface
            }
            if (name === 'LooseShield') {
                return this.$Lang.ptlb.fdLooseShield
            }
            if (name === 'AirGap') {
                return this.$Lang.ptlb.fdAirGap
            }
            if (name === 'MetalParticle') {
                return this.$Lang.ptlb.fdMetalParticle
            }
            return name
        },
        getPercentValue(value) {
            if (value === null) {
                return '(0%)'
            }
            if (value.indexOf('.') > 0) {
                return '(' + (parseFloat(value) / 100.0).toFixed(2) + '%)'
            }
            return '(' + (parseFloat(value) / 100.0) + '%)'
        },
        showChart(fileData) {
            if (this.object === undefined || this.object === null) return
            if (this.object.leixing_id === undefined || this.object.leixing_id === null) return
            const leixing_id = this.object.leixing_id
            if (leixing_id === 4) {
                this.showYspChart(fileData)
                return
            }
            if (leixing_id === 10) {
                this.showDlqChart(fileData)
                return
            }
            if (leixing_id === 18) {
                this.showGispdChart(fileData)
                return
            }
            if (leixing_id === 38) {
                this.showTgpdChart(fileData)
                return
            }
        },
        showYspChart(fileData) {
            const x2js = new X2js()
            let json_file_data_xml = {}
            try {
                json_file_data_xml = x2js.xml2js(fileData.xmldata)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`xml2js 异常, 错误原因:${error}`
                })
            }
            if (!json_file_data_xml) return
            const xaxis_times = []
            const yaxis_values = []
            const list_linedata = fileData.datdata.split('\r\n')
            for (let i = 0; i < list_linedata.length; i++) {
                const linedata = list_linedata[i]
                const list_data = linedata.split(',')
                xaxis_times.push(parseFloat(list_data[0]))
                yaxis_values.push(parseFloat(list_data[1]))
            }
            const list_point = []
            const list_peakinfo_xval = json_file_data_xml.Root.PeakInfo.XVal
            for (let i = 0; i < list_peakinfo_xval.length; i++) {
                const peakinfo_xval = list_peakinfo_xval[i]
                const point = {
                    xAxis: peakinfo_xval._value,
                    name:peakinfo_xval._desc,
                    yAxis:yaxis_values[peakinfo_xval._value],
                    value:yaxis_values[peakinfo_xval._value],
                    label: {
                        formatter:'{b}:{c}'
                    }
                }
                list_point.push(point)
            }
            const option = {
                backgroundColor: '#b0d5df',
                grid: {
                    left:20,
                    right:20,
                    top:20,
                    bottom:20,
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis'
                },
                toolbox: {
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xaxis_times
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLabel: {
                        formatter: '{value}' + json_file_data_xml.Root.CurveInfo.YVal._unit
                    }
                },
                series: [
                    {
                        name:  json_file_data_xml.Root.CurveInfo._desc,
                        type: 'line',
                        symbol: 'none',
                        markPoint: {
                            data: list_point
                        },
                        data: yaxis_values
                    }
                ]
            }
            this.option = option
        },
        showDlqChart(fileData) {
            const x2js = new X2js()
            let json_file_data_xml = {}
            try {
                json_file_data_xml = x2js.xml2js(fileData.xmldata)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`xml2js 异常, 错误原因:${error}`
                })
            }
            if (!json_file_data_xml) return
            const list_phase_data = fileData.datdata.split('---\r\n')
            let xaxis_times = []
            const xaxis_A_times = []
            const xaxis_B_times = []
            const xaxis_C_times = []
            //
            const A_list_linedata = list_phase_data[0].split('\r\n')
            const A_yaxis_values = []
            for (let i = 0; i < A_list_linedata.length; i++) {
                const linedata = A_list_linedata[i]
                const list_data = linedata.split(',')
                xaxis_A_times.push(parseFloat(list_data[0]))
                A_yaxis_values.push(parseFloat(list_data[1]))
            }
            xaxis_A_times.pop()
            //
            const B_list_linedata = list_phase_data[1].split('\r\n')
            const B_yaxis_values = []
            for (let i = 0; i < B_list_linedata.length; i++) {
                const linedata = B_list_linedata[i]
                const list_data = linedata.split(',')
                xaxis_B_times.push(parseFloat(list_data[0]))
                B_yaxis_values.push(parseFloat(list_data[1]))
            }
            xaxis_B_times.pop()
            //
            const C_list_linedata = list_phase_data[2].split('\r\n')
            const C_yaxis_values = []
            for (let i = 0; i < C_list_linedata.length; i++) {
                const linedata = C_list_linedata[i]
                const list_data = linedata.split(',')
                xaxis_C_times.push(parseFloat(list_data[0]))
                C_yaxis_values.push(parseFloat(list_data[1]))
            }
            xaxis_C_times.pop()
            if (xaxis_A_times.length) {
                xaxis_times = xaxis_A_times
            } else if (xaxis_B_times.length) {
                xaxis_times = xaxis_B_times
            } else if (xaxis_C_times.length) {
                xaxis_times = xaxis_C_times
            }
            //
            const option = {
                backgroundColor:'#b0d5df',
                title: {
                    text: this.getLangTitle(json_file_data_xml.Root.CurveInfo._name),
                    top: 20,
                    left: 'center',
                    textAlign: 'center'
                },
                grid: {
                    left:20,
                    right:20,
                    top:80,
                    bottom:20,
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        animation: false,
                        label: {
                            backgroundColor: '#ccc',
                            borderColor: '#aaa',
                            borderWidth: 1,
                            shadowBlur: 0,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            color: '#222'
                        }
                    }
                },
                legend: {
                    data: [this.Axdl, this.Bxdl, this.Cxdl],
                    orient:'vertical',
                    left:20
                },
                toolbox: {
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    max:'dataMax',
                    min:'dataMin',
                    data: xaxis_times
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLabel: {
                        formatter: '{value}' + json_file_data_xml.Root.CurveInfo.YVal._unit
                    }
                },
                dataZoom: [{
                    type: 'inside',
                    start: 0,
                    end: 100
                }],
                series: [
                    {
                        name: this.Axdl,
                        type: 'line',
                        symbol: 'none',
                        color:'#e6a23c',
                        data: A_yaxis_values
                    },
                    {
                        name: this.Bxdl,
                        type: 'line',
                        symbol: 'none',
                        color:'green',
                        data: B_yaxis_values
                    },
                    {
                        name: this.Cxdl,
                        type: 'line',
                        symbol: 'none',
                        color:'red',
                        data: C_yaxis_values
                    }
                ]

            }
            this.option = option
        },
        showGispdChart(fileData) {
            const x2js = new X2js()
            let json_file_data_xml = {}
            try {
                json_file_data_xml = x2js.xml2js(fileData.xmldata)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`xml2js 异常, 错误原因:${error}`
                })
            }
            if (!json_file_data_xml) return
            const prps = []
            const list_linedata = fileData.datdata.split('\r\n')
            for (var i = 0; i < list_linedata.length; i++) {
                const linedata = list_linedata[i]
                const list_data = linedata.split(',')
                prps.push(
                    [
                        parseFloat(list_data[0]),
                        parseFloat(list_data[1]),
                        parseFloat(list_data[2])
                    ]
                )
            }
            const sin = []
            for (let x = 0; x < 360; x += 1.8) {
                const value = -40 + 40 * Math.sin(x / 180 * Math.PI)
                sin.push([x, 50, value])
            }
            const prpd = []
            const noise = []
            for (let i = 0; i < prps.length; i++) {
                const value = prps[i]
                noise.push([value[0], value[1], -80 - value[2]])
                prpd.push([value[0], 50, value[2]])
            }
            const option = {
                backgroundColor:'#b0d5df',
                title: {
                    show: true,
                    left: 'left',
                    text: `${this.getLangTitle(json_file_data_xml.root.PDResult._desc)}:${this.getLangTitle(json_file_data_xml.root.PDResult._name)}${this.getPercentValue(json_file_data_xml.root.PDResult._confidenceLevel)}`
                },
                legend: {
                    show: true,
                    data: [
                        {
                            name: 'PRPS',
                            icon: 'pin'
                        },
                        {
                            name: 'PRPD',
                            icon: 'pin'
                        }
                    ]
                },
                visualMap: [
                    {
                        seriesIndex: 1,
                        show: false,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${-param - 80}dBm`
                        },
                        // inRange:
                        // {
                        //     color: [
                        //         '#a50026',
                        //         '#d73027',
                        //         '#f46d43',
                        //         '#fdae61',
                        //         '#fee090',
                        //         '#ffffbf',
                        //         '#e0f3f8',
                        //         '#abd9e9',
                        //         '#74add1',
                        //         '#4575b4',
                        //         '#313695'
                        //     ]
                        // }
                        type: 'piecewise',
                        itemGap: 4,
                        pieces: [
                            { min: -80, max: -69, label: '[-80,-70]', color: 'red' },
                            { min: -69, max: -60, label: '[-70,-60]', color: 'red' },
                            { min: -60, max: -50, label: '[-60,-50]', color: 'red' },
                            { min: -50, max: -40, label: '[-50,-40]', color: 'red' },
                            { min: -40, max: -30, label: '[-40,-30]', color: 'yellow' },
                            { min: -30, max: -20, label: '[-30,-20]', color: 'yellow' },
                            { min: -20, max: -11, label: '[-20,-10]', color: 'yellow' },
                            { min: -11, max: 0, label: '[-10, 0]', color: 'blue' }
                        ]
                    },
                    {
                        seriesIndex: 2,
                        show: true,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${param}dBm`
                        },
                        // inRange: {
                        //     color: [
                        //         '#313695',
                        //         '#4575b4',
                        //         '#74add1',
                        //         '#abd9e9',
                        //         '#e0f3f8',
                        //         '#ffffbf',
                        //         '#fee090',
                        //         '#fdae61',
                        //         '#f46d43',
                        //         '#d73027',
                        //         '#a50026',
                        //         '#a50026'
                        //     ]
                        // }
                        type: 'piecewise',
                        itemGap: 4,
                        pieces: [
                            { min: -80, max: -69, label: '[-80,-70]', color: 'blue' },
                            { min: -69, max: -60, label: '[-70,-60]', color: 'yellow' },
                            { min: -60, max: -50, label: '[-60,-50]', color: 'yellow' },
                            { min: -50, max: -40, label: '[-50,-40]', color: 'yellow' },
                            { min: -40, max: -30, label: '[-40,-30]', color: 'red' },
                            { min: -30, max: -20, label: '[-30,-20]', color: 'red' },
                            { min: -20, max: -10, label: '[-20,-10]', color: 'red' },
                            { min: -10, max: 0, label: '[-10, 0]', color: 'red' }
                        ]
                    },
                    {
                        seriesIndex: 3,
                        show: false,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${param}dBm`
                        },
                        // inRange: {
                        //     color: [
                        //         '#313695',
                        //         '#4575b4',
                        //         '#74add1',
                        //         '#abd9e9',
                        //         '#e0f3f8',
                        //         '#ffffbf',
                        //         '#fee090',
                        //         '#fdae61',
                        //         '#f46d43',
                        //         '#d73027',
                        //         '#a50026',
                        //         '#a50026'
                        //     ]
                        // }
                        type: 'piecewise',
                        itemGap: 4,
                        pieces: [
                            { min: -80, max: -70, label: '[-80,-70]', color: 'red' },
                            { min: -70, max: -60, label: '[-70,-60]', color: 'red' },
                            { min: -60, max: -50, label: '[-60,-50]', color: 'red' },
                            { min: -50, max: -40, label: '[-50,-40]', color: 'red' },
                            { min: -40, max: -30, label: '[-40,-30]', color: 'red' },
                            { min: -30, max: -20, label: '[-30,-20]', color: 'red' },
                            { min: -20, max: -10, label: '[-20,-10]', color: 'red' },
                            { min: -10, max: 0, label: '[-10, 0]', color: 'red' }
                        ]
                    }
                ],
                toolbox: { // 可视化的工具箱
                    show: true,
                    feature: {
                        dataView: { // 数据视图
                            show: true
                        },
                        restore: { // 重置
                            show: true
                        },
                        dataZoom: { // 数据缩放视图
                            show: true
                        },
                        saveAsImage: { // 保存图片
                            show: true
                        }
                    }
                },
                tooltip: {
                    formatter:(param) => {
                        return `${this.xw}: ${param.value[0]}°<br>${this.zq}: ${param.value[1]}<br>${this.fu}:${-param.value[2] - 80}dBm`
                    }
                },
                xAxis3D: {
                    type: 'value',
                    name: this.xw,
                    min:0,
                    interval:45,
                    max:360,
                    axisLabel: { formatter: '{value}°' },
                    splitArea: {
                        show: true
                    }
                },
                yAxis3D: {
                    type: 'value',
                    name: this.zq,
                    min:0,
                    max:50,
                    interval: 5,
                    axisLabel: { formatter: '{value}' },
                    splitArea: {
                        show: true
                    }
                },
                zAxis3D: {
                    type: 'value',
                    name: this.fu,
                    interval:10,
                    min: -80,
                    max: 0,
                    axisLabel: { formatter: '{value}dBm' }
                },
                grid3D: {
                    left:0,
                    right:0,
                    top:20,
                    bottom:0,
                    viewControl: {
                        autoRotate: true,
                        projection: 'orthographic'
                    },
                    light: {
                        main: {
                            intensity: 1.2,
                            shadow: true
                        },
                        ambient: {
                            intensity: 0.3
                        }
                    }
                },
                series: [
                    {
                        stack: 'total',
                        name:'noise',
                        type: 'bar3D',
                        barSize: [0.25, 0.5],
                        shading: 'lambert',
                        itemStyle: {
                            opacity: 0
                        },
                        data: prps
                    },
                    {
                        stack: 'total',
                        name:'PRPS',
                        type: 'bar3D',
                        barSize: [0.25, 0.5],
                        shading: 'lambert',
                        data: noise
                    },
                    {
                        name:'PRPD',
                        type: 'scatter3D',
                        symbolSize: 4,
                        shading: 'lambert',
                        data: prpd
                    },
                    {
                        name:'sin',
                        type: 'line3D',
                        symbolSize: 4,
                        shading: 'lambert',
                        data: sin
                    }
                ]
            }
            this.option = option
        },
        showTgpdChart(fileData) {
            const x2js = new X2js()
            let json_file_data_xml = {}
            try {
                json_file_data_xml = x2js.xml2js(fileData.xmldata)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`xml2js 异常, 错误原因:${error}`
                })
            }
            if (!json_file_data_xml) return
            const prps = []
            const list_linedata = fileData.datdata.split('\r\n')
            for (var i = 0; i < list_linedata.length; i++) {
                const linedata = list_linedata[i]
                const list_data = linedata.split(',')
                prps.push(
                    [
                        parseFloat(list_data[0]),
                        parseFloat(list_data[1]),
                        parseFloat(list_data[2])
                    ]
                )
            }
            const sin = []
            for (let x = 0; x < 360; x += 1.8) {
                const value = -40 + 40 * Math.sin(x / 180 * Math.PI)
                sin.push([x, 50, value])
            }
            const prpd = []
            const noise = []
            for (let i = 0; i < prps.length; i++) {
                const value = prps[i]
                noise.push([value[0], value[1], -80 - value[2]])
                prpd.push([value[0], 50, value[2]])
            }
            const option = {
                backgroundColor:'#b0d5df',
                title: {
                    show: true,
                    left: 'left',
                    text: `${this.getLangTitle(json_file_data_xml.root.PDResult._desc)}:${this.getLangTitle(json_file_data_xml.root.PDResult._name)}${this.getPercentValue(json_file_data_xml.root.PDResult._confidenceLevel)}`
                },
                legend: {
                    show: true,
                    data: [
                        {
                            name: 'PRPS',
                            icon: 'pin'
                        },
                        {
                            name: 'PRPD',
                            icon: 'pin'
                        }
                    ]
                },
                visualMap: [
                    {
                        seriesIndex: 1,
                        show: false,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${-param - 80}dBm`
                        },
                        // inRange:
                        // {
                        //     color: [
                        //         '#a50026',
                        //         '#d73027',
                        //         '#f46d43',
                        //         '#fdae61',
                        //         '#fee090',
                        //         '#ffffbf',
                        //         '#e0f3f8',
                        //         '#abd9e9',
                        //         '#74add1',
                        //         '#4575b4',
                        //         '#313695'
                        //     ]
                        // }
                        type: 'piecewise',
                        itemGap: 4,
                        pieces: [
                            { min: -80, max: -69, label: '[-80,-70]', color: 'red' },
                            { min: -69, max: -60, label: '[-70,-60]', color: 'red' },
                            { min: -60, max: -50, label: '[-60,-50]', color: 'red' },
                            { min: -50, max: -40, label: '[-50,-40]', color: 'red' },
                            { min: -40, max: -30, label: '[-40,-30]', color: 'yellow' },
                            { min: -30, max: -20, label: '[-30,-20]', color: 'yellow' },
                            { min: -20, max: -11, label: '[-20,-10]', color: 'yellow' },
                            { min: -11, max: 0, label: '[-10, 0]', color: 'blue' }
                        ]
                    },
                    {
                        seriesIndex: 2,
                        show: true,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${param}dBm`
                        },
                        // inRange: {
                        //     color: [
                        //         '#313695',
                        //         '#4575b4',
                        //         '#74add1',
                        //         '#abd9e9',
                        //         '#e0f3f8',
                        //         '#ffffbf',
                        //         '#fee090',
                        //         '#fdae61',
                        //         '#f46d43',
                        //         '#d73027',
                        //         '#a50026',
                        //         '#a50026'
                        //     ]
                        // }
                        type: 'piecewise',
                        itemGap: 4,
                        pieces: [
                            { min: -80, max: -69, label: '[-80,-70]', color: 'blue' },
                            { min: -69, max: -60, label: '[-70,-60]', color: 'yellow' },
                            { min: -60, max: -50, label: '[-60,-50]', color: 'yellow' },
                            { min: -50, max: -40, label: '[-50,-40]', color: 'yellow' },
                            { min: -40, max: -30, label: '[-40,-30]', color: 'red' },
                            { min: -30, max: -20, label: '[-30,-20]', color: 'red' },
                            { min: -20, max: -10, label: '[-20,-10]', color: 'red' },
                            { min: -10, max: 0, label: '[-10, 0]', color: 'red' }
                        ]
                    },
                    {
                        seriesIndex: 3,
                        show: false,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${param}dBm`
                        },
                        // inRange: {
                        //     color: [
                        //         '#313695',
                        //         '#4575b4',
                        //         '#74add1',
                        //         '#abd9e9',
                        //         '#e0f3f8',
                        //         '#ffffbf',
                        //         '#fee090',
                        //         '#fdae61',
                        //         '#f46d43',
                        //         '#d73027',
                        //         '#a50026',
                        //         '#a50026'
                        //     ]
                        // }
                        type: 'piecewise',
                        itemGap: 4,
                        pieces: [
                            { min: -80, max: -70, label: '[-80,-70]', color: 'red' },
                            { min: -70, max: -60, label: '[-70,-60]', color: 'red' },
                            { min: -60, max: -50, label: '[-60,-50]', color: 'red' },
                            { min: -50, max: -40, label: '[-50,-40]', color: 'red' },
                            { min: -40, max: -30, label: '[-40,-30]', color: 'red' },
                            { min: -30, max: -20, label: '[-30,-20]', color: 'red' },
                            { min: -20, max: -10, label: '[-20,-10]', color: 'red' },
                            { min: -10, max: 0, label: '[-10, 0]', color: 'red' }
                        ]
                    }
                ],
                toolbox: { // 可视化的工具箱
                    show: true,
                    feature: {
                        dataView: { // 数据视图
                            show: true
                        },
                        restore: { // 重置
                            show: true
                        },
                        dataZoom: { // 数据缩放视图
                            show: true
                        },
                        saveAsImage: { // 保存图片
                            show: true
                        }
                    }
                },
                tooltip: {
                    formatter:(param) => {
                        return `${this.xw}: ${param.value[0]}°<br>${this.zq}: ${param.value[1]}<br>${this.fu}:${-param.value[2] - 80}dBm`
                    }
                },
                xAxis3D: {
                    type: 'value',
                    name: this.xw,
                    min:0,
                    interval:45,
                    max:360,
                    axisLabel: { formatter: '{value}°' },
                    splitArea: {
                        show: true
                    }
                },
                yAxis3D: {
                    type: 'value',
                    name: this.zqdw,
                    min:0,
                    max:50,
                    interval: 5,
                    axisLabel: { formatter: '{value}' },
                    splitArea: {
                        show: true
                    }
                },
                zAxis3D: {
                    type: 'value',
                    name: this.fu,
                    interval:10,
                    min: -80,
                    max: 0,
                    axisLabel: { formatter: '{value}dBm' }
                },
                grid3D: {
                    left:0,
                    right:0,
                    top:20,
                    bottom:0,
                    viewControl: {
                        autoRotate: true,
                        projection: 'orthographic'
                    },
                    light: {
                        main: {
                            intensity: 1.2,
                            shadow: true
                        },
                        ambient: {
                            intensity: 0.3
                        }
                    }
                },
                series: [
                    {
                        stack: 'total',
                        name:'noise',
                        type: 'bar3D',
                        barSize: [0.25, 0.5],
                        shading: 'lambert',
                        itemStyle: {
                            opacity: 0
                        },
                        data: prps
                    },
                    {
                        stack: 'total',
                        name:'PRPS',
                        type: 'bar3D',
                        barSize: [0.25, 0.5],
                        shading: 'lambert',
                        data: noise
                    },
                    {
                        name:'PRPD',
                        type: 'scatter3D',
                        symbolSize: 4,
                        shading: 'lambert',
                        data: prpd
                    },
                    {
                        name:'sin',
                        type: 'line3D',
                        symbolSize: 4,
                        shading: 'lambert',
                        data: sin
                    }
                ]
            }
            this.option = option
        },
        resize() {
            const ref_echarts = this.ref_echarts
            if (ref_echarts === undefined || ref_echarts === null) return
            ref_echarts.resize()
        }
    },
    async mounted() {
        await this.reload()
    }
}
</script>

<style lang="scss">
.om_history_file_data {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    box-sizing: border-box;
    background: #b0d5df;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .om_echart_view {
        width: 100%;
        height:100%;
    }
}
</style>