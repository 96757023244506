<template>
    <om_dialog
    ref="om_dialog"
    class="om_dialog"
    width="300px"
    height="200px"
    :title="initjdz"
    >
        <div class="om_dialog__body">
            <div>
                <span style="color: black">{{starttm}}：</span>
                <el-date-picker v-model="timeFrom" type="date" placeholder="Pick a day" />
            </div>
            <div>
                <span style="color: black">{{endtm}}：</span>
                <el-date-picker v-model="timeTo" type="date" placeholder="Pick a day" />
            </div>
        </div>
        <template v-slot:footer>
            <div class="om_dialog__footer">
                <el-button @click="handle_cancel_click">{{qx}}</el-button>
                <el-button type="primary" @click="handle_commit_click">{{qd}}</el-button>
            </div>
        </template>
    </om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
import moment from 'moment'
export default {
    name: 'om_jizhun_dialog',
    components: {
        om_dialog
    },
    setup() {
        const om_dialog = ref(null)
        const ref_upload = ref(null)
        return {
            om_dialog,
            ref_upload
        }
    },
    data() {
        return {
            timeFrom: '',
            timeTo: '',
            select_data_: [],
            time: []
        }
    },
    computed: {
        qx() {
            return this.$Lang.header.xgmm.qx
        },
        qd() {
            return this.$Lang.header.dtbj.scbjt.qd
        },
        starttm() {
            return this.$Lang.utils.time.starttm
        },
        endtm() {
            return this.$Lang.utils.time.endtm
        },
        initjdz() {
            return this.$Lang.jcsj.sssj.sjxq.initjdz
        }
    },
    methods: {
        showDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            om_dialog.showDialog()
            this.$nextTick(
                () => {
                    this.timeFrom = this.time[0]
                    this.timeTo = this.time[1]
                }
            )
        },
        async handle_commit_click() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            this.dialogVisible = false
            try {
                const paramset = {
                    timeFrom: moment(this.timeFrom).format('YYYY-MM-DD 00:00:00'),
                    timeTo: moment(this.timeTo).format('YYYY-MM-DD 23:59:59')
                }
                const resultset = await this.$ajax.p_init_jizhun_data(paramset)
                const code = resultset[0][0].code
                if (code !== 0) {
                    this.$Message.warning(`p_init_jizhun_data,错误原因：${resultset[0][0].text}`)
                }
                this.$Message.success(`基准值初始化成功`)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_init_jizhun_data,错误原因：${error}`
                })
            }
            this.$emit('commit')
            this.om_dialog.closeDialog()
        },
        handle_cancel_click() {
            this.om_dialog.closeDialog()
        }
    },
    mounted() {
        this.time = [
            moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().format('YYYY-MM-DD HH:mm:ss')
        ]
    }
}
</script>

<style lang="scss">
.om_dialog {
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .om_dialog__footer {
        box-sizing: border-box;
        padding: 4px;
    }
}
</style>