export default {
    scene: {
        viewBox : {
            x: 0,
            y: 0,
            width: 1666,
            height: 831
        }
    },
    items:[
        {
            x: 100,
            y: 100,
            width: 100,
            height: 32,
            mingzi: 'L1#C15FGBFHGGJGDFDFDTERRTEDFDF',
            obj_id: null
        },
        {
            x: 200,
            y: 100,
            width: 100,
            height: 32,
            obj_id: null
        },
        {
            x: 300,
            y: 100,
            width: 100,
            height: 32,
            obj_id: null
        },
        {
            x: 400,
            y: 100,
            width: 100,
            height: 32,
            obj_id: null
        }
    ]
}