<template>
<om_dialog
ref="ref_dialog"
width="800px"
height="740px"
:title="ptwjhf">
    <div class="om_prps_replay_dialog">
        <om_echarts_list_prps :list_prps="list_prps"/>
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
import om_echarts_list_prps from '@/components/om_echarts_list_prps'
export default {
    name: 'om_prps_replay_dialog',
    components: {
        om_dialog,
        om_echarts_list_prps
    },
    props: {
        list_prps: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    setup() {
        const ref_dialog = ref(null)
        return {
            ref_dialog
        }
    },
    computed: {
        ptwjhf() {
            return this.$Lang.jcsj.sssj.sspthf.ptwjhf
        }
    },
    methods: {
        showDialog() {
            this.ref_dialog.showDialog()
        },
        closeDialog() {
            this.ref_dialog.closeDialog()
        }
    }
}
</script>

<style lang="scss" scoped>
.om_prps_replay_dialog {
    width: 100%;
    height: 100%;
    z-Index: 800;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>