<template>
    <div class="om_verify_code">
        <div class="verify_code_generate" @click.prevent.stop="regenerate">
            {{ verify_code_generate }}
        </div>
        <div class="om_seprator"></div>
        <div class="verify_code_input">
            <input
            class="input"
            height="100%"
            ref="verify_code_input"
            type="text"
            v-model="verify_code_input"
            spellcheck="false"
            tabindex="3"
            />
        </div>
        <div class="om_seprator"></div>
        <div class="verify_code_change" @click.prevent.stop="regenerate">
            {{hyg}}
        </div>
    </div>
</template>

<script>
const rander_range = [
    /* '0', */ /* '1', */ '2', '3', '4', '5', '6', '7', '8', '9',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', /* 'i', */ 'j', 'k', /* 'l', */ 'm', 'n', /* 'o', */ 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
]
export default {
    name: 'om_verify_code',
    data() {
        return {
            verify_code_generate: '',
            verify_code_input: ''
        }
    },
    computed: {
        hyg() {
            return this.$Lang.login.hyg
        }
    },
    methods: {
        verify() {
            if (this.verify_code_generate.toLowerCase() === this.verify_code_input.toLowerCase()) {
                return true
            }
            this.regenerate()
            return false
        },
        regenerate() {
            const letters = []
            const letters_length = 4
            const length = rander_range.length
            for (let i = 0; i < letters_length; i++) {
                const index = Math.floor(length * Math.random())
                let letter = rander_range[index]
                let letter_case = false
                if (Math.random() > 0.5) {
                    letter_case = true
                }
                if (letter_case) {
                    letter = letter.toUpperCase()
                } else {
                    letter = letter.toLowerCase()
                }
                letters.push(letter)
            }
            const verify_code_generate = letters.join('')
            this.verify_code_generate = verify_code_generate
        }
    },
    mounted() {
        this.regenerate()
    }
}
</script>

<style scoped lang="scss">
.om_verify_code {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    user-select: none;
    .verify_code_generate {
        flex: 0;
        background-color: aqua;
        color: rgb(241, 13, 43);
        width: 80px;
        min-width: 80px;
        height: 100%;
        line-height: 30px;
        font: bold;
        font-size: 20px;
        font-weight: bold;
        cursor: default;
        user-select: none;
        text-align: center;
        letter-spacing: 4px;
    }
    .om_seprator {
        flex: 0;
        width: 8px;
        min-width: 8px;
    }
    .verify_code_input {
        flex: 1;
        min-width: 60px;
        display: block;
        box-sizing: border-box;
        .input {
            width: 100%;
            height: 30px;
            display: block;
            background: white;
            text-align: center;
            letter-spacing: 4px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid skyblue;
            color: #606266;
            font-size: inherit;
            outline: 0;
            margin: 0px;
            padding: 0px;
        }
    }
    .om_seprator {
        flex: 0;
        width: 8px;
        min-width: 8px;
    }
    .verify_code_change {
        display: inline-block;
        flex: 0;
        color: goldenrod;
        width: 60px;
        min-width: 60px;
        font-weight: bold;
        height: 100%;
        line-height: 28px;
        text-align: center;
        font: bold;
        font-size: 14px;
        cursor: default;
        user-select: none;
        box-sizing: border-box;
        border: 1px solid skyblue;
        background: whitesmoke;
    }
}
</style>
