<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="300px"
height="280px"
:title="xtsz">
    <div class="om_dialog__body">
        <div class="display: flex; flex-direction: column;">
            <el-checkbox v-model="isShowHomeBottom" label="是否显示首页底部区域" size="large" />
            <el-checkbox v-model="isShowHomeRight" label="是否显示首页右侧区域" size="large" />
            <el-checkbox v-model="isShowDzdt" label="是否显示电子地图" size="large" />
        </div>
    </div>
    <template v-slot:footer>
        <div class="om_dialog__footer">
            <el-button type="primary" @click="closeDialog()">{{qx}}</el-button>
            <el-button type="primary" @click="handle_ok_button_click()">{{qd}}</el-button>
        </div>
    </template>
</om_dialog>
</template>

<script>
import om_dialog from '@/components/om_dialog/index.vue'
export default {
    name: 'om_web_config_dialog',
    components:{
        om_dialog
    },
    data: function() {
        return {
            isShowHomeBottom: true,
            isShowHomeRight: true,
            isShowDzdt: true
        }
    },
    computed: {
        xtsz() {
            return this.$Lang.header.xtsz.name
        },
        qx() {
            return this.$Lang.header.xgmm.qx
        },
        qd() {
            return this.$Lang.header.xgmm.qd
        }
    },
    methods: {
        showDialog() {
            this.$refs.om_dialog.showDialog()
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        async handle_ok_button_click() {
            const paramset = [
                { id: 1, useflag: this.isShowHomeBottom ? 1 : 0 },
                { id: 2, useflag: this.isShowHomeRight ? 1 : 0 },
                { id: 3, useflag: this.isShowDzdt ? 1 : 0 }
            ]
            let dataset = null
            try {
                dataset = await this.$ajax.p_update_ms_config(paramset)
                const error = dataset[0][0]
                if (error.code !== 0) {
                    this.$message.warning(`p_update_ms_config request failed, error code:${error.code}, error text:${error.text}`)
                    return false
                }
            } catch (e) {
                this.$message.warning(`p_update_ms_config request failed, error message:${e}!`)
                return false
            }
            this.closeDialog()
            this.$router.go(0)
            return true
        }
    },
    mounted() {
        const settings = this.$store.state.station.settings
        if (settings === undefined || settings === null) return
        this.isShowHomeBottom = settings[0].useflag > 0
        this.isShowHomeRight = settings[1].useflag > 0
        this.isShowDzdt = settings[2].useflag > 0
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .om_dialog__body {
        box-sizing: border-box;
        padding: 4px;
    }
    .om_dialog__footer {
        box-sizing: border-box;
        padding: 4px;
    }
}
</style>