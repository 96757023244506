<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="400px"
height="280px"
:title="zjzqsz">
    <div class="om_dialog__body">
        <div class="leixing_range">
            <el-select
            :placeholder="qxzzjzqsz"
            v-model="selected_leixing_id"
            >
                <el-option
                v-for="(leixing, index) in timeleixings"
                :label="leixing.leixing_name"
                :value="leixing.leixing_id"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
    </div>
    <template v-slot:footer>
        <div class="om_dialog__footer">
            <el-button type="primary" @click="closeDialog()">{{qx}}</el-button>
            <el-button type="primary" @click="handle_commit_click()">{{qd}}</el-button>
        </div>
    </template>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
export default {
    name: 'om_calibrate_setup_dialog',
    components: {
        om_dialog
    },
    setup() {
        const om_dialog = ref(null)
        return {
            om_dialog
        }
    },
    data() {
        return {
            selected_leixing_id: 1,
            zjcycle: {},
            objects: [],
            selected_obj_id: null,
            imageUrl: null
        }
    },
    computed: {
        timeleixings() {
            const minute = this.$Lang.header.zjgn.minute
            const items = []
            const tm = {
                leixing_id: 1,
                leixing_name: '1 ' + minute
            }
            items.push(tm)
            const tm2 = {
                leixing_id: 5,
                leixing_name: '5 ' + minute
            }
            items.push(tm2)
            const tm3 = {
                leixing_id: 10,
                leixing_name: '10 ' + minute
            }
            items.push(tm3)
            return items
        },
        zjzqsz() {
            return this.$Lang.header.zjgn.zjzqsz
        },
        qxzzjzqsz() {
            return this.$Lang.header.zjgn.qxzzjzqsz
        },
        qx() {
            return this.$Lang.header.zjgn.qx
        },
        qd() {
            return this.$Lang.header.zjgn.qd
        }
    },
    methods: {
        showDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            om_dialog.showDialog()
        },
        closeDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            om_dialog.closeDialog()
        },
        handle_commit_click() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            const zj_cycle = this.selected_leixing_id
            this.handle_update_equip_cycle(zj_cycle)
            this.$nextTick(
                () => {
                    om_dialog.closeDialog()
                }
            )
        },
        async handle_query_equip_cycle() {
            try {
                const resultset = await this.$ajax.p_query_zjgn_cycle({ leixing_code: 'zj_equip_cycle' })
                const code = resultset[0][0].code
                const length = resultset[1].length
                if (code !== 0) {
                    this.$Message.warning(`p_query_zjgn_cycle,错误原因：${resultset[0][0].text}`)
                }
                if (length !== 0) {
                    this.zjcycle = resultset[1]
                    const zjcyclevalue = this.zjcycle.leixing_value
                    const leixings = this.timeleixings
                    for (var i = 0; i < leixings.length; i++) {
                        if (leixings[i].leixing_id === zjcyclevalue) {
                            this.selected_leixing_id = zjcyclevalue.leixing_value
                        }
                    }
                }
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_zjgn_cycle,错误原因：${error}`
                })
            }
        },
        async handle_update_equip_cycle(cycle) {
            try {
                const resultset = await this.$ajax.p_update_zjgn_cycle({ leixing_code: 'zj_equip_cycle', leixing_value: cycle })
                const code = resultset[0][0].code
                if (code !== 0) {
                    this.$Message.warning(`p_update_zjgn_cycle,错误原因：${resultset[0][0].text}`)
                }
                const cycledata = { zj_cycle: cycle }
                this.$emit('commit', cycledata)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_update_zjgn_cycle,错误原因：${error}`
                })
            }
        },
        reset_equip_cycle(cycle) {
            if (cycle !== undefined && cycle !== null) {
                this.selected_leixing_id = cycle
            }
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
.om_dialog {
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .leixing_range {
            flex: 0;
            min-height: 40px;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .object_range {
            flex: 0;
            min-height: 40px;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .om_dialog__footer {
        box-sizing: border-box;
        padding: 4px;
    }
}
</style>