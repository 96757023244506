<template>
    <div stlye="display:none;">
    </div>
</template>

<script>
export default {
    name : 'om_websocket',
    props: {
        wsuri: {
            type: String,
            default: `ws://${window.location.hostname}:8081/`
        }
    },
    data() {
        return {
            wsClient: null,
            timeout: 30 * 1000,
            timeoutObj: null,
            serverTimeoutObj:null,
            socketdata:{}
        }
    },
    created() {
        this.connect()
    },
    destroyed() {
        this.disconnect()
    },
    methods: {
        click_event() {
            this.wsClient.send('')
        },
        resetHeartbeat() {
            clearTimeout(this.timeoutObj)
            delete this.timeoutObj
            this.timeoutObj = null
            clearTimeout(this.serverTimeoutObj)
            delete this.serverTimeoutObj
            this.serverTimeoutObj = null
        },
        startHeartbeat() {
            this.resetHeartbeat()
            this.timeoutObj = setTimeout(
                () => {
                    // 这里发送一个心跳，后端收到后，返回一个心跳消息，
                    this.wsClient.send('{}')
                    // 等待有没有数据返回，如果超过一定时间还没重置，说明后端主动断开了
                    this.serverTimeoutObj = setTimeout(
                        () => {
                            this.wsClient.close()
                        },
                        this.timeout
                    )
                },
                this.timeout
            )
        },
        // 初始化websocket,并连接websocket服务端
        connect() {
            this.wsClient = new WebSocket(this.wsuri)
            this.wsClient.onmessage = this.websocketonmessage
            this.wsClient.onopen = this.websocketonopen
            this.wsClient.onerror = this.websocketonerror
            this.wsClient.onclose = this.websocketclose
        },
        reconnect() {
            this.disconnect()
            this.connect()
        },
        disconnect() {
            if (!this.wsClient) return
            this.resetHeartbeat()
            this.wsClient.close()
            delete this.wsClient
            this.wsClient = null
        },
        websocketonopen() {
        },
        // 数据接收
        websocketonmessage({ data }) {
            const msg = JSON.parse(data)
            this.$store.commit('set_zx_data', msg)
            // const ind = data.indexOf('zx_data_calc') >= 0
            // if (ind) {
            //     this.$Message.warning('sdfsdf,data:' + data)
            // }
        },
        // 连接建立失败重连
        websocketonerror() {
            this.wsClient.close()
        },
        // 关闭
        websocketclose() {
            this.reconnect()
        }
    }
}
</script>
