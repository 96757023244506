<template>
<div class="om_ysp_sbz_view">
    <div class="sbz">
        <h2>{{dqsbz}}[{{sbz[0]}}:{{sbz[1]}}:{{sbz[2]}}]</h2>
    </div>
    <div class="content">
        <div class="title">
            <ul>
                <div v-html="sbzsm">
                </div>
                <h2>{{dqsbz}}[{{sbz[0]}}:{{sbz[1]}}:{{sbz[2]}}]</h2>
            </ul>
        </div>
        <div class="sbz_container">
            <div class="sbz_container_left">
                <table cellpadding="0px" cellspacing="0px" >
                    <tr height="25px">
                        <td width="100px" align="center" bgcolor="#35b5df">C2H2/C2H4</td>
                        <td width="100px" align="center" bgcolor="#c0c0c0" >CH4/H2</td>
                        <td width="auto" align="center" bgcolor="#c0c0c0" colspan="4"></td>
                    </tr>
                    <tr height="50px">
                        <td width="100px" align="center" bgcolor="#35b5df" rowspan="3" >2</td>
                        <td width="100px" align="center" bgcolor="#c0c0c0">2</td>
                        <td width="100px" align="center" bgcolor="#000080">
                            <div v-if="state.color_220" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#000080">
                            <div v-if="state.color_221" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#000080">
                            <div v-if="state.color_222" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td rowspan="9" bgcolor="#c0c0c0"></td>
                    </tr>
                    <tr height="50px">
                        <td width="100px" align="center" bgcolor="#c0c0c0">1</td>
                        <td width="100px" align="center" bgcolor="blue">
                            <div v-if="state.color_210" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="blue">
                            <div v-if="state.color_211" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="blue">
                            <div v-if="state.color_212" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td width="100px" align="center" bgcolor="#c0c0c0">0</td>
                        <td width="100px" align="center" bgcolor="blue">
                            <div v-if="state.color_200" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="blue">
                            <div v-if="state.color_201" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="blue">
                            <div v-if="state.color_202" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td rowspan="3" width="100px" align="center" bgcolor="#35b5df">1</td>
                        <td width="100px" align="center" bgcolor="#c0c0c0">2</td>
                        <td width="100px" align="center" bgcolor="35b5df">
                            <div v-if="state.color_120" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="35b5df">
                            <div v-if="state.color_121" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="35b5df">
                            <div v-if="state.color_122" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td width="100px" align="center" bgcolor="#c0c0c0">1</td>
                        <td width="100px" align="center" bgcolor="#00ffff">
                            <div v-if="state.color_110" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#00ffff">
                            <div v-if="state.color_111" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#00ffff">
                            <div v-if="state.color_112" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td width="100px" align="center" bgcolor="#c0c0c0">0</td>
                        <td width="100px" align="center" bgcolor="#00ffff">
                            <div v-if="state.color_100" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#00ffff">
                            <div v-if="state.color_101" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#00ffff">
                            <div v-if="state.color_102" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td rowspan="3" width="100px" align="center" bgcolor="#35b5df">0</td>
                        <td width="100px" align="center" bgcolor="#c0c0c0">2</td>
                        <td width="100px" align="center" bgcolor="#dfbeb5">
                            <div v-if="state.color_020" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#df7560">
                            <div v-if="state.color_021" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#df510b">
                            <div v-if="state.color_022" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td width="100px" align="center" bgcolor="#c0c0c0">1</td>
                        <td width="100px" align="center" bgcolor="#b5dfdf">
                            <div v-if="state.color_010" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;">
                            </div>
                        </td>
                        <td width="100px" align="center" bgcolor="#dfdfdf">
                            {{dzd}}
                            <div v-if="state.color_011" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;"></div>
                        </td>
                        <td width="100px" align="center" bgcolor="#df510b">
                            <div v-if="state.color_012" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;"></div>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td width="100px" align="center" bgcolor="#c0c0c0">0</td>
                        <td width="100px" align="center" bgcolor="#b5e3b5">
                            <div v-if="state.color_000" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;"></div>
                        </td>
                        <td width="100px" align="center" bgcolor="#dfbeb5">
                            <div v-if="state.color_001" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;"></div>
                        </td>
                        <td width="100px" align="center" bgcolor="#df510b">
                            <div v-if="state.color_002" style="width:10px; height: 10px; background-color: red;border:2px solid white; border-radius: 5px;"></div>
                        </td>
                    </tr>
                    <tr height="auto">
                        <td colspan="2" bgcolor="#c0c0c0"></td>
                        <td width="100px" align="center" bgcolor="#c0c0c0">0</td>
                        <td width="100px" align="center" bgcolor="#c0c0c0">1</td>
                        <td width="100px" align="center" bgcolor="#c0c0c0">2</td>
                        <td width="100px" align="center" bgcolor="#c0c0c0">C2H4/C2H6</td>
                    </tr>
                </table>
            </div>
            <div class="sbz_container_right">
                <table>
                    <tr>
                        <td colspan="2" align="center" bgcolor="#c0c0c0">{{spsb}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#000080"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{grdnfd}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#0000ff"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{dnfd}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#35b5df"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{grdhfd}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#00ffff"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{dhfd}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#b5dfdf"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{jf}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#df5108"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{gwgr}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#df7560"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{zwgr}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#dfbeb5"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{dwgr}}</td>
                    </tr>
                    <tr>
                        <td width="10px" bgcolor="#b5e3b5"></td>
                        <td width="auto" align="center" bgcolor="#c0c0c0">{{zczt}}</td>
                    </tr>
                </table>
            </div>
            <!-- <div style="width:200px; height:100%; overflow: auto;">
            三比值法的应用原则是：
            （1）只有根据气体各组分含量的注意值或气体增长率的注意值有理由判断设备可能存在故障时，气体比值才是最有效的，并应予以计算。对气体含量正常，且无增长趋势的设备，比值没有意义。
            （2）假如气体的比值与以前的不同，可能有新的故障重叠或正常老化上。
            为了得到仅仅相对于新故障的气体比值，要从最后一次分析结果中减去上一次的分析数据，
            并重新计算比值（尤其在CO和CO2含量较大的情况下）。
            在进行比较时，要注意在相同的负荷和温度等情况下在相同的位置取样。
            （3）由于溶解气体分析本身存在的试验误差，导致气体比值也存在某些不确定性。
            </div> -->
        </div>
    </div>
</div>
</template>

<script>
import { toDecimal } from '@/utils'
export default {
    name:'om_ysp_sbz_view',
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            state: {
                color_220: true,
                color_221: true,
                color_222: true,
                color_210: true,
                color_211: true,
                color_212: true,
                color_200: true,
                color_201: true,
                color_202: true,
                //
                color_120: true,
                color_121: true,
                color_122: true,
                color_110: true,
                color_111: true,
                color_112: true,
                color_100: true,
                color_101: true,
                color_102: true,
                //
                color_020: true,
                color_021: true,
                color_022: true,
                color_010: true,
                color_011: true,
                color_012: true,
                color_000: true,
                color_001: true,
                color_002: true
            }
        }
    },
    computed: {
        H2() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 2)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        CH4() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 5)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        C2H2() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 6)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        C2H4() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 7)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        C2H6() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 8)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        canshus() {
            const map_canshu = this.$store.state.station.map_canshu
            if (map_canshu === undefined || map_canshu === null) return []
            const canshus = []
            const obj_canshus = this.object.canshus
            for (const key in obj_canshus) {
                const canshu = map_canshu[key]
                if (canshu.canshu_id === 1) {
                    continue
                }
                if (canshu.datatype === 4) {
                    continue
                }
                if (canshu.datatype === 5) {
                    continue
                }
                canshus.push(canshu)
            }
            return canshus
        },
        sbz() {
            const H2 = this.H2
            if (H2 === null) {
                return null
            }
            const CH4 = this.CH4
            if (CH4 === null) {
                return null
            }
            const C2H2 = this.C2H2
            if (C2H2 === null) {
                return null
            }
            const C2H4 = this.C2H4
            if (C2H4 === null) {
                return null
            }
            const C2H6 = this.C2H6
            if (C2H6 === null) {
                return null
            }
            const fH2 = toDecimal(H2.data, 1)
            const fCH4 = toDecimal(CH4.data)
            const fC2H2 = toDecimal(C2H2.data)
            const fC2H4 = toDecimal(C2H4.data)
            const fC2H6 = toDecimal(C2H6.data)
            //
            try {
                let i = fC2H2 / fC2H4
                if (i >= 3) {
                    i = 2
                } else if (i >= 1) {
                    i = 1
                } else if (i >= 0.1) {
                    i = 1
                } else {
                    i = 0
                }
                let j = fCH4 / fH2
                if (j >= 3) {
                    j = 2
                } else if (j >= 1) {
                    j = 2
                } else if (j >= 0.1) {
                    j = 0
                } else {
                    j = 1
                }
                let k = fC2H4 / fC2H6
                if (k >= 3) {
                    k = 2
                } else if (k >= 1) {
                    k = 1
                } else if (k >= 0.1) {
                    k = 0
                } else {
                    k = 0
                }
                return [i, j, k]
            } catch (error) {
                // console.log('sbz:', error)
            }
            return null
        },
        dqsbz() {
            return this.$Lang.jcsj.sssj.sbzfx.dqsbz
        },
        dzd() {
            return this.$Lang.jcsj.sssj.sbzfx.dzd
        },
        sbzsm() {
            return this.$Lang.jcsj.sssj.sbzfx.sbzsm
        },
        spsb() {
            return this.$Lang.jcsj.sssj.sbzfx.spsb
        },
        grdnfd() {
            return this.$Lang.jcsj.sssj.sbzfx.grdnfd
        },
        dnfd() {
            return this.$Lang.jcsj.sssj.sbzfx.dnfd
        },
        grdhfd() {
            return this.$Lang.jcsj.sssj.sbzfx.grdhfd
        },
        dhfd() {
            return this.$Lang.jcsj.sssj.sbzfx.dhfd
        },
        jf() {
            return this.$Lang.jcsj.sssj.sbzfx.jf
        },
        gwgr() {
            return this.$Lang.jcsj.sssj.sbzfx.gwgr
        },
        zwgr() {
            return this.$Lang.jcsj.sssj.sbzfx.zwgr
        },
        dwgr() {
            return this.$Lang.jcsj.sssj.sbzfx.dwgr
        },
        zczt() {
            return this.$Lang.jcsj.sssj.sbzfx.zczt
        }
    },
    watch: {
        'sbz': {
            imidiate: true,
            deep: true,
            handler() {
                this.setState()
            }
        }
    },
    methods: {
        setState() {
            const state = this.state
            if (state === undefined || state === null) return
            for (const key in state) {
                state[key] = false
            }
            const sbz = this.sbz
            if (sbz === null) {
                return
            }
            //
            const i = sbz[0]
            const j = sbz[1]
            const k = sbz[2]
            const currentKey = `color_${i}${j}${k}`
            state[currentKey] = true
        }
    },
    mounted() {
        this.setState()
    }
}
</script>

<style lang="scss">
.om_ysp_sbz_view {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sbz {
        flex: 0;
        min-height: 100px;
        width: 1000px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items:center;
        justify-content: center;
        border: 2px solid gray;
        padding: 20px
    }
    .content {
        flex: 0;
        margin: 0px;
        padding: 0px;
        width: 100%;
        min-height: 500px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content:center;
        .title {
            flex: 0;
            height: 500px;
            width: 400px;
            min-width: 400px;
            overflow: hidden;
            box-sizing: border-box;
            display: flex;
            align-items:center;
            justify-content: center;
            padding: 20px;
            border: 2px solid gray;
        }
        .sbz_container {
            flex: 0;
            height: 100%;
            overflow: hidden;
            box-sizing: border-box;
            align-items:center;
            justify-content:center;
            overflow: hidden;
            margin: 0px;
            padding: 0px;
            width: 600px;
            height: 500px;
            min-width: 600px;
            min-height: 500px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: nowrap;
            .sbz_container_left {
                padding: 0px;
                margin: 0px;
                width: 500px;
                height: 500px;
                min-width: 500px;
                min-height: 500px;
                table {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    border: 1px solid #607560;
                    font-size: 12px;
                    border-collapse: collapse;
                }
                table td {
                    border: 1px solid #607560;
                }
            }
            .sbz_container_right {
                padding: 0px;
                margin: 0px;
                width: 100px;
                height: 500px;
                min-width: 100px;
                min-height: 500px;
                table {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #607560;
                    font-size: 12px;
                    border-collapse: collapse;
                }
                table td {
                    border: 1px solid #607560;
                }
            }
        }
    }
}
</style>