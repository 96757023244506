<template>
<div class="om_typical_file">
    <div class="gragh">
        <div class="sidebar" >
            <om_container :title="ptwjlb">
                <om_typical_file_list ref="ref_typical_file_list" :leixing_id="leixing_id" @current-change="handle_current_change"/>
            </om_container>
        </div>
        <div class="content">
            <om_container :title="ptzs">
                <om_typical_file_data ref="ref_typical_file_data" :leixing_id="leixing_id" :selectedFileName="selectedFileName"/>
            </om_container>
        </div>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import om_container from '@/components/om_container'
import om_typical_file_list from './om_typical_file_list'
import om_typical_file_data from './om_typical_file_data'
export default {
    name:'om_typical_file',
    components: {
        om_container,
        om_typical_file_list,
        om_typical_file_data
    },
    props: {
        leixing_id: {
            type: [String, Number],
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            selectedFileName: null
        }
    },
    setup() {
        const ref_typical_file_list = ref(null)
        return {
            ref_typical_file_list
        }
    },
    computed: {
        ptwjlb() {
            return this.$Lang.dxpt.ptwjlb
        },
        ptzs() {
            return this.$Lang.dxpt.ptzs
        }
    },
    methods: {
        setSelectedFileName(name) {
            const ref_typical_file_list = this.ref_typical_file_list
            if (ref_typical_file_list === undefined || ref_typical_file_list === null) return
            ref_typical_file_list.setSelectedFileName(name)
        },
        handle_current_change(selectedFileName) {
            this.selectedFileName = selectedFileName
            this.$emit('current-change', selectedFileName)
        }
    }
}
</script>

<style lang='scss' scoped>
.om_typical_file {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .gragh {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        .sidebar {
            flex: 0;
            width: 300px;
            min-width: 300px;
            height: 100%;
            padding: 4px;
            padding-right: 2px;
            box-sizing: border-box;
            overflow: hidden;
        }
        .content {
            flex: 1;
            height: 100%;
            padding: 4px;
            padding-left: 2px;
            box-sizing: border-box;
            overflow: hidden;
        }
    }
}
</style>
