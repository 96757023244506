<template>
<div class="om_echarts">
    <div id="main" ref="ref_echart" class="om_echart_view"></div>
</div>
</template>

<script>
// Object.defineProperty(
//     ref_echart,
//     'clientWidth',
//     {
//         // get: (value) => {
//         //     console.log('clientWidth:', value)
//         //     if (value === undefined || value === null || value <= 0) {
//         //         return 100
//         //     }
//         //     return value
//         // }
//         value: '100%'
//     }
// )
// Object.defineProperty(
//     ref_echart,
//     'clientHeight',
//     {
//         // get: (value) => {
//         //     console.log('clientHeight:', value)
//         //     if (value === undefined || value === null || value <= 0) {
//         //         return 100
//         //     }
//         //     return value
//         // }
//         value: '100%'
//     }
// )
import * as echarts from 'echarts'
import 'echarts-gl'
import { ref, markRaw } from 'vue'
export default {
    name:'om_echarts',
    props: {
        option: {
            type: Object,
            default: () => {
                return null
            }
        },
        merge: {
            type: Boolean,
            default: () => {
                return true
            }
        }
    },
    data() {
        return {
            chart: null
        }
    },
    setup() {
        const ref_echart = ref(null)
        return {
            ref_echart
        }
    },
    watch: {
        option: {
            immidiate: true,
            deep: true,
            handler() {
                this.$nextTick(
                    () => {
                        this.showChart()
                    }
                )
            }
        }
    },
    methods:{
        showChart() {
            const chart = this.chart
            if (chart === undefined || chart === null) {
                return
            }
            const option = this.option
            if (option === undefined || option === null) {
                chart.clear()
                return
            }
            const notMerge = !this.merge
            chart.setOption(option, notMerge)
            this.resize()
        },
        resize() {
            this.$nextTick(
                () => {
                    const chart = this.chart
                    if (chart === undefined || chart === null) {
                        return
                    }
                    chart.resize()
                }
            )
        },
        initChart() {
            const ref_echart = this.ref_echart
            if (ref_echart === undefined || ref_echart === null) return
            this.destoryChart()
            this.chart = markRaw(echarts.init(ref_echart))
            this.resize()
            window.addEventListener(
                'resize',
                () => {
                    this.resize()
                },
                true
            )
        },
        destoryChart() {
            window.removeEventListener('resize', this.resize())
            const chart = this.chart
            if (chart === undefined || chart === null) {
                return
            }
            chart.clear()
            chart.dispose()
            this.chart = null
        },
        saveAsImage(imagename) {
            const chart = this.chart
            if (chart === undefined || chart === null) {
                return
            }
            const picInfo = chart.getDataURL()
            const elink = document.createElement('a')
            let name = imagename
            if (imagename === undefined || imagename === null || imagename === '') {
                name = 'image'
            }
            elink.download = name
            elink.style.display = 'none'
            elink.href = picInfo
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
            // this.$Message.warning(dd)
        }
    },
    unmounted() {
        this.destoryChart()
    },
    mounted() {
        this.initChart()
        this.showChart()
    }
}
</script>

<style lang="scss" scoped>
.om_echarts {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .om_echart_view {
        width: 100%;
        height:100%;
    }
}
</style>