<template>
<div class="om_bdz_condition_health_statics">
    <om_echarts :option="option" ref="ref_echarts"/>
</div>
</template>

<script>
import om_echarts from '@/components/om_echarts'
import { toDecimal } from '@/utils'
import { ref } from 'vue'
export default {
    name:'om_bdz_condition_health_statics',
    components: {
        om_echarts
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    computed: {
        cac() {
            const cac = this.$store.getters.cac
            if (cac === undefined || cac === null) return null
            return cac
        },
        MemoryUsage() {
            const cac = this.cac
            if (cac === undefined || cac === null) return 0
            const obj_id = cac.obj_id
            const canshu = this.$store.getters.canshu(obj_id, 3)
            if (canshu === undefined || canshu === null) return 0
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) return 0
            return rdata
        },
        DiskUsage() {
            const cac = this.cac
            if (cac === undefined || cac === null) return 0
            const obj_id = cac.obj_id
            const canshu = this.$store.getters.canshu(obj_id, 5)
            if (canshu === undefined || canshu === null) return 0
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) return 0
            return rdata
        },
        CPUUsage() {
            const cac = this.cac
            if (cac === undefined || cac === null) return 0
            const obj_id = cac.obj_id
            const canshu = this.$store.getters.canshu(obj_id, 6)
            if (canshu === undefined || canshu === null) return 0
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) return 0
            return rdata
        },
        ncsyl() {
            return this.$Lang.xtsy.xtyxgk.ncsyl
        },
        cpsyl() {
            return this.$Lang.xtsy.xtyxgk.cpsyl
        },
        cpusyl() {
            return this.$Lang.xtsy.xtyxgk.cpusyl
        },
        option() {
            const MemoryUsage = toDecimal(this.MemoryUsage, 1)
            const DiskUsage = toDecimal(this.DiskUsage, 1)
            const CPUUsage = toDecimal(this.CPUUsage, 1)
            const seria = {
                type: 'gauge',
                center: ['50%', '50%'],
                radius: 40,
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        width: 6,
                        color: [
                            [0.0, 'green'],
                            [0.70, 'green'],
                            [0.90, '#e6a23c'],
                            [1.00, 'red']
                        ]
                    }
                },
                pointer: {
                    show: true,
                    itemStyle: {
                        color: 'inherit'
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    distance: -10,
                    length: 4,
                    lineStyle: {
                        width: 2,
                        color: '#999'
                    }
                },
                axisLabel: {
                    distance: -10,
                    color: '#999',
                    fontSize: 10
                },
                anchor: {
                    show: false
                },
                title: {
                    show: true,
                    offsetCenter: ['0%', '68%'],
                    fontSize: 10,
                    fontWeight: 'bold',
                    borderWidth: 1,
                    borderColor: 'gray',
                    borderRadius: 4,
                    padding: 4
                },
                detail: {
                    offsetCenter: ['0%', '30%'],
                    width: 20,
                    height: 14,
                    fontSize: 14,
                    color: 'inherit',
                    borderRadius: 3,
                    formatter: '{value}%'
                },
                data: [
                    {
                        value: 0
                    }
                ]
            }
            const seria1 = JSON.parse(JSON.stringify(seria))
            seria1.data[0].value = MemoryUsage
            seria1.center = ['25%', '80%']
            seria1.radius = 55
            seria1.data[0].name = this.ncsyl
            const seria2 = JSON.parse(JSON.stringify(seria))
            seria2.data[0].value = DiskUsage
            seria2.center = ['50%', '33%']
            seria2.radius = 60
            seria2.data[0].name = this.cpsyl
            const seria3 = JSON.parse(JSON.stringify(seria))
            seria3.data[0].value = CPUUsage
            seria3.center = ['75%', '80%']
            seria3.radius = 55
            seria3.data[0].name = this.cpusyl
            const option = {
                series: [
                    seria1,
                    seria2,
                    seria3
                ]
            }
            return option
        }
    },
    methods: {
        resize() {
            if (this.ref_echarts) {
                this.ref_echarts.resize()
            }
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.om_bdz_condition_health_statics {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
}
</style>
