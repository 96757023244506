<template>
<div class="om_device_condition_health_statics">
    <om_echarts :option="option" ref="ref_echarts"/>
</div>
</template>

<script>
import om_echarts from '@/components/om_echarts'
import { toDecimal } from '@/utils'
import { ref } from 'vue'
export default {
    name:'om_device_condition_health_statics',
    components: {
        om_echarts
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    computed: {
        // cac() {
        //     const cac = this.$store.getters.cac
        //     if (cac === undefined || cac === null) return null
        //     return cac
        // },
        wsj() {
            return this.$Lang.jcsj.sssj.sjxq.wsj
        },
        // DiskUsage() {
        //     const cac = this.cac
        //     if (cac === undefined || cac === null) return 0
        //     const obj_id = cac.obj_id
        //     const canshu = this.$store.getters.canshu(obj_id, 5)
        //     if (canshu === undefined || canshu === null) return 0
        //     const rdata = canshu.rdata
        //     if (rdata === undefined || rdata === null) return 0
        //     return rdata
        // },
        // CPUUsage() {
        //     const cac = this.cac
        //     if (cac === undefined || cac === null) return 0
        //     const obj_id = cac.obj_id
        //     const canshu = this.$store.getters.canshu(obj_id, 6)
        //     if (canshu === undefined || canshu === null) return 0
        //     const rdata = canshu.rdata
        //     if (rdata === undefined || rdata === null) return 0
        //     return rdata
        // },
        // ncsyl() {
        //     return this.$Lang.xtsy.xtyxgk.ncsyl
        // },
        // cpsyl() {
        //     return this.$Lang.xtsy.xtyxgk.cpsyl
        // },
        // cpusyl() {
        //     return this.$Lang.xtsy.xtyxgk.cpusyl
        // },
        option() {
            const sf6Param = this.SF6Param()
            const sf6Usage = this.SF6Usage(sf6Param)
            const seria = {
                type: 'gauge',
                center: ['50%', '50%'],
                radius: 40,
                min: -0.1,
                max: 0.9,
                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        width: 20,
                        color: [
                            [0.6, '#FF0000'],
                            [0.65, '#FFD700'],
                            [1, '#008000']
                        ]
                    }
                },
                pointer: {
                    length: '80%',
                    width: 5,
                    itemStyle: {
                        color: 'auto'
                    }
                },
                axisTick: {
                    show: true,
                    distance: -20,
                    length: 5,
                    lineStyle: {
                        color: '#fff',
                        width: 1
                    }
                },
                splitLine: {
                    distance: -20,
                    length: 20,
                    lineStyle: {
                        width: 2,
                        color: '#fff'
                    }
                },
                axisLabel: {
                    distance: 26,
                    color: 'auto',
                    fontSize: 18
                },
                anchor: {
                    show: false
                },
                title: {
                    show: false,
                    offsetCenter: ['0%', '80%'],
                    fontSize: 12,
                    fontWeight: 'bold',
                    borderWidth: 1,
                    borderColor: 'gray',
                    borderRadius: 4,
                    padding: 4
                },
                detail: {
                    offsetCenter: ['0%', '45%'],
                    valueAnimation: true,
                    width: 30,
                    height: 16,
                    fontSize: 18,
                    color: 'auto',
                    borderRadius: 2,
                    formatter: '{value} Mpa'
                },
                data: [
                    {
                        value: 0.5
                    }
                ]
            }
            const seria1 = JSON.parse(JSON.stringify(seria))
            if (sf6Param === null) {
                seria1.data[0].value = 0 // sf6Usage
                seria1.detail.formatter = this.wsj
            } else {
                const alarmup = sf6Param.alarmup
                // const alarmdown = sf6Param.alarmdown
                const attenup = sf6Param.attenup
                if (sf6Usage === this.wsj) {
                    seria1.data[0].value = 0 // sf6Usage
                    seria1.detail.formatter = this.wsj
                } else {
                    seria1.data[0].value = sf6Usage
                    seria1.axisLine.lineStyle.color[0][0] = alarmup + 0.1
                    seria1.axisLine.lineStyle.color[1][0] = attenup + 0.1
                }
            }
            seria1.center = ['50%', '50%']
            seria1.radius = 160
            // seria1.data[0].name = this.ncsyl
            const option = {
                series: [
                    seria1
                ]
            }
            return option
        }
    },
    methods: {
        SF6Param() {
            const map_canshu = this.$store.state.station.map_canshu
            if (map_canshu === undefined || map_canshu === null) return null
            const object = this.object
            if (object === undefined || object === null) return null
            const obj_canshus = object.canshus
            if (obj_canshus === undefined || obj_canshus === null) return null
            for (const key in obj_canshus) {
                const canshu = map_canshu[key]
                if (canshu.canshu_id === 2) {
                    return canshu
                }
            }
            return null
        },
        SF6Usage(param) {
            if (param === null) return this.wsj
            const canshu = param
            if (canshu.datatype === 4) {
                return this.wsj
            }
            if (canshu.datatype === 5) {
                return this.wsj
            }
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return this.wsj
            const data = canshu.data
            if (data === undefined || data === null) return this.wsj
            // 整型
            if (datatype === 2) {
                const data_int = parseInt(data)
                const dataFormat = data_int
                return dataFormat
            }
            // 其他数据类型，默认按照浮点型处理
            const data_float = toDecimal(data, 3)
            const dataFormat = data_float
            return dataFormat
        },
        resize() {
            if (this.ref_echarts) {
                this.ref_echarts.resize()
            }
        }
    },
    mounted() {
        // const param = this.SF6Param()
        // if (param === null) {
        //     this.$Message.warning('s4dd: none')
        // } else {
        //     const data = this.SF6Usage(param)
        //     const p = {
        //         data: param.data,
        //         rdata: param.rdata,
        //         alarmup: param.alarmup,
        //         alarmdn: param.alarmdn,
        //         attenup: param.attenup,
        //         datatype: param.datatype,
        //         rtutype: param.rtutype,
        //         d2: data
        //     }
        //     this.$Message.warning('s4dd: ' + JSON.stringify(p))
        // }
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.om_device_condition_health_statics {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
}
</style>
