<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="95%"
height="95%"
:title="sjzlTitle"
>
    <template v-slot:title="{title}">
        <div class="om_dialog_header_title">
            <div class="title">
                {{title}}
            </div>
            <div class="export">
                <el-button type="success" round @click="handle_click_export_event">{{dc}}</el-button>
            </div>
        </div>
    </template>
    <div class="om_dialog__body">
        <el-tabs type="border-card" @tab-click="handle_tab_click_event" v-model="selected_leixing_id">
            <el-tab-pane v-for="(leixing, index) in leixings" :key="index" :label="leixing.mingzi" :name="leixing.leixing_id">
                <div v-if="selected_leixing_id===leixing.leixing_id" class="leixing_content">
                    <el-table
                    ref="om_device_canshu_view_table"
                    :data="objects(leixing.leixing_id)"
                    :showHeader="true"
                    border
                    stripe
                    >
                        <el-table-column
                        :label="jczz"
                        align="center"
                        prop="mingzi"
                        width="200"
                        show-overflow-tooltip
                        >
                            <template v-slot:default="scope">
                                <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                                    <el-tag>{{scope.row.mingzi}}</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        :label="cjsj"
                        align="center"
                        width="160"
                        show-overflow-tooltip
                        >
                            <template v-slot:default="scope">
                                <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                                    <el-tag>{{rtime(scope.row.obj_id, 1)}}</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        v-for="(canshu, index) in canshus(leixing.leixing_id)"
                        :key="index"
                        :label="label(canshu.shuoming, canshu.danwei)"
                        align="center"
                        width="160"
                        show-overflow-tooltip
                        >
                            <template v-slot:default="scope">
                                <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                                    <el-tag>{{rdata(scope.row.obj_id, canshu.canshu_id)}}</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</om_dialog>
</template>

<script>
import XLSX from 'xlsx'
import moment from 'moment'
import { sheet2blob, openDownloadDialog, toDateTime, toDecimal } from '@/utils'
import om_dialog from '@/components/om_dialog'
export default {
    name: 'om_data_overview_dialog',
    components: {
        om_dialog
    },
    data() {
        return {
            selected_leixing_id: null,
            title: ''
        }
    },
    computed: {
        leixings() {
            const leixings = this.$store.getters.leixings
            const leixings_exclude_condition = leixings.filter(
                (leixing) => {
                    if (leixing.leixing_id === 31) {
                        return false
                    }
                    if (leixing.leixing_id === 34) {
                        return false
                    }
                    if (leixing.leixing_id === 35) {
                        return false
                    }
                    return true
                }
            )
            return leixings_exclude_condition
        },
        sjzlTitle() {
            return this.$Lang.header.sjzl.title
        },
        jczz() {
            return this.$Lang.header.sjzl.jczz
        },
        cjsj() {
            return this.$Lang.header.sjzl.cjsj
        },
        warn_empt() {
            return this.$Lang.header.sjzl.warn_empt
        },
        dc() {
            return this.$Lang.button.dc
        }
    },
    methods: {
        handle_click_export_event() {
            const columns = []
            const result = this.canshus(this.selected_leixing_id)
            result.forEach(
                (canshu) => {
                    const column = {}
                    column.bianma = canshu.mingzi
                    column.mingzi = this.label(canshu.shuoming, canshu.danwei)
                    column.canshu_id = canshu.canshu_id
                    columns.push(column)
                }
            )
            columns.unshift(
                {
                    bianma: 'rtime',
                    mingzi: this.cjsj
                }
            )
            columns.unshift(
                {
                    bianma: 'mingzi',
                    mingzi: this.jczz
                }
            )
            const objects = this.objects(this.selected_leixing_id)
            const leixing_mingzi = objects[0].leixing.mingzi
            const records = []
            for (let row = 0; row < objects.length; row++) {
                const object = objects[row]
                const record = {}
                for (let col = 0; col < columns.length; col++) {
                    const column = columns[col]
                    const field = column.bianma
                    const header = column.mingzi
                    const canshu_id = column.canshu_id
                    if (field === 'mingzi') {
                        const mingzi = object['mingzi']
                        record[this.jczz] = mingzi
                        continue
                    }
                    if (field === 'rtime') {
                        const rtime = this.rtime(object['obj_id'], 1)
                        record[this.cjsj] = rtime
                        continue
                    }
                    record[header] = this.rdata(object['obj_id'], canshu_id)
                }
                records.push(record)
            }
            if (records.length <= 0) {
                this.$Message({
                    type:'warning',
                    message: this.warn_empt
                })
                return
            }
            const sheet = XLSX.utils.json_to_sheet(records)
            if (sheet === undefined || sheet === null) {
                this.$Message({
                    type:'warning',
                    message:`json_to_sheet error`
                })
                return
            }
            const blob = sheet2blob(XLSX, sheet)
            if (blob === undefined || blob === null) {
                this.$Message({
                    type:'warning',
                    message:`sheet2blob error`
                })
                return
            }
            const timeFrom = moment().format('YYYYMMDD')
            const fileName = `${leixing_mingzi}_${timeFrom}.xls`
            openDownloadDialog(blob, fileName)
        },
        showDialog() {
            this.$refs.om_dialog.showDialog()
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        canshus(leixing_id) {
            const map_leixing_canshu = this.$store.state.station.map_leixing_canshu
            if (map_leixing_canshu === undefined || map_leixing_canshu === null) return []
            const canshus = map_leixing_canshu[leixing_id]
            if (canshus === undefined || canshus === null) return []
            let _canshus = canshus.filter(
                (canshu) => {
                    if (canshu.canshu_id === 1) return false
                    return canshu.useflag === 1
                }
            )
            _canshus = _canshus.sort(
                (oldItem, newItem) => {
                    if (oldItem.criteriatype > newItem.criteriatype) {
                        return true
                    }
                    return false
                }
            )
            return _canshus
        },
        objects(_leixing_id) {
            const map_object = this.$store.state.station.map_object
            if (map_object === undefined || map_object === null) return []
            const objects = []
            for (const key in map_object) {
                const object = map_object[key]
                if (map_object === undefined || map_object === null) continue
                const leixing_id = object.leixing_id
                if (leixing_id === undefined || leixing_id === null) continue
                if (leixing_id === 1 || leixing_id === 2 || leixing_id === 21 || leixing_id === 24) continue
                if (leixing_id !== _leixing_id) continue
                objects.push(object)
            }
            return objects
        },
        canshu(obj_id, canshu_id) {
            const canshu = this.$store.getters.canshu(obj_id, canshu_id)
            if (canshu === undefined || canshu === null) {
                return null
            }
            return canshu
        },
        label(mingzi, danwei) {
            if (danwei === undefined || danwei === null || danwei === '') return mingzi
            return `${mingzi}(${danwei})`
        },
        rtime(obj_id, canshu_id) {
            const canshu = this.canshu(obj_id, canshu_id)
            if (canshu === undefined || canshu === null) return ''
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return ''
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) return ''
            return rtime
        },
        rdata(obj_id, canshu_id) {
            const canshu = this.canshu(obj_id, canshu_id)
            if (canshu === undefined || canshu === null) return ''
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return ''
            const rdata = canshu.rdata
            if (rdata === null || rdata === undefined) return ''
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return ''
            const rtutype = canshu.rtutype
            if (rtutype === undefined || rtutype === null) return ''
            // 遥测
            if (rtutype === 0) {
                // 浮点型
                if (datatype === 1) {
                    return toDecimal(rdata, 3)
                }
                // 整型
                if (datatype === 2) {
                    return this.yxbianma(leixing_id, canshu_id, parseInt(parseInt(rdata)))
                }
                // 日期时间型
                if (datatype === 3) {
                    return toDateTime(rdata)
                }
                return ''
            }
            if (rtutype === 1) {
                // 整型
                if (datatype === 2) {
                    return this.yxbianma(leixing_id, canshu_id, parseInt(parseInt(rdata)))
                }
                return ''
            }
            return ''
        },
        yxbianma(leixing_id, canshu_id, data_int) {
            if (leixing_id === undefined || leixing_id === null) {
                return data_int
            }
            if (canshu_id === undefined || canshu_id === null) {
                return data_int
            }
            const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
            const bianma = this.$store.getters.getYxbianma(obj_id)
            if (bianma === undefined || bianma === null) {
                return data_int
            }
            return `${data_int}(${bianma.shuoming})`
        },
        handle_tab_click_event(tab) {
            const name = tab.paneName
            this.selected_leixing_id = name
        }
    },
    mounted() {
        const leixings = this.leixings
        if (leixings === undefined || leixings === null) return
        if (!Array.isArray(leixings)) return
        const count = leixings.length
        if (count <= 0) return
        this.selected_leixing_id = leixings[0].leixing_id
    }
}
</script>

<style lang="scss">
.om_dialog {
    .om_dialog_header_title {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        .title {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            margin: 0px;
        }
        .export {
            flex: 0;
            min-width: 80px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            margin: 0px;
            padding-bottom: 4px;
        }
    }
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: block;
        .el-tabs--border-card {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            width: 100% !important;
            height: 100% !important;
            padding: 0px !important;
            box-sizing: border-box !important;
            .el-tabs__header {
                flex: 0;
                width: 100%;
                height: 39px;
                min-height: 39px;
                background-color: #F5F7FA;
                border-bottom: 1px solid #E4E7ED;
                margin: 0;
            }
            .el-tabs__content {
                flex: 1;
                width: 100% !important;
                height: 100% !important;
                padding: 0px !important;
                .el-tab-pane {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
        .leixing_content {
            width: 100%;
            height: 100%;
            color: red; display: block;
            background: green;
            display: true;
        }
    }
    .el-table {
        width: 100%;
        height: 100%;
        font-size: 12px !important;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;
        .el-table__header-wrapper {
            overflow: hidden !important;
            flex: 0 auto;
            width: 100%;
            min-height: 0px;
        }
        .el-table__body-wrapper {
            flex: 1;
            width: 100%;
            overflow: auto !important;
            display: block;
        }
        td, th {
            padding: 2px;
        }
        .el-image {
            width: 100%;
            height: 100%;
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            img {
                flex: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
</style>