<template>
<div class="om_log">
    <div class="content_form">
        <div class="time">
            <span>{{rqfw}}</span>
            <el-date-picker
            type="daterange"
            :clearable="false"
            :range-separator="zhi"
            start-placeholder="ksrq"
            end-placeholder="jsrq"
            v-model="time">
            </el-date-picker>
        </div>
        <div class="data_flag_range">
            <el-select
                placeholder="select"
                v-model="data_flag_selected"
            >
                <el-option
                v-for="(data_flag, index) in data_flag_range"
                :label="data_flag.mingzi"
                :value="data_flag.data_flag"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
        <div class="query">
            <el-button type="primary" round @click="search_list">{{cx}}</el-button>
        </div>
        <div class="export">
            <el-button type="success" round @click="export_excel_handler">{{dc}}</el-button>
        </div>
    </div>
    <div class="content_container">
        <div class="omTabel_box">
            <el-table
                ref="content_table"
                :data="tableBody"
                :border="true"
                :show-overflow-tooltip="true"
                stripe
            >
                <el-table-column
                type="index"
                :label='xh'
                align="center"
                :index="currentIndex"
                show-overflow-tooltip
                :width="60"
                >
                </el-table-column>

                <el-table-column
                :label='tableColumns.col_1'
                prop="log_type_name"
                align="center"
                :show-overflow-tooltip="true"
                :width="120"
                >
                </el-table-column>
                <el-table-column
                :label='tableColumns.col_2'
                prop="create_name"
                align="center"
                :show-overflow-tooltip="true"
                :width="420"
                >
                </el-table-column>
                <el-table-column
                :label='tableColumns.col_3'
                prop="create_time"
                align="center"
                :show-overflow-tooltip="true"
                :width="200"
                >
                </el-table-column>
                <el-table-column
                :label='tableColumns.col_4'
                prop="warn_status"
                align="center"
                :show-overflow-tooltip="true"
                :width="200"
                >
                </el-table-column>
                <el-table-column
                    :label='tableColumns.col_5'
                    align="center"
                >
                    <template #default="{ row }">
                        <!-- 阈值修改 -->
                        <div v-if="row.log_type === 21" class="yuzhi">
                            {{row.shebei_mingzi}} {{yxg}}}
                            <!-- <div class="row1" style="color: #000; text-align: left;">
                                <div>{{row.bdz_mingzi}} => {{row.leixing_mingzi}} =></div>
                                <div>{{row.shebei_mingzi}} => {{row.canshu_mingzi}};</div>
                            </div>
                            <div
                                v-for="(item, index) in row.threshold_current"
                                :key="index"
                            >
                                <div class="row1" style="color: #000; text-align: left;">{{item.shuoming}}：</div>
                                <div class="row1">
                                    有效上限：<span>{{item.alarmdn}}</span>,
                                    有效下限：<span>{{item.alarmdn}}</span>
                                </div>
                                <div class="row1">
                                    报警上限：<span>{{item.alarmdn}}</span>,
                                    报警下限：<span>{{item.alarmdn}}</span>
                                </div>
                                <div class="row1">
                                    预警上限：<span>{{item.alarmdn}}</span>,
                                    预警下限：<span>{{item.alarmdn}}</span>
                                </div>
                                <div class="row1">诊断值：<span>{{item.alarmdn}}</span></div>
                            </div> -->
                        </div>
                        <div v-else>{{row.monitor_value}}</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    :label='tableColumns.col_6'
                    align="center"
                >
                    <template #default="{ row }">
                        <div v-if="row.log_type === 21" class="yuzhi">
                            <div class="row1" style="color: #000; text-align: left;">
                                <div>{{row.bdz_mingzi}} => {{row.leixing_mingzi}} =></div>
                                <div>{{row.shebei_mingzi}} => {{row.canshu_mingzi}};</div>
                            </div>
                            <div
                                v-for="(item, index) in row.threshold_oldValue"
                                :key="index"
                            >
                                <div class="row1" style="color: #000; text-align: left;">{{item.shuoming}}：</div>
                                <div class="row1">
                                    有效上限：<span>{{item.alarmdn}}</span>,
                                    有效下限：<span>{{item.alarmdn}}</span>
                                </div>
                                <div class="row1">
                                    报警上限：<span>{{item.alarmdn}}</span>,
                                    报警下限：<span>{{item.alarmdn}}</span>
                                </div>
                                <div class="row1">
                                    预警上限：<span>{{item.alarmdn}}</span>,
                                    预警下限：<span>{{item.alarmdn}}</span>
                                </div>
                                <div class="row1">诊断值：<span>{{item.alarmdn}}</span></div>
                            </div>
                        </div>
                        <div v-else>{{row.monitor_historical_value}}</div>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </div>
    <div class="content_page">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page=pageIndex
        :page-sizes="[30, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total">
        </el-pagination>
    </div>
</div>
</template>

<script>
import { computed, toRef } from 'vue'
import moment from 'moment'
export default {
    name:'om_log',
    props: {
        Lang: {
            type: Object,
            default: () => {
                return window.Lang
            }
        }
    },
    setup(props) {
        const Lang_ = toRef(props, 'Lang')
        const headerSelectOptions = computed(() => {
            const options = Lang_.value.sysLog.headerSelectOptions
            // console.log('options', options)
            return options
        })
        const tableColumns = computed(() => {
            const columns = Lang_.value.sysLog.tableColumns
            // console.log('columns', columns)
            return columns
        })
        return {
            headerSelectOptions,
            tableColumns
        }
    },
    data() {
        return {
            tableBody: [],
            total: 0,
            time: [],
            pageIndex: 1,
            pageSize: 30,
            data_flag_range: [
                { data_flag: '', mingzi: this.headerSelectOptions.log_type_0 },
                { data_flag: 1, mingzi: this.headerSelectOptions.log_type_1 },
                { data_flag: 2, mingzi: this.headerSelectOptions.log_type_2 },
                { data_flag: 3, mingzi: this.headerSelectOptions.log_type_3 },
                { data_flag: 4, mingzi: this.headerSelectOptions.log_type_4 }
            ],
            data_flag_selected: ''
        }
    },
    computed: {
        rqfw() {
            return this.$Lang.jcsj.ycls.rqfw
        },
        zhi() {
            return this.$Lang.jcsj.ycls.zhi
        },
        xh() {
            return this.$Lang.jcsj.ycls.xh
        },
        zdsj() {
            return this.$Lang.jcsj.ycls.zdsj
        },
        cjsj() {
            return this.$Lang.jcsj.ycls.cjsj
        },
        ksrq() {
            return this.$Lang.jcsj.ycls.ksrq
        },
        jsrq() {
            return this.$Lang.jcsj.ycls.jsrq
        },
        yxg() {
            return this.$Lang.jcsj.ycls.yxg
        },
        select() {
            return this.$Lang.button.select
        },
        cx() {
            const cx = this.$Lang.button.cx
            return cx
        },
        dc() {
            return this.$Lang.button.dc
        }
    },
    methods:{
        handleSizeChange(val) {
            this.pageSize = val
            this.search_list()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.search_list()
        },
        currentIndex(index) {
            return this.pageSize * (this.pageIndex - 1) + index + 1
        },
        clear() {
            this.total = 0
            this.tableBody = []
        },
        async fetch_table_list() {
            if (this.time === undefined || this.time === null || this.time === '' || this.time.length === 0) return this.clear()
            if (this.time[0] === undefined || this.time[0] === null) return this.clear()
            if (this.time[1] === undefined || this.time[1] === null) return this.clear()

            const pageSize = this.pageSize
            const pageIndex = this.pageIndex
            const timeFrom = this.time[0] ? moment(this.time[0]).format('YYYY-MM-DD 00:00:00') : ''
            const timeTo = this.time[1] ? moment(this.time[1]).format('YYYY-MM-DD 23:59:59') : ''
            try {
                const params = {
                    type: this.data_flag_selected,
                    pageSize: pageSize,
                    pageIndex: pageIndex - 1,
                    timeFrom: timeFrom,
                    timeTo: timeTo
                }
                const resultset = await this.$ajax.p_log_query(params)
                if (!resultset[1] || resultset[1].length === 0) return this.clear()
                //
                const recordset = resultset[1]
                const total = resultset[2][0].total
                await this.$nextTick()
                this.total = parseInt(total)
                this.tableBody = recordset
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_log_query异常, 错误原因：${error}`
                })
            }
        },
        async search_list() {
            await this.fetch_table_list()
        },
        async export_excel_handler() {
            // this.$Message.warning('开发中 敬请期待')
            const timeFrom = this.time[0]
            const timeTo = this.time[1]
            const fileName = this.$Lang.sysLog.name
            // window.open(`/export_log?timeFrom=${timeFrom}&timeTo=${timeTo}&type=${data_flag_selected}`)
            try {
                const params = {
                    timeFrom: timeFrom,
                    timeTo: timeTo,
                    type: this.data_flag_selected,
                    lang: localStorage.getItem('lang')
                }
                const resultset = await this.$ajax.export_log(params, 'sys_log')
                // console.log('resultset', resultset)
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(resultset)
                link.download = `${fileName}.xlsx`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_log_query异常, 错误原因：${error}`
                })
            }
        }
    },
    async mounted() {
        this.time = [
            moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().format('YYYY-MM-DD HH:mm:ss')
        ]
        this.fetch_table_list()
    }
}
</script>

<style lang="scss" scoped>
.om_log {
    width:100%;
    height:100%;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
    .content_form {
        width: 100%;
        min-height: 40px;
        flex: 0;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .time {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 420px;
            width: 420px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .data_flag_range {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 100px;
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .query {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            margin: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        .export {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 1;
            height: 100%;
            width: 60px;
            margin: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .content_container {
        overflow: hidden;
        width:100%;
        flex:1;
        .omTabel_box{
            width: 100%;
            height: 100%;
            overflow: auto;
            // .el-table--border {
            //     width: 100%;
            //     height: 100%;
            //     font-size: 12px !important;
            //     display: flex;
            //     flex-direction: column;
            //     .el-table__header-wrapper {
            //         flex: 0 auto;
            //         width: 100%;
            //         box-sizing: border-box;
            //     }
            //     .el-table__body-wrapper {
            //         flex: 1;
            //         width: 100%;
            //         overflow: auto;
            //         box-sizing: border-box;
            //     }
            //     td, th {
            //         padding: 0px;
            //     }
            // }
            // .el-image {
            //     max-width: 16px;
            //     max-height: 16px;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     text-align: center;
            // }
        }
    }
    .content_page {
        flex: 0;
        width:100%;
        height: 32px;
        min-height: 32px;
        box-sizing: border-box;
        width:100%;
    }

    .data_flag {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.yuzhi{
    .row1{
        span{
            color: #0f0;
            font-weight: 700;
        }
    }
}
</style>
