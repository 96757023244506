
<template>
<om_dialog
ref="ref_dialog"
width="800px"
height="740px"
:title="title">
    <div class="om_prps_record_dialog">
        <div class="toolbar">
            <el-button type="success" :disabled="button_record_start_disabled" @click="handle_button_start_record_click">{{kslz}}</el-button>
            <el-button type="success" :disabled="button_record_stop_disabled" @click="handle_button_stop_record_click">{{tzlz}}</el-button>
        </div>
        <div class="content">
            <div class="real">
                <om_prps_view :prps="prps"/>
            </div>
            <div v-show="showMask" class="mask">
                <div>{{recordCount}}</div>
            </div>
        </div>
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
import om_prps_view from '@/components/om_prps_view'
import om_file_saver from 'file-saver'
import moment from 'moment'
export default {
    name: 'om_prps_record_dialog',
    components:{
        om_dialog,
        om_prps_view
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            list_prps: [],
            button_record_start_disabled: false,
            button_record_stop_disabled: true,
            showMask: false
        }
    },
    setup() {
        const ref_dialog = ref(null)
        return {
            ref_dialog
        }
    },
    computed: {
        title() {
            const defaultName = this.ptlz
            const object = this.object
            if (object === undefined || object === null) return defaultName
            const mingzi = object.mingzi
            if (mingzi === undefined || mingzi === null || mingzi === '') return defaultName
            const title = `${mingzi}${defaultName}`
            return title
        },
        prps() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 21)
            if (canshu === undefined || canshu === null) return null
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) return null
            return rdata
        },
        ptlz() {
            return this.$Lang.jcsj.sssj.ssptlz.ptlz
        },
        kslz() {
            return this.$Lang.jcsj.sssj.ssptlz.kslz
        },
        tzlz() {
            return this.$Lang.jcsj.sssj.ssptlz.tzlz
        },
        xw() {
            return this.$Lang.jcsj.sssj.ssptsj.xw
        },
        zq() {
            return this.$Lang.jcsj.sssj.ssptsj.zq
        },
        fu() {
            return this.$Lang.jcsj.sssj.ssptsj.fu
        },
        zqdw() {
            return this.$Lang.jcsj.sssj.ssptsj.zqdw
        },
        option() {
            const prps = this.prps
            if (prps === null || prps === undefined) return null
            if (prps.length <= 0) return null
            const sin = []
            for (let x = 0; x < 360; x += 1.8) {
                const value = -40 + 40 * Math.sin(x / 180 * Math.PI)
                sin.push([x, 50, value])
            }
            const prpd = []
            const noise = []
            for (let i = 0; i < prps.length; i++) {
                const value = prps[i]
                noise.push([value[0], value[1], -80 - value[2]])
                prpd.push([value[0], 50, value[2]])
            }
            const option = {
                backgroundColor:'#b0d5df',
                title: {
                    show: true,
                    left: 'left',
                    text: ''
                },
                legend: {
                    show: true,
                    data: [
                        {
                            name: 'PRPS',
                            icon: 'pin'
                        },
                        {
                            name: 'PRPD',
                            icon: 'pin'
                        }
                    ]
                },
                visualMap: [
                    {
                        seriesIndex: 1,
                        show: false,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${-param - 80}dBm`
                        },
                        inRange:
                        {
                            color: [
                                '#a50026',
                                '#d73027',
                                '#f46d43',
                                '#fdae61',
                                '#fee090',
                                '#ffffbf',
                                '#e0f3f8',
                                '#abd9e9',
                                '#74add1',
                                '#4575b4',
                                '#313695'
                            ]
                        }
                    },
                    {
                        seriesIndex: [2, 3],
                        show: true,
                        right: 0,
                        dimension: 2,
                        min: -80,
                        max: 0,
                        calculable: false,
                        align: 'top',
                        top: 'middle',
                        formatter: (param) => {
                            return `${param}dBm`
                        },
                        inRange: {
                            color: [
                                '#313695',
                                '#4575b4',
                                '#74add1',
                                '#abd9e9',
                                '#e0f3f8',
                                '#ffffbf',
                                '#fee090',
                                '#fdae61',
                                '#f46d43',
                                '#d73027',
                                '#a50026',
                                '#a50026'
                            ]
                        }
                    }
                ],
                toolbox: { // 可视化的工具箱
                    show: true,
                    feature: {
                        dataView: { // 数据视图
                            show: true
                        },
                        restore: { // 重置
                            show: true
                        },
                        saveAsImage: { // 保存图片
                            show: true
                        }
                    }
                },
                tooltip: {
                    formatter:(param) => {
                        return `${this.xw}: ${param.value[0]}°<br>${this.zq}: ${param.value[1]}<br>${this.fu}:${param.value[2]}dBm`
                    }
                },
                xAxis3D: {
                    type: 'value',
                    name: this.xw,
                    min:0,
                    interval:45,
                    max:360,
                    axisLabel: { formatter: '{value}°' },
                    splitArea: {
                        show: true
                    }
                },
                yAxis3D: {
                    type: 'value',
                    name: this.zqdw,
                    min:0,
                    max:50,
                    interval: 5,
                    axisLabel: { formatter: '{value}' },
                    splitArea: {
                        show: true
                    }
                },
                zAxis3D: {
                    type: 'value',
                    name: this.fu,
                    interval:10,
                    min: -80,
                    max: 0,
                    axisLabel: { formatter: '{value}dBm' }
                },
                grid3D: {
                    left:0,
                    right:0,
                    top:20,
                    bottom:0,
                    viewControl: {
                        autoRotate: false,
                        projection: 'orthographic'
                    },
                    light: {
                        main: {
                            intensity: 1.2,
                            shadow: true
                        },
                        ambient: {
                            intensity: 0.3
                        }
                    }
                },
                series: [
                    {
                        stack: 'total',
                        name:'noise',
                        type: 'bar3D',
                        barSize: [0.25, 0.5],
                        shading: 'lambert',
                        itemStyle: {
                            opacity: 0
                        },
                        data: prps
                    },
                    {
                        stack: 'total',
                        name:'PRPS',
                        type: 'bar3D',
                        barSize: [0.25, 0.5],
                        shading: 'lambert',
                        data: noise
                    },
                    {
                        name:'PRPD',
                        type: 'scatter3D',
                        symbolSize: 4,
                        shading: 'lambert',
                        data: prpd
                    },
                    {
                        name:'sin',
                        type: 'line3D',
                        symbolSize: 4,
                        shading: 'lambert',
                        data: sin
                    }
                ]
            }
            return option
        },
        recordCount() {
            const list_prps = this.list_prps
            if (list_prps === undefined || list_prps === null) return 0
            return list_prps.length
        }
    },
    watch: {
        'option': {
            immidiate: true,
            deep: true,
            handler() {
                const prps = this.prps
                if (prps === undefined || prps === null) {
                    return
                }
                if (this.list_prps === undefined || this.list_prps === null) {
                    this.list_prps = []
                }
                if ( !this.button_record_start_disabled ) {
                    return
                }
                const list_prps = this.list_prps
                const length = list_prps.length
                if (length >= 900) {
                    list_prps.shift()
                }
                list_prps.push(prps)
            }
        }
    },
    methods: {
        showDialog() {
            this.clear()
            this.resetChannel(1)
            this.ref_dialog.showDialog()
        },
        closeDialog() {
            this.clear()
            this.ref_dialog.closeDialog()
        },
        afterClose() {
            this.resetChannel(0)
        },
        clear() {
            this.button_record_start_disabled = false
            this.button_record_stop_disabled = true
            this.list_prps = []
            this.showMask = false
        },
        resetChannel(mode) {
            const object = this.object
            if (object === undefined || object === null) return false
            const bdz = object.bdz_id
            const leixing = object.leixing_id
            const shebei = object.shebei_id
            // this.$Message.warning(bdz + ',' + leixing + ',' + shebei)
            if (bdz === null || leixing === null || shebei === null) return false
            try {
                this.$ajax.reset_channel_mode(bdz, leixing, shebei, mode)
            } catch (error) {
                this.$Message.warning(error.toString())
            }
            return true
        },
        handle_button_start_record_click() {
            if (this.button_record_start_disabled) return
            this.button_record_start_disabled = true
            this.button_record_stop_disabled = false
            this.list_prps = []
            this.showMask = true
        },
        handle_button_stop_record_click() {
            if (this.button_record_stop_disabled) return
            const list_prps = this.list_prps
            if (list_prps === undefined || list_prps === null) {
                return
            }
            this.button_record_start_disabled = false
            this.button_record_stop_disabled = true
            const tmp_list_prps = JSON.parse(JSON.stringify(list_prps))
            this.list_prps = []
            this.showMask = false
            this.exportToJson(tmp_list_prps)
        },
        exportToJson(jsonObj) {
            if (!Array.isArray(jsonObj)) return
            if (jsonObj.length <= 0) return
            const data = JSON.stringify(jsonObj)
            const timeFrom = moment().format('YYYYMMDD_HHmmSS')
            const fileName = `${this.object.mingzi.trim()}_${timeFrom}.omgis`
            const blob = new Blob([data], { type: '' })
            om_file_saver.saveAs(blob, fileName)
        }
    }
}
</script>

<style lang="scss" scoped>
.om_prps_record_dialog {
    width: 100%;
    height: 100%;
    z-Index: 800;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .toolbar {
        width: 100%;
        min-height: 40px;
        flex: 0;
        padding: 0px;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 2px solid #2376bf;
        .el-button {
            padding: 4px;
            margin: 4px;
            box-sizing: border-box;
            overflow: hidden;
        }
    }
    .content {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px;
        padding-left: 0px;
        box-sizing: border-box;
        overflow: hidden;
        .real {
            width: 100%;
            height: 100%;
            margin: 0px;
            padding: 0px;
        }
        .mask {
            position: absolute;
            top: 50%;
            bottom: 50%;
            left: 50%;
            bottom: 50%;
            width: 100px;
            height: 100px;
            border-radius: 20px;
            background: rgba(0,0,0,0.25);
            font-size: 40px;
            color: rgb(0, 255, 13);
            font-weight: bolder;
            border: none;
            overflow: hidden;
            z-index: 100;
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>