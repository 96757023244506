<template>
<svg
id="scene"
data-itemtype="scene"
data-index="-1"
xmlns="http://www.w3.org/2000/svg"
version="1.1"
:viewBox=viewBox
preserveAspectRatio="none"
@mousedown.stop.prevent="mouseEvent($event)"
@mousemove.prevent.stop="mouseEvent($event)"
@mouseup.prevent.stop="mouseEvent($event)">
    <g id="background" class="background">
        <image
        x="0"
        y="0"
        width="100%"
        height="100%"
        :xlink:href="background"
        pointer-events="none"
        preserveAspectRatio="none"
        @mousedown.stop.prevent="mouseEvent($event)"
        @mousemove.prevent.stop="mouseEvent($event)"
        @mouseup.prevent.stop="mouseEvent($event)"
        >
        </image>
    </g>
    <g id="item" class="item">
        <om_device
        @mousedown.stop.prevent="mouseEvent($event)"
        @mousemove.prevent.stop="mouseEvent($event)"
        @mouseup.prevent.stop="mouseEvent($event)"
        v-for="(item, index) in items"
        data-itemtype="item"
        :data-index="index"
        :item="item"
        :key="index"
        />
    </g>
    <!--给外面的canvas提供一个具名插槽，方便选中图元，并对图元进行操作 -->
    <slot name="controller" />
</svg>
</template>

<script>
import om_device from './om_device'
export default {
    name: 'scene',
    components: { om_device },
    props: {
        detail: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    computed: {
        viewBox() {
            const detail = this.detail
            if (detail === undefined || detail === null) return '0 0 1024 768'
            const scene = detail.scene
            if (scene === undefined || scene === null) return '0 0 1024 768'
            const viewBox = scene.viewBox
            if (viewBox === undefined || viewBox === null) return '0 0 1024 768'
            return `0 0 ${viewBox.width} ${viewBox.height}`
        },
        background() {
            const detail = this.detail
            if (detail === undefined || detail === null) return '/images/svg/station.jpg'
            const scene = detail.scene
            if (scene === undefined || scene === null) return '/images/svg/station.jpg'
            const background = scene.background
            if (background === undefined || background === null) return '/images/svg/station.jpg'
            return background
        },
        items() {
            const detail = this.detail
            if (detail === undefined || detail === null) return []
            const items = detail.items
            if (items === undefined || items === null) return []
            if (!Array.isArray(items)) return []
            return items
        }
    },
    methods: {
        // 物理坐标转用户坐标
        toSceneCtm(point) {
            const scene = document.getElementById('scene')
            const sceneCTM = scene.getScreenCTM()
            const ptInPage = scene.createSVGPoint()
            ptInPage.x = point.x
            ptInPage.y = point.y
            const ptInScene = ptInPage.matrixTransform( sceneCTM.inverse() )
            return ptInScene
        },
        itemAt(index) {
            const detail = this.detail
            if (detail === undefined || detail === null) return null
            const items = detail.items
            if (items === undefined || items === null) return null
            if (!Array.isArray(items)) return null
            const count = items.length
            if (index >= count) return null
            if (index < 0) return null
            const item = items[index]
            return item
        },
        mouseEvent(e) {
            const mouseEventName = e.type
            const target = e.target
            if (target === undefined || target === null) {
                this.$emit(mouseEventName, e)
                return
            }
            const ptInPage = {
                x: e.pageX,
                y: e.pageY
            }
            const ptInScene = this.toSceneCtm(ptInPage)
            target.sceneX = ptInScene.x
            target.sceneY = ptInScene.y
            const dataset = target.dataset
            if (dataset === undefined || dataset === null) {
                this.$emit(mouseEventName, e)
                return
            }
            const itemtype = dataset.itemtype
            if (itemtype === undefined || itemtype === null) {
                this.$emit(mouseEventName, e)
                return
            }
            if (itemtype === 'item') {
                const index = dataset.index
                if (index === undefined || index === null) return
                target.item = this.itemAt(index)
                this.$emit(mouseEventName, e)
                return
            }
            this.$emit(mouseEventName, e)
        }
    }
}
</script>

<style scoped lang="scss">
svg {
    margin: 0;
    padding: 0;
    width: 100%;
    height:100%;
    border: none;
    box-sizing: border-box;
    overflow: hidden;
}
</style>
