<template>
<div class="om_graph_view">
    <om_history_graph_view ref="om_history_graph_view" :object="object"/>
</div>
</template>

<script>
import { ref } from 'vue'
import om_history_graph_view from './om_history_graph_view'
export default {
    name:'om_graph_view',
    components: {
        om_history_graph_view
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const om_history_graph_view = ref(null)
        return {
            om_history_graph_view
        }
    },
    methods: {
        resize() {
            if (this.om_history_graph_view) {
                this.om_history_graph_view.resize()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.om_graph_view {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    box-sizing: border-box;
    background: white;
}
</style>