<template>
<div class="login-wrap">
    <div class="ms-login">
        <div class="ms-title">{{title}}</div>
        <el-form class="ms-content" :model="param" :rules="rules" ref="login" label-width="0px">
            <el-form-item prop="username">
                <el-input v-model="param.username" placeholder="" tabindex="1">
                    <template v-slot:prepend>
                        <el-button icon="el-icon-lx-people">{{accountLabel}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                type="password"
                tabindex="2"
                placeholder=""
                v-model="param.password"
                @keyup.enter="handle_submit_click_event"
                >
                    <template v-slot:prepend>
                        <el-button icon="el-icon-lx-lock">{{passwordLabel}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
            <div class="login_verify_container">
                <om_verify_code ref="om_verify_code" class="om_verify_code" @keyup.enter="handle_submit_click_event"></om_verify_code>
            </div>
            <el-form-item @keyup.enter="handle_submit_click_event">
                <el-button type="primary" :disabled="button_login_diasbled" round style="width: 100%; height: 100%; margin-top:10px;" @click="handle_submit_click_event" tabindex="4">{{dl}}</el-button>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
import om_verify_code from './components/om_verify_code'
import md5 from 'js-md5'
export default {
    components:{
        om_verify_code
    },
    name: 'om_login_view',
    // props: {
    //     title: {
    //         type: String,
    //         default: () => {
    //             return ''
    //         }
    //     }
    // },
    data: function() {
        return {
            param: {
                username: '',
                password: ''
            },
            rules: {
                username: [{ required: true, message: this.wanr_zh, trigger: 'blur' }],
                password: [{ required: true, message: this.wanr_mm, trigger: 'blur' }]
            },
            button_login_diasbled: false,
            timeout: null
        }
    },
    computed: {
        title() {
            return this.$Lang.title
        },
        accountLabel() {
            return this.$Lang.login.zh
        },
        passwordLabel() {
            return this.$Lang.login.mm
        },
        dl() {
            return this.$Lang.login.dl
        },
        wanr_zh() {
            return this.$Lang.login.wanr_zh
        },
        wanr_mm() {
            return this.$Lang.login.wanr_mm
        },
        warn_dlyz() {
            return this.$Lang.login.warn_dlyz
        },
        warn_yzm() {
            return this.$Lang.login.warn_yzm
        }
    },
    methods: {
        handle_submit_click_event() {
            const url = decodeURI(window.location.href.split('?')[1])
            this.$message.warning(`p_user_login value:` + url)
            this.button_login_diasbled = true
            this.$refs.login.validate( async(valid) => {
                // 对账号或者密码进行校验
                if (!valid) {
                    this.$message.warning(this.warn_dlyz)
                    this.button_login_diasbled = false
                    return false
                }
                // 对验证码进行校验
                const is_verify_success = this.$refs.om_verify_code.verify()
                if (!is_verify_success) {
                    this.$message.warning(this.warn_yzm)
                    this.button_login_diasbled = false
                    return false
                }
                // 用户名、密码发送到后台进行登陆校验
                let dataset = null
                try {
                    // console.log('this.$ajax', this.$ajax)
                    dataset = await this.$ajax.p_user_login({
                        user_id:this.param.username,
                        user_pwd:md5(this.param.password)
                    })
                    const error = dataset[0][0]
                    if (error.code !== 0) {
                        this.button_login_diasbled = false
                        this.$message.warning(`p_user_login request failed, error code:${error.code}, error text:${error.text}`)
                        return false
                    }
                    // 授权成功后，加载全局信息，保存到vuex中
                    // this.$store.commit('init')
                    //
                    const token = dataset[1][0].token || ''
                    const user_id = dataset[1][0].user_id || ''
                    const quanxian = dataset[1][0].quanxian
                    sessionStorage.setItem('user_id', user_id)
                    sessionStorage.setItem('quanxian', quanxian)
                    sessionStorage.setItem('username', this.param.username)
                    sessionStorage.setItem('x-access-token', token)
                    // ------------------------------------------------非空判断
                } catch (e) {
                    this.$message.warning(`p_user_login request failed, error message:${e}!`)
                    this.button_login_diasbled = false
                    return false
                }
                this.button_login_diasbled = false
                // 跳转到首页
                this.$router.push('/')
                return true
            })
            this.button_login_diasbled = false
        },
        async do_login() {
            const url = decodeURI(window.location.href.split('?')[1])
            // this.$message.warning(`p_user_login value:` + url)
            if (url === null || url === undefined) return false
            const params = url.split('=')
            if (params === null || params === undefined) return false
            if (!Array.isArray(params)) return false
            if (params.length !== 2) return false
            const tok = params[0]
            if (tok !== 'token') return false
            const value = params[1].split('#')[0]
            if (value !== '90e3dd17108eaec0cb52760bf59ccb2e') return
            // let dataset = null
            try {
                this.button_login_diasbled = true
                this.param.username = 'guest'
                this.param.password = '123456'
                const dataset = await this.$ajax.p_user_login({
                    user_id: this.param.username,
                    user_pwd:md5(this.param.password)
                })
                const error = dataset[0][0]
                if (error.code !== 0) {
                    this.button_login_diasbled = false
                    this.$message.warning(`p_user_login request failed, error code:${error.code}, error text:${error.text}`)
                    return false
                }
                // 授权成功后，加载全局信息，保存到vuex中
                // this.$store.commit('init')
                //
                const token = dataset[1][0].token || ''
                const user_id = dataset[1][0].user_id || ''
                const quanxian = dataset[1][0].quanxian
                sessionStorage.setItem('user_id', user_id)
                sessionStorage.setItem('quanxian', quanxian)
                sessionStorage.setItem('username', this.param.username)
                sessionStorage.setItem('x-access-token', token)
                // this.$message.warning(`p_user_login request ff24ff!` + token)
                if (this.timeout !== null) {
                    clearInterval(this.timeout)
                    this.timeout = null
                }
                this.button_login_diasbled = false
                this.$router.push('/')
                // ------------------------------------------------非空判断
                return true
            } catch (e) {
                this.button_login_diasbled = false
                this.$message.warning(`p_user_login request failed, error message:${e}!`)
                return false
            }
        }
    },
    async mounted() {
        // const element = document.documentElement
        // if (element.requestFullscreen) {
        //     element.requestFullscreen()
        // } else if (element.webkitRequestFullScreen) {
        //     element.webkitRequestFullScreen()
        // } else if (element.mozRequestFullScreen) {
        //     element.mozRequestFullScreen()
        // } else if (element.msRequestFullscreen) {
        //     element.msRequestFullscreen()
        // }
        this.do_login()
        // this.$message.warning(`p_user_login request fff34ff!` + (ret === true ? 'yes' : 'no'))
        // this.timeout = setInterval(() => {
        //     this.do_login()
        //     // this.$message.warning(`p_user_login request fffff!` + suc)
        // }, 10000)
    }
}
</script>

<style lang="scss">
.login-wrap {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: url(/images/login-bg.jpg) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    .ms-login {
        flex: 0;
        width: 500px;
        min-width: 500px;
        // height: 290px;
        // min-height: 290px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items:center;
        .ms-title {
            flex: 0;
            word-wrap:break-word;
            width: 100%;
            line-height: 40px;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            color: #337AB7;
            border-bottom: 2px solid #ddd;
            flex-direction: row;
            align-items:center;
            justify-content:center;
        }
        .ms-content {
            flex: 1;
            width: 100%;
            box-sizing: border-box;
            padding: 10px 60px 10px 60px;
            .login_verify_container {
                text-align: center;
                width: 100%;
            }
            .om_button_submit {
                width: 100%;
            }
        }
    }
}
</style>