<template>
<div class="om_device_event_view">
    <div class="pie">
        <om_container :title="zzzttj">
            <om_echarts ref="ref_echarts" :option="option"></om_echarts>
        </om_container>
    </div>
    <div class="statics">
        <om_container  :title="qzzzzttj">
            <table width="100%" height="100%" border="1px" align="center">
                <tr height="20px">
                    <th align="center" colspan="3">{{zzzl}}:{{shebei_total}}{{dw}}</th>
                </tr>
                <tr height="20px">
                    <th align="center">{{bjfl}}</th>
                    <th align="center">{{sl}}</th>
                    <th align="center">{{zb}}</th>
                </tr>
                <tr height="20px">
                    <td align="center">{{zzzc}}</td>
                    <td align="center">{{shebei_total-device_fault_event_total}}</td>
                    <td align="center">{{device_normal_percent}}</td>
                </tr>
                <tr height="20px">
                    <td align="center">{{zzgz}}</td>
                    <td align="center">{{device_fault_event_total}}</td>
                    <td align="center">{{device_unnormal_percent}}</td>
                </tr>
            </table>
        </om_container>
    </div>
    <div class="detail">
        <om_container :title="zzgzlbmx">
            <template v-slot:title="{title}">
                <div
                class="title"
                style="
                    width: 100%;
                    height: 100%;
                    display:flex;
                    flex-direction: row;
                    justify-content: center;
                    overflow: hidden;
                    box-sizing: border-box;
                "
                >
                    <div
                    style="
                        flex: 1;
                        display:flex;
                        flex-direction: row;
                        align-items: center;
                        overflow: hidden;
                        box-sizing: border-box;
                    "
                    >
                        {{title}}
                    </div>
                    <div
                    style="
                        flex: 0;
                        min-width: 60px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        overflow: hidden;
                        box-sizing: border-box;
                    "
                    >
                        <el-button type="success" round @click="handle_click_export_event">{{dc}}</el-button>
                    </div>
                </div>
            </template>
            <el-table
            :data="device_fault_event"
            height="100%"
            max-height= "calc(100vh - 30px)"
            :show-overflow-tooltip="true"
            border
            :stripe="true">
                <el-table-column
                align="center"
                prop="mingzi"
                :label="jczz"
                width="200">
                    <template #default="scope">
                        <div class="name"><el-tag>{{scope.row.mingzi}}</el-tag></div>
                    </template>
                </el-table-column>
                <el-table-column
                align="center"
                prop="ctime"
                :label="zdsj"
                width="180">
                    <template #default="scope">
                        <div class="name"><el-tag>{{ctime(scope.row)}}</el-tag></div>
                    </template>
                </el-table-column>
                <el-table-column
                align="center"
                prop="data_flag"
                :label="zzzt"
                width="140">
                    <template #default="scope">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <img :src="device_flag_object(scope.row).src"/>
                            <span>{{device_flag_object(scope.row).mingzi}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                align="center"
                :label="ckxq"
                min-width="120"
                width="auto">
                    <template #default="scope">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <el-button class="more" type="warning" size="small" round @click="handle_click_event(scope.row)">{{ck}}</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </om_container>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import XLSX from 'xlsx'
import moment from 'moment'
import om_container from '@/components/om_container'
import om_echarts from '@/components/om_echarts'
import { sheet2blob, openDownloadDialog, toDecimal, device_flag_object } from '@/utils'
export default {
    name: 'om_device_event_view',
    components: {
        om_container,
        om_echarts
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    computed: {
        device_fault_event() {
            const device_fault_event = this.$store.getters.device_fault_event
            return device_fault_event
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        device_fault_event_total() {
            const device_fault_event_total = this.$store.getters.device_fault_event_total
            return device_fault_event_total
        },
        device_normal_percent() {
            return toDecimal((this.shebei_total - this.device_fault_event_total) * 100 / this.shebei_total, 2)
        },
        device_unnormal_percent() {
            return toDecimal(this.device_fault_event_total * 100 / this.shebei_total, 2)
        },
        device_event_pie() {
            const shebei_total = this.shebei_total
            const device_fault_event_total = this.device_fault_event_total
            const device_normal_total = shebei_total - device_fault_event_total
            const shebei_event_pie = [
                { value: device_normal_total, name: this.zzzc },
                { value: device_fault_event_total, name: this.zzgz }
            ]
            return shebei_event_pie
        },
        zzzttj() {
            return this.$Lang.qxgl.zzzttj.name
        },
        qzzzzttj() {
            return this.$Lang.qxgl.qzzzzttj.name
        },
        zzzl() {
            return this.$Lang.qxgl.qzzzzttj.zzzl
        },
        dw() {
            return this.$Lang.qxgl.qzzzzttj.dw
        },
        bjfl() {
            return this.$Lang.qxgl.qzzzzttj.bjfl
        },
        sl() {
            return this.$Lang.qxgl.qzzzzttj.sl
        },
        zb() {
            return this.$Lang.qxgl.qzzzzttj.zb
        },
        zzzc() {
            return this.$Lang.qxgl.zzzttj.zzzc
        },
        zzgz() {
            return this.$Lang.qxgl.zzzttj.zzgz
        },
        zzgzlbmx() {
            return this.$Lang.qxgl.zzgzlbmx.name
        },
        jczz() {
            return this.$Lang.qxgl.zzgzlbmx.jczz
        },
        zdsj() {
            return this.$Lang.qxgl.zzgzlbmx.zdsj
        },
        zzzt() {
            return this.$Lang.qxgl.zzgzlbmx.zzzt
        },
        ckxq() {
            return this.$Lang.qxgl.zzgzlbmx.ckxq
        },
        ck() {
            return this.$Lang.qxgl.zzgzlbmx.ck
        },
        warn_empt() {
            return this.$Lang.qxgl.zzgzlbmx.warn_empt
        },
        dc() {
            return this.$Lang.button.dc
        },
        option() {
            const serie = {
                name: this.zzzttj,
                type: 'pie',
                center: ['50%', '50%'],
                radius: '60%',
                data: this.device_event_pie
            }
            const option = {
                title : {
                    text: this.zzzttj,
                    x:'center'
                },
                tooltip : {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {
                            show: true
                        },
                        saveAsImage : {
                            show: true
                        }
                    }
                },
                color:['#9AFF9A', '#A6A6A6'],
                calculable : true,
                series: [
                    serie
                ]
            }
            return option
        }
    },
    methods: {
        canshu(object) {
            if (object === undefined || object === null) return null
            const obj_id = object.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        ctime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const ctime = canshu.ctime
            if (ctime === undefined || ctime === null) {
                return ''
            }
            return ctime
        },
        rtime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) {
                return ''
            }
            return rtime
        },
        device_flag_object(object) {
            const data_flag = this.data_flag(object)
            return device_flag_object(data_flag)
        },
        handle_click_event(shebei) {
            const leixing_id = shebei.leixing_id
            if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) {
                this.$router.push('/condition')
                return
            }
            this.$router.push('/report')
            this.$nextTick(
                () => {
                    this.$store.commit('setSelectedShebeiObject', shebei)
                }
            )
        },
        handle_click_export_event() {
            const columns = []
            columns.unshift(
                {
                    bianma: 'data_flag',
                    mingzi: this.zzzt
                }
            )
            columns.unshift(
                {
                    bianma: 'ctime',
                    mingzi: this.zdsj
                }
            )
            columns.unshift(
                {
                    bianma: 'mingzi',
                    mingzi: this.jczz
                }
            )
            const data = JSON.parse(JSON.stringify(this.device_fault_event))
            const records = []
            for (let row = 0; row < data.length; row++) {
                const rowData = data[row]
                const record = {}
                for (let col = 0; col < columns.length; col++) {
                    const column = columns[col]
                    const field = column.bianma
                    if (field === 'mingzi') {
                        const mingzi = rowData['mingzi']
                        record[this.jczz] = mingzi
                        continue
                    }
                    if (field === 'ctime') {
                        const ctime = this.ctime(rowData)
                        record[this.zdsj] = ctime
                        continue
                    }
                    if (field === 'data_flag') {
                        const device_flag_object = this.device_flag_object(rowData)
                        record[this.zzzt] = device_flag_object.mingzi
                        continue
                    }
                }
                records.push(record)
            }
            if (records.length <= 0) {
                this.$Message({
                    type:'warning',
                    message: this.warn_empt
                })
                return
            }
            const sheet = XLSX.utils.json_to_sheet(records)
            if (sheet === undefined || sheet === null) {
                this.$Message({
                    type:'warning',
                    message:`json_to_sheet error`
                })
                return
            }
            const blob = sheet2blob(XLSX, sheet)
            if (blob === undefined || blob === null) {
                this.$Message({
                    type:'warning',
                    message:`sheet2blob error`
                })
                return
            }
            const timeFrom = moment().format('YYYYMMDD')
            const fileName = `${this.zzgzlbmx}_${timeFrom}.xls`
            openDownloadDialog(blob, fileName)
        },
        resize() {
            const ref_echarts = this.ref_echarts
            if (ref_echarts === undefined || ref_echarts === null) return
            ref_echarts.resize()
        }
    },
    async mounted() {
        this.resize()
    }
}
</script>

<style lang='scss'>
.om_device_event_view {
    width: 100%;
    height: 100%;
    background: white;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    .pie {
        flex: 0;
        height: 100%;
        min-width: 400px;
        box-sizing: border-box;
        overflow: hidden;
        padding-right: 2px;
        justify-content: flex-center;
    }
    .statics {
        flex: 0;
        min-width: 400px;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        padding-left: 2px;
        padding-right: 2px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        table {
            border-collapse: collapse;
            margin: 0 auto;
            text-align: center;
        }
        table td, table th {
            border: 1px solid #cad9ea;
            color: #666;
            height: 30px;
        }
        table thead th {
            background-color: #CCE8EB;
            width: 100px;
        }
        table tr:nth-child(odd) {
            background: #fff;
        }
        table tr:nth-child(even) {
            background: #F5FAFA;
        }
    }
    .detail {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding-left: 2px;
        .el-table {
            width: 100%;
            height: 100%;
            font-size: 12px !important;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .el-table__header-wrapper {
                box-sizing: border-box;
                width: 100%;
                overflow: hidden;
                flex: 0 auto;
                min-height: 0px;
            }
            .el-table__body-wrapper {
                width: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                flex: 1;
            }
            td, th {
                padding: 0px;
            }
            .more {
                width: 50px;
                height: 100%;
                padding: 0px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 20px !important;
            }
        }
    }
}
</style>