<template>
<div class="om_datetime_statics">
    <div class="dif">
        {{htybjc}}<h3>{{interval}}</h3>{{dw}}
    </div>
    <div style='min-height: 36px;' class="dif">
        {{dwdemo}}
    </div>
     <div class="time">
        <div class="systime">
            <om_echarts_time :title="htsj" :time="systime" ref="ref_echarts_systime"/>
        </div>
        <div class="currenttime">
            <om_echarts_time :title="bjsj" :time="currentDateTime" ref="ref_echarts_currentDateTime"/>
        </div>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import om_echarts_time from '@/components/om_echarts_time'
export default {
    name:'om_datetime_statics',
    components: {
        om_echarts_time
    },
    data() {
        return {
            currentDateTime: null,
            showTwoline: false
        }
    },
    setup() {
        const ref_echarts_systime = ref(null)
        const ref_echarts_currentDateTime = ref(null)
        return {
            ref_echarts_systime,
            ref_echarts_currentDateTime
        }
    },
    computed: {
        cac() {
            const cac = this.$store.getters.cac
            if (cac === undefined || cac === null) return null
            return cac
        },
        systime() {
            const currentDateTime = new Date()
            const cac = this.cac
            if (cac === undefined || cac === null) {
                return currentDateTime
            }
            const obj_id = cac.obj_id
            const canshu = this.$store.getters.canshu(obj_id, 7)
            if (canshu === undefined || canshu === null) {
                return currentDateTime
            }
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) {
                return currentDateTime
            }
            return this.toDateTime(rdata)
        },
        interval() {
            const systime = this.systime
            if (systime === 0) return 0
            const currentDateTime = this.currentDateTime
            if (systime === undefined || systime === null) return 0
            if (currentDateTime === undefined || currentDateTime === null) return 0
            const s1 = this.systime.getTime()
            const s2 = currentDateTime.getTime()
            const interval = parseInt((s2 - s1) / 1000)
            return interval
        },
        htybjc() {
            return this.$Lang.xtsy.yxsj.htybjc
        },
        dw() {
            return this.$Lang.xtsy.yxsj.dw
        },
        dwdemo() {
            return this.$Lang.xtsy.yxsj.dwdemo
        },
        htsj() {
            return this.$Lang.xtsy.yxsj.htsj
        },
        bjsj() {
            return this.$Lang.xtsy.yxsj.bjsj
        }
    },
    methods: {
        toDateTime(sec) {
            const secs = parseInt(sec)
            const date = new Date(secs * 1000)
            return date
        },
        resize() {
            if (this.ref_echarts_systime) {
                this.ref_echarts_systime.resize()
            }
            if (this.ref_echarts_currentDateTime) {
                this.ref_echarts_currentDateTime.resize()
            }
        }
    },
    created() {
        setInterval(
            () => {
                this.currentDateTime = new Date()
            },
            1000
        )
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.om_datetime_statics {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .dif {
        flex: 0;
        min-height: 20px;
        width: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
        box-sizing: border-box;
        word-break: break-all;
        display: flex;
        justify-content: center;
        align-items: center;
        color: cadetblue;
        font-weight: bold;
        background: cornsilk;
    }
    .time {
        width: 100%;
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        .systime {
            flex: 1;
            height: 100%;
            padding: 0;
            margin: 0;
            overflow: hidden;
            box-sizing: border-box;
        }
        .currenttime {
            flex: 1;
            height: 100%;
            padding: 0;
            margin: 0;
            overflow: hidden;
            box-sizing: border-box;
        }
    }
}
</style>
