<template>
<div class="om_shebei_event_view">
    <div class="statics">
        <om_container  :title="card_name1">
            <table width="100%" height="100%" border="1px" align="center">
                <tr height="20px">
                    <th align="center" colspan="1">{{zbj}}:{{
                        shebeiData.map(item => item.AlarmTotal).length ?
                        shebeiData.map(item => item.AlarmTotal)
                        .reduce((total, item) => total += item)
                        : 0
                    }}{{dw}}</th>
                    <th align="center" colspan="2">{{zyj}}:{{
                        shebeiData.map(item => item.WarningTotal).length ?
                        shebeiData.map(item => item.WarningTotal)
                        .reduce((total, item) => total += item)
                        : 0
                    }}{{dw}}</th>
                </tr>
                <tr height="20px">
                    <th align="center">{{zzlx}}</th>
                    <th align="center">{{bjcs}}</th>
                    <th align="center">{{yjcs}}</th>
                </tr>
                <tr
                    height="20px"
                    v-for="(item, i) in shebeiData"
                    :key="i"
                >
                    <th align="center">{{item.type}}</th>
                    <th align="center">{{item.AlarmTotal}}</th>
                    <th align="center">{{item.WarningTotal}}</th>
                </tr>
            </table>
        </om_container>
    </div>
    <div class="detail">
        <om_container :title="table_name1">
            <el-table
            :data="detailData"
            :show-overflow-tooltip="true"
            border
            :stripe="true">
                <el-table-column
                    align="center"
                    :label="jcsb"
                    width="300"
                    prop="InstallationPosition"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="type"
                    :label="sblx"
                    width="200"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="AlarmTotal"
                    :label="bjcs"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="WarningTotal"
                    :label="yjcs"
                    width="150"
                >
                </el-table-column>

                <!-- <el-table-column
                align="center"
                :label="cz"
                width="auto">
                    <template #default="scope">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <el-button class="more" type="warning" size="small" round @click="handle_click_event(scope.row)">{{ck}}</el-button>
                        </div>
                    </template>
                </el-table-column> -->
            </el-table>
        </om_container>
    </div>
</div>
</template>
<script>
import { ref } from 'vue'
import om_container from '@/components/om_container'
export default {
    name: 'om_device_event_view',
    props: {
        shebeiData: {
            type: Array,
            defalut() {
                return []
            }
        },
        detailData: {
            type: Array,
            defalut: () => ([])
        }
    },
    components: {
        om_container
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    computed: {
        card_name1() {
            return this.$Lang.Dailyeport.card.name1
        },
        card_name2() {
            return this.$Lang.Dailyeport.card.name2
        },
        zbj() {
            return this.$Lang.Dailyeport.card.zbj
        },
        zyj() {
            return this.$Lang.Dailyeport.card.zyj
        },
        zgz() {
            return this.$Lang.Dailyeport.card.zgz
        },
        dw() {
            return this.$Lang.Dailyeport.card.dw
        },
        zzlx() {
            return this.$Lang.Dailyeport.card.zzlx
        },
        bjcs() {
            return this.$Lang.Dailyeport.card.bjcs
        },
        yjcs() {
            return this.$Lang.Dailyeport.card.yjcs
        },
        gzcs() {
            return this.$Lang.Dailyeport.card.gzcs
        },

        table_name1() {
            return this.$Lang.Dailyeport.tableView.name1
        },
        table_name2() {
            return this.$Lang.Dailyeport.tableView.name2
        },
        ck() {
            return this.$Lang.Dailyeport.tableView.ck
        },
        jcsb() {
            return this.$Lang.Dailyeport.tableView.jcsb
        },
        sblx() {
            return this.$Lang.Dailyeport.tableView.sblx
        },
        cz() {
            return this.$Lang.Dailyeport.tableView.cz
        }
    },
    methods: {
        handle_click_event(shebei) {
        // console.log('shebei', shebei)
            const leixing_id = shebei.leixing_id
            if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) {
                this.$router.push('/condition')
                return
            }
            this.$router.push('/report')
            this.$nextTick(
                () => {
                    this.$store.commit('setSelectedShebeiObject', shebei)
                }
            )
        }
    }
}
</script>
<style lang='scss'>
.om_shebei_event_view {
    width: 100%;
    height: 100%;
    background: white;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    .pie {
        flex: 0;
        height: 100%;
        min-width: 400px;
        box-sizing: border-box;
        overflow: hidden;
        padding-right: 2px;
        justify-content: flex-center;
    }
    .statics {
        flex: 0;
        min-width: 600px;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        padding-left: 2px;
        padding-right: 2px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        table {
            border-collapse: collapse;
            margin: 0 auto;
            text-align: center;
        }
        table td, table th {
            border: 1px solid #cad9ea;
            color: #666;
            height: 30px;
        }
        table thead th {
            background-color: #CCE8EB;
            width: 100px;
        }
        table tr:nth-child(odd) {
            background: #fff;
        }
        table tr:nth-child(even) {
            background: #F5FAFA;
        }
    }
    .detail {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding-left: 2px;
        .el-table {
            width: 100%;
            height: 100%;
            font-size: 12px !important;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .el-table__header-wrapper {
                box-sizing: border-box;
                width: 100%;
                overflow: hidden;
                flex: 0 auto;
                min-height: 0px;
            }
            .el-table__body-wrapper {
                width: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                flex: 1;
            }
            td, th {
                padding: 0px;
            }
            .more {
                width: 50px;
                height: 100%;
                padding: 0px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 20px !important;
            }
        }
    }
}
</style>
