<template>
<div class="om_ysp_canshu_view">
    <el-tabs type="border-card" tab-position="bottom" v-model="activeName">
        <el-tab-pane :label="sjxqName" name="om_device_canshu_view">
            <om_device_canshu_view class="om_device_canshu_view" ref="om_device_canshu_view" :object="object"/>
        </el-tab-pane>
        <el-tab-pane :label="sbzfxName" name="om_ysp_sbz_view">
            <om_ysp_sbz_view class="om_ysp_sbz_view" ref="om_ysp_sbz_view" :object="object"/>
        </el-tab-pane>
        <el-tab-pane :label="dwsjfxName" name="om_ysp_dwsjx_view">
            <om_ysp_dwsjx_view class="om_ysp_dwsjx_view" ref="om_ysp_dwsjx_view" :object="object"/>
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
import om_device_canshu_view from '../om_device_canshu_view'
import om_ysp_dwsjx_view from './om_ysp_dwsjx_view'
import om_ysp_sbz_view from './om_ysp_sbz_view'
export default {
    name:'om_ysp_canshu_view',
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    components: {
        om_device_canshu_view,
        om_ysp_sbz_view,
        om_ysp_dwsjx_view
    },
    setup() {
        const activeName = 'om_device_canshu_view'
        return {
            activeName
        }
    },
    computed: {
        sjxqName() {
            return this.$Lang.jcsj.sssj.sjxq.name
        },
        sbzfxName() {
            return this.$Lang.jcsj.sssj.sbzfx.name
        },
        dwsjfxName() {
            return this.$Lang.jcsj.sssj.dwsjfx.name
        }
    }
}
</script>

<style lang="scss" scoped="true">
.om_ysp_canshu_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>