<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="300px"
height="200px"
:title="qxzjczz">
    <div class="om_dialog__body">
        <div class="leixing_range">
            <el-select
            :placeholder="qxzjczzlx"
            v-model="selected_leixing_id"
            >
                <el-option
                v-for="(leixing, index) in leixings"
                :label="leixing.mingzi"
                :value="leixing.leixing_id"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
        <div class="object_range">
            <el-select
            :placeholder="qxzjczz"
            v-model="selected_obj_id"
            >
                <el-option
                v-for="(object, index) in objects"
                :label="object.mingzi"
                :value="object.obj_id"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
    </div>
    <template v-slot:footer>
        <div class="om_dialog__footer">
            <el-button type="primary" @click="closeDialog()">{{qx}}</el-button>
            <el-button type="primary" @click="handle_commit_click()">{{qd}}</el-button>
        </div>
    </template>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
export default {
    name: 'om_upload_dialog',
    components: {
        om_dialog
    },
    setup() {
        const om_dialog = ref(null)
        return {
            om_dialog
        }
    },
    data() {
        return {
            selected_leixing_id: null,
            objects: [],
            selected_obj_id: null,
            imageUrl: null
        }
    },
    computed: {
        leixings() {
            const biandianzhans = this.$store.state.station.biandianzhans
            if (biandianzhans === undefined || biandianzhans === null) return []
            if (biandianzhans.length <= 0) return ''
            const biandianzhan = biandianzhans[0]
            const leixings = biandianzhan.leixings
            const leixings_exclude_condition = leixings.filter(
                (leixing) => {
                    const leixing_id = leixing.leixing_id
                    if (leixing_id === 31) {
                        return false
                    }
                    if (leixing_id === 34) {
                        return false
                    }
                    if (leixing_id === 35) {
                        return false
                    }
                    return true
                }
            )
            return leixings_exclude_condition
        },
        qxzjczzlx() {
            return this.$Lang.header.dtbj.xz.qxzjczzlx
        },
        qxzjczz() {
            return this.$Lang.header.dtbj.xz.qxzjczz
        },
        qx() {
            return this.$Lang.header.dtbj.xz.qx
        },
        qd() {
            return this.$Lang.header.dtbj.xz.qd
        }
    },
    watch: {
        'selected_leixing_id': {
            immidiate: true,
            deep: true,
            handler() {
                const leixing_id = this.selected_leixing_id
                if (leixing_id === undefined || leixing_id === null) return []
                const objects = this.getObjects(leixing_id)
                if (objects === undefined || objects === null) return
                if (!Array.isArray(objects)) return
                if (objects.length <= 0) return
                this.objects = objects
                this.selected_obj_id = this.objects[0].obj_id
            }
        }
    },
    methods: {
        showDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            om_dialog.showDialog()
        },
        closeDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            om_dialog.closeDialog()
        },
        handle_commit_click() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            const selected_obj_id = this.selected_obj_id
            if (selected_obj_id === undefined || selected_obj_id === null) return
            const selected_object = this.object(selected_obj_id)
            if (selected_object === undefined || selected_object === null) return
            this.$emit('commit', selected_object)
            this.$nextTick(
                () => {
                    om_dialog.closeDialog()
                }
            )
        },
        getObjects(leixing_id) {
            if (leixing_id === undefined || leixing_id === null) return []
            const map_object = this.$store.state.station.map_object
            if (map_object === undefined || map_object === null) return []
            const objects = []
            for (const key in map_object) {
                const object = map_object[key]
                if (map_object === undefined || map_object === null) continue
                const _leixing_id = object.leixing_id
                if (_leixing_id === undefined || _leixing_id === null) continue
                if (_leixing_id === 1 || _leixing_id === 2 || _leixing_id === 21 || _leixing_id === 24) continue
                if (leixing_id !== _leixing_id) continue
                objects.push(object)
            }
            return objects
        },
        object(obj_id) {
            if (obj_id === undefined || obj_id === null) return null
            const map_object = this.$store.state.station.map_object
            if (map_object === undefined || map_object === null) return []
            const object = map_object[obj_id]
            return object
        }
    },
    mounted() {
        const leixings = this.leixings
        if (leixings === undefined || leixings === null) return
        if (!Array.isArray(leixings)) return
        if (leixings.length <= 0) return
        const leixing_id = leixings[0].leixing_id
        if (leixing_id === undefined || leixing_id === null) return
        this.selected_leixing_id = leixing_id
        const objects = this.getObjects(leixing_id)
        if (objects === undefined || objects === null) return
        if (!Array.isArray(objects)) return
        if (objects.length <= 0) return
        this.objects = objects
        this.selected_obj_id = this.objects[0].obj_id
    }
}
</script>

<style lang="scss">
.om_dialog {
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .leixing_range {
            flex: 0;
            min-height: 40px;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .object_range {
            flex: 0;
            min-height: 40px;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .om_dialog__footer {
        box-sizing: border-box;
        padding: 4px;
    }
}
</style>