<template>
    <om_dialog
    ref="om_dialog"
    class="om_dialog"
    width="95%"
    height="95%"
    :title="yzbd"
    >
        <div class="om_dialog__header">
            <el-select
            placeholder="请选择对应类型"
            v-model="leixing_selected"
            @change="select_leixing"
            style="margin: 8px"
            >
                <el-option
                v-for="(leixing, index) in leixings"
                :label="leixing.mingzi"
                :value="leixing.leixing_id"
                :key="index"
                >
                </el-option>
            </el-select>
            <el-select
            placeholder="请选择对应设备"
            v-model="shebei_selected"
            @change="select_shebei"
            style="margin: 8px"
            >
                <el-option
                v-for="(shebei, index) in shebeis"
                :label="shebei.mingzi"
                :value="shebei.obj_id"
                :key="index"
                >
                </el-option>
            </el-select>
            <el-select
            placeholder="请选择对应参数"
            v-model="canshu_selected"
            style="margin: 8px"
            >
                <el-option
                v-for="(canshu, index) in canshu_option"
                :label="canshu.shuoming"
                :value="canshu.obj_id"
                :key="index"
                >
                </el-option>
            </el-select>
            <el-button type="primary" round @click="handle_query_canshu">{{cx}}</el-button>
        </div>
        <div class="om_dialog__body">
            <el-table
            :showHeader="true"
            :data="canshu_data"
            @selection-change="select_canshus"
            border
            stripe
            >
                <el-table-column
                type="selection"
                width="35"
                >
                </el-table-column>
                <el-table-column
                :label="jczz"
                align="center"
                prop="shebei_mingzi"
                width="150"
                show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                :label="jcl"
                align="center"
                prop="shuoming"
                width="150"
                show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                :label="zdz"
                align="center"
                width="160"
                prop="data"
                show-overflow-tooltip
                >
                    <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-tag>{{ dataFormat(scope.row, 'data') }}</el-tag>
                    </div>
                </template>
                </el-table-column>
                <el-table-column
                :label="jdz"
                align="center"
                width="160"
                prop="jizhun"
                show-overflow-tooltip
                >
                    <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-tag>{{ dataFormat(scope.row, 'jizhun') }}</el-tag>
                    </div>
                </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="yxsx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.youxiaoup" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="yxxx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.youxiaodn" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="bjsx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.alarmup" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="bjxx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.alarmdn" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="yjsx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.attenup" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="quanxian>0"
                :label="yjxx"
                width="130"
                align="center"
                show-overflow-tooltip
                >
                    <template #default="scope">
                        <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                            <el-input-number v-model="scope.row.attendn" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <om_rate_dialog ref="om_rate_dialog" class="om_rate_dialog" :leixing_selected="leixing_selected"></om_rate_dialog>
            <om_jizhun_dialog ref="om_jizhun_dialog" class="om_jizhun_dialog" @commit="handle_update_jizhun"></om_jizhun_dialog>
        </div>
        <template #footer>
            <div style="padding: 8px; width: 100%; height: 100%; display: flex; flex-direction:row; justify-content: flex-front; align-items: center;">
                <el-button type="warning" @click="handle_rate_event">{{ setrate }}</el-button>
                <el-button type="warning" @click="handle_init_event">{{ initjdz }}</el-button>
            </div>
            <div style="padding: 8px; width: 100%; height: 100%; display: flex; flex-direction:row; justify-content: flex-end; align-items: center;">
                <el-button type="primary" round @click="handle_calibrate_data('jizhun')">{{ setzxz }}</el-button>
                <el-button type="primary" round @click="handle_calibrate_data('data')">{{ setjdz }}</el-button>
                <el-button type="primary" round @click="handle_click_event">{{bc}}</el-button>
            </div>
        </template>
    </om_dialog>
</template>

<script>
import { ref } from 'vue'
import { canshu_flag_object, toDateTime, toDecimal } from '@/utils'
import om_dialog from '@/components/om_dialog/index.vue'
import om_rate_dialog from './om_rate_dialog'
import om_jizhun_dialog from './om_jizhun_dialog'
export default {
    name: 'om_calibrate_dialog',
    components: {
        om_dialog,
        om_rate_dialog,
        om_jizhun_dialog
    },
    data() {
        return {
            shebeis: [],
            shebei_set: [],
            select_data: [],
            canshu_data: [],
            canshu_option: [],
            leixing_selected: '',
            shebei_selected: '',
            canshu_selected: ''
        }
    },
    setup() {
        const om_dialog = ref(null)
        const om_rate_dialog = ref(null)
        const om_jizhun_dialog = ref(null)
        return {
            om_dialog,
            om_rate_dialog,
            om_jizhun_dialog
        }
    },
    computed: {
        quanxian() {
            return sessionStorage.getItem('quanxian')
        },
        leixings() {
            const leixings = this.$store.getters.leixings
            const leixings_exclude_condition = leixings.filter(
                (leixing) => {
                    if (leixing.leixing_id === 31) {
                        return false
                    }
                    if (leixing.leixing_id === 34) {
                        return false
                    }
                    if (leixing.leixing_id === 35) {
                        return false
                    }
                    return true
                }
            )
            return leixings_exclude_condition
        },
        yzbd() {
            return this.$Lang.header.yzbd.name
        },
        wsj() {
            return this.$Lang.jcsj.sssj.sjxq.wsj
        },
        jcl() {
            return this.$Lang.jcsj.sssj.sjxq.jcl
        },
        cjsj() {
            return this.$Lang.jcsj.sssj.sjxq.cjsj
        },
        ssjcz() {
            return this.$Lang.jcsj.sssj.sjxq.ssjcz
        },
        zdsj() {
            return this.$Lang.jcsj.sssj.sjxq.zdsj
        },
        zdz() {
            return this.$Lang.jcsj.sssj.sjxq.zdz
        },
        jczz() {
            return this.$Lang.jcsj.sssj.sjxq.jczz
        },
        jdz() {
            return this.$Lang.jcsj.sssj.sjxq.jdz
        },
        zdzt() {
            return this.$Lang.jcsj.sssj.sjxq.zdzt
        },
        yxsx() {
            return this.$Lang.jcsj.sssj.sjxq.yxsx
        },
        yxxx() {
            return this.$Lang.jcsj.sssj.sjxq.yxxx
        },
        bjsx() {
            return this.$Lang.jcsj.sssj.sjxq.bjsx
        },
        bjxx() {
            return this.$Lang.jcsj.sssj.sjxq.bjxx
        },
        yjsx() {
            return this.$Lang.jcsj.sssj.sjxq.yjsx
        },
        yjxx() {
            return this.$Lang.jcsj.sssj.sjxq.yjxx
        },
        jczz() {
            return this.$Lang.jcsj.sssj.sjxq.jczz
        },
        jdz() {
            return this.$Lang.jcsj.sssj.sjxq.jdz
        },
        setrate() {
            return this.$Lang.jcsj.sssj.sjxq.setrate
        },
        initjdz() {
            return this.$Lang.jcsj.sssj.sjxq.initjdz
        },
        setzxz() {
            return this.$Lang.jcsj.sssj.sjxq.setzxz
        },
        setjdz() {
            return this.$Lang.jcsj.sssj.sjxq.setjdz
        },
        alldevice() {
            return this.$Lang.jcsj.sssj.sjxq.alldevice
        },
        allcanshu() {
            return this.$Lang.jcsj.sssj.sjxq.allcanshu
        },
        selectparam() {
            return this.$Lang.jcsj.sssj.sjxq.selectparam
        },
        success() {
            return this.$Lang.jcsj.sssj.sjxq.success
        },
        cx() {
            return this.$Lang.button.cx
        },
        bc() {
            return this.$Lang.header.dtbj.bc
        }
    },
    methods: {
        showDialog() {
            this.canshu_data = []
            this.$refs.om_dialog.showDialog()
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        yxbianma(leixing_id, canshu_id, data_int) {
            if (leixing_id === undefined || leixing_id === null) {
                return data_int
            }
            if (canshu_id === undefined || canshu_id === null) {
                return data_int
            }
            const obj_id = `${leixing_id}_${canshu_id}_${parseInt(data_int)}`
            const bianma = this.$store.getters.getYxbianma(obj_id)
            if (bianma === undefined || bianma === null) {
                return data_int
            }
            return `${data_int}(${bianma.shuoming})`
        },
        toDateTime(sec) {
            return toDateTime(sec)
        },
        toDecimal(x, fixed) {
            return toDecimal(x, fixed)
        },
        danwei(danwei) {
            if (!danwei) return ''
            if (danwei === '') return ''
            return `(${danwei})`
        },
        dataFormat(canshu, flag) {
            if (canshu === undefined || canshu === null) return this.wsj
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return this.wsj
            const canshu_id = canshu.canshu_id
            if (canshu_id === undefined || canshu_id === null) return this.wsj
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return this.wsj
            const rtutype = canshu.rtutype
            if (rtutype === undefined || rtutype === null) return this.wsj
            let data = ''
            if (flag === 'data') data = canshu.rdata
            if (flag === 'jizhun') data = canshu.jizhun
            if (data === undefined || data === null) return this.wsj
            if (rtutype === 1) {
                if (datatype === 2) {
                    const data_int = parseInt(data)
                    const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
                    const bianma = this.$store.getters.getYxbianma(obj_id)
                    if (bianma === undefined || bianma === null) {
                        const danwei = canshu.danwei.trim()
                        if (danwei === undefined || danwei === null || danwei === '') {
                            const dataFormat = data_int
                            return dataFormat
                        }
                        const dataFormat = `${data_int}(${danwei})`
                        return dataFormat
                    }
                    const dataFormat = `${data_int}(${bianma.shuoming})`
                    return dataFormat
                }
                // 其他数据类型，默认按照浮点型处理
                const data_float = toDecimal(data, 3)
                const danwei = canshu.danwei.trim()
                if (danwei === undefined || danwei === null || danwei === '') {
                    const dataFormat = data_float
                    return dataFormat
                }
                const dataFormat = `${data_float}(${danwei})`
                return dataFormat
            }
            // 整型
            if (datatype === 2) {
                const data_int = parseInt(data)
                const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
                const bianma = this.$store.getters.getYxbianma(obj_id)
                if (bianma === undefined || bianma === null) {
                    const danwei = canshu.danwei.trim()
                    if (danwei === undefined || danwei === null || danwei === '') {
                        const dataFormat = data_int
                        return dataFormat
                    }
                    const dataFormat = `${data_int}(${danwei})`
                    return dataFormat
                }
                const dataFormat = `${data_int}(${bianma.shuoming})`
                return dataFormat
            }
            if (datatype === 3) {
                const data_datetime = toDateTime(data)
                return data_datetime
            }
            // 其他数据类型，默认按照浮点型处理
            const data_float = toDecimal(data, 3)
            const danwei = canshu.danwei.trim()
            if (danwei === undefined || danwei === null || danwei === '') {
                const dataFormat = data_float
                return dataFormat
            }
            const dataFormat = `${data_float}(${danwei})`
            return dataFormat
        },
        canshu_flag_object(data_flag) {
            if (data_flag === undefined || data_flag === 0) {
                data_flag = 0
            }
            const status = canshu_flag_object(data_flag)
            return status
        },
        select_canshus(selected) {
            this.select_data = selected
        },
        select_leixing(leixing_id) {
            const shebei_all = {
                obj_id: '',
                mingzi: this.alldevice
            }
            const canshu_all = {
                obj_id: '',
                shuoming: this.allcanshu
            }
            this.canshu_option = []
            this.leixings.forEach(
                (value, index) => {
                    if (value.leixing_id === leixing_id) {
                        // 获取类型下的所有参数
                        let shebeis = []
                        const obj_id = this.leixings[index].shebeis[0].obj_id
                        const canshus = this.$store.getters.canshus(obj_id)
                        for (const index in canshus) {
                            const canshu = JSON.parse(JSON.stringify(canshus[index]))
                            if (canshu.canshu_id === 1) {
                                continue
                            }
                            if (canshu.datatype === 3) {
                                continue
                            }
                            if (canshu.datatype === 4) {
                                continue
                            }
                            if (canshu.datatype === 5) {
                                continue
                            }
                            if (value.leixing_id === 18 && canshu.canshu_id === 17 ) {
                                continue
                            }
                            if (value.leixing_id === 38 && canshu.canshu_id === 17) {
                                continue
                            }
                            canshu.obj_id = canshu.obj_id.split('_')[3]
                            this.canshu_option.push(canshu)
                        }
                        shebeis = JSON.parse(JSON.stringify(this.leixings[index].shebeis))
                        this.shebeis = shebeis
                        this.shebeis.splice( 0, 0, shebei_all)
                        this.canshu_option.splice( 0, 0, canshu_all)
                        this.shebei_selected = JSON.parse(JSON.stringify(this.shebeis[0].obj_id))
                        this.canshu_selected = JSON.parse(JSON.stringify(this.canshu_option[0].obj_id))
                        return
                    }
                }
            )
        },
        select_shebei(obj_id) {
            this.shebei_set = []
            // 加载类型下全部设备
            if (obj_id === '') {
                this.shebeis.forEach(
                    (shebei, index) => {
                        if (index !== 0) {
                            this.shebei_set.push(shebei.obj_id)
                        }
                    }
                )
            }
        },
        handle_query_canshu() {
            this.canshu_data = []
            if (this.shebei_selected === '') {
                this.select_shebei('')
            }
            this.shebei_set.push(this.shebei_selected)
            this.shebei_set.forEach(
                async(value) => {
                    let shebei_mingzi = ''
                    let canshu_mingzi = ''
                    const obj_id = value
                    const canshu_id = this.canshu_selected
                    // 获取参数名字
                    if (obj_id !== undefined && obj_id !== null && obj_id !== '') {
                        const object = this.$store.getters.object(obj_id)
                        shebei_mingzi = object.mingzi
                        if (canshu_id !== undefined && canshu_id !== null && canshu_id !== '') {
                            const canshu = this.$store.getters.canshu(obj_id, canshu_id)
                            canshu_mingzi = canshu.shuoming
                        }
                    }
                    const map_canshu = await this.$store.state.station.map_canshu
                    if (map_canshu === undefined || map_canshu === null) return
                    const canshus = this.$store.getters.canshus(obj_id)
                    for (const index in canshus) {
                        const canshu = map_canshu[index]
                        // 判断指定参数
                        if (canshu.shuoming !== canshu_mingzi && this.canshu_selected !== '') {
                            continue
                        }
                        if (canshu.canshu_id === 1) {
                            continue
                        }
                        if (canshu.datatype === 3) {
                            continue
                        }
                        if (canshu.datatype === 4) {
                            continue
                        }
                        if (canshu.datatype === 5) {
                            continue
                        }
                        if (this.leixing_selected === 18 && canshu.canshu_id === 17 ) {
                            continue
                        }
                        if (this.leixing_selected === 38 && canshu.canshu_id === 17) {
                            continue
                        }
                        canshu.shebei_mingzi = shebei_mingzi
                        this.canshu_data.push(canshu)
                        this.shebei_set = []
                    }
                    return
                }
            )
        },
        handle_calibrate_data(flag) {
            const canshus = this.select_data
            if (canshus === undefined || canshus === null) return
            if (canshus.length === 0) {
                this.$message.warning(this.selectparam)
                return
            }
            canshus.forEach(
                (canshu) => {
                    canshu['youxiaoup'] = canshu['yxsx']
                    canshu['youxiaodn'] = canshu['yxxx']
                    canshu['alarmup'] = parseFloat( ( canshu[`${flag}`] * ( 1 + ( canshu['bjsx'] / 100)) ).toFixed(2) )
                    canshu['alarmdn'] = parseFloat( ( canshu[`${flag}`] * ( 1 + ( canshu['bjxx'] / 100)) ).toFixed(2) )
                    canshu['attenup'] = parseFloat( ( canshu[`${flag}`] * ( 1 + ( canshu['yjsx'] / 100)) ).toFixed(2) )
                    canshu['attendn'] = parseFloat( ( canshu[`${flag}`] * ( 1 + ( canshu['yjxx'] / 100)) ).toFixed(2) )
                }
            )
        },
        handle_update_jizhun() {
            this.om_dialog.closeDialog()
            this.$router.go(0)
        },
        handle_rate_event() {
            const om_rate_dialog = this.om_rate_dialog
            if (om_rate_dialog === undefined || om_rate_dialog === null) return
            om_rate_dialog.showDialog()
        },
        handle_init_event() {
            const om_jizhun_dialog = this.om_jizhun_dialog
            if (om_jizhun_dialog === undefined || om_jizhun_dialog === null) return
            om_jizhun_dialog.showDialog()
        },
        async handle_click_event() {
            const canshus = this.select_data
            console.log('length', canshus.length)
            if (canshus === undefined || canshus === null) return
            if (canshus.length === 0) {
                this.$message.warning(this.selectparam)
                return
            }
            try {
                const resultset = await this.$ajax.p_update_ms_relaalarm_ts(canshus)
                const code = resultset[0][0].code
                if (code === 0) {
                    this.$Message.success(this.success)
                    this.om_dialog.closeDialog()
                    // this.$router.go(0)
                    return
                }
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_update_ms_relaalarm_ts,错误原因：${error}`
                })
                return
            }
        }
    },
    async mounted() {
        this.leixing_selected = JSON.parse(JSON.stringify(this.leixings[0].leixing_id))
        this.select_leixing(this.leixing_selected)
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .om_dialog__header {
        width: 100%;
        height: 100%;
        max-height: 40px;
        position: relative;
        box-sizing: border-box;
    }
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .om_rate_dialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,0.5);
            overflow: hidden;
            z-index: 600;
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }
        .om_jizhun_dialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,0.5);
            overflow: hidden;
            z-index: 600;
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }
    }
}
</style>