<template>
<div class="om_bdz_equip_health_statics">
    <table width="100%" height="100%" border="1px" align="center">
        <tr height="20px">
            <th align="center">{{bjfl}}</th>
            <th align="center">{{sl}}</th>
            <th align="center">{{zb}}</th>
        </tr>
        <tr height="20px">
            <td align="center">{{sjzz}}</td>
            <td align="center">{{shebei_total-shebei_alarm_event_total-shebei_atten_event_total}}</td>
            <td align="center">{{shebei_normal_percent}}</td>
        </tr>
        <tr height="20px">
            <td align="center">{{sjyj}}</td>
            <td align="center">{{shebei_atten_event_total}}</td>
            <td align="center">{{shebei_atten_percent}}</td>
        </tr>
        <tr height="20px">
            <td align="center">{{sjbj}}</td>
            <td align="center">{{shebei_alarm_event_total}}</td>
            <td align="center">{{shebei_alarm_percent}}</td>
        </tr>
    </table>
</div>
</template>

<script>
import { toDecimal, equip_flag_object } from '@/utils'
export default {
    name: 'om_bdz_equip_health_statics',
    components: {
    },
    computed: {
        shebei_fault_event() {
            const shebei_fault_event = this.$store.getters.shebei_fault_event
            return shebei_fault_event
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        shebei_alarm_event_total() {
            const shebei_alarm_event_total = this.$store.getters.shebei_alarm_event_total
            return shebei_alarm_event_total
        },
        shebei_atten_event_total() {
            const shebei_atten_event_total = this.$store.getters.shebei_atten_event_total
            return shebei_atten_event_total
        },
        shebei_event_pie() {
            const shebei_total = this.shebei_total
            const shebei_alarm_event_total = this.shebei_alarm_event_total
            const shebei_atten_event_total = this.shebei_atten_event_total
            const shebei_normal_total = shebei_total - shebei_alarm_event_total - shebei_atten_event_total
            const shebei_event_pie = [
                { value: shebei_normal_total, name:'数据正常' },
                { value: shebei_atten_event_total, name:'数据预警' },
                { value: shebei_alarm_event_total, name:'数据报警' }
            ]
            return shebei_event_pie
        },
        shebei_normal_percent() {
            return toDecimal((this.shebei_total - this.shebei_alarm_event_total - this.shebei_atten_event_total) * 100 / this.shebei_total, 2)
        },
        shebei_alarm_percent() {
            return toDecimal(this.shebei_alarm_event_total * 100 / this.shebei_total, 2)
        },
        shebei_atten_percent() {
            return toDecimal(this.shebei_atten_event_total * 100 / this.shebei_total, 2)
        },
        bjfl() {
            return this.$Lang.xtsy.qzsjzttj.bjfl
        },
        sl() {
            return this.$Lang.xtsy.qzsjzttj.sl
        },
        zb() {
            return this.$Lang.xtsy.qzsjzttj.zb
        },
        sjzz() {
            return this.$Lang.xtsy.qzsjzttj.sjzz
        },
        sjyj() {
            return this.$Lang.xtsy.qzsjzttj.sjyj
        },
        sjbj() {
            return this.$Lang.xtsy.qzsjzttj.sjbj
        }
    },
    methods: {
        canshu(object) {
            if (object === undefined || object === null) return null
            const obj_id = object.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        ctime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const ctime = canshu.ctime
            if (ctime === undefined || ctime === null) {
                return ''
            }
            return ctime
        },
        rtime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) {
                return ''
            }
            return rtime
        },
        equip_flag_object(object) {
            const data_flag = this.data_flag(object)
            return equip_flag_object(data_flag)
        }
    }
}
</script>

<style lang="scss" scoped>
.om_bdz_equip_health_statics {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    table {
        border-collapse: collapse;
        margin: 0 auto;
        text-align: center;
    }
    table td, table th {
        border: 1px solid #cad9ea;
        color: #666;
        height: 30px;
    }
    table thead th {
        background-color: #CCE8EB;
        width: 100px;
    }
    table tr:nth-child(odd) {
        background: #fff;
    }
    table tr:nth-child(even) {
        background: #F5FAFA;
    }
}
</style>
