<template>
<div class="om_bdz_device_health_statics">
    <table width="100%" height="100%" border="1px" align="center">
        <tr height="20px">
            <th align="center">{{bjfl}}</th>
            <th align="center">{{sl}}</th>
            <th align="center">{{zb}}</th>
        </tr>
        <tr height="20px">
            <td align="center">{{zzzc}}</td>
            <td align="center">{{shebei_total-device_fault_event_total}}</td>
            <td align="center">{{device_normal_percent}}</td>
        </tr>
        <tr height="20px">
            <td align="center">{{zzgz}}</td>
            <td align="center">{{device_fault_event_total}}</td>
            <td align="center">{{device_unnormal_percent}}</td>
        </tr>
    </table>
</div>
</template>

<script>
import { toDecimal, device_flag_object } from '@/utils'
export default {
    name: 'om_bdz_device_health_statics',
    computed: {
        device_fault_event() {
            const device_fault_event = this.$store.getters.device_fault_event
            return device_fault_event
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        device_fault_event_total() {
            const device_fault_event_total = this.$store.getters.device_fault_event_total
            return device_fault_event_total
        },
        device_normal_percent() {
            return toDecimal((this.shebei_total - this.device_fault_event_total) * 100 / this.shebei_total, 2)
        },
        device_unnormal_percent() {
            return toDecimal(this.device_fault_event_total * 100 / this.shebei_total, 2)
        },
        device_event_pie() {
            const shebei_total = this.shebei_total
            const device_fault_event_total = this.device_fault_event_total
            const device_normal_total = shebei_total - device_fault_event_total
            const shebei_event_pie = [
                { value: device_normal_total, name:'装置正常' },
                { value: device_fault_event_total, name:'装置故障' }
            ]
            return shebei_event_pie
        },
        bjfl() {
            return this.$Lang.xtsy.qzzzzttj.bjfl
        },
        sl() {
            return this.$Lang.xtsy.qzzzzttj.sl
        },
        zb() {
            return this.$Lang.xtsy.qzzzzttj.zb
        },
        zzzc() {
            return this.$Lang.xtsy.qzzzzttj.zzzc
        },
        zzgz() {
            return this.$Lang.xtsy.qzzzzttj.zzgz
        }
    },
    methods: {
        canshu(object) {
            if (object === undefined || object === null) return null
            const obj_id = object.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        ctime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const ctime = canshu.ctime
            if (ctime === undefined || ctime === null) {
                return ''
            }
            return ctime
        },
        rtime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) {
                return ''
            }
            return rtime
        },
        device_flag_object(object) {
            const data_flag = this.data_flag(object)
            return device_flag_object(data_flag)
        }
    }
}
</script>

<style lang='scss'>
.om_bdz_device_health_statics {
    width: 100%;
    height: 100%;
    background: white;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    table {
        border-collapse: collapse;
        margin: 0 auto;
        text-align: center;
    }
    table td, table th {
        border: 1px solid #cad9ea;
        color: #666;
        height: 30px;
    }
    table thead th {
        background-color: #CCE8EB;
        width: 100px;
    }
    table tr:nth-child(odd) {
        background: #fff;
    }
    table tr:nth-child(even) {
        background: #F5FAFA;
    }
}
</style>