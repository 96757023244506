export default function (options = {}) {
    const bodyWidht = window.innerWidth;
    let moreBarWidth = bodyWidht < 1920 ? true : false;
    const { xAxisData, seriesData, ...rest } = options;
    const defaultOptions = {
        tooltip: {
            trigger: "axis",
        },
        grid: {
            top: 10,
            left: 40,
            right: 30,
            bottom: moreBarWidth ? 50 : 20,
        },
        series:
            seriesData &&
            seriesData.map(item => ({
                ...item,
                type: "pie",
                barWidth: moreBarWidth ? 12 : 24,
            })),
    };
    return Object.keys(rest).reduce((acc, key) => {
        if (acc[key]) {
            acc[key] = Object.assign(acc[key], rest[key]);
        } else {
            acc[key] = rest[key];
        }
        return acc;
    }, defaultOptions);
}
