<template>
<div class="om_typical_file_list">
    <div class="filelist">
        <el-table
        ref="om_file_list"
        @current-change="handle_current_change"
        :data="fileList"
        :current-row-key="selectedFileName"
        row-key="shortname"
        highlight-current-row
        stripe
        >
            <el-table-column
            align="center"
            :label="ptlb"
            prop="shortname"
            show-overflow-tooltip
            />
        </el-table>
    </div>
</div>
</template>

<script>
export default {
    name:'om_typical_file_list',
    props: {
        leixing_id: {
            type: [String, Number],
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            fileList: [],
            selectedFileName: null
        }
    },
    watch: {
        'leixing_id': {
            immidiate: true,
            deep: true,
            async handler() {
                await this.reload()
                this.setSelectedFileName()
            }
        }
    },
    computed: {
        ptlb() {
            return this.$Lang.dxpt.ptlb
        }
    },
    methods: {
        async reload() {
            try {
                const leixing_id = this.leixing_id
                if (leixing_id === undefined || leixing_id === null) {
                    this.fileList = []
                    this.selectedFileName = null
                    return
                }
                const params = {
                    leixing_id: leixing_id
                }
                const resultset = await this.$ajax.getTypicalFileList(params)
                if (resultset[1] === undefined || resultset[1] === null || resultset[1].length === 0) {
                    this.fileList = []
                    this.selectedFileName = null
                    return
                }
                const fileList = resultset[1]
                await this.$nextTick()
                this.fileList = fileList
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`getTypicalFileList 异常, 错误原因:${error}`
                })
            }
        },
        setSelectedFileName(name) {
            if (name === undefined || name === null) {
                const fileList = this.fileList
                if (fileList === undefined || fileList === null || fileList.length <= 0) return
                const fileSelected = fileList[0]
                const selectedFileName = fileSelected.shortname
                this.selectedFileName = selectedFileName
                //
                this.$emit('current-change', selectedFileName)
                return
            }
            if (this.selectedFileName === name) return
            this.selectedFileName = name
            //
            this.$emit('current-change', name)
        },
        handle_current_change(e) {
            this.$emit('current-change', e.shortname)
        }
    },
    async mounted() {
        await this.reload()
        this.setSelectedFileName
    }
}
</script>

<style lang="scss">
.om_typical_file_list {
    flex: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    .filelist {
        flex: 1;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        .el-table {
            width: 100%;
            height: 100%;
            font-size: 12px !important;
            overflow: hidden !important;
            display: flex;
            flex-direction: column;
            .el-table__header-wrapper {
                overflow: hidden !important;
                flex: 0 auto;
                width: 100%;
                min-height: 0px;
            }
            .el-table__body-wrapper {
                flex: 1;
                width: 100%;
                overflow-x: hidden !important;
                overflow-y: auto !important;
                display: block;
            }
            td, th {
                padding: 0px;
            }
        }
    }
}
</style>