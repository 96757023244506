<template>
<div class="om_device_canshu_view">
    <div class="item_table">
        <el-table
        ref="om_device_canshu_view_table"
        :data="canshus"
        :showHeader="true"
        border
        stripe
        >
            <el-table-column
            :label="jcl"
            align="center"
            prop="shuoming"
            :width="width200"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="cjsj"
            align="center"
            prop="rtime"
            :width="width160"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="ssjcz"
            align="center"
            :width="width160"
            prop="data"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-tag>{{ rdataFormat(scope.row) }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="zdsj"
            align="center"
            prop="ctime"
            :width="width160"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            :label="zdz"
            align="center"
            :width="width160"
            prop="data"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-tag>{{ dataFormat(scope.row) }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="zdzt"
            :width="width100"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <img :src="canshu_flag_object(scope.row.data_flag).src" alt=""/>
                        {{canshu_flag_object(scope.row.data_flag).mingzi}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="yxsx"
            :width="width100"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        {{scope.row.youxiaoup}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="yxxx"
            :width="width100"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        {{scope.row.youxiaodn}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="bjsx"
            :width="width100"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        {{scope.row.alarmup}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="bjxx"
            :width="width100"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        {{scope.row.alarmdn}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="yjsx"
            :width="width100"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        {{scope.row.attenup}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            v-if="quanxian>0"
            :label="yjxx"
            :width="width100"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        {{scope.row.attendn}}
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <!-- <div class="item_health" v-show="isSF6">
        <om_container :title="sf6szyb" style="width:100%; height:100%;">
            <om_device_condition_health_statics ref="om_device_condition_health_statics" :object="object" />
        </om_container>
    </div> -->
</div>
</template>

<script>
import { canshu_flag_object, toDateTime, toDecimal } from '@/utils'
import om_container from '@/components/om_container'
import om_device_condition_health_statics from './om_device_condition_health_statics'
import { ref } from 'vue'
export default {
    name:'om_device_canshu_view',
    components: {
        om_container,
        om_device_condition_health_statics
    },
    setup() {
        const om_device_condition_health_statics = ref(null)
        return {
            om_device_condition_health_statics
        }
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    computed: {
        quanxian() {
            return sessionStorage.getItem('quanxian')
        },
        width200() {
            const quanxian = this.quanxian
            return quanxian > 0 ? 200 : 400
        },
        width160() {
            const quanxian = this.quanxian
            return quanxian > 0 ? 160 : 300
        },
        width100() {
            const quanxian = this.quanxian
            return quanxian > 0 ? 100 : 200
        },
        isSF6() {
            const object = this.object
            if (object === undefined || object === null) return false
            const leixing_id = object.leixing_id
            return leixing_id === 29
        },
        canshus() {
            const map_canshu = this.$store.state.station.map_canshu
            if (map_canshu === undefined || map_canshu === null) return []
            const object = this.object
            if (object === undefined || object === null) return []
            const obj_canshus = object.canshus
            if (obj_canshus === undefined || obj_canshus === null) return []
            const canshus = []
            for (const key in obj_canshus) {
                const canshu = map_canshu[key]
                if (canshu.canshu_id === 1) {
                    continue
                }
                if (canshu.datatype === 4) {
                    continue
                }
                if (canshu.datatype === 5) {
                    continue
                }
                canshus.push(canshu)
            }
            return canshus
        },
        wsj() {
            return this.$Lang.jcsj.sssj.sjxq.wsj
        },
        jcl() {
            return this.$Lang.jcsj.sssj.sjxq.jcl
        },
        cjsj() {
            return this.$Lang.jcsj.sssj.sjxq.cjsj
        },
        ssjcz() {
            return this.$Lang.jcsj.sssj.sjxq.ssjcz
        },
        zdsj() {
            return this.$Lang.jcsj.sssj.sjxq.zdsj
        },
        zdz() {
            return this.$Lang.jcsj.sssj.sjxq.zdz
        },
        zdzt() {
            return this.$Lang.jcsj.sssj.sjxq.zdzt
        },
        yxsx() {
            return this.$Lang.jcsj.sssj.sjxq.yxsx
        },
        yxxx() {
            return this.$Lang.jcsj.sssj.sjxq.yxxx
        },
        bjsx() {
            return this.$Lang.jcsj.sssj.sjxq.bjsx
        },
        bjxx() {
            return this.$Lang.jcsj.sssj.sjxq.bjxx
        },
        yjsx() {
            return this.$Lang.jcsj.sssj.sjxq.yjsx
        },
        yjxx() {
            return this.$Lang.jcsj.sssj.sjxq.yjxx
        },
        sf6szyb() {
            return this.$Lang.jcsj.sssj.sjxq.sf6yb
        }
    },
    methods: {
        resize() {
            if (this.om_device_condition_health_statics) {
                this.om_device_condition_health_statics.resize()
            }
        },
        dataFormat(canshu) {
            if (canshu === undefined || canshu === null) return this.wsj
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return this.wsj
            const canshu_id = canshu.canshu_id
            if (canshu_id === undefined || canshu_id === null) return this.wsj
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return this.wsj
            const rtutype = canshu.rtutype
            if (rtutype === undefined || rtutype === null) return this.wsj
            const data = canshu.data
            if (data === undefined || data === null) return this.wsj
            if (rtutype === 1) {
                if (datatype === 2) {
                    const data_int = parseInt(data)
                    const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
                    const bianma = this.$store.getters.getYxbianma(obj_id)
                    if (bianma === undefined || bianma === null) {
                        const danwei = canshu.danwei.trim()
                        if (danwei === undefined || danwei === null || danwei === '') {
                            const dataFormat = data_int
                            return dataFormat
                        }
                        const dataFormat = `${data_int}(${danwei})`
                        return dataFormat
                    }
                    const dataFormat = `${data_int}(${bianma.shuoming})`
                    return dataFormat
                }
                // 其他数据类型，默认按照浮点型处理
                const data_float = toDecimal(data, 3)
                const danwei = canshu.danwei.trim()
                if (danwei === undefined || danwei === null || danwei === '') {
                    const dataFormat = data_float
                    return dataFormat
                }
                const dataFormat = `${data_float}(${danwei})`
                return dataFormat
            }
            // 整型
            if (datatype === 2) {
                const data_int = parseInt(data)
                const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
                const bianma = this.$store.getters.getYxbianma(obj_id)
                if (bianma === undefined || bianma === null) {
                    const danwei = canshu.danwei.trim()
                    if (danwei === undefined || danwei === null || danwei === '') {
                        const dataFormat = data_int
                        return dataFormat
                    }
                    const dataFormat = `${data_int}(${danwei})`
                    return dataFormat
                }
                const dataFormat = `${data_int}(${bianma.shuoming})`
                return dataFormat
            }
            if (datatype === 3) {
                const data_datetime = data
                return data_datetime
            }
            // 其他数据类型，默认按照浮点型处理
            const data_float = toDecimal(data, 3)
            const danwei = canshu.danwei.trim()
            if (danwei === undefined || danwei === null || danwei === '') {
                const dataFormat = data_float
                return dataFormat
            }
            const dataFormat = `${data_float}(${danwei})`
            return dataFormat
        },
        rdataFormat(canshu) {
            if (canshu === undefined || canshu === null) return this.wsj
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return this.wsj
            const canshu_id = canshu.canshu_id
            if (canshu_id === undefined || canshu_id === null) return this.wsj
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return this.wsj
            const rtutype = canshu.rtutype
            if (rtutype === undefined || rtutype === null) return this.wsj
            const data = canshu.rdata
            if (data === undefined || data === null) return this.wsj
            if (rtutype === 1) {
                if (datatype === 2) {
                    const data_int = parseInt(data)
                    const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
                    const bianma = this.$store.getters.getYxbianma(obj_id)
                    if (bianma === undefined || bianma === null) {
                        const danwei = canshu.danwei.trim()
                        if (danwei === undefined || danwei === null || danwei === '') {
                            const dataFormat = data_int
                            return dataFormat
                        }
                        const dataFormat = `${data_int}(${danwei})`
                        return dataFormat
                    }
                    const dataFormat = `${data_int}(${bianma.shuoming})`
                    return dataFormat
                }
                // 其他数据类型，默认按照浮点型处理
                const data_float = toDecimal(data, 3)
                const danwei = canshu.danwei.trim()
                if (danwei === undefined || danwei === null || danwei === '') {
                    const dataFormat = data_float
                    return dataFormat
                }
                const dataFormat = `${data_float}(${danwei})`
                return dataFormat
            }
            // 整型
            if (datatype === 2) {
                const data_int = parseInt(data)
                const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
                const bianma = this.$store.getters.getYxbianma(obj_id)
                if (bianma === undefined || bianma === null) {
                    const danwei = canshu.danwei.trim()
                    if (danwei === undefined || danwei === null || danwei === '') {
                        const dataFormat = data_int
                        return dataFormat
                    }
                    const dataFormat = `${data_int}(${danwei})`
                    return dataFormat
                }
                const dataFormat = `${data_int}(${bianma.shuoming})`
                return dataFormat
            }
            if (datatype === 3) {
                const data_datetime = toDateTime(data)
                return data_datetime
            }
            // 其他数据类型，默认按照浮点型处理
            const data_float = toDecimal(data, 3)
            const danwei = canshu.danwei.trim()
            if (danwei === undefined || danwei === null || danwei === '') {
                const dataFormat = data_float
                return dataFormat
            }
            const dataFormat = `${data_float}(${danwei})`
            return dataFormat
        },
        canshu_flag_object(data_flag) {
            if (data_flag === undefined || data_flag === 0) {
                data_flag = 0
            }
            const status = canshu_flag_object(data_flag)
            return status
        }
    },
    mounted() {
        // const xs = this.object.leixing_id
        // this.$Message.warning('sdd: ' + xs)
    }
}
</script>

<style lang="scss">
.om_device_canshu_view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .item_table {
        width: 100%;
        height: 100%;
        overflow: hidden;
        // flex: 1 1;
    }
    .el-table {
        width: 100%;
        height: 100%;
        font-size: 12px !important;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;
        font-size: 12px !important;
        .el-table__header-wrapper {
            overflow: hidden !important;
            flex: 0 auto;
            width: 100%;
            min-height: 0px;
        }
        .el-table__body-wrapper {
            flex: 1;
            width: 100%;
            overflow: auto !important;
            display: block;
        }
        td, th {
            padding: 2px;
        }
        .el-image {
            width: 100%;
            height: 100%;
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            img {
                flex: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .more {
        width: 80px;
        height: 20px;
        padding: 0px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 20px !important;
    }
    .item_health {
        // flex: 0;
        width: 100%;
        height: 60%;
        min-height: 280px;
        // flex: 0 0;
        margin: 0px;
        padding: 4px;
        padding-top: 2px;
        padding-left: 2px;
        overflow: hidden;
        box-sizing: border-box;
        border: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>