<!--
 * @Author: wugs@weijie.com w19920622
 * @Date: 2022-08-02 22:05:19
 * @LastEditors: wugs@weijie.com w19920622
 * @LastEditTime: 2022-09-05 22:07:38
 * @FilePath: \omWork\om\web\om_a300_view\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
<div id="app">
    <el-config-provider :locale="locale" :size="size" :zIndex="zIndex">
        <router-view>
        </router-view>
    </el-config-provider>
</div>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import ru from 'element-plus/lib/locale/lang/ru'

export default {
    name: 'app',
    components: {
        [ElConfigProvider.name]: ElConfigProvider
    },
    setup() {
        return {
            size: 'small',
            zIndex: 3000
        }
    },
    computed: {
        locale() {
            const systemLang = localStorage.getItem('lang')
            if (systemLang === 'zhCn') {
                return zhCn
            }
            if (systemLang === 'en') {
                return en
            }
            if (systemLang === 'ru') {
                return ru
            }
            return zhCn
        }
    }
}
</script>

<style lang="scss">
html, body {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    box-sizing: border-box;
    user-select: none;
    font-family: monospace;
    font-size: 12px;
    #app {
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
        overflow: hidden;
    }
}

</style>
