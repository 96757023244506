<template>
<div class="om_main_view">
    <om_init_data style='display:none;'/>
    <om_websocket style='display:none;'/>
    <div class="om_main_view__header">
        <om_header_view />
    </div>
    <div class="om_main_view__body">
        <router-view v-slot="{ Component }" name="om_main_router_view">
            <transition>
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </transition>
        </router-view>
    </div>
    <div class="om_main_view__footer">
        <om_footer_view />
    </div>
</div>
</template>

<script>
// 引入WEBSOCKET获取实时数据
import om_init_data from '@/components/om_init_data'
import om_websocket from '@/components/om_websocket'
import om_header_view from './om_header_view'
import om_footer_view from './om_footer_view'
export default {
    name:'om_main_view',
    components: {
        om_init_data,
        om_websocket,
        om_header_view,
        om_footer_view
    },
    mounted() {
        this.$router.push('/home')
    }
}
</script>

<style lang="scss" scoped>
.om_main_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #2376b7;
    .om_main_view__header {
        flex: 0;
        height: 58px;
        min-height: 58px;
        width: 100%;
        overflow: hidden;
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }
    .om_main_view__body {
        flex: 1;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        background: white;
        border-left: 4px solid #2376b7;
        border-right: 4px solid #2376b7;
        border-radius: 8px;
    }
    .om_main_view_footer {
        flex: 0;
        width: 100%;
        min-height: 24px;
        overflow: hidden;
        box-sizing: border-box;
        background: 2376b7;
    }
}
</style>
