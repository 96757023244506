<template>
<div class="om_gispd_prp_view">
    <om_echarts ref="ref_echarts" class="om_echart_view" :option="option" />
</div>
</template>

<script>
import { ref } from 'vue'
import om_echarts from '@/components/om_echarts'
export default {
    name:'om_gispd_prp_view',
    components: {
        om_echarts
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    computed: {
        prp() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 20)
            if (canshu === undefined || canshu === null) return null
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) return null
            return rdata
        },
        fdl() {
            return this.$Lang.jcsj.sssj.ssfdlpt.fdl
        },
        ge() {
            return this.$Lang.jcsj.sssj.ssfdlpt.ge
        },
        gs() {
            return this.$Lang.jcsj.sssj.ssfdlpt.gs
        },
        zdz() {
            return this.$Lang.jcsj.sssj.ssfdlpt.zdz
        },
        option() {
            let prp = this.prp
            if (prp === null || prp === undefined) {
                prp = []
            }
            if (!Array.isArray(prp)) {
                prp = []
            }
            const option = {
                toolbox: {
                    show: true,
                    feature: {
                        dataView: {
                            show: true
                        },
                        restore: {
                            show: true
                        },
                        saveAsImage: {
                            show: true
                        }
                    }
                },
                visualMap: {
                    show: true,
                    right:0,
                    dimension: 1,
                    calculable: true,
                    align: 'top',
                    top: 'middle',
                    min:0,
                    max:10000,
                    formatter: '{value}pC',
                    inRange: {
                        color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
                    }
                },
                backgroundColor:'#b0d5df',
                tooltip: {},
                grid:
                {
                    left:40,
                    right:20,
                    top:40,
                    bottom:20
                },
                xAxis: {
                    type: 'value',
                    name: this.ge,
                    scale: true,
                    interval: 100,
                    boundaryGap: ['0%', '0%'],
                    min:0,
                    max:650,
                    axisLabel: { formatter: this.gs }
                },
                yAxis: {
                    type: 'value',
                    name: `${this.fdl}(pC)`,
                    boundaryGap: ['0%', '0%'],
                    min: 0,
                    max:'dataMax',
                    scale: true,
                    axisLabel: { formatter: '{value}' }
                },
                series: [
                    {
                        type:'bar',
                        smooth: true,
                        showSymbol: false,
                        markPoint: {
                            data: [
                                { type: 'max', name: this.zdz }
                            ]
                        },
                        data: prp
                    }
                ]
            }
            return option
        }
    },
    methods: {
        resize() {
            const ref_echarts = this.ref_echarts
            if (ref_echarts === undefined || ref_echarts === null) return
            ref_echarts.resize()
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.om_gispd_prp_view {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .om_echart_view {
        width: 100%;
        height:100%;
    }
}
</style>