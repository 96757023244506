<template>
<div class="om_history_file_list">
    <div class="time_view">
            <el-date-picker
            type = "date"
            :clearable="false"
            :placeholder="xzrq"
            :size="size"
            v-model = "curday">
                <template #default="cell">
                    <div class="cell" >
                        <span >{{ cell.text }}</span>
                        <span :class="{ date_alarm: isAlarm(cell.date)}"></span>
                    </div>
                </template>
            </el-date-picker>
        </div>
    <div class="file_list">
    <el-tree
    :props="defaultProps"
    :data="dayFileList"
    :current-node-key="current_node_key"
    height="100%"
    max-height= "calc(100vh - 30px)"
    node-key="shortname"
    :default-expand-all="true"
    :expand-on-click-node="false"
    :highlight-current="true"
    @node-click="handle_node_click"
    :empty-text="ptlb">
        <template #default="{ data }">
            <div class="custom_tree_node" >
                <span class="mingzi" style="color: red;" v-show="data.flag === 1">{{data.shortname}}</span>
                <span class="mingzi" v-show="data.flag === 0">{{data.shortname}}</span>
            </div>
        </template>
    </el-tree>
    <!-- <el-list
    :props="defaultProps"
    :data="dayFileList"
    @node-click="handle_node_click">
        <template #default="{ data }">
            <div class="custom_tree_node" >
                <span class="mingzi" style="color: red;" v-show="data.flag === 1">{{data.shortname}}</span>
                <span class="mingzi" v-show="data.flag === 0">{{data.shortname}}</span>
            </div>
        </template>
    </el-list> -->
    </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
    name:'om_history_file_list',
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'shortname'
            },
            pathFileList: [],
            dayFileList: [],
            alarmlist: [],
            curday: moment()
            // pickerOptions: {
            //     cellClassName(date) {
            //         this.$Message.warning('sss' + date.getDay)
            //         if (date.getDate === 0) {
            //             return 'c-red'
            //         }
            //     }
            // }
        }
    },
    props: {
        fileList: {
            type: Array,
            default: () => {
                return []
            }
        },
        selectedFileName: {
            type: String,
            default: () => {
                return ''
            }
        }
        // curday: {
        //     type: Object,
        //     default: () => {
        //         return new Date()
        //     }
        // }
    },
    watch: {
        curday: {
            imidiate: true,
            deep: true,
            handler() {
                this.filterFileList()
            }
        }
    },
    computed: {
        rdtime() {
            return this.pathFileList.length
        },
        ptlb() {
            return ''
            // return this.$Lang.jcsj.ptfx.ptlb
        },
        xzrq() {
            return this.$Lang.jcsj.ptfx.xzrq
        }
    },
    mounted() {
        this.curday = new Date()
    },
    methods: {
        handle_current_change(e) {
            this.$emit('current-change', e)
        },
        isAlarm(celldate) {
            return this.alarmlist.indexOf(celldate.format('yyyy-MM-dd')) >= 0
            // this.$Message.warning(celldate.format('yyyy-MM-dd'))
        },
        handle_node_click(e) {
            // this.$Message.warning(e.shortname)
            this.$emit('current-change', e)
            // if (e.children === undefined || e.children === null) {
            //     this.$emit('current-change', e)
            // }
        },
        init_file_list(filelist) {
            if (filelist === null || filelist === undefined) {
                this.pathFileList = []
                this.filterPathList = []
                this.dayFileList = []
                this.alarmlist = []
                // this.$Message.warning('ssdd')
                return true
            }
            this.pathFileList = filelist
            this.alarmlist = []
            // const aa = []
            const pathlist = this.pathFileList
            for (let i = 0; i < pathlist.length; i++) {
                const item = pathlist[i]
                if (item.flag === 1) {
                    this.alarmlist.push(item.shortname)
                }
                // aa.push(item.shortname)
            }
            // this.$Message.warning(JSON.stringify('al' + aa + '>>larm:' + this.alarmlist))
            this.filterFileList()
            return true
        },
        filterFileList() {
            const day = moment(this.curday)
            let dayname = ''
            if (day === null) {
                dayname = (new Date()).format('yyyy-MM-dd')
            } else {
                dayname = day.format('yyyy-MM-DD')
            }
            this.dayFileList = []
            const pathlist = this.pathFileList
            for (let i = 0; i < pathlist.length; i++) {
                const item = pathlist[i]
                if (dayname === item.shortname) {
                    this.dayFileList = item.children
                    break
                }
            }
            window.console.log('files1', this.pathFileList)
            window.console.log('files', this.dayFileList)
            if (this.dayFileList.length > 0) {
                this.handle_node_click(this.dayFileList[0])
            } else {
                this.handle_node_click(null)
            }
        },
        async getCurrentFileList(date) {
            const mindate = date
            const maxdate = date
            mindate.setDate(date.getDate() - 5)
            maxdate.setDate(date.getDate() + 5)
            const y1 = mindate.getFullYear()
            const m1 = mindate.getMonth()
            const d1 = mindate.getDay()
            const y2 = maxdate.getFullYear()
            const m2 = maxdate.getMonth()
            const d2 = maxdate.getDay()
            const mindatename = '' + y1 + (m1 < 10 ? '0' : '') + m1 + (d1 < 10 ? '0' : '') + d1
            const maxdatename = '' + y2 + (m2 < 10 ? '0' : '') + m2 + (d2 < 10 ? '0' : '') + d2
            try {
                const object = this.object
                const params = {
                    bdz_id: object.bdz_id,
                    leixing_id: object.leixing_id,
                    shebei_id: object.shebei_id
                }
                const resultset = await this.$ajax.getFileList(params)
                if (resultset[1] === undefined || resultset[1] === null || resultset[1].length === 0) {
                    this.fileList = []
                    this.pathFilelist = []
                    this.selectedFileName = null
                    return
                }
                const fileList = resultset[1]
                const items = {}
                const def = {
                    shortname : 'def',
                    children : []
                }
                for (let i = 0; i < fileList.length; i++) {
                    const f = fileList[i]
                    const p = f.shortname.indexOf('_')
                    if (p === 8) {
                        const key = f.shortname.substring(0, 4) + '-' + f.shortname.substring(4, 6) + '-' + f.shortname.substring(6, 8)
                        const len = f.shortname.length
                        const flag = len >= 21 && f.shortname.substring(20, 21) === '1' ? 1 : 0
                        let ex = items[key]
                        if (ex === undefined || ex === null) {
                            ex = {
                                shortname : key,
                                flag : 0,
                                children : []
                            }
                        }
                        ex.children.push({
                            shortname : f.shortname,
                            flag : flag
                        })
                        if (flag === 1) {
                            ex.flag = 1
                        }
                        items[key] = ex
                    } else {
                        def.children.push({
                            shortname : f.shortname,
                            flag : 0
                        })
                    }
                }
                const list = []
                for (const key2 in items) {
                    if (key2 >= mindatename && key2 <= maxdatename) {
                        list.push(items[key2])
                    }
                }
                if (def.children.length > 0) {
                    list.push(def)
                }
                // await this.$nextTick()
                // this.selectedFileName = fileList[0].shortname
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`getFileList 异常, 错误原因:${error}`
                })
            }
        }
    }
}
</script>

<style lang="scss">
.om_history_file_list {
    height: 100%;
    width: 100%;
    margin-right: 4px;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: flex-start;
    // flex-direction: row;
    // justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    .time_view {
        height: 26px;
        width: 100%;
        margin: 0px;
        flex: 0 0 28px;
        // background-color: green;
    }
    .tree_list {
        height: calc(100% - 26px);
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        justify-content: flex-start;
        display: flex;
        font-size: 14px;
    }
    .time {
        box-sizing: border-box;
        flex: 0;
        height: 54px;
        min-width: 200px;
        width: 100%;
        margin: 0px;
        padding-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .file_list {
        height: 100px;
        width: 100%;
        margin-right: 4px;
        overflow: auto;
        box-sizing: border-box;
        display: flex;
        flex: 1 1 auto;
        font-size: 14px;
        // background-color: red;
        // flex-direction: column;
        // justify-content: center;
        // align-items: flex-start;
        // box-sizing: border-box;
    }
    .custom_tree_node {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .device {
            flex: 0;
        }
        .equip {
            flex: 0;
        }
        .mingzi {
            flex: 1;
        }
    }
}

.current {
    width: 80%;
    height: 80%;
    position: absolute;
    background-color: rgb(89, 168, 182);
    border-radius: 50%;
    color: #fff;
}

.date_item {
    // height: 20px;
    // width: 20px;
    position: relative;
}
.date_alarm {
    height: 2px;
    width: 4px;
    top: 30px;
    left: 20px;
    position: absolute;
    background-color: #f00;
}
</style>