<template>
<div class="om_canvas">
    <om_scene
    :detail="detail"
    @mousedown.prevent.stop="mouseEvent($event)"
    @mousemove.prevent.stop="mouseEvent($event)"
    @mouseup.prevent.stop="mouseEvent($event)">
    >
        <template v-slot:controller>
            <g id="controller" class="controller">
                <om_controller v-if="activedItem!==null" :item="activedItem"></om_controller>
            </g>
        </template>
    </om_scene>
</div>
</template>

<script>
import om_scene from './om_scene'
import om_controller from './om_controller'
export default {
    name: 'om_canvas',
    components: {
        om_scene,
        om_controller
    },
    props: {
        detail: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            activedItem: null,
            lastScenePoint_: null,
            handlertype_: null
        }
    },
    methods: {
        // -----------------------------------------------------------------------------------------------------------------------------------------------
        mouseEvent(e) {
            const mouseEventName = e.type
            this.$emit(mouseEventName, e)
            if (mouseEventName === 'mousedown') {
                this.mousedownEvent(e)
                return
            }
            if (mouseEventName === 'mousemove') {
                this.mousemoveEvent(e)
                return
            }
            if (mouseEventName === 'mouseup') {
                // this.mouseupEvent(e)
                return
            }
        },
        mousedownEvent(e) {
            const ptInScene = {
                x: e.target.sceneX,
                y: e.target.sceneY
            }
            this.lastScenePoint_ = ptInScene
            this.beginSelectItem(e)
        },
        beginSelectItem(e) {
            const target = e.target
            if (target === undefined || target === null) return
            const dataset = target.dataset
            if (dataset === undefined || dataset === null) return
            const itemtype = dataset.itemtype
            if (itemtype === undefined || itemtype === null) return
            // 选中场景，取消
            if (itemtype === 'scene') {
                this.clearActivedItemState()
                return
            }
            // 选中该项
            if (itemtype === 'item') {
                const index = dataset.index
                if (index === undefined || index === null) return
                this.activedItem = this.itemAt(index)
                return
            }
            const handlertype = dataset.handlertype
            if (handlertype === undefined || handlertype === null) return
            this.handlertype_ = handlertype
        },
        mousemoveEvent(e) {
            const ptInScene = {
                x: e.target.sceneX,
                y: e.target.sceneY
            }
            this._currentScenePoint = ptInScene
            this.sceneX = e.target.sceneX
            this.sceneY = e.target.sceneY
            if (e.buttons !== 1) return
            if (this.activedItem === undefined || this.activedItem === null) return
            this.handleDragOrResizeItem(e)
        },
        // --------------------------------------------------------------------------------------------------------------------------------------------
        clearActivedItemState() {
            const activedItem = this.activedItem
            if (activedItem === undefined || activedItem === null) return
            this.activedItem = null
        },
        removeActiveItem() {
            const activedItem = this.activedItem
            if (activedItem === undefined || activedItem === null) return
            const detail = this.detail
            if (detail === undefined || detail === null) return null
            const items = detail.items
            if (items === undefined || items === null) return null
            const index = items.indexOf(this.activedItem)
            delete items.splice(index, 1)
            this.clearActivedItemState()
        },
        itemAt(index) {
            const detail = this.detail
            if (detail === undefined || detail === null) return null
            const items = detail.items
            if (items === undefined || items === null) return null
            if (!Array.isArray(items)) return null
            const count = items.length
            if (index >= count) return null
            if (index < 0) return null
            const item = items[index]
            return item
        },
        addItem(item) {
            const detail = this.detail
            if (detail === undefined || detail === null) return null
            const items = detail.items
            if (items === undefined || items === null) return null
            if (!Array.isArray(items)) return null
            const scene = this.detail.scene
            if (scene === undefined || scene === null) return null
            this.$nextTick(
                () => {
                    item.x = scene.viewBox.width / 2
                    item.y = scene.viewBox.height / 2
                    item.width = 100
                    item.height = 32
                    items.push(item)
                    this.activedItem = item
                }
            )
        },
        moveItem(size) {
            const activedItem = this.activedItem
            if (activedItem === undefined || activedItem === null) return
            this.$nextTick(
                () => {
                    activedItem.x += size.cx
                    activedItem.y += size.cy
                }
            )
        },
        // --------------------------------------------------------------------------------------------------------------------------------------------
        handleDragOrResizeItem(e) {
            if (this.handlertype_ === 'drag') {
                this.handleItemDrag(e, this.lastScenePoint_, this._currentScenePoint)
                this.lastScenePoint_ = this._currentScenePoint
                return
            }
            if (
                this.handlertype_ === 'resize_tl' ||
                this.handlertype_ === 'resize_tm' ||
                this.handlertype_ === 'resize_tr' ||
                this.handlertype_ === 'resize_r' ||
                this.handlertype_ === 'resize_br' ||
                this.handlertype_ === 'resize_bm' ||
                this.handlertype_ === 'resize_bl' ||
                this.handlertype_ === 'resize_l'
            ) {
                this.handleItemResize(this.handlertype_, this.lastScenePoint_, this._currentScenePoint)
                this.lastScenePoint_ = this._currentScenePoint
                return
            }
            return
        },
        handleItemDrag(e, lastScenePoint, currentScenePoint) {
            const activedItem = this.activedItem
            if (activedItem === undefined || activedItem === null) return
            //
            const width = currentScenePoint.x - lastScenePoint.x
            const height = currentScenePoint.y - lastScenePoint.y
            // 拖动的过程中， 不能离开视野
            const newRect = {
                x: activedItem.x + width,
                y: activedItem.y + height,
                width: activedItem.width,
                height: activedItem.height
            }
            //
            activedItem.x = newRect.x
            activedItem.y = newRect.y
            activedItem.width = newRect.width
            activedItem.height = newRect.height
        },
        handleItemResize(handlertype, lastScenePoint, currentScenePoint) {
            if (handlertype === 'resize_tm') {
                this.handleItemResize_tm(lastScenePoint, currentScenePoint)
                return
            }
            if (handlertype === 'resize_bm') {
                this.handleItemResize_bm(lastScenePoint, currentScenePoint)
                return
            }
            if (handlertype === 'resize_r') {
                this.handleItemResize_r(lastScenePoint, currentScenePoint)
                return
            }
            if (handlertype === 'resize_l') {
                this.handleItemResize_l(lastScenePoint, currentScenePoint)
            }
            if (handlertype === 'resize_tl') {
                this.handleItemResize_tl(lastScenePoint, currentScenePoint)
                return
            }
            if (handlertype === 'resize_tr') {
                this.handleItemResize_tr(lastScenePoint, currentScenePoint)
                return
            }
            if (handlertype === 'resize_bl') {
                this.handleItemResize_bl(lastScenePoint, currentScenePoint)
                return
            }
            if (handlertype === 'resize_br') {
                this.handleItemResize_br(lastScenePoint, currentScenePoint)
            }
        },
        handleItemResize_r(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const width = currentScenePoint.x - lastScenePoint.x
            let newWidth = this.activedItem.width + width
            if (newWidth < 0) {
                newWidth = 0
            }
            this.activedItem.width = newWidth
        },
        handleItemResize_l(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const width = currentScenePoint.x - lastScenePoint.x
            let newWidth = this.activedItem.width - width
            if (newWidth < 0) {
                newWidth = 0
            }
            this.activedItem.x += width
            this.activedItem.width = newWidth
        },
        handleItemResize_tm(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const height = currentScenePoint.y - lastScenePoint.y
            let newHeight = this.activedItem.height - height
            if (newHeight < 0) {
                newHeight = 0
            }
            this.activedItem.y += height
            this.activedItem.height = newHeight
        },
        handleItemResize_bm(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const height = currentScenePoint.y - lastScenePoint.y
            let newHeight = this.activedItem.height + height
            if (newHeight < 0) {
                newHeight = 0
            }
            this.activedItem.height = newHeight
        },
        handleItemResize_tl(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const width = currentScenePoint.x - lastScenePoint.x
            const height = currentScenePoint.y - lastScenePoint.y
            let newWidth = this.activedItem.width - width
            let newHeight = this.activedItem.height - height
            if (newWidth < 0) {
                newWidth = 0
            }
            if (newHeight < 0) {
                newHeight = 0
            }
            this.activedItem.x += width
            this.activedItem.y += height
            this.activedItem.width = newWidth
            this.activedItem.height = newHeight
        },
        handleItemResize_br(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const width = currentScenePoint.x - lastScenePoint.x
            const height = currentScenePoint.y - lastScenePoint.y
            let newWidth = this.activedItem.width + width
            let newHeight = this.activedItem.height + height
            if (newWidth < 0) {
                newWidth = 0
            }
            if (newHeight < 0) {
                newHeight = 0
            }
            this.activedItem.width = newWidth
            this.activedItem.height = newHeight
        },
        handleItemResize_bl(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const width = currentScenePoint.x - lastScenePoint.x
            const height = currentScenePoint.y - lastScenePoint.y
            let newWidth = this.activedItem.width - width
            let newHeight = this.activedItem.height + height
            if (newWidth < 0) {
                newWidth = 0
            }
            if (newHeight < 0) {
                newHeight = 0
            }
            this.activedItem.x += width
            this.activedItem.y += 0
            this.activedItem.width = newWidth
            this.activedItem.height = newHeight
        },
        handleItemResize_tr(lastScenePoint, currentScenePoint) {
            if (this.activedItem === undefined || this.activedItem === null) return
            const width = currentScenePoint.x - lastScenePoint.x
            const height = currentScenePoint.y - lastScenePoint.y
            let newWidth = this.activedItem.width + width
            let newHeight = this.activedItem.height - height
            if (newWidth < 0) {
                newWidth = 0
            }
            if (newHeight < 0) {
                newHeight = 0
            }
            this.activedItem.x += 0
            this.activedItem.y += height
            this.activedItem.width = newWidth
            this.activedItem.height = newHeight
        },
        // --------------------------------------------------------------------------------------------------------------------------------------------
        keydownEvent(e) {
            e.preventDefault()
            const keyCode = window.event.keyCode
            // delete or backspace
            if ( keyCode === 8 || keyCode === 46) {
                this.removeActiveItem()
                return
            }
            // up
            if ( keyCode === 37) {
                const size = {
                    cx: -1,
                    cy: 0
                }
                this.moveItem(size)
                return
            }
            if ( keyCode === 38) {
                const size = {
                    cx: 0,
                    cy: -1
                }
                this.moveItem(size)
                return
            }
            if ( keyCode === 39) {
                const size = {
                    cx: 1,
                    cy: 0
                }
                this.moveItem(size)
                return
            }
            if ( keyCode === 40) {
                const size = {
                    cx: 0,
                    cy: 1
                }
                this.moveItem(size)
                return
            }
        }
    },
    destroyed() {
        document.removeEventListener('keydown', this.keydownEvent)
    },
    mounted() {
        document.addEventListener('keydown', this.keydownEvent)
    }
}
</script>

<style lang="scss" scoped>
.om_canvas {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    .om_scene {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
</style>