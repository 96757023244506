export default function (options = {}) {
    const bodyWidht = window.innerWidth;
    let moreBarWidth = bodyWidht < 1920 ? true : false;
    const { xAxisData, seriesData, ...rest } = options;
    const defaultOptions = {
        tooltip: {
            trigger: "axis",
        },
        grid: {
            top: 50,
            left: 40,
            right: 10,
            bottom: moreBarWidth ? 70 : 20,
        },
        legend: {
            orient: "horizontal",
            x: "left",
            y: " center",
        },
        xAxis: {
            type: "category",
            data: xAxisData,
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#eee"
                }
            },
            axisLabel: {
                rotate:45,
                textStyle: {
                    color: "#fff",
                    fontSize: 12
                },
                margin:12,
                formatter:function(value)
                {
                    var ret = "";//拼接加\n返回的类目项
                    var maxLength = 11;//每项显示文字个数
                    var valLength = value.length;//X轴类目项的文字个数
                    var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                    if (rowN > 1)//如果类目项的文字大于5,
                    {
                        for (var i = 0; i < rowN; i++) {
                            var temp = "";//每次截取的字符串
                            var start = i * maxLength;//开始截取的位置
                            var end = start + maxLength;//结束截取的位置
                            //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                            temp = value.substring(start, end) + "\n";
                            ret += temp; //凭借最终的字符串
                        }
                        return ret;
                    }
                    else {
                        return value;
                    }
                } ,//控制x轴名称过长，实现4字折行
            }
        },
        yAxis: [{
            type: "value",
            min: 0,
            max(value) {
                return value.max > 100 ? Math.ceil(value.max / 50) * 50 : 100;
            },
        }],
        series:
            seriesData &&
            seriesData.map(item => ({
                ...item,
                type: "line",
            })),
        color: ["#5AD8A6", "#AE75EA", "#FDC464", "#FF9348", "#5B8FF9"],
    };
    return Object.keys(rest).reduce((acc, key) => {
        if (acc[key]) {
            acc[key] = Object.assign(acc[key], rest[key]);
        } else {
            acc[key] = rest[key];
        }
        return acc;
    }, defaultOptions);
}
