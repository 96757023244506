<template>
<div class="om_device_view">
    <el-tabs type="border-card" @tab-click="handle_tab_click_event" v-model="activeName">
        <el-tab-pane :label="sssjName" name="om_device_realtime_data">
            <om_device_realtime_data v-if="activeName==='om_device_realtime_data'" class="om_device_realtime_data" ref="om_device_realtime_data" :object="object"/>
        </el-tab-pane>
        <el-tab-pane :label="yclsName" name="om_device_history_data">
            <om_device_history_data v-if="activeName==='om_device_history_data'" class="om_device_history_data" ref="om_device_history_data" :object="object"/>
        </el-tab-pane>
        <el-tab-pane :label="yxlsName" name="om_device_history_yxdata">
            <om_device_history_yxdata v-if="activeName==='om_device_history_yxdata'" class="om_device_history_yxdata" ref="om_device_history_yxdata" :object="object"/>
        </el-tab-pane>
        <template v-if="isShebei">
            <el-tab-pane :label="ptfxName" name="om_device_graph_view">
                <om_device_graph_view v-if="activeName==='om_device_graph_view'" class="om_device_graph_view" ref="om_device_graph_view" :object="object"/>
            </el-tab-pane>
        </template>
    </el-tabs>
</div>
</template>

<script>
import om_device_realtime_data from './om_device_realtime_data'
import om_device_history_data from './om_device_history_data'
import om_device_history_yxdata from './om_device_history_yxdata'
import om_device_graph_view from './om_device_graph_view'
import { ref } from 'vue'
export default {
    name:'om_device_view',
    components: {
        om_device_realtime_data,
        om_device_history_data,
        om_device_history_yxdata,
        om_device_graph_view
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const activeName = 'om_device_realtime_data'
        const om_device_realtime_data = ref(null)
        const om_device_history_data = ref(null)
        const om_device_history_yxdata = ref(null)
        const om_device_graph_view = ref(null)
        return {
            activeName,
            om_device_realtime_data,
            om_device_history_data,
            om_device_history_yxdata,
            om_device_graph_view
        }
    },
    computed: {
        isShebei() {
            const object = this.object
            if (object === undefined || object === null) return false
            const leixing_id = object.leixing_id
            if (leixing_id === undefined || leixing_id === null) return false
            if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) {
                return false
            }
            return true
        },
        sssjName() {
            return this.$Lang.jcsj.sssj.name
        },
        yclsName() {
            return this.$Lang.jcsj.ycls.name
        },
        yxlsName() {
            return this.$Lang.jcsj.yxls.name
        },
        ptfxName() {
            return this.$Lang.jcsj.ptfx.name
        }
    },
    methods: {
        handle_tab_click_event(tab) {
            const name = tab.paneName
            this.activeName = name
            if (name === 'om_device_realtime_data') {
                if (!this.om_device_realtime_data) return
                this.om_device_realtime_data.resize()
            }
            if (name === 'om_device_history_data') {
                if (!this.om_device_history_data) return
                this.om_device_history_data.resize()
            }
            if (name === 'om_device_history_yxdata') {
                if (!this.om_device_history_yxdata) return
                this.om_device_history_yxdata.resize()
            }
            if (name === 'om_device_graph_view') {
                if (!this.om_device_graph_view) return
                this.om_device_graph_view.resize()
            }
        },
        resize() {
            if (this.om_device_realtime_data) {
                this.om_device_realtime_data.resize()
            }
            if (this.om_device_history_data) {
                this.om_device_history_data.resize()
            }
            if (this.om_device_history_yxdata) {
                this.om_device_history_yxdata.resize()
            }
            if (this.om_device_graph_view) {
                this.om_device_graph_view.resize()
            }
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang="scss">
.om_device_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    .el-tabs--border-card {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100% !important;
        height: 100% !important;
        padding: 0px !important;
        box-sizing: border-box !important;
        .el-tabs__header {
            flex: 0;
            width: 100%;
            height: 39px;
            min-height: 39px;
            background-color: #F5F7FA;
            border-bottom: 1px solid #E4E7ED;
            margin: 0;
        }
        .el-tabs__content {
            flex: 1;
            width: 100% !important;
            height: 100% !important;
            padding: 0px !important;
            .el-tab-pane {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
</style>