<template>
<div class="om_gispd_canshu_view">
    <div class="toolbar">
        <el-button v-if="object.leixing_id===38" type="success" @click="handle_real_prp_gragh_button_click">{{ssfdlpt}}</el-button>
        <el-button type="success" @click="handle_real_gragh_button_click">{{ssptck}}</el-button>
        <el-button type="success" @click="handle_real_gragh_compare_button_click">{{ssptdtddb}}</el-button>
        <el-button type="success" @click="handle_record_button_click">{{ssptlz}}</el-button>
        <el-button type="success" @click="handle_import_button_click">{{sspthf}}</el-button>
    </div>
    <div class="canshu">
        <om_device_canshu_view class="om_device_canshu_view" ref="om_device_canshu_view" :object="object"/>
    </div>
    <om_real_gragh_dialog ref="ref_real_gragh_dialog" :object="object" @close="closeDlg"/>
    <om_real_gragh_compare_dialog ref="ref_real_gragh_compare_dialog" :object="object" @close="closeCompareDlg"/>
    <om_real_prp_gragh_dialog ref="ref_real_prp_gragh_dialog" :object="object"/>
    <om_prps_record_dialog ref="ref_prps_record_dialog" :object="object" @close="closeRecordDlg"/>
    <om_prps_import_dialog ref="ref_prps_import_dialog" @success="handle_import_success"/>
    <om_prps_replay_dialog ref="ref_prps_replay_dialog" :list_prps="list_prps"/>
</div>
</template>

<script>
import om_device_canshu_view from '../om_device_canshu_view'
import om_real_gragh_dialog from './om_real_gragh_dialog'
import om_real_gragh_compare_dialog from './om_real_gragh_compare_dialog'
import om_real_prp_gragh_dialog from './om_real_prp_gragh_dialog'
import om_prps_record_dialog from './om_prps_record_dialog'
import om_prps_import_dialog from './om_prps_import_dialog'
import om_prps_replay_dialog from './om_prps_replay_dialog'
import { ref } from 'vue'
export default {
    name:'om_gispd_canshu_view',
    components: {
        om_device_canshu_view,
        om_real_gragh_dialog,
        om_real_gragh_compare_dialog,
        om_real_prp_gragh_dialog,
        om_prps_record_dialog,
        om_prps_import_dialog,
        om_prps_replay_dialog
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            list_prps: []
        }
    },
    setup() {
        const ref_real_gragh_dialog = ref(null)
        const ref_real_gragh_compare_dialog = ref(null)
        const ref_real_prp_gragh_dialog = ref(null)
        const ref_prps_record_dialog = ref(null)
        const ref_prps_import_dialog = ref(null)
        const ref_prps_replay_dialog = ref(null)
        return {
            ref_real_gragh_dialog,
            ref_real_gragh_compare_dialog,
            ref_prps_record_dialog,
            ref_real_prp_gragh_dialog,
            ref_prps_import_dialog,
            ref_prps_replay_dialog
        }
    },
    computed: {
        ssfdlpt() {
            return this.$Lang.jcsj.sssj.ssfdlpt.name
        },
        ssptck() {
            return this.$Lang.jcsj.sssj.ssptck.name
        },
        ssptdtddb() {
            return this.$Lang.jcsj.sssj.ssptdtddb.name
        },
        ssptlz() {
            return this.$Lang.jcsj.sssj.ssptlz.name
        },
        sspthf() {
            return this.$Lang.jcsj.sssj.sspthf.name
        }
    },
    methods: {
        closeDlg() {
            const ref_real_gragh_dialog = this.ref_real_gragh_dialog
            if (ref_real_gragh_dialog === undefined || ref_real_gragh_dialog === null) return
            ref_real_gragh_dialog.afterClose()
        },
        closeCompareDlg() {
            const ref_real_gragh_compare_dialog = this.ref_real_gragh_compare_dialog
            if (ref_real_gragh_compare_dialog === undefined || ref_real_gragh_compare_dialog === null) return
            ref_real_gragh_compare_dialog.afterClose()
        },
        closeRecordDlg() {
            const ref_prps_record_dialog = this.ref_prps_record_dialog
            if (ref_prps_record_dialog === undefined || ref_prps_record_dialog === null) return
            ref_prps_record_dialog.afterClose()
        },
        handle_real_prp_gragh_button_click() {
            const ref_real_prp_gragh_dialog = this.ref_real_prp_gragh_dialog
            if (ref_real_prp_gragh_dialog === undefined || ref_real_prp_gragh_dialog === null) return
            ref_real_prp_gragh_dialog.showDialog()
        },
        handle_real_gragh_button_click() {
            const ref_real_gragh_dialog = this.ref_real_gragh_dialog
            if (ref_real_gragh_dialog === undefined || ref_real_gragh_dialog === null) return
            ref_real_gragh_dialog.showDialog()
        },
        handle_real_gragh_compare_button_click() {
            const ref_real_gragh_compare_dialog = this.ref_real_gragh_compare_dialog
            if (ref_real_gragh_compare_dialog === undefined || ref_real_gragh_compare_dialog === null) return
            ref_real_gragh_compare_dialog.showDialog()
        },
        handle_record_button_click() {
            const ref_prps_record_dialog = this.ref_prps_record_dialog
            if (ref_prps_record_dialog === undefined || ref_prps_record_dialog === null) return
            ref_prps_record_dialog.showDialog()
        },
        handle_import_button_click() {
            const ref_prps_import_dialog = this.ref_prps_import_dialog
            if (ref_prps_import_dialog === undefined || ref_prps_import_dialog === null) return
            ref_prps_import_dialog.showDialog()
        },
        handle_import_success(list_prps) {
            const ref_prps_replay_dialog = this.ref_prps_replay_dialog
            if (ref_prps_replay_dialog === undefined || ref_prps_replay_dialog === null) return
            this.list_prps = list_prps
            ref_prps_replay_dialog.showDialog()
        }
    }
}
</script>

<style lang="scss" scoped>
.om_gispd_canshu_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .toolbar {
        width: 100%;
        min-height: 40px;
        flex: 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 4px;
        margin: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .el-button {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 8px;
            margin: 4px;
            overflow: hidden;
            box-sizing: border-box;
        }
    }
    .canshu {
        width: 100%;
        min-height: 200px;
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
</style>