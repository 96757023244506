import request from './request'
import { uuid } from 'vue-uuid'
import moment from 'moment'
import JSuirpt from 'jsencrypt'

const needEncrypt = false
const ajaxUrl = '/ajax'
async function executeProcedure(name, json_paramset, url) {
    const user_token = ''
    let newName = name
    let newParam = json_paramset
    if (needEncrypt) {
        newName = ertwiopt('[]' + name)
        const pm = '[]' + JSON.stringify(json_paramset)
        var len = pm.length
        var idx = 0
        var pm2 = []
        while (idx < len) {
            if (idx + 50 <= len) {
                pm2.push(ertwiopt(pm.substring(idx, idx + 50)))
                idx += 50
                continue
            }
            pm2.push(ertwiopt(pm.substring(idx)))
            idx += 50
        }
        newParam = pm2.join('=^=')
    }

    const json_obj_request = {
        id :uuid.v1(),
        version: '1.0.0.0',
        time:moment().format('yyyy-MM-DD hh:mm:ss.SSS'),
        token:user_token,
        digest: '',
        type: 'procedure',
        procedure:{
            name: newName,
            paramset: newParam
        }
    }
    const json_obj_response = await request({
        url: url || ajaxUrl,
        method: 'post',
        data: json_obj_request
    })
    if (!json_obj_response) throw new Error('json_obj_response is null')
    // if (!json_obj_response.status) throw new Error('json_obj_response.status is null')
    // if (json_obj_response.status.code !== 200) throw new Error(`json_obj_response.status failed, code:${json_obj_response.status.code}, text:${json_obj_response.status.text}`)
    if (!json_obj_response.procedure) throw new Error('json_obj_response.procedure is null')
    if (!json_obj_response.procedure.resultset) throw new Error('json_obj_response.procedure.resultset is null')
    if (!Array.isArray(json_obj_response.procedure.resultset)) throw new Error('json_obj_response.procedure.resultset is not type of array')
    if (!json_obj_response.procedure.resultset[0]) throw new Error('json_obj_response.procedure.resultset[0] is null')
    if (json_obj_response.procedure.resultset[0][0].code !== 0) {
        throw new Error(json_obj_response.procedure.resultset[0][0].text)
    }
    //
    const resultset = json_obj_response.procedure.resultset
    return resultset
}

function ertwiopt(str) {
    const other = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCK3siV2MKl7ADFMajEsbc/ZrSTfo9h37c6+m0cUHOTqGR4V+Ugzc5Wzpcrz6QGu7/umQBJRC3LZ8xRW8+J6Z1lI0+Tr6LT8NfLUeyBTBXkBI1j0BIzmEjsW/a1vDr2ahXn1RFvtnHeKs41lbICkY7mRA2cAyiMWhrteM1d1MR3gQIDAQAB-----END PUBLIC KEY-----'
    const enuyt = new JSuirpt()
    enuyt.setPublicKey(other)
    const encrypted = enuyt.encrypt(str)
    return encrypted
}

async function executeFile(name, paramset) {
    const user_token = ''
    const json_obj_request = {
        id: uuid.v1(),
        version: '1.0.0.0',
        time: moment().format('yyyy-MM-DD hh:mm:ss.SSS'),
        token: user_token,
        digest: '',
        type: 'file',
        file: {
            name: name,
            paramset: paramset || {}
        }
    }
    const json_obj_response = await request({
        url: '/file',
        method: 'post',
        data: json_obj_request
    })
    if (!json_obj_response) throw new Error('json_obj_response is null')
    if (!json_obj_response.file) throw new Error('json_obj_response.file is null')
    if (!json_obj_response.file.resultset) throw new Error('json_obj_response.file.resultset is null')
    if (!Array.isArray(json_obj_response.file.resultset)) throw new Error('json_obj_response.file.resultset is not type of array')
    if (!json_obj_response.file.resultset[0]) throw new Error('json_obj_response.file.resultset[0] is null')
    if (json_obj_response.file.resultset[0][0].code !== 0) {
        throw new Error(json_obj_response.file.resultset[0][0].text)
    }
    //
    const resultset = json_obj_response.file.resultset
    return resultset
}

function Ajax() {}

Ajax.prototype.p_user_login = async function(paramset) { // 登录
    return await executeProcedure('p_user_login', paramset, '/login')
}
Ajax.prototype.Dailyeport_query = async function(paramset) {
    return await executeProcedure('Dailyeport_query', paramset)
}
Ajax.prototype.export_log = async function(paramset, url = '') {
    return await request({
        url: `export_log/${url}`,
        method: 'post',
        responseType: 'blob',
        data: paramset
    })
}

Ajax.prototype.reset_channel_mode = async function(bdz, leixing, shebei, mode) {
    const json_param = JSON.stringify({
        bdz : bdz,
        leixing: leixing,
        shebei: shebei,
        type: mode
    })
    return await request({
        url: `rechannel`,
        method: 'post',
        responseType: 'blob',
        data: json_param
    })
}

Ajax.prototype.p_user_password_reset = async function(paramset) {
    return await executeProcedure('p_user_password_reset', paramset)
}

Ajax.prototype.p_query_leixing = async function(paramset) {
    return await executeProcedure('p_query_leixing', paramset)
}

Ajax.prototype.p_query_leixing_canshu = async function(paramset) {
    return await executeProcedure('p_query_leixing_canshu', paramset)
}

Ajax.prototype.p_query_ms_leixing_canshu_yx_data_enum = async function(paramset) {
    return await executeProcedure('p_query_ms_leixing_canshu_yx_data_enum', paramset)
}

Ajax.prototype.p_query_shebei = async function(paramset) {
    return await executeProcedure('p_query_shebei', paramset)
}

Ajax.prototype.p_query_shebei_canshu = async function(paramset) {
    return await executeProcedure('p_query_shebei_canshu', paramset)
}

Ajax.prototype.p_query_zx_data = async function(paramset) {
    return await executeProcedure('p_query_zx_data', paramset)
}

Ajax.prototype.p_query_shebei_by_leixing_and_jiange = async function(paramset) {
    return await executeProcedure('p_query_shebei_by_leixing_and_jiange', paramset)
}

Ajax.prototype.p_query_shebei_by_leixing = async function(paramset) {
    return await executeProcedure('p_query_shebei_by_leixing', paramset)
}

Ajax.prototype.p_shebei_tree_query = async function(paramset) {
    return await executeProcedure('p_shebei_tree_query', paramset)
}

Ajax.prototype.p_query_lx_data = async function(paramset) {
    return await executeProcedure('p_query_lx_data', paramset)
}

Ajax.prototype.p_query_lx_data_chart = async function(paramset) {
    return await executeProcedure('p_query_lx_data_chart', paramset)
}

Ajax.prototype.p_query_lx_data_yx = async function(paramset) {
    return await executeProcedure('p_query_lx_data_yx', paramset)
}

Ajax.prototype.p_query_lx_data_yx_chart = async function(paramset) {
    return await executeProcedure('p_query_lx_data_yx_chart', paramset)
}

Ajax.prototype.p_update_ms_relaalarm_ts = async function(paramset) {
    return await executeProcedure('p_update_ms_relaalarm_ts', paramset)
}

// 系统设置集
Ajax.prototype.p_query_ms_config = async function(paramset) {
    return await executeProcedure('p_query_ms_config', paramset)
}

Ajax.prototype.p_update_ms_config = async function(paramset) {
    return await executeProcedure('p_update_ms_config', paramset)
}

// svg操作集
Ajax.prototype.p_update_ms_svg = async function(paramset) {
    return await executeProcedure('p_update_ms_svg', paramset)
}

Ajax.prototype.p_query_ms_svg = async function(paramset) {
    return await executeProcedure('p_query_ms_svg', paramset)
}

// 语言切换
Ajax.prototype.p_init_jczd_data = async function(paramset) {
    return await executeProcedure('p_init_jczd_data', paramset)
}

// 文件操作
Ajax.prototype.getFileList = async function(paramset) {
    return await executeFile('getFileList', paramset)
}

Ajax.prototype.getFileData = async function(paramset) {
    return await executeFile('getFileData', paramset)
}

Ajax.prototype.getTypicalFileList = async function(paramset) {
    return await executeFile('getTypicalFileList', paramset)
}

Ajax.prototype.getTypicalFileData = async function(paramset) {
    return await executeFile('getTypicalFileData', paramset)
}

// 阈值标定
Ajax.prototype.p_query_yuzhi_rate = async function(paramset) {
    return await executeProcedure('p_query_yuzhi_rate', paramset)
}

Ajax.prototype.p_update_yuzhi_rate = async function(paramset) {
    return await executeProcedure('p_update_yuzhi_rate', paramset)
}

Ajax.prototype.p_init_jizhun_data = async function(paramset) {
    return await executeProcedure('p_init_jizhun_data', paramset)
}

Ajax.prototype.p_query_jizhun_data = async function(paramset) {
    return await executeProcedure('p_query_jizhun_data', paramset)
}
Ajax.prototype.p_log_query = async function(paramset) {
    return await executeProcedure('p_log_query', paramset)
}

// 自檢-磁盤內存cpu使用率
Ajax.prototype.p_query_zjgn_memory = async function(paramset) {
    return await executeProcedure('p_query_zj_memory', paramset)
}

// 自檢-獲取裝置自檢
Ajax.prototype.p_query_zjgn_equip = async function(paramset) {
    return await executeProcedure('p_query_zj_equip', paramset)
}

// 自檢-裝置自檢周期设置
Ajax.prototype.p_update_zjgn_cycle = async function(paramset) {
    return await executeProcedure('p_update_zj_cycle', paramset)
}

// 自檢-获取裝置自檢周期
Ajax.prototype.p_query_zjgn_cycle = async function(paramset) {
    return await executeProcedure('p_query_zj_cycle', paramset)
}

export default new Ajax()
