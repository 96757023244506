<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="50%"
height="80%"
:title="zjgnTitle"
>
    <template v-slot:title="{title}">
        <div class="om_dialog_header_title">
            <div class="title">
                {{title}}
            </div>
            <div class="export">
                <el-button type="primary" round @click="handle_click_setup_event">{{sd}}</el-button>
            </div>
            <div class="export">
                <el-button type="success" round @click="handle_click_export_event">{{dc}}</el-button>
            </div>
        </div>
    </template>
    <div class="om_dialog__body">
        <div class="vert_container">
            <om_container :title="gkTitle" style="width:100%; height:20%; margin:1px; ">
                <div class="leixing_content">
                    <el-table
                    ref="om_device_canshu_view_table"
                    :data="zjgkdatas"
                    :showHeader="true"
                    height="100%"
                    max-height= "calc(100vh - 30px)"
                    border
                    stripe
                    >
                        <el-table-column
                        :label="gklb"
                        align="center"
                        prop="mingzi"
                        width="200"
                        show-overflow-tooltip
                        >
                            <template v-slot:default="scope">
                                <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                                    <el-tag>{{scope.row.parameter}}</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        :label="zjsj"
                        align="center"
                        prop="sjtime"
                        width="200"
                        show-overflow-tooltip
                        >
                            <template v-slot:default="scope">
                                <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                                    <el-tag>{{sjtime(scope.row.check_time)}}</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        :label="zjjg"
                        align="center"
                        prop="mingzi"
                        width="240"
                        show-overflow-tooltip
                        >
                            <template v-slot:default="scope">
                                <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                                    <el-tag>{{scope.row.data_float}} %</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        :label="zjjl"
                        align="center"
                        prop="mingzi"
                        width="auto"
                        show-overflow-tooltip
                        >
                            <template v-slot:default="scope">
                                    <span>{{getmessagesj(scope.row.result)}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </om_container>
            <div style="width:100%; height:60%; margin:1px; ">
                <om_container :title="sbbjTitle">
                    <div class="device_view">
                        <div class="statics">
                            <table width="100%" height="100%" border="1px" align="center">
                                <tr height="20px">
                                    <th align="center" colspan="3">{{sbzl}}:{{shebei_total}}{{dw}}</th>
                                </tr>
                                <tr height="20px">
                                    <th align="center">{{bjfl}}</th>
                                    <th align="center">{{sl}}</th>
                                    <th align="center">{{zb}}</th>
                                </tr>
                                <tr height="20px">
                                    <td align="center">{{sjzc}}</td>
                                    <td align="center">{{shebei_total-shebei_alarm_event_total-shebei_atten_event_total}}</td>
                                    <td align="center">{{shebei_normal_percent}}</td>
                                </tr>
                                <tr height="20px">
                                    <td align="center">{{sjyj}}</td>
                                    <td align="center">{{shebei_atten_event_total}}</td>
                                    <td align="center">{{shebei_atten_percent}}</td>
                                </tr>
                                <tr height="20px">
                                    <td align="center">{{sjbj}}</td>
                                    <td align="center">{{shebei_alarm_event_total}}</td>
                                    <td align="center">{{shebei_alarm_percent}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="detail">
                            <el-table
                            :data="shebei_fault_event"
                            :show-overflow-tooltip="true"
                            height="100%"
                            max-height= "calc(100vh - 30px)"
                            max-
                            border
                            :stripe="true">
                                <el-table-column
                                align="center"
                                prop="mingzi"
                                :label="jczz"
                                width="220">
                                    <template #default="scope">
                                        <div class="name"><el-tag>{{scope.row.mingzi}}</el-tag></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                align="center"
                                prop="ctime"
                                :label="zdsj"
                                width="180">
                                    <template #default="scope">
                                        <div class="name"><el-tag>{{ctime(scope.row)}}</el-tag></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                align="center"
                                prop="data_flag"
                                :label="sjzt"
                                width="140">
                                    <template #default="scope">
                                        <img :src="equip_flag_object(scope.row).src"/>
                                        <span>{{equip_flag_object(scope.row).mingzi}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                align="center"
                                :label="ckxq"
                                width="auto">
                                    <template #default="scope">
                                        <div style="display: flex; justify-content: center; align-items: center;">
                                            <el-button class="more" type="warning" size="small" round @click="handle_click_event(scope.row)">{{ck}}</el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </om_container>
            </div>
            <div style="width:100%; height:20%; margin:1px; ">
                <om_container :title="zzzjTitle">
                    <div class="device_view">
                        <div class="detail">
                            <el-table
                            :data="zzzjdatas"
                            :show-overflow-tooltip="true"
                            height="100%"
                            max-height= "calc(100vh - 30px)"
                            border
                            :stripe="true">
                                <el-table-column
                                align="center"
                                prop="mingzi"
                                :label="zzmc"
                                width="280">
                                    <template #default="scope">
                                        <div class="name"><el-tag>{{scope.row.name}}</el-tag></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                align="center"
                                prop="sjtime"
                                :label="zjsj"
                                width="200">
                                    <template #default="scope">
                                        <div class="name"><el-tag>{{sjtime(scope.row.time)}}</el-tag></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                align="center"
                                prop="mingzi"
                                :label="zjjg"
                                width="auto">
                                    <template #default="scope">
                                        <div class="name"><el-tag>{{getmessagezh(scope.row.result)}}</el-tag></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </om_container>
            </div>
        </div>
        <om_calibrate_setup_dialog class="om_calibrate_setup_dialog" ref="om_calibrate_setup_dialog"  @commit="handle_setup_commit_click"/>
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import XLSX from 'xlsx'
import moment from 'moment'
import om_container from '@/components/om_container'
import { multisheet2blob, openDownloadDialog, toDateTime, toDecimal, equip_flag_object } from '@/utils'
import om_dialog from '@/components/om_dialog'
import om_calibrate_setup_dialog from './om_calibrate_setup_dialog'
export default {
    name: 'om_calibrate_zj_dialog',
    components: {
        om_container,
        om_dialog,
        om_calibrate_setup_dialog
    },
    data() {
        return {
            title: '',
            timer: null,
            zjgkdatas: [],
            zzzjdatas: [],
            zjcycle: 0
        }
    },
    setup() {
        const om_calibrate_setup_dialog = ref(null)
        return {
            om_calibrate_setup_dialog
        }
    },
    computed: {
        zjgnTitle() {
            return this.$Lang.header.zjgn.title
        },
        gkTitle() {
            return this.$Lang.header.zjgn.gk
        },
        sbbjTitle() {
            return this.$Lang.header.zjgn.sbbj
        },
        sbbjtj() {
            return this.$Lang.header.zjgn.sbbjtj
        },
        sbbjxq() {
            return this.$Lang.header.zjgn.sbbjxq
        },
        zzzjTitle() {
            return this.$Lang.header.zjgn.zzzj
        },
        bjfl() {
            return this.$Lang.header.zjgn.bjfl
        },
        sl() {
            return this.$Lang.header.zjgn.sl
        },
        zb() {
            return this.$Lang.header.zjgn.zb
        },
        gklb() {
            return this.$Lang.header.zjgn.gklb
        },
        zzmc() {
            return this.$Lang.header.zjgn.zzmc
        },
        zjsj() {
            return this.$Lang.header.zjgn.zjsj
        },
        zjjg() {
            return this.$Lang.header.zjgn.zjjg
        },
        zjjl() {
            return this.$Lang.header.zjgn.zjjl
        },
        shebei_fault_event() {
            const shebei_fault_event = this.$store.getters.shebei_fault_event
            return shebei_fault_event
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        shebei_alarm_event_total() {
            const shebei_alarm_event_total = this.$store.getters.shebei_alarm_event_total
            return shebei_alarm_event_total
        },
        shebei_atten_event_total() {
            const shebei_atten_event_total = this.$store.getters.shebei_atten_event_total
            return shebei_atten_event_total
        },
        shebei_normal_percent() {
            return toDecimal((this.shebei_total - this.shebei_alarm_event_total - this.shebei_atten_event_total) * 100 / this.shebei_total, 2)
        },
        shebei_alarm_percent() {
            return toDecimal(this.shebei_alarm_event_total * 100 / this.shebei_total, 2)
        },
        shebei_atten_percent() {
            return toDecimal(this.shebei_atten_event_total * 100 / this.shebei_total, 2)
        },
        sjzc() {
            return this.$Lang.qxgl.sjzttj.sjzc
        },
        sjyj() {
            return this.$Lang.qxgl.sjzttj.sjyj
        },
        sjbj() {
            return this.$Lang.qxgl.sjzttj.sjbj
        },
        sbzl() {
            return this.$Lang.qxgl.qzsjzttj.sbzl
        },
        dw() {
            return this.$Lang.qxgl.qzsjzttj.dw
        },
        zdsj() {
            return this.$Lang.qxgl.sjbjlbmx.zdsj
        },
        sjzt() {
            return this.$Lang.qxgl.sjbjlbmx.sjzt
        },
        ckxq() {
            return this.$Lang.qxgl.sjbjlbmx.ckxq
        },
        ck() {
            return this.$Lang.qxgl.sjbjlbmx.ck
        },
        jczz() {
            return this.$Lang.header.sjzl.jczz
        },
        cjsj() {
            return this.$Lang.header.sjzl.cjsj
        },
        warn_empt() {
            return this.$Lang.header.sjzl.warn_empt
        },
        dc() {
            return this.$Lang.button.dc
        },
        sd() {
            return this.$Lang.button.sd
        }
    },
    methods: {
        getmessagezh(code) {
            switch (code) {
            case 'msg_normal':
                return this.$Lang.utils.zhzt.normal
            case 'msg_txyc':
                return this.$Lang.utils.zhzt.txyc
            case 'msg_txzd':
                return this.$Lang.utils.zhzt.txzd
            case 'msg_sjyc':
                return this.$Lang.utils.zhzt.sjyc
            case 'msg_zzgz':
                return this.$Lang.utils.zhzt.zzgz
            }
        },
        getmessagesj(code) {
            switch (code) {
            case 'msg_empty':
                return this.$Lang.utils.sjzt.ksj
            case 'msg_normal':
                return this.$Lang.utils.sjzt.sjzc
            case 'msg_invalid':
                return this.$Lang.utils.sjzt.wxsj
            case 'msg_sjzd':
                return this.$Lang.utils.sjzt.sjzd
            case 'msg_sjst':
                return this.$Lang.utils.sjzt.sjst
            case 'msg_sjyw':
                return this.$Lang.utils.sjzt.sjyw
            case 'msg_sjyj':
                return this.$Lang.utils.sjzt.sjyj
            case 'msg_sjbj':
                return this.$Lang.utils.sjzt.sjbj
            }
        },
        gktosheet() {
            const data = JSON.parse(JSON.stringify(this.zjgkdatas))
            const records = []
            for (let row = 0; row < data.length; row++) {
                const rowData = data[row]
                const record = {}
                record[this.gklb] = rowData['parameter']
                record[this.zjsj] = this.sjtime(rowData['check_time'])
                record[this.zjjg] = rowData['data_float'] + ' %'
                record[this.zjjl] = this.getmessagesj(rowData['result'])
                records.push(record)
            }
            return records
        },
        sbsltosheet() {
            const cpitemzc = {
                fl: this.sjzc,
                sl: this.shebei_total - this.shebei_alarm_event_total - this.shebei_atten_event_total,
                zb: this.shebei_normal_percent
            }
            const cpitemyj = {
                fl: this.sjyj,
                sl: this.shebei_atten_event_total,
                zb: this.shebei_atten_percent
            }
            const cpitembj = {
                fl: this.sjbj,
                sl: this.shebei_alarm_event_total,
                zb: this.shebei_alarm_percent
            }
            const cpitems = [cpitemzc, cpitemyj, cpitembj]
            const data = JSON.parse(JSON.stringify(cpitems))
            const records = []
            for (let row = 0; row < data.length; row++) {
                const rowData = data[row]
                const record = {}
                record[this.bjfl] = rowData['fl']
                record[this.sl] = rowData['sl']
                record[this.zb] = rowData['zb']
                records.push(record)
            }
            return records
        },
        sbtosheet() {
            const columns = []
            columns.unshift(
                {
                    bianma: 'data_flag',
                    mingzi: this.sjzt
                }
            )
            columns.unshift(
                {
                    bianma: 'ctime',
                    mingzi: this.zdsj
                }
            )
            columns.unshift(
                {
                    bianma: 'mingzi',
                    mingzi: this.jczz
                }
            )
            const data = JSON.parse(JSON.stringify(this.shebei_fault_event))
            const records = []
            for (let row = 0; row < data.length; row++) {
                const rowData = data[row]
                const record = {}
                for (let col = 0; col < columns.length; col++) {
                    const column = columns[col]
                    const field = column.bianma
                    if (field === 'mingzi') {
                        const mingzi = rowData['mingzi']
                        record[this.jczz] = mingzi
                        continue
                    }
                    if (field === 'ctime') {
                        const ctime = this.ctime(rowData)
                        record[this.zdsj] = ctime
                        continue
                    }
                    if (field === 'data_flag') {
                        const equip_flag_object = this.equip_flag_object(rowData)
                        record[this.sjzt] = equip_flag_object.mingzi
                        continue
                    }
                }
                records.push(record)
            }
            return records
        },
        zztosheet() {
            const data = JSON.parse(JSON.stringify(this.zzzjdatas))
            const records = []
            for (let row = 0; row < data.length; row++) {
                const rowData = data[row]
                const record = {}
                record[this.zzmc] = rowData['name']
                record[this.zjsj] = this.sjtime(rowData['time'])
                record[this.zjjg] = this.getmessagezh(rowData['result'])
                records.push(record)
            }
            return records
        },
        getColumnWidth(nums) {
            const columns = []
            for (var i = 0; i <= nums.length; i++) {
                columns.push( { wch: nums[i] })
            }
            return columns
        },
        getRowHeight(rowsz, height) {
            const rows = []
            for (var i = 0; i <= rowsz; i++) {
                rows.push( { hpx: height })
            }
            return rows
        },
        handle_click_export_event() {
            const gkrecords = this.gktosheet()
            const sbslrecords = this.sbsltosheet()
            const sbrecords = this.sbtosheet()
            const zzrecords = this.zztosheet()

            if (gkrecords.length <= 0 && sbslrecords.length <= 0 && sbrecords.length <= 0 && zzrecords.length <= 0) {
                this.$Message.warning(this.warn_empt)
                return
            }
            const sheets = []
            const sheetNames = []
            if (gkrecords.length > 0) {
                const sheet = XLSX.utils.json_to_sheet(gkrecords)
                if (sheet === undefined || sheet === null) {
                    this.$Message.warning(`json_to_sheet error`)
                    return
                }
                const cols = this.getColumnWidth([30, 40, 30, 30])
                sheet['!cols'] = cols
                const rows = this.getRowHeight(gkrecords.length, 18)
                sheet['!rows'] = rows
                sheets.push(sheet)
                sheetNames.push(this.gkTitle)
            }
            if (sbslrecords.length > 0) {
                const sheet = XLSX.utils.json_to_sheet(sbslrecords)
                if (sheet === undefined || sheet === null) {
                    this.$Message.warning(`json_to_sheet error`)
                    return
                }
                const cols = this.getColumnWidth([30, 40, 30])
                sheet['!cols'] = cols
                const rows = this.getRowHeight(sbslrecords.length, 18)
                sheet['!rows'] = rows
                sheets.push(sheet)
                sheetNames.push(this.sbbjtj)
            }
            if (sbrecords.length > 0) {
                const sheet = XLSX.utils.json_to_sheet(sbrecords)
                if (sheet === undefined || sheet === null) {
                    this.$Message.warning(`json_to_sheet error`)
                    return
                }
                const cols = this.getColumnWidth([40, 40, 30, 30])
                sheet['!cols'] = cols
                const rows = this.getRowHeight(sbrecords.length, 18)
                sheet['!rows'] = rows
                sheets.push(sheet)
                sheetNames.push(this.sbbjxq)
            }
            if (zzrecords.length > 0) {
                const sheet = XLSX.utils.json_to_sheet(zzrecords)
                if (sheet === undefined || sheet === null) {
                    this.$Message.warning(`json_to_sheet error`)
                    return
                }
                const cols = this.getColumnWidth([30, 40, 30])
                sheet['!cols'] = cols
                const rows = this.getRowHeight(zzrecords.length, 18)
                sheet['!rows'] = rows
                sheets.push(sheet)
                sheetNames.push(this.zzzjTitle)
            }
            const blob = multisheet2blob(XLSX, sheets, sheetNames)
            if (blob === undefined || blob === null) {
                this.$Message.warning(`sheet2blob error`)
                return
            }

            const timeFrom = moment().format('YYYYMMDD')
            const fileName = `${this.zjgnTitle}_${timeFrom}.xls`
            openDownloadDialog(blob, fileName)
        },
        handle_click_setup_event() {
            const om_calibrate_setup_dialog = this.om_calibrate_setup_dialog
            if (om_calibrate_setup_dialog === undefined || om_calibrate_setup_dialog === null) return
            if (this.zjcycle !== undefined && this.zjcycle !== null) {
                this.$refs.om_calibrate_setup_dialog.reset_equip_cycle(this.zjcycle)
            }
            om_calibrate_setup_dialog.showDialog()
        },
        showDialog() {
            this.$refs.om_dialog.showDialog()
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        handle_setup_commit_click(cycledata) {
            const cycle = cycledata.zj_cycle
            if (cycle !== undefined && cycle !== null) {
                this.resetZjcycle(parseInt(cycle))
            }
        },
        canshus(leixing_id) {
            const map_leixing_canshu = this.$store.state.station.map_leixing_canshu
            if (map_leixing_canshu === undefined || map_leixing_canshu === null) return []
            const canshus = map_leixing_canshu[leixing_id]
            if (canshus === undefined || canshus === null) return []
            let _canshus = canshus.filter(
                (canshu) => {
                    if (canshu.canshu_id === 1) return false
                    return true
                }
            )
            _canshus = _canshus.sort(
                (oldItem, newItem) => {
                    if (oldItem.criteriatype > newItem.criteriatype) {
                        return true
                    }
                    return false
                }
            )
            return _canshus
        },
        objectgks() {
            const gkitems = this.$store.getters.zjmemory()
            return gkitems
        },
        canshu(object) {
            if (object === undefined || object === null) return null
            const obj_id = object.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        label(mingzi, danwei) {
            if (danwei === undefined || danwei === null || danwei === '') return mingzi
            return `${mingzi}(${danwei})`
        },
        equip_flag_object(object) {
            const data_flag = this.data_flag(object)
            return equip_flag_object(data_flag)
        },
        ctime(object) {
            const canshu = this.canshu(object)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const ctime = canshu.ctime
            if (ctime === undefined || ctime === null) {
                return ''
            }
            return ctime
        },
        rtime(obj_id, canshu_id) {
            const canshu = this.canshu(obj_id, canshu_id)
            if (canshu === undefined || canshu === null) return ''
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return ''
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) return ''
            return rtime
        },
        sjtime(tm) {
            if (tm === undefined || tm === null) {
                return ''
            }
            return tm
        },
        rdata(obj_id, canshu_id) {
            const canshu = this.canshu(obj_id, canshu_id)
            if (canshu === undefined || canshu === null) return ''
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return ''
            const rdata = canshu.rdata
            if (rdata === null || rdata === undefined) return ''
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return ''
            const rtutype = canshu.rtutype
            if (rtutype === undefined || rtutype === null) return ''
            // 遥测
            if (rtutype === 0) {
                // 浮点型
                if (datatype === 1) {
                    return toDecimal(rdata, 2)
                }
                // 整型
                if (datatype === 2) {
                    return this.yxbianma(leixing_id, canshu_id, parseInt(parseInt(rdata)))
                }
                // 日期时间型
                if (datatype === 3) {
                    return toDateTime(rdata)
                }
                return ''
            }
            if (rtutype === 1) {
                // 整型
                if (datatype === 2) {
                    return this.yxbianma(leixing_id, canshu_id, parseInt(parseInt(rdata)))
                }
                return ''
            }
            return ''
        },
        handle_click_event(shebei) {
            this.closeDialog()
            const leixing_id = shebei.leixing_id
            if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) {
                this.$router.push('/condition')
                return
            }
            this.$router.push('/report')
            this.$nextTick(
                () => {
                    this.$store.commit('setSelectedShebeiObject', shebei)
                }
            )
        },
        yxbianma(leixing_id, canshu_id, data_int) {
            if (leixing_id === undefined || leixing_id === null) {
                return data_int
            }
            if (canshu_id === undefined || canshu_id === null) {
                return data_int
            }
            const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
            const bianma = this.$store.getters.getYxbianma(obj_id)
            if (bianma === undefined || bianma === null) {
                return data_int
            }
            return `${data_int}(${bianma.shuoming})`
        },
        async handle_query_gk() {
            try {
                const resultset = await this.$ajax.p_query_zjgn_memory({})
                const code = resultset[0][0].code
                const length = resultset[1].length
                if (code !== 0) {
                    this.$Message.warning(`p_query_zjgn_memory,错误原因：${resultset[0][0].text}`)
                }
                if (length !== 0) {
                    this.zjgkdatas = resultset[1]
                }
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_zjgn_memory,错误原因：${JSON.stringify(error)}`
                })
            }
        },
        async handle_query_equip() {
            try {
                const resultset = await this.$ajax.p_query_zjgn_equip({})
                const code = resultset[0][0].code
                const length = resultset[1].length
                if (code !== 0) {
                    this.$Message.warning(`p_query_zjgn_equip,错误原因：${resultset[0][0].text}`)
                }
                if (length !== 0) {
                    this.zzzjdatas = resultset[1]
                }
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_zjgn_equip,错误原因：${error}`
                })
            }
        },
        resetZjcycle(cycle) {
            const curcycle = this.zjcycle !== undefined && this.zjcycle !== null ? this.zjcycle : 1
            if (curcycle !== cycle && cycle !== undefined && cycle !== null) {
                const newcycle = cycle !== undefined && cycle !== null ? cycle : 1
                this.zjcycle = newcycle
                const mseconds = parseInt(newcycle) * 60000
                if (this.timer !== null) {
                    clearInterval(this.timer)
                    this.timer = null
                }
                this.timer = setInterval(
                    () => {
                        this.handle_query_gk()
                        this.handle_query_equip()
                    },
                    mseconds
                )
            }
        },
        async handle_query_equip_cycle() {
            try {
                const resultset = await this.$ajax.p_query_zjgn_cycle( { leixing_code: 'zj_equip_cycle' } )
                const code = resultset[0][0].code
                const length = resultset[1].length
                if (code !== 0) {
                    this.$Message.warning(`p_query_zjgn_cycle,错误原因2s：${resultset[0][0].text}`)
                }
                if (length !== 0) {
                    const zjcycledata = resultset[1][0]
                    this.resetZjcycle(zjcycledata.leixing_value)
                }
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_zjgn_equip,错误原因：${error}`
                })
            }
        }
    },
    mounted() {
        if (this.zzzjdatas === null || this.zzzjdatas.length === 0 || this.zjgkdatas === null || this.zjgkdatas.length === 0) {
            this.handle_query_gk()
            this.handle_query_equip()
            this.handle_query_equip_cycle()
        }
    },
    created() {
    },
    beforeDestroy() {
        if (this.timer !== undefined && this.timer !== null) {
            clearInterval(this.timer)
        }
        this.timer = null
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .om_dialog_header_title {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        .title {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            margin: 0px;
        }
        .export {
            flex: 0;
            min-width: 80px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            margin: 0px;
            padding-bottom: 4px;
        }
    }
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: block;
        .el-tabs--border-card {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            width: 100% !important;
            height: 100% !important;
            padding: 0px !important;
            box-sizing: border-box !important;
            .el-tabs__header {
                flex: 0;
                width: 100%;
                height: 39px;
                min-height: 39px;
                background-color: #F5F7FA;
                border-bottom: 1px solid #E4E7ED;
                margin: 0;
            }
            .el-tabs__content {
                flex: 1;
                width: 100% !important;
                height: 100% !important;
                padding: 0px !important;
                .el-tab-pane {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
        .leixing_content {
            width: 100%;
            height: 100%;
            color: red; display: block;
            background: green;
            display: true;
        }
        .om_calibrate_setup_dialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,0.5);
            overflow: hidden;
            z-index: 600;
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }
    }
    .el-table {
        width: 100%;
        font-size: 12px !important;
        overflow: auto !important;
        display: flex;
        flex-direction: column;
        .el-table__header-wrapper {
            overflow: visible !important;
            flex: 0 auto;
            width: 100%;
        }
        td, th {
            padding: 2px;
        }
        .el-image {
            width: 100%;
            height: 100%;
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            img {
                flex: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .device_view {
        width: 100%;
        height: 100%;
        background: white;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
    }
    .vert_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
    .statics {
        flex: 0;
        min-width: 400px;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        padding-left: 2px;
        padding-right: 2px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        table {
            border-collapse: collapse;
            margin: 0 auto;
            text-align: center;
        }
        table td, table th {
            border: 1px solid #cad9ea;
            color: #666;
            height: 30px;
        }
        table thead th {
            background-color: #CCE8EB;
            width: 100px;
        }
        table tr:nth-child(odd) {
            background: #fff;
        }
        table tr:nth-child(even) {
            background: #F5FAFA;
        }
    }
    .detail {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding-left: 2px;
        .el-table {
            width: 100%;
            font-size: 12px !important;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .el-table__header-wrapper {
                box-sizing: border-box;
                width: 100%;
                overflow: visible !important;
                flex: 0 auto;
            }
            .el-table__body-wrapper {
                width: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                flex: 1;
            }
            td, th {
                padding: 0px;
            }
            .more {
                width: 50px;
                height: 100%;
                padding: 0px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 20px !important;
            }
        }
    }
}
</style>