<template>
<om_dialog
ref="ref_dialog"
width="300px"
height="200px"
:title="drptwj">
    <div class="om_prps_import_dialog">
        <el-upload
        ref="ref_upload"
        action="alert"
        accept=".omgis"
        :show-file-list="false"
        :on-change="beforeUpload"
        :auto-upload="false"
        :multiple="false"
        >
            <el-button type="success">
                {{qscptwj}}
            </el-button>
        </el-upload>
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
export default {
    name: 'om_prps_import_dialog',
    components: {
        om_dialog
    },
    setup() {
        const ref_dialog = ref(null)
        return {
            ref_dialog
        }
    },
    computed: {
        drptwj() {
            return this.$Lang.jcsj.sssj.sspthf.drptwj
        },
        qscptwj() {
            return this.$Lang.jcsj.sssj.sspthf.qscptwj
        },
        warn_file() {
            return this.$Lang.jcsj.sssj.sspthf.warn_file
        },
        warn_size() {
            return this.$Lang.jcsj.sssj.sspthf.warn_size
        }
    },
    methods: {
        showDialog() {
            this.ref_dialog.showDialog()
        },
        closeDialog() {
            this.ref_dialog.closeDialog()
        },
        beforeUpload(file) {
            const fileName = file.name.trim()
            if (fileName === undefined || fileName === null) {
                this.$Message.error(`${this.warn_file}!`)
                return
            }
            const args = fileName.split('.')
            if (args === undefined || args === null) return
            if (args.length < 2) {
                this.$Message.error(`${this.warn_file}!`)
                return
            }
            const is_omgis = args[args.length - 1] === 'omgis'
            if (!is_omgis) {
                this.$Message.error(`${this.warn_file}!`)
                return false
            }
            const isLt2M = file.size / 1024 / 1024 < 5
            if (!isLt2M) {
                this.$Message.error(`${this.warn_size}`)
                return false
            }
            const fileReader = new FileReader()
            fileReader.readAsText(file.raw)
            fileReader.onload = (e) => {
                const data = e.target.result
                if (data === '') return
                try {
                    const list_prps = JSON.parse(data)
                    this.$emit('success', list_prps)
                    this.ref_dialog.closeDialog()
                } catch (error) {
                    this.$Message.error(error)
                }
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
.om_prps_import_dialog {
    width: 100%;
    height: 100%;
    z-Index: 800;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .el-button {
        margin: 10px;
        padding: 10px;
        display: flex;
        box-sizing: border-box;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>