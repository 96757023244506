<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="300px"
height="300px"
:title="qscbjt">
    <div class="om_dialog__body">
        <el-upload
        class="avatar-uploader"
        ref="ref_upload"
        action="/upload"
        :headers="token"
        accept=".jpg"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="handleUploadSuccess"
        :auto-upload="true"
        :multiple="false"
        >
            <img v-if="imageUrl!=null" class="avatar" :src="imageUrl" alt=""/>
            <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
        </el-upload>
    </div>
    <template v-slot:footer>
        <div class="om_dialog__footer">
            <el-button type="primary" @click="closeDialog()">{{qx}}</el-button>
            <el-button type="primary" @click="handle_commit_click()">{{qd}}</el-button>
        </div>
    </template>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
import { Plus } from '@element-plus/icons-vue'
export default {
    name: 'om_upload_dialog',
    components: {
        om_dialog,
        Plus
    },
    setup() {
        const om_dialog = ref(null)
        const ref_upload = ref(null)
        return {
            om_dialog,
            ref_upload
        }
    },
    data() {
        return {
            imageUrl: null
        }
    },
    computed: {
        token() {
            const token = sessionStorage.getItem('x-access-token')
            if (token === undefined || token === null) return null
            const headers = {
                'x-access-token': token
            }
            return headers
        },
        qscbjt() {
            return this.$Lang.header.dtbj.scbjt.qscbjt
        },
        qx() {
            return this.$Lang.header.dtbj.scbjt.qx
        },
        qd() {
            return this.$Lang.header.dtbj.scbjt.qd
        },
        warn_type() {
            return this.$Lang.header.dtbj.scbjt.warn_type
        },
        warn_size() {
            return this.$Lang.header.dtbj.scbjt.warn_size
        }
    },
    methods: {
        showDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            om_dialog.showDialog()
        },
        closeDialog() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            this.imageUrl = null
            om_dialog.closeDialog()
        },
        // ------------------------------------------------------------------
        beforeUpload(file) {
            // 上传图片前处理函数
            const isJPG = file.type === 'image/jpeg'
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isJPG) {
                this.$Message.error(`${this.warn_type}`)
                return false
            }
            if (!isLt2M) {
                this.$Message.error(`${this.warn_size}`)
                return false
            }
            return true
        },
        handleUploadSuccess(res, file) {
            const URL = window.URL || window.webkitURL
            const src = URL.createObjectURL(file.raw)
            this.imageUrl = src
        },
        handle_commit_click() {
            const om_dialog = this.om_dialog
            if (om_dialog === undefined || om_dialog === null) return
            this.$emit('commit')
            this.$nextTick(
                () => {
                    om_dialog.closeDialog()
                }
            )
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
.om_dialog {
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .avatar-uploader {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            overflow: hidden;
            .el-upload {
                flex: 0;
                min-width: 128px;
                height: 128px;
                box-sizing: border-box;
                border: 2px dashed #d9d9d9;
                border-radius: 8px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .avatar-uploader-icon {
                    flex: 0;
                    min-width: 16px;
                    height: 16px;
                    color: #8c939d;
                    overflow: hidden;
                    box-sizing: border-box;
                }
                .avatar {
                    display: block;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    box-sizing: border-box;
                }
            }
            .el-upload:hover {
                border-color: #409eff;
            }
        }
    }
    .om_dialog__footer {
        box-sizing: border-box;
        padding: 4px;
    }
}
</style>