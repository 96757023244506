<template>
<div class="om_device_realtime_data">
    <template v-if="leixing_id===4">
        <om_ysp_canshu_view class="om_ysp_canshu_view" ref="om_ysp_canshu_view" :object="object"/>
    </template>
    <!-- <template v-else>
        <om_gispd_canshu_view class="om_gispd_canshu_view" ref="om_gispd_canshu_view" :object="object"/>
    </template> -->
    <template v-if="leixing_id===18||leixing_id===38">
        <om_gispd_canshu_view class="om_gispd_canshu_view" ref="om_gispd_canshu_view" :object="object"/>
    </template>
    <template v-else>
        <om_device_canshu_view class="om_device_canshu_view" ref="om_device_canshu_view" :object="object"/>
    </template>
</div>
</template>

<script>
import { ref } from 'vue'
import om_device_canshu_view from './om_device_canshu_view'
import om_ysp_canshu_view from './om_ysp_canshu_view'
import om_gispd_canshu_view from './om_gispd_canshu_view'
export default {
    name:'om_device_realtime_data',
    components: {
        om_device_canshu_view,
        om_ysp_canshu_view,
        om_gispd_canshu_view
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const om_gispd_canshu_view = ref(null)
        return {
            om_gispd_canshu_view
        }
    },
    computed: {
        leixing_id() {
            return this.object.leixing_id
        }
    },
    watch: {
        'object.obj_id': {
            immidiate: true,
            deep: false,
            handler() {
                this.resize()
            }
        }
    },
    methods: {
        resize() {
            if (this.om_gispd_canshu_view) {
                this.om_gispd_canshu_view.resize
            }
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang="scss">
.om_device_realtime_data {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    .el-tabs--bottom .el-tabs__header.is-bottom {
        margin-top: 0px !important;
    }
}
</style>