<template>
<div class="om_overview">
    <div class="home_show_mode">
        <div class="mode" v-show="is_home_show_mode">
            <el-radio-group v-model="home_show_mode">
                <el-radio-button :label="0">{{bgzs}}</el-radio-button>
                <el-radio-button :label="1">{{jxtzs}}</el-radio-button>
            </el-radio-group>
        </div>
        <div class="total">
            <div class="item">
                <span style="color: black; font-size: 18px; font-weight: bold;">{{sbzs}}:{{shebei_total}}</span>
            </div>
            <div class="item">
                <span style="display: flex; align-items: center; color: gray; font-size: 18px; font-weight: bold;"><img width="24" height="24" src="/images/device_flag/unnormal.gif" atl="装置故障"/>{{zzgz}}:{{device_fault_event_total}}</span>
            </div>
            <div class="item">
                <span style="display: flex; align-items: center; color: red; font-size: 18px; font-weight: bold;"><img width="24" height="24" src="/images/equip_flag/alarm.gif" atl="数据报警"/>{{sjbj}}:{{shebei_alarm_event_total}}</span>
            </div>
            <div class="item">
                <span style="display: flex; align-items: center; color: #e6a23c; font-size: 18px; font-weight: bold;"><img width="18" height="18" src="/images/equip_flag/atten.gif" atl="数据预警"/>{{sjyj}}:{{shebei_atten_event_total}}</span>
            </div>
        </div>
    </div>
    <div class="home_show_content">
        <om_table_overview v-show="home_show_mode<=0"/>
        <om_svg_view v-show="home_show_mode>0"/>
    </div>
</div>
</template>

<script>
import om_svg_view from './om_svg_view'
import om_table_overview from './om_table_overview'
export default {
    name: 'om_overview',
    components: {
        om_svg_view,
        om_table_overview
    },
    data() {
        return {
            home_show_mode: 0
        }
    },
    computed: {
        is_home_show_mode() {
            const settings = this.$store.state.station.settings
            if (settings === undefined || settings === null) return true
            return settings[2].useflag > 0
        },
        bdz_mingzi() {
            const biandianzhan = this.$store.getters.biandianzhan
            if (biandianzhan === undefined || biandianzhan === null) return ''
            const bdz_mingzi = biandianzhan.mingzi
            if (bdz_mingzi === undefined || bdz_mingzi === null) return ''
            return bdz_mingzi
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        shebei_alarm_event_total() {
            const shebei_alarm_event_total = this.$store.getters.shebei_alarm_event_total
            return shebei_alarm_event_total
        },
        shebei_atten_event_total() {
            const shebei_atten_event_total = this.$store.getters.shebei_atten_event_total
            return shebei_atten_event_total
        },
        device_fault_event_total() {
            const device_fault_event_total = this.$store.getters.device_fault_event_total
            return device_fault_event_total
        },
        bgzs() {
            return this.$Lang.xtsy.sbzl.bgzs
        },
        jxtzs() {
            return this.$Lang.xtsy.sbzl.jxtzs
        },
        sbzs() {
            return this.$Lang.xtsy.sbzl.sbzs
        },
        zzgz() {
            return this.$Lang.xtsy.sbzl.zzgz
        },
        sjbj() {
            return this.$Lang.xtsy.sbzl.sjbj
        },
        sjyj() {
            return this.$Lang.xtsy.sbzl.sjyj
        }
    }
}
</script>

<style lang="scss" scoped>
.om_overview {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    font-size: 14px;
    .home_show_mode {
        flex: 0 auto;
        width: 100%;
        min-height: 48px;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .mode {
            flex: 0;
            min-width: 240px;
            height: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .total {
            flex: 1;
            width: 100%;
            height: 100%;
            margin: 0px;
            padding: 0px;
            overflow: hidden;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .item {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .home_show_content {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
    }
}
</style>
