<template>
    <div class="chart-container">
        <div style="position: relative;width: 100%; height: 100%;" >
            <div v-show="!empty" ref="labelRef" style="position: absolute; width: 100%; height: 100%;z-index: 1;">
                <span id="x1" style="position: absolute;left:37%;top: 85%; color:white;">0°</span>
                <span id="x2" style="position: absolute;left:42%;top: 85%;color: white;">90°</span>
                <span id="x3" style="position: absolute;left:49%;top: 85%;color: white;">180°</span>
                <span id="x4" style="position: absolute;left:56%;top: 85%;color: white;">270°</span>
                <span id="x5" style="position: absolute;left:63%;top: 85%;color: white;">360°</span>
                <span id="y1" style="position: absolute;left:66%;top: 80%;color: white;">0</span>
                <span id="y2" style="position: absolute;left:64%;top: 73%;color: white;">10</span>
                <span id="y3" style="position: absolute;left:62%;top: 67%;color: white;">20</span>
                <span id="y4" style="position: absolute;left:61%;top: 61%;color: white;">30</span>
                <span id="y5" style="position: absolute;left:60%;top: 58%;color: white;">40</span>
                <span id="y6" style="position: absolute;left:59%;top: 54%;color: white;">50</span>
                <span id="z1" style="position: absolute;left:30%;top: 85%;color: white;">-80</span>
                <span id="z2" style="position: absolute;left:30%;top: 80%;color: white;">-70</span>
                <span id="z3" style="position: absolute;left:29%;top: 75%;color: white;">-60</span>
                <span id="z4" style="position: absolute;left:28%;top: 70%;color: white;">-50</span>
                <span id="z5" style="position: absolute;left:27%;top: 65%;color: white;">-40</span>
                <span id="z6" style="position: absolute;left:26%;top: 60%;color: white;">-30</span>
                <span id="z7" style="position: absolute;left:25%;top: 55%;color: white;">-20</span>
                <span id="z8" style="position: absolute;left:24%;top: 50%;color: white;">-10</span>
                <span id="z9" style="position: absolute;left:23%;top: 45%;color: white;">0</span>
                <span id="xt" style="position: absolute;left:49%;top: 90%;color: white;">{{xt}}</span>
                <span id="yt" style="position: absolute;left:64%;top: 67%;color: white;">{{yt}}</span>
                <span id="zt" style="position: absolute;left:23%;top: 65%;color: white;width:40px; display:inline-block; white-space: normal;word-break: break-all;">{{zt}}<br/>(dBm)</span>
            </div>
            <div ref="chartRef" style="position: absolute; width: 100%; height: 100%;z-index: 2;"></div>
        </div>
        
        <el-empty v-show="empty" />
    </div>
</template>

<script>
    import { ref } from "vue";
    // import elementResizeDetectorMaker from "element-resize-detector";
    // 柱状图 默认配置及合并方式
    import setBarOptions from "./barChart";
    import setBar3DOptions from "./bar3dChart"
    import setScatterOptions from "./scatterChart";
    // 折线 默认配置及合并方式
    import setLineOptions from "./lineChart";
    //饼图
    import setPieOptions from "./pieChart";

    // 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
    import * as echarts from "echarts/core";
    import 'echarts/lib/component/dataZoom'
    import glmatrix from 'claygl/src/dep/glmatrix';
    // 引入柱状图图表，图表后缀都为 Chart
    import { BarChart, ScatterChart, LineChart , PieChart } from "echarts/charts";
    import { Bar3DChart, Line3DChart } from "echarts-gl/charts"
    import 'echarts-gl';
    // 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件
    import {
        LegendComponent,
        TooltipComponent,
        GridComponent,
        DatasetComponent,
        TransformComponent,
        VisualMapComponent,
        ToolboxComponent,
    } from "echarts/components";
    // 标签自动布局，全局过渡动画等特性
    import { LabelLayout, UniversalTransition } from "echarts/features";
    // 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
    import { CanvasRenderer } from "echarts/renderers";
    import { TitleComponent } from 'echarts/components'
    // 注册
    echarts.use([
        BarChart,
        ScatterChart,
        LegendComponent,
        VisualMapComponent,
        ToolboxComponent,
        TooltipComponent,
        GridComponent,
        DatasetComponent,
        TransformComponent,
        LabelLayout,
        UniversalTransition,
        CanvasRenderer,
        LineChart,
        PieChart,
        Bar3DChart,
        Line3DChart,
        TitleComponent

    ]);

    const mergeSet = {
        bar: setBarOptions,
        scatter: setScatterOptions,
        line: setLineOptions,
        pie: setPieOptions,
        bar3d: setBar3DOptions
    };

    export default {
        props: {
            type: {
                type: String,
                require: true,
            },
            options: Object,
            xt: '',
            yt: '',
            zt: ''
        },
        setup(props, cxt) {
            let chartInstance;
            const chartRef = ref(null);
            const labelRef = ref(null)
            const empty = ref(false);
            // const erd = elementResizeDetectorMaker();
            // const reChart = resizeChart()
            const resizeLabel = resetChartLabel()

            // 初始化实例
            function initChart() {
                chartInstance = echarts.init(chartRef.value);
                // erd.listenTo(chartRef.value, reChart);
                // window.onresize = reChart
                // window.addEventListener('resize', function() {
                //     this.resetChartLabel()
                //     window.console.log('resize2','dddds')
                // })
            }

            // 清除实例影响
            function deleteChart() {
                // erd.uninstall(chartRef.value);
                // window.removeEventListener('resize', reChart)
                chartInstance && chartInstance.dispose();
            }

            // resize封装
            function resizeChart() {
                // return function () {
                    window.console.log('resize','ddd')
                    chartInstance.resize();
                    resizeLabel()
                // };
            }

            function setChart() {
                if (!chartInstance) return;
                chartInstance.clear();
                empty.value = false;
                const { type, options } = props;
                const fn = mergeSet[type];
                if (fn && options) {
                    const opt = fn(options);
                    if (process.env.NODE_ENV === "development") {
                    }
                    chartInstance.setOption(options,false);

                } else {
                    empty.value = true;
                }
            }
            function resetChartLabel() {
            return function () {
                if (!chartInstance) return;
                let coord = chartInstance._coordSysMgr['_coordinateSystems'][0]
                // let camera = coord.viewGL.camera
                // 视图中心 也就是canvas的中心
                let cx = coord.viewGL.viewport.width / 2;
                let cy = coord.viewGL.viewport.height / 2;
                // window.console.log('screen', cx + ',' + cy)
                // window.console.log('position', JSON.stringify(camera.position))
                // window.console.log('viewp', JSON.stringify(camera.viewMatrix))
                // window.console.log('projectm', JSON.stringify(camera.projectionMatrix))
                // const child = coord.viewGL.scene._children
                // window.console.log('child1', child[0].name)
                // const dpr = coord.viewGL.viewport.devicePixelRatio
                // var viewproj = glmatrix.mat4.create()
                // glmatrix.mat4.multiply(viewproj, camera.projectionMatrix.array, camera.viewMatrix.array)
                // const dpr = coord.getDevicePixelRatio()
                // window.console.log('dpr', dpr)
                // window.console.log('viewproj', JSON.stringify(viewproj))
                // var xAxis = coord.getAxis('x');
                // var labels = xAxis.getViewLabels()
                // window.console.log('labels', JSON.stringify(labels))
                
                // const b = coord.viewGL.scene._children[0].isRenderable()
                // if (b) {
                    // var scene = coord.viewGL.scene._children[0].worldTransform
                    // window.console.log('scene0', JSON.stringify(scene))
                    // const pos0 =  coord.viewGL.scene._children[0].position
                    // window.console.log('pos0', JSON.stringify(pos0))
                    // var scene2 = coord.viewGL.scene._children[1].worldTransform
                    // window.console.log('scene2', JSON.stringify(scene2))
                    // const pos1 =  coord.viewGL.scene._children[1].position
                    // window.console.log('pos1', JSON.stringify(pos1))
                    // const len = coord.viewGL.scene._children.length
                    // for( let i=0; i< len; i++) {
                    //     const ch = coord.viewGL.scene._children[i]
                    //     window.console.log('wp', len + ',' + ch._children.length + ',' + ch.name + ',' + JSON.stringify(ch.position) + ',w=' + JSON.stringify(ch.wordTransform))
                    //     const len1 = ch._children.length
                    //     for (let j=0; j < len1; j++) {
                    //         const ch1 = ch._children[j]
                    //         window.console.log('w', len + ',' + len1 + ',' + ch1.name + ',' + JSON.stringify(ch1.position) + ',w=' + JSON.stringify(ch1.wordTransform))
                    //     }
                    // }
                    // const model = chartInstance._coordSysMgr
                    // const crd = coord.getAxis('x')._viewGL
                    // // if (crd !== null)
                    // window.console.log('crd', model)
                    // window.console.log('secene', coord)
                // }
                // const b1 = coord.viewGL.scene._children[1].isRenderable()
                // if (b1) {
                //     var scene = coord.viewGL.scene._children[1].worldTransform
                //     window.console.log('scene1', JSON.stringify(scene))
                // }
                // const b2 = coord.viewGL.scene._children[2].isRenderable()
                // if (b2) {
                //     var scene = coord.viewGL.scene._children[2].wordTransform
                //     window.console.log('scene2', JSON.stringify(scene))
                // }
                // const list = coord.viewGL.scene
                // window.console.log('list', JSON.stringify(list.worldTransform))
                
                // var tickCoord = xAxis.dataToCoord(labels[0].tickValue)
                // window.console.log('tickcoord', JSON.stringify(tickCoord))
                // const items = [[0,-50,0],[90,-50,0],[360,-50,0],[360,-25,0],[360,0,0],[0,-50,75],[360,-50,75]]
                // items.forEach(item => {
                //     let pp = coord.dataToPoint(item)
                //     window.console.log('pp', JSON.stringify(item) + ',' + JSON.stringify(pp))
                //     let vv = Object.create(camera.position).set(pp[0],pp[1],pp[2])
                //     vv = vv.transformMat4(camera.viewMatrix).transformMat4(camera.projectionMatrix)
                //     window.console.log('vv', JSON.stringify(item) + ',' + vv.x + ',' + vv.y)
                //     window.console.log('vv1', JSON.stringify(item) + ',' + vv.x/220 + ',' + vv.y/220)
                //     let screenX = Math.round(cx * vv.x/220 + cx);
                //     let screenY = Math.round(-cy * vv.y/220 + cy)
                //     window.console.log('ss', JSON.stringify(item) + ',' + screenX + ',' + screenY)
                // })

                // const ppx1 = coord.dataToPoint([0,-50,0])
                //         let vvx1 = Object.create(camera.position).set(ppx1[0],ppx1[1],ppx1[2])
                //     vvx1 = vvx1.transformMat4(camera.viewMatrix).transformMat4(camera.projectionMatrix)


                // window.console.log('xx1', labelRef.value.children)
                let ex = cy/cx
                const fx = 100*(cx-cy)/(cx*2)
                const labelitems = labelRef.value.children
                for (let i =0; i < labelitems.length; i++) {
                    let item = labelitems[i]
                    if (item.id === 'x1') {
                        item.style.left = Math.round(fx + 20*ex) + '%'
                        item.style.top = '88%'
                    } else if (item.id === 'x2') {
                        item.style.left = Math.round(fx + 33.4*ex) + '%'
                        item.style.top = '88%'
                    } else if (item.id === 'x3') {
                        item.style.left = Math.round(fx + 48*ex) + '%'
                        item.style.top = '88%'
                    } else if (item.id === 'x4') {
                        item.style.left = Math.round(fx + 63*ex) + '%'
                        item.style.top = '88%'
                    } else if (item.id === 'x5') {
                        item.style.left = Math.round(fx + 77.5*ex) + '%'
                        item.style.top = '88%'
                    } else if (item.id === 'y1') {
                        item.style.left = Math.round(fx + 82.6*ex) + '%'
                        item.style.top = '84%'
                    } else if (item.id === 'y2') {
                        item.style.left = Math.round(fx + 80.4*ex) + '%'
                        item.style.top = '78%'
                    } else if (item.id === 'y3') {
                        item.style.left = Math.round(fx + 77.8*ex) + '%'
                        item.style.top = '72%'
                    } else if (item.id === 'y4') {
                        item.style.left = Math.round(fx + 75.35*ex) + '%'
                        item.style.top = '67%'
                    } else if (item.id === 'y5') {
                        item.style.left = Math.round(fx + 73.5*ex) + '%'
                        item.style.top = '63%'
                    } else if (item.id === 'y6') {
                        item.style.left = Math.round(fx + 71.8*ex) + '%'
                        item.style.top = '60%'
                    } else if (item.id === 'z1') {
                        item.style.left = Math.round(fx + 14.4*ex) + '%'
                        item.style.top = '85%'
                    } else if (item.id === 'z2') {
                        item.style.left = Math.round(fx + 13.2*ex) + '%'
                        item.style.top = '80.3%'
                    } else if (item.id === 'z3') {
                        item.style.left = Math.round(fx + 12.8*ex) + '%'
                        item.style.top = '74.2%'
                    } else if (item.id === 'z4') {
                        item.style.left = Math.round(fx + 11.8*ex) + '%'
                        item.style.top = '67.2%'
                    } else if (item.id === 'z5') {
                        item.style.left = Math.round(fx + 11.6*ex) + '%'
                        item.style.top = '61%'
                    } else if (item.id === 'z6') {
                        item.style.left = Math.round(fx + 10.5*ex) + '%'
                        item.style.top = '53.5%'
                    } else if (item.id === 'z7') {
                        item.style.left = Math.round(fx + 9.6*ex) + '%'
                        item.style.top = '46.5%'
                    } else if (item.id === 'z8') {
                        item.style.left = Math.round(fx + 8.8*ex) + '%'
                        item.style.top = '38%'
                    } else if (item.id === 'z9') {
                        item.style.left = Math.round(fx + 9.2*ex) + '%'
                        item.style.top = '31%'
                    } else if (item.id === 'xt') {
                        item.style.left = Math.round(fx + 48*ex) + '%'
                        item.style.top = '92%'
                    } else if (item.id === 'yt') {
                        item.style.left = Math.round(fx + 85*ex) + '%'
                        item.style.top = '70%'
                    } else if (item.id === 'zt') {
                        item.style.left = Math.round(fx + 1*ex) + '%'
                        item.style.top = '61%'
                    }
                }  //(16.5- 10.8)/(85-31) = (x-10.8)/(80.3-31)
            }
        }

            return {
                empty,
                chartRef, labelRef,
                initChart,
                deleteChart,
                resizeChart,
                setChart
            };
        },
        watch: {
            options: {
                handler(nv) {
                    // this.$nextTick(
                    //     () => {
                    //         this.setChart()
                    //     }
                    // )
                    this.setChart();
                    // const p10 = chartInstance.convertToPixel({xAxis3DIndex: 0, yAxis3DIndex: 0, zAxis3DIndex: 0}, [10, -40, 10])
                    // window.console.log('p102t', p10)
                },
                immidiate: true,
                deep: true  
            },
        },
        mounted() {
            this.initChart();
            this.setChart();
            this.$nextTick(() => {
                this.resizeChart()
            })
            // this.resizeChart()
        },
        beforeDestroy() {
            // window.console.log('destroly','dssr')
            this.deleteChart();
        },
    };
</script>

<style lang="scss" scoped>
    .chart-container {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: rgb(120, 120, 120);

        .el-empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10%;
            text-align: center;
            line-height: 2;
        }
    }
</style>
