<template>
<div class="om_device_history_table">
    <div class="content_form">
        <div class="time">
            <span>{{rqfw}}</span>
            <el-date-picker
            type="daterange"
            :clearable="false"
            :range-separator="zhi"
            start-placeholder="starttm"
            end-placeholder="endtm"
            v-model="time">
            </el-date-picker>
        </div>
        <div class="data_flag_range">
            <el-select
            :placeholder="qxzzt"
            v-model="data_flag_selected"
            >
                <el-option
                v-for="(data_flag, index) in data_flag_range"
                :label="data_flag.mingzi"
                :value="data_flag.data_flag"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
        <div class="canshu_range">
            <el-select
            :placeholder="timeTypetm"
            v-model="type_tm_selected"
            >
                <el-option :label="fiveminute" :value="0" :key="0"></el-option>
                <el-option :label="hourtm" :value="2" :key="1"></el-option>
                <el-option :label="daytm" :value="3" :key="2"></el-option>
            </el-select>
        </div>
        <div class="query">
            <el-button type="primary" round @click="handle_click_event">{{cx}}</el-button>
        </div>
        <div class="export">
            <el-button type="success" round @click="handle_click_export_event">{{dc}}</el-button>
        </div>
    </div>
    <div class="content_container">
        <div style="width:100%; height: 100%; display: flex; justify-content: center; align-items:center;" v-show="isLoading">
            <span style="font-size:26px;">{{loading}}</span>
        </div>
        <el-table
        ref="content_table"
        :data="tableBody"
        :border="true"
        :show-overflow-tooltip="true"
        stripe
        style="width:100%; height: 100%;"
        >
            <el-table-column
            type="index"
            :label='xh'
            align="center"
            :index=currentIndex
            show-overflow-tooltip
            :width="60"
            >
            </el-table-column>

            <el-table-column
            :label='zdsj'
            prop="ctime"
            align="center"
            :show-overflow-tooltip="true"
            :width="160"
            >
            </el-table-column>

            <el-table-column
            v-if="quanxian>0"
            :label='cjsj'
            prop="rtime"
            align="center"
            :show-overflow-tooltip="true"
            :width="160"
            >
            </el-table-column>

            <el-table-column
            :label='zzzt'
            prop="data_flag"
            align="center"
            :show-overflow-tooltip="true"
            :width="120"
            >
                <template #default="scope">
                    <div class="data_flag">
                        <img :src="device_flag_object(scope.row.data_flag).src"/>
                        <span>{{device_flag_object(scope.row.data_flag).mingzi}}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
            :label='sjzt'
            prop="data_flag"
            align="center"
            :show-overflow-tooltip="true"
            :width="100"
            >
                <template #default="scope">
                    <div class="data_flag">
                        <img :src="equip_flag_object(scope.row.data_flag).src"/>
                        <span>{{equip_flag_object(scope.row.data_flag).mingzi}}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
            v-for="(item, idx) in tableHeader"
            :label="item.mingzi"
            :prop="item.bianma"
            :key="idx"
            align="center"
            :show-overflow-tooltip="true"
            :width="item.width"
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <template v-if="scope.row[item.bianma].datatype===1">
                            <div>
                                <el-image :src="canshu_flag_object(scope.row[item.bianma].data_flag).src"/>
                            </div>
                            <div>
                                {{ scope.row[item.bianma].data }}
                            </div>
                        </template>
                        <template v-if="scope.row[item.bianma].datatype===2">
                            <div>
                                <el-image :src="canshu_flag_object(scope.row[item.bianma].data_flag).src"/>
                            </div>
                            <div>
                                {{yxdata(scope.row[item.bianma])}}
                            </div>
                        </template>
                        <template v-if="scope.row[item.bianma].datatype===3">
                            <div>
                                {{ scope.row[item.bianma].data }}
                            </div>
                        </template>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="content_page">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page=pageIndex
        :page-sizes="[30, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total=total>
        </el-pagination>
    </div>
</div>
</template>

<script>
import XLSX from 'xlsx'
import { ref } from 'vue'
import { sheet2blob, openDownloadDialog, device_flag_object, equip_flag_object, canshu_flag_object, toDecimal } from '@/utils'
import moment from 'moment'
export default {
    name:'om_device_history_table',
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        },
        Lang: {
            type: Object,
            default: () => {
                return window.Lang
            }
        }
    },
    setup(props) {
        const systemLang = ref(props.Lang)
        const qxzzt = systemLang.value.jcsj.ycls.qxzzt
        const qbzt = systemLang.value.jcsj.ycls.qbzt
        const zzgz = systemLang.value.jcsj.ycls.zzgz
        const sjbj = systemLang.value.jcsj.ycls.sjbj
        const sjyj = systemLang.value.jcsj.ycls.sjyj
        return {
            qxzzt, qbzt, zzgz, sjbj, sjyj
        }
    },
    data() {
        const normalmask = 0
        const unnormalmask = 0x00ff0000
        const attenmask = 0x01000000
        const alarmmask = 0x02000000
        return {
            tableHeader: [],
            tableBody: [],
            total: 0,
            time: [],
            pageIndex: 1,
            pageSize: 30,
            data_flag_range: [
                { data_flag: normalmask, mingzi: this.qbzt },
                { data_flag: unnormalmask, mingzi: this.zzgz },
                { data_flag: alarmmask, mingzi: this.sjbj },
                { data_flag: attenmask, mingzi: this.sjyj }
            ],
            data_flag_selected: 0,
            type_tm_selected: 0,
            isLoading: false
        }
    },
    computed: {
        rqfw() {
            return this.$Lang.jcsj.ycls.rqfw
        },
        zhi() {
            return this.$Lang.jcsj.ycls.zhi
        },
        xh() {
            return this.$Lang.jcsj.ycls.xh
        },
        zdsj() {
            return this.$Lang.jcsj.ycls.zdsj
        },
        cjsj() {
            return this.$Lang.jcsj.ycls.cjsj
        },
        zzzt() {
            return this.$Lang.jcsj.ycls.zzzt
        },
        sjzt() {
            return this.$Lang.jcsj.ycls.sjzt
        },
        bgsj() {
            return this.$Lang.jcsj.ycls.bgsj
        },
        txqs() {
            return this.$Lang.jcsj.ycls.txqs
        },
        starttm() {
            return this.$Lang.utils.time.starttm
        },
        endtm() {
            return this.$Lang.utils.time.endtm
        },
        warn_empt() {
            return this.$Lang.jcsj.ycls.warn_empt
        },
        cx() {
            return this.$Lang.button.cx
        },
        dc() {
            return this.$Lang.button.dc
        },
        fiveminute() {
            return this.$Lang.utils.time.fiveminute
        },
        hourtm() {
            return this.$Lang.utils.time.hourtm
        },
        daytm() {
            return this.$Lang.utils.time.daytm
        },
        nonetm() {
            return this.$Lang.utils.time.nonetm
        },
        timeTypetm() {
            return this.$Lang.utils.time.timeTypetm
        },
        loading() {
            return this.$Lang.footer.load
        }
    },
    watch: {
        'object.obj_id': {
            immidiate: true,
            deep: true,
            async handler() {
                await this.reload()
            }
        }
    },
    methods:{
        device_flag_object(data_flag) {
            if (undefined === data_flag) {
                data_flag = 0
            }
            const device_flag = device_flag_object(data_flag)
            return device_flag
        },
        equip_flag_object(data_flag) {
            if (undefined === data_flag) {
                data_flag = 0
            }
            const equip_flag = equip_flag_object(data_flag)
            return equip_flag
        },
        canshu_flag_object(data_flag) {
            if (undefined === data_flag) {
                data_flag = 0
            }
            const status = canshu_flag_object(data_flag)
            return status
        },
        yxbianma(leixing_id, canshu_id, data_int) {
            if (leixing_id === undefined || leixing_id === null) {
                return data_int
            }
            if (canshu_id === undefined || canshu_id === null) {
                return data_int
            }
            const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
            const bianma = this.$store.getters.getYxbianma(obj_id)
            if (bianma === undefined || bianma === null) {
                return data_int
            }
            return `${data_int}(${bianma.shuoming})`
        },
        yxdata(item) {
            return this.yxbianma(item.leixing_id, item.canshu_id, toDecimal(item.data, 0))
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.handle_click_event()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.handle_click_event()
        },
        currentIndex(index) {
            return this.pageSize * (this.pageIndex - 1) + index + 1
        },
        clear() {
            this.total = 0
            this.tableHeader = []
            this.tableBody = []
        },
        async reload() {
            if (this.time === undefined || this.time === null || this.time === '' || this.time.length === 0) return this.clear()
            if (this.time[0] === undefined || this.time[0] === null) return this.clear()
            if (this.time[1] === undefined || this.time[1] === null) return this.clear()
            if (this.object === undefined || this.object === null) return this.clear()
            if (this.object.bdz_id === undefined || this.object.bdz_id === null) return this.clear()
            if (this.object.leixing_id === undefined || this.object.leixing_id === null) return this.clear()
            if (this.object.leixing_id <= 0 || this.object.leixing_id === 1 || this.object.leixing_id === 2 || this.object.leixing_id === 21 || this.object.leixing_id === 24) return this.clear()
            if (this.object.shebei_id === undefined || this.object.shebei_id === null) return this.clear()
            this.isLoading = true
            const bdz_id = this.object.bdz_id
            const leixing_id = this.object.leixing_id
            const shebei_id = this.object.shebei_id
            const pageSize = this.pageSize
            const pageIndex = this.pageIndex
            const timeFrom = this.time[0] ? moment(this.time[0]).format('YYYY-MM-DD 00:00:00') : ''
            const timeTo = this.time[1] ? moment(this.time[1]).format('YYYY-MM-DD 23:59:59') : ''
            const data_flag_selected = this.data_flag_selected
            const type_tm_selected = this.type_tm_selected
            try {
                const params = {
                    bdz_id: bdz_id,
                    leixing_id: leixing_id,
                    shebei_id: shebei_id,
                    data_flag: data_flag_selected,
                    type: type_tm_selected,
                    pageSize: pageSize,
                    pageIndex: pageIndex - 1,
                    timeFrom: timeFrom,
                    timeTo: timeTo
                }
                const resultset = await this.$ajax.p_query_lx_data(params)
                this.isLoading = false
                if (!resultset[1] || resultset[1].length === 0) return this.clear()
                //
                const recordset = resultset[1]
                const total = resultset[2][0].total
                const header = resultset[3]
                await this.$nextTick()
                this.total = parseInt(total)
                this.tableHeader = header
                this.tableBody = recordset
            } catch (error) {
                this.isLoading = false
                this.$Message({
                    type:'warning',
                    message:`p_query_lx_data异常, 错误原因：${error}`
                })
            }
        },
        async reload_all() {
            if (this.time === undefined || this.time === null || this.time === '' || this.time.length === 0) return []
            if (this.time[0] === undefined || this.time[0] === null) return []
            if (this.time[1] === undefined || this.time[1] === null) return []
            if (this.object === undefined || this.object === null) return []
            if (this.object.bdz_id === undefined || this.object.bdz_id === null) return []
            if (this.object.leixing_id === undefined || this.object.leixing_id === null) return []
            if (this.object.leixing_id <= 0 || this.object.leixing_id === 1 || this.object.leixing_id === 2 || this.object.leixing_id === 21 || this.object.leixing_id === 24) return []
            if (this.object.shebei_id === undefined || this.object.shebei_id === null) return []
            const bdz_id = this.object.bdz_id
            const leixing_id = this.object.leixing_id
            const shebei_id = this.object.shebei_id
            const pageSize = 10000000
            const pageIndex = 1
            const timeFrom = this.time[0] ? moment(this.time[0]).format('YYYY-MM-DD 00:00:00') : ''
            const timeTo = this.time[1] ? moment(this.time[1]).format('YYYY-MM-DD 23:59:59') : ''
            const data_flag_selected = this.data_flag_selected
            const type_tm_selected = this.type_tm_selected
            try {
                const params = {
                    bdz_id: bdz_id,
                    leixing_id: leixing_id,
                    shebei_id: shebei_id,
                    data_flag: data_flag_selected,
                    type: type_tm_selected,
                    pageSize: pageSize,
                    pageIndex: pageIndex - 1,
                    timeFrom: timeFrom,
                    timeTo: timeTo
                }
                const resultset = await this.$ajax.p_query_lx_data(params)
                if (!resultset[1] || resultset[1].length === 0) return []
                //
                const recordset = resultset[1]
                return recordset
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_lx_data异常, 错误原因：${error}`
                })
            }
        },
        async handle_click_event() {
            await this.reload()
        },
        async handle_click_export_event() {
            if (this.time === undefined || this.time === null || this.time === '' || this.time.length === 0) return
            if (this.time[0] === undefined || this.time[0] === null) return
            if (this.time[1] === undefined || this.time[1] === null) return
            if (this.object === undefined || this.object === null) return
            const columns = JSON.parse(JSON.stringify(this.tableHeader))
            columns.unshift(
                {
                    bianma: 'data_flag',
                    mingzi: '状态'
                }
            )
            columns.unshift(
                {
                    bianma: 'rtime',
                    mingzi: this.cjsj
                }
            )
            columns.unshift(
                {
                    bianma: 'ctime',
                    mingzi: this.zdsj
                }
            )
            const tableDatas = await this.reload_all()
            const data = JSON.parse(JSON.stringify(tableDatas))
            const records = []
            for (let row = 0; row < data.length; row++) {
                const rowData = data[row]
                const record = {}
                for (let col = 0; col < columns.length; col++) {
                    const column = columns[col]
                    const field = column.bianma
                    const header = column.mingzi
                    if (field === 'ctime') {
                        const ctime = rowData['ctime']
                        record[this.zdsj] = ctime
                        continue
                    }
                    if (field === 'rtime') {
                        const rtime = rowData['rtime']
                        record[this.cjsj] = rtime
                        continue
                    }
                    if (field === 'data_flag') {
                        const data_flag = rowData['data_flag']
                        const equip_flag_object = this.equip_flag_object(data_flag)
                        record[this.sjzt] = equip_flag_object.mingzi
                        const device_flag_object = this.device_flag_object(data_flag)
                        record[this.zzzt] = device_flag_object.mingzi
                        continue
                    }
                    const canshu_data = rowData[field]
                    if (canshu_data === undefined || canshu_data === null) {
                        continue
                    }
                    let data = canshu_data.data
                    if (data === undefined || data === null) {
                        data = ''
                    }
                    if (data < -12344) {
                        data = ''
                    }
                    record[header] = canshu_data.data
                }
                records.push(record)
            }
            if (records.length <= 0) {
                this.$Message({
                    type:'warning',
                    message: this.warn_empt
                })
                return
            }
            const sheet = XLSX.utils.json_to_sheet(records)
            if (sheet === undefined || sheet === null) {
                this.$Message({
                    type:'warning',
                    message:`json_to_sheet error`
                })
                return
            }
            const blob = sheet2blob(XLSX, sheet)
            if (blob === undefined || blob === null) {
                this.$Message({
                    type:'warning',
                    message:`sheet2blob error`
                })
                return
            }
            const timeFrom = moment(this.time[0]).format('YYYYMMDD')
            const fileName = `${this.object.mingzi.trim()}_${timeFrom}.xls`
            openDownloadDialog(blob, fileName)
        }
    },
    async mounted() {
        this.time = [
            moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().format('YYYY-MM-DD HH:mm:ss')
        ]
        this.reload(0)
    }
}
</script>

<style lang="scss" scoped>
.om_device_history_table {
    width:100%;
    height:100%;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
    .content_form {
        width: 100%;
        min-height: 40px;
        flex: 0;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .time {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 420px;
            width: 420px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .data_flag_range {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 100px;
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .canshu_range {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 150px;
            width: 150px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .query {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            margin: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        .export {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 1;
            height: 100%;
            width: 60px;
            margin: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .content_container {
        overflow: hidden;
        width:100%;
        flex:1;
        .el-table--border {
            width: 100%;
            height: 100%;
            font-size: 12px !important;
            display: flex;
            flex-direction: column;
            .el-table__header-wrapper {
                flex: 0 auto;
                width: 100%;
                box-sizing: border-box;
            }
            .el-table__body-wrapper {
                flex: 1;
                width: 100%;
                overflow: auto;
                box-sizing: border-box;
            }
            td, th {
                padding: 0px;
            }
        }
        .el-image {
            max-width: 16px;
            max-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
    .content_page {
        flex: 0;
        width:100%;
        height: 32px;
        min-height: 32px;
        box-sizing: border-box;
        width:100%;
    }

    .data_flag {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
