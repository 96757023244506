import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import om_login_view from '@/views/om_login_view'
import om_main_view from '@/views/om_main_view'
import om_home_view from '@/views/om_main_view/om_body_view/om_home_view'
import om_data_view from '@/views/om_main_view/om_body_view/om_data_view'
import sys_log from '@/views/om_main_view/om_body_view/om_log'
import om_condition_view from '@/views/om_main_view/om_body_view/om_condition_view'
import om_event_view from '@/views/om_main_view/om_body_view/om_event_view'
import Dailyeport from '@/views/om_main_view/om_body_view/Dailyeport'
import om_control_view from '@/views/om_main_view/om_body_view/om_control_view'
import om_typical_gragh_view from '@/views/om_main_view/om_body_view/om_typical_gragh_view'

const systemLang = window.Lang

const routes =
[
    {
        path: '/',
        component: om_main_view,
        meta: { title: systemLang.xtsy.name },
        name:'main',
        children:
        [
            {
                path: '/home',
                components: {
                    om_main_router_view: om_home_view
                },
                meta: { title: systemLang.xtsy.name }
            },
            {
                path: '/report',
                components: {
                    om_main_router_view: om_data_view
                },
                meta: { title: systemLang.sjgl.name }
            },
            {
                path: '/condition',
                components: {
                    om_main_router_view: om_condition_view
                },
                meta: { title: systemLang.gkgl.name }
            },
            {
                path: '/event',
                components: {
                    om_main_router_view: om_event_view
                },
                meta: { title: systemLang.qxgl.name }
            },
            {
                path: '/Dailyeport',
                components: {
                    om_main_router_view: Dailyeport
                },
                meta: { title: systemLang.Dailyeport.name }
            },
            {
                path: '/gragh',
                components: {
                    om_main_router_view: om_typical_gragh_view
                },
                meta: { title: systemLang.dxpt.name }
            },
            {
                path: '/control',
                components: {
                    om_main_router_view: om_control_view
                },
                meta: { title: '控制管理' }
            },
            {
                path: '/sys_log',
                components: {
                    om_main_router_view: sys_log
                },
                meta: { title: '控制管理' }
            },
            {
                path: '/self-inspection',
                components: {
                    om_main_router_view: om_home_view
                },
                meta: { title: systemLang.header.zjgn.name }
            }
        ]
    },
    {
        path: '/login',
        component: om_login_view,
        meta: { title: '系统登录' },
        props: { title: document.title }
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

// 使用钩子函数对路由进行权限跳转
router.beforeEach(async(to, from, next) => {
    document.title = to.meta.title || 'vue-manage-system'
    const token = sessionStorage.getItem('x-access-token')
    if (!token) {
        // 没有token
        if (to.path === '/login') {
            next()
        } else {
            next('/login')
        }
        return
    }
    if (to.path !== '/self-inspection') {
        await store.dispatch('init')
        next()
    }
})
export default router