<template>
<div class="om_history_graph_view">
    <div class="om_not_support_chart" v-if="!isShowChart">
        {{tips_nsc}}
    </div>
    <div class="om_support_chart" v-else>
        <div class="om_has_not_chart" style="font-size:26px;" v-show="isLoading">
            {{tips_nhc}}
        </div>
        <div class="om_has_chart" v-show="isLoading === false">
            <div class="sidebar" >
                <om_container :title="ptwjlb">
                    <template v-slot:title="{title}">
                        <div class="title">
                            <div class="left">{{title}}</div>
                            <div class="right">
                                <!-- <el-button style="display: hidden;" v-if="object.leixing_id===18||object.leixing_id===38" type="success" @click="handle_button_play_click">
                                    {{bf}}
                                </el-button> -->
                            </div>
                        </div>
                    </template>
                    <om_history_file_list ref="om_history_file_list" :fileList="pathFileList" :selectedFileName="selectedFileName" @current-change="handle_current_change"/>
                </om_container>
            </div>
            <div class="content">
                <om_container :title="ptzs">
                    <om_history_file_data ref="om_history_file_data" :object="object" :selectedFileName="selectedFileName"/>
                </om_container>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import om_container from '@/components/om_container'
import om_history_file_list from './om_history_file_list'
import om_history_file_data from './om_history_file_data'
export default {
    name:'om_history_graph_view',
    components: {
        om_container,
        om_history_file_list,
        om_history_file_data
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            fileList: [],
            pathFilelist: [],
            selectedFileName: null,
            isLoading: false
        }
    },
    setup() {
        const om_history_file_list = ref(null)
        const om_history_file_data = ref(null)
        return {
            om_history_file_list,
            om_history_file_data
        }
    },
    computed: {
        isShowChart() {
            const object = this.object
            if (object.leixing_id === 4) return true
            if (object.leixing_id === 10) return true
            if (object.leixing_id === 18) return true
            if (object.leixing_id === 38) return true
            return false
        },
        hasChart() {
            // this.$Message.warning('dd')
            if (this.pathFilelist === undefined || this.pathFilelist === null) return false
            if (this.pathFilelist.length <= 0) return false
            return true
        },
        tips_nsc() {
            return this.$Lang.jcsj.ptfx.tips_nsc
        },
        tips_nhc() {
            return this.$Lang.jcsj.ptfx.tips_nhc
        },
        ptwjlb() {
            return this.$Lang.jcsj.ptfx.ptwjlb
        },
        ptzs() {
            return this.$Lang.jcsj.ptfx.ptzs
        },
        bf() {
            return this.$Lang.jcsj.ptfx.bf
        }
    },
    watch: {
        'object.obj_id': {
            immidiate: true,
            deep: false,
            async handler() {
                await this.reload()
            }
        }
    },
    methods: {
        async reload() {
            try {
                const object = this.object
                const params = {
                    bdz_id: object.bdz_id,
                    leixing_id: object.leixing_id,
                    shebei_id: object.shebei_id
                }
                this.isLoading = true
                const resultset = await this.$ajax.getFileList(params)
                // window.console.log('fileparam', JSON.stringify(params))
                // window.console.log('fileset', JSON.stringify(resultset))
                if (resultset[1] === undefined || resultset[1] === null || resultset[1].length === 0) {
                    this.fileList = []
                    this.pathFilelist = []
                    this.selectedFileName = null
                    this.isLoading = false
                    if (this.$refs.om_history_file_list !== undefined && this.$refs.om_history_file_list !== null && this.$refs.om_history_file_list.init_file_list !== null) {
                        this.$refs.om_history_file_list.init_file_list(this.pathFilelist)
                    }
                    return
                }
                const fileList = resultset[1]
                const items = {}
                const def = {
                    shortname : 'def',
                    children : []
                }
                for (let i = 0; i < fileList.length; i++) {
                    const f = fileList[i]
                    const p = f.shortname.indexOf('_')
                    if (p === 8) {
                        const key = f.shortname.substring(0, 4) + '-' + f.shortname.substring(4, 6) + '-' + f.shortname.substring(6, 8)
                        const len = f.shortname.length
                        const flag = len >= 23 && f.shortname.substring(22, 23) !== '0' ? 1 : 0
                        let ex = items[key]
                        if (ex === undefined || ex === null) {
                            ex = {
                                shortname : key,
                                flag : 0,
                                children : []
                            }
                        }
                        ex.children.push({
                            shortname : f.shortname,
                            flag : flag
                        })
                        if (flag === 1) {
                            ex.flag = 1
                        }
                        items[key] = ex
                    } else {
                        def.children.push({
                            shortname : f.shortname,
                            flag : 0
                        })
                    }
                }
                const list = []
                for (const key2 in items) {
                    list.push(items[key2])
                }
                if (def.children.length > 0) {
                    list.push(def)
                }
                this.pathFilelist = list
                this.isLoading = false
                await this.$nextTick()
                // this.selectedFileName = fileList[0].shortname
                this.fileList = fileList
                this.isLoading = false
                if (this.$refs.om_history_file_list !== undefined && this.$refs.om_history_file_list !== null && this.$refs.om_history_file_list.init_file_list !== null) {
                    this.$refs.om_history_file_list.init_file_list(list)
                }
            } catch (error) {
                this.isLoading = false
                this.$Message({
                    type:'warning',
                    message:`getFileList 异常, 错误原因:${error}`
                })
            }
        },
        resize() {
            if (this.om_history_file_data) {
                this.om_history_file_data.resize()
            }
        },
        handle_current_change(e) {
            if (e !== null) {
                // this.$Message.warning(e.shortname)
                this.selectedFileName = e.shortname
            } else {
                this.selectedFileName = ''
            }
        },
        handle_button_play_click() {
        }
    },
    async mounted() {
        await this.reload()
    }
}
</script>

<style lang="scss" scoped>
.om_history_graph_view {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    box-sizing: border-box;
    background: white;
    .om_not_support_chart {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-family: monospace;
        font-weight: bold;
        color: black;
    }
    .om_support_chart {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        box-sizing: border-box;
        background: white;
        .om_has_not_chart {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            font-family: monospace;
            font-weight: bold;
            color: black;
        }
        .om_has_chart {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            .sidebar {
                flex: 0;
                width: 300px;
                min-width: 300px;
                height: 100%;
                padding: 4px;
                padding-right: 2px;
                box-sizing: border-box;
                overflow: hidden;
                .title {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-items: flex-start;
                    align-items: center;
                    overflow: hidden;
                    box-sizing: border-box;
                    .left {
                        flex: 1;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-items: flex-start;
                        align-items: center;
                        overflow: hidden;
                        box-sizing: border-box;
                    }
                    .right {
                        flex: 0;
                        min-width: 50px;
                        display: flex;
                        flex-direction: row;
                        justify-items: flex-end;
                        align-items: center;
                        overflow: hidden;
                        box-sizing: border-box;
                    }
                }
            }
            .content {
                flex: 1;
                height: 100%;
                padding: 4px;
                padding-left: 2px;
                box-sizing: border-box;
                overflow: hidden;
            }
        }
    }
}
</style>