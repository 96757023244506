<template>
<div class="om_control_view">
    om_control_view
</div>
</template>

<script>
export default {
    name:'om_control_view',
    components: {
    }
}
</script>

<style scoped lang='scss'>
.om_control_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background:chartreuse;
}
</style>
