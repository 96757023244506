<template>
<div class="om_report_content">
    <om_container>
        <template v-slot:title>
            <om_object_info_view :object="object"/>
        </template>
        <om_device_view ref="om_device_view" :object="object"/>
    </om_container>
</div>
</template>

<script>
import { ref } from 'vue'
import om_container from '@/components/om_container'
import om_object_info_view from './om_object_info_view/index.vue'
import om_device_view from './om_device_view/index.vue'
export default {
    name:'om_content_view',
    components: {
        om_container,
        om_object_info_view,
        om_device_view
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const om_device_view = ref(null)
        return {
            om_device_view
        }
    },
    watch: {
        'object.obj_id': {
            immidiate: true,
            deep: true,
            handler() {
                this.resize()
            }
        }
    },
    methods: {
        resize() {
            if (this.om_device_view) {
                this.om_device_view.resize()
            }
        }
    },
    mounted() {
        this.resize()
    }
}
</script>

<style scoped lang="scss">
.om_report_content {
    width: 100%;
    height:100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .om_report_content_object {
        flex:0;
        width: 100%;
        height: 28px;
        min-height: 28px;
    }
    .report_content_container {
        flex: 1;
        width:100%;
        overflow: hidden;
    }
}
</style>