<template>
<div class="om_device_history_chart">
    <div class="content_form">
        <div class="canshu_range">
            <el-select
            :placeholder="qxzjcl"
            v-model="canshu_id"
            >
                <template v-for="(canshu, index) in canshus">
                    <el-option
                    v-if="canshu.datatype<4"
                    :label="`${canshu.canshu_id}:${canshu.shuoming}`"
                    :value="canshu.canshu_id"
                    :key="index"
                    >
                    </el-option>
                </template>
            </el-select>
        </div>
        <div class="time">
            <span>{{rqfw}}</span>
            <el-date-picker
            type="daterange"
            :clearable="false"
            :range-separator="zhi"
            start-placeholder="starttm"
            end-placeholder="endtm"
            v-model="time">
            </el-date-picker>
        </div>
        <el-button @click="handle_click_event" type="primary">{{cx}}</el-button>
    </div>
    <div class="content_container">
        <om_echarts ref="ref_echarts" class="om_echart_view" :option="option"></om_echarts>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import om_echarts from '@/components/om_echarts'
import { ref } from 'vue'
export default {
    name:'om_history_charts',
    components: {
        om_echarts
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            option: null,
            canshu_id: null,
            type_tm_selected: 0,
            time: []
        }
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    computed: {
        canshus() {
            const canshus = []
            const obj_canshus = this.object.canshus
            for (const key in obj_canshus) {
                const canshu = obj_canshus[key]
                if (canshu.canshu_id === 1) {
                    continue
                }
                if (canshu.datatype > 3) {
                    continue
                }
                if (canshu.rtutype !== 1) {
                    continue
                }
                canshus.push(obj_canshus[key])
            }
            return canshus
        },
        rqfw() {
            return this.$Lang.jcsj.yxls.rqfw
        },
        zhi() {
            return this.$Lang.jcsj.ycls.zhi
        },
        qxzjcl() {
            return this.$Lang.jcsj.yxls.qxzjcl
        },
        starttm() {
            return this.$Lang.utils.time.starttm
        },
        endtm() {
            return this.$Lang.utils.time.endtm
        },
        fiveminute() {
            return this.$Lang.utils.time.fiveminute
        },
        hourtm() {
            return this.$Lang.utils.time.hourtm
        },
        daytm() {
            return this.$Lang.utils.time.daytm
        },
        nonetm() {
            return this.$Lang.utils.time.nonetm
        },
        timeTypetm() {
            return this.$Lang.utils.time.timeTypetm
        },
        cx() {
            return this.$Lang.button.cx
        }
    },
    watch: {
        'object.obj_id': {
            immidiate: true,
            deep: false,
            async handler() {
                if (this.canshus === undefined || this.canshus === null) {
                    this.canshu_id = null
                    this.option = null
                    return
                }
                if (this.canshus.length <= 0) {
                    this.canshu_id = null
                    this.option = null
                    return
                }
                this.canshu_id = this.canshus[0].canshu_id
                await this.reload()
            }
        },
        'type_tm_selected': {
            immidiate: true,
            deep: false,
            async handler() {
                if (this.canshus === undefined || this.canshus === null) {
                    this.canshu_id = null
                    this.option = null
                    return
                }
                if (this.canshus.length <= 0) {
                    this.canshu_id = null
                    this.option = null
                    return
                }
                this.canshu_id = this.canshus[0].canshu_id
                await this.reload()
            }
        },
        'canshus': {
            immidiate: true,
            deep: false,
            async handler() {
                if (this.canshus === undefined || this.canshus === null) {
                    this.canshu_id = null
                    this.option = null
                    return
                }
                if (this.canshus.length <= 0) {
                    this.canshu_id = null
                    this.option = null
                    return
                }
                this.canshu_id = this.canshus[0].canshu_id
                await this.reload()
            }
        }
    },
    methods:{
        async reload() {
            if (this.time === undefined || this.time === null || this.time === '' || this.time.length === 0) return
            if (this.time[0] === undefined || this.time[0] === null) return
            if (this.time[1] === undefined || this.time[1] === null) return
            if (this.object === undefined || this.object === null) return
            if (this.object.bdz_id === undefined || this.object.bdz_id === null) return
            if (this.object.leixing_id === undefined || this.object.leixing_id === null) return
            if (this.object.leixing_id <= 0 || this.object.leixing_id === 1 || this.object.leixing_id === 2 || this.object.leixing_id === 21 || this.object.leixing_id === 24) return
            if (this.object.shebei_id === undefined || this.object.shebei_id === null) return
            if (this.canshu_id === undefined || this.canshu_id === null) return
            const bdz_id = this.object.bdz_id
            const leixing_id = this.object.leixing_id
            const shebei_id = this.object.shebei_id
            const canshu_id = this.canshu_id
            const typetm = this.type_tm_selected
            const timeFrom = this.time[0] ? moment(this.time[0]).format('YYYY-MM-DD 00:00:00') : ''
            const timeTo = this.time[1] ? moment(this.time[1]).format('YYYY-MM-DD 23:59:59') : ''
            try {
                const params = {
                    bdz_id: bdz_id,
                    leixing_id: leixing_id,
                    shebei_id: shebei_id,
                    canshu_id: canshu_id,
                    type: typetm,
                    timeFrom: timeFrom,
                    timeTo: timeTo
                }
                const resultset = await this.$ajax.p_query_lx_data_yx_chart(params)
                if (!resultset[1] || resultset[1].length === 0) return
                const data = resultset[1][0].data
                const option = {
                    title: {
                        text: ''
                    },
                    grid:{
                        top:30,
                        bottom:70,
                        left:30,
                        right:30
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            params = params[0]
                            return params.value[0] + ' : ' + params.value[1]
                        },
                        axisPointer: {
                            animation: false
                        }
                    },
                    xAxis: {
                        type: 'time',
                        splitLine: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: [0, '0%'],
                        splitLine: {
                            show: true
                        }
                    },
                    dataZoom: [{
                        type: 'inside',
                        start: 0,
                        end: 100
                    }, {
                        start: 0,
                        end: 100
                    }],
                    series: [{
                        type: 'line',
                        step: 'end',
                        showSymbol: true,
                        data: data
                    }]
                }
                this.option = option
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_lx_data_chart异常, 错误原因:${error}`
                })
            }
        },
        async handle_click_event() {
            await this.reload()
        },
        resize() {
            const ref_echarts = this.ref_echarts
            if (ref_echarts === undefined || ref_echarts === null) return
            ref_echarts.resize()
        }
    },
    async mounted() {
        this.time = [
            moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().format('YYYY-MM-DD HH:mm:ss')
        ]
        this.resize()
        window.addEventListener(
            'resize',
            () => {
                this.resize()
            },
            true
        )
        if (this.canshus === undefined || this.canshus === null) {
            return
        }
        if (this.canshus.length <= 0) {
            return
        }
        this.canshu_id = this.canshus[0].canshu_id
        await this.reload()
    }
}
</script>

<style lang="scss">
.om_device_history_chart {
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    .content_form {
        width: 100%;
        min-height: 40px;
        flex: 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .canshu_range {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 180px;
            width: 180px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .time {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 450px;
            width: 450px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .query {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 100px;
            width: 100px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
    .content_container {
        flex: 1;
        width:100%;
        overflow: auto;
        .om_echart_view {
            width:100%;
            height:100%;
        }
    }
}
</style>