<template>
<use
:xlink:href="`#${uuid}`"
:x="x"
:y="y"
:width="width"
:height="height"
pointer-events="all"
>
    <symbol
    :id="uuid"
    preserveAspectRatio="none"
    :viewBox=viewBox>
        <title>{{mingzi}}</title>
        <rect x="0" y="0" width="100%" height="100%" style="fill:gray;stroke:black;stroke-width:2;opacity:0.5;rx:8;ry:8;"></rect>
        <image x="2" y="2" :width="height-4" :height="height-4" :xlink:href="device_flag_object.src"></image>
        <text :x="height" :y="height/2" fill="white" dominant-baseline="middle" text-anchor="start">{{mingzi}}</text>
    </symbol>
</use>
</template>

<script>
import { uuid } from 'vue-uuid'
import { contain_device_flag, device_flag_object, equip_flag_object } from '@/utils'
export default {
    name: 'om_device',
    props: {
        item: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    computed: {
        uuid() {
            return uuid.v1().replace(/-/g, '')
        },
        viewBox() {
            const viewBox = `0 0 ${this.width} ${this.height}`
            return viewBox
        },
        x() {
            const item = this.item
            if (item === undefined || item === null) return 0
            return item.x
        },
        y() {
            const item = this.item
            if (item === undefined || item === null) return 0
            return item.y
        },
        width() {
            const item = this.item
            if (item === undefined || item === null) return 20
            const width = item.width
            if (width < 20) return 20
            return width
        },
        height() {
            const item = this.item
            if (item === undefined || item === null) return 20
            const height = item.height
            if (height < 20) return 20
            return height
        },
        canshu() {
            const item = this.item
            if (item === undefined || item === null) return null
            const obj_id = item.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag() {
            const canshu = this.canshu
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        device_flag_object() {
            const data_flag = this.data_flag
            if (contain_device_flag(data_flag)) {
                return device_flag_object(data_flag)
            }
            return equip_flag_object(data_flag)
        },
        mingzi() {
            const item = this.item
            if (item === undefined || item === null) return ''
            const mingzi = item.mingzi
            if (mingzi === undefined || mingzi === null) return ''
            return mingzi
        }
    }
}
</script>