<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="100%"
height="100%"
:title="title">
    <div class="gragh">
        <div class="toolbar">
            <el-select
            :placeholder="tips"
            tag-type="success"
            multiple
            :multiple-limit="5"
            v-model="selected_gis_id"
            >
                <el-option
                v-for="(gis, index) in list_gis"
                :label="gis.mingzi"
                :value="gis.obj_id"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
        <div style="width:100%; height:100%; display:flex; flex-flow:column nowrap;justify-content: center;align-items: center;">
            <div class="prps2" v-if="selected_gis.length < 3">
            <!-- <template> -->
                <div class="item" >
                    <om_container :title="prps_title(0)">
                        <om_prps_view ref="prpsview0" :prps="prps(0)" :prname="prps_name(0)"/>
                    </om_container>
                </div>
                <div class="item" v-if="selected_gis.length > 1">
                    <om_container :title="prps_title(1)">
                        <om_prps_view ref="prpsview1" :prps="prps(1)" :prname="prps_name(1)"/>
                    </om_container>
                </div>
            </div>
            <div class="prps" v-if="selected_gis.length > 2">
            <!-- <template> -->
                <div class="item" >
                    <om_container :title="prps_title(0)">
                        <om_prps_view ref="prpsview0" :prps="prps(0)" :prname="prps_name(0)"/>
                    </om_container>
                </div>
                <div class="item" v-if="selected_gis.length > 1">
                    <om_container :title="prps_title(1)">
                        <om_prps_view ref="prpsview1" :prps="prps(1)" :prname="prps_name(1)"/>
                    </om_container>
                </div>
                <div class="item" v-if="selected_gis.length > 2">
                    <om_container :title="prps_title(2)">
                        <om_prps_view ref="prpsview2" :prps="prps(2)" :prname="prps_name(2)"/>
                    </om_container>
                </div>
            </div>
            <div class="prps" v-if="selected_gis.length > 2">
            <!-- </template>
            <template> -->
                <div class="item2" v-if="selected_gis.length > 3">
                    <om_container :title="prps_title(3)">
                        <om_prps_view ref="prpsview3" :prps="prps(3)" :prname="prps_name(3)"/>
                    </om_container>
                </div> 
                <div class="item2" v-if="selected_gis.length > 4">
                    <om_container :title="prps_title(4)">
                        <om_prps_view ref="prpsview4" :prps="prps(4)" :prname="prps_name(4)"/>
                    </om_container>
                </div>
                <div class="item2" v-if="selected_gis.length > 5">
                    <om_container :title="prps_title(5)">
                        <om_prps_view ref="prpsview5" :prps="prps(5)" :prname="prps_name(5)"/>
                    </om_container>
                </div>
            </div>
            <!-- </template> -->
                <!-- <div class="item2" v-for="(gis, index) in selected_gis" :key="index">
                    <om_container :title="prps_title(gis.mingzi)">
                        <om_prps_view :ref="'prpsview{{ index }}'" :prps="prps(gis)" :prname="prps_name(gis.mingzi)"/>
                    </om_container>
                </div> -->
            <!-- </template>
            <template v-else>
                <div class="item2" v-for="(gis, index) in selected_gis" :key="index">
                    <om_container :title="prps_title(gis.mingzi)">
                        <om_prps_view :prps="prps(gis)" :prname="prps_name(gis.mingzi)"/>
                    </om_container>
                </div>
            </template> -->
        </div>
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_container from '@/components/om_container'
import om_dialog from '@/components/om_dialog/index.vue'
import om_prps_view from '@/components/om_prps_view'
export default {
    components:{
        om_dialog,
        om_container,
        om_prps_view
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            selected_gis_id: [],
            selected_gis: [],
            real_gis: []
        }
    },
    setup() {
        // const om_prps_view = ref(null)
        const prpsview0 = ref(null)
        const prpsview1 = ref(null)
        const prpsview2 = ref(null)
        const prpsview3 = ref(null)
        const prpsview4 = ref(null)
        const prpsview5 = ref(null)
        return {
            // om_prps_view,
            prpsview0,prpsview1,prpsview2,prpsview3,prpsview4,prpsview5
        }
    },
    computed: {
        title() {
            const defaultName = this.sspt
            const object = this.object
            if (object === undefined || object === null) return defaultName
            const mingzi = object.mingzi
            if (mingzi === undefined || mingzi === null || mingzi === '') return defaultName
            const title = `${mingzi}${defaultName}`
            return title
        },
        list_gis() {
            const list_gis = []
            const map_object = this.$store.state.station.map_object
            if (map_object === undefined || map_object === null) return []
            for (const key in map_object) {
                const object = map_object[key]
                if (object === undefined || object === null) continue
                const leixing_id = object.leixing_id
                if (leixing_id !== this.object.leixing_id) continue
                if (object.obj_id === this.object.obj_id) continue
                list_gis.push(object)
            }
            return list_gis
        },
        tips() {
            return this.$Lang.jcsj.sssj.ssptdtddb.tips
        },
        sspt() {
            return this.$Lang.jcsj.sssj.ssptdtddb.sspt
        },
        sst() {
            return this.$Lang.jcsj.sssj.ssptdtddb.sst
        }
    },
    watch: {
        'selected_gis_id': {
            immidiate: true,
            deep: true,
            handler() {
                const object = this.object
                if (object === undefined || object === null) return []
                const curgis = []
                const selected_gis = []
                // this.$Message.warning(object.mingzi)
                selected_gis.push(object)
                curgis.push(object.bdz_id + '-' + object.leixing_id + '-' + object.shebei_id)
                const selected_gis_id = this.selected_gis_id
                if (selected_gis_id === undefined || selected_gis_id === null) {
                    this.selected_gis = selected_gis
                    this.refreshChannel(curgis)
                    return
                }
                if (!Array.isArray(selected_gis_id)) {
                    this.selected_gis = selected_gis
                    this.refreshChannel(curgis)
                    return
                }
                const map_object = this.$store.state.station.map_object
                if (map_object === undefined || map_object === null) {
                    this.selected_gis = selected_gis
                    this.refreshChannel(curgis)
                    return
                }
                for (let i = 0; i < selected_gis_id.length; i++) {
                    const gis_id = selected_gis_id[i]
                    const gis = map_object[gis_id]
                    if (gis === undefined || gis === null) continue
                    selected_gis.push(gis)
                    curgis.push(gis.bdz_id + '-' + gis.leixing_id + '-' + gis.shebei_id)
                }
                this.selected_gis = selected_gis
                this.refreshChannel(curgis)
                this.$nextTick(() => {
                    const len = selected_gis.length
                    this.prpsview0.resize()
                    if ( len > 1) this.prpsview1.resize()
                    if ( len > 2) this.prpsview2.resize()
                    if ( len > 3) this.prpsview3.resize()
                    if ( len > 4) this.prpsview4.resize()
                    if ( len > 5) this.prpsview5.resize()
                    // window.console.log('view1', this.prpsview2)
                })
                
            }
        }
    },
    methods: {
        prps_title(index) {
            const prps_title = `${this.selected_gis[index].mingzi}${this.sst}`
            return prps_title
        },
        prps_name(index) {
            const pr_name = `${this.selected_gis[index].mingzi}prps`
            return pr_name
        },
        prps(index) {
            const object = this.selected_gis[index]
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 21)
            if (canshu === undefined || canshu === null) return null
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) return null
            return rdata
        },
        showDialog() {
            this.$refs.om_dialog.showDialog()
            const list_gis = this.list_gis
            if (list_gis === undefined || list_gis === null) return
            if (list_gis.length === 0) return
            this.selected_gis_id = list_gis[0].obj_id
            this.resize()
        },
        afterClose() {
            this.refreshChannel([])
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        resize() {
            // if (!this.om_prps_view) return
            // this.om_prps_view.resize()
            const len = selected_gis.length
                    this.prpsview0.resize()
                    if ( len > 1) this.prpsview1.resize()
                    if ( len > 2) this.prpsview2.resize()
                    if ( len > 3) this.prpsview3.resize()
                    if ( len > 4) this.prpsview4.resize()
                    if ( len > 5) this.prpsview5.resize()
        },
        refreshChannel(realgislist) {
            for (let j = 0; j < this.real_gis.length; j++) {
                const rg = this.real_gis[j]
                if (realgislist.indexOf(rg) < 0) {
                    this.resetChannel(rg, 0)
                }
            }
            for (let i = 0; i < realgislist.length; i++) {
                const g = realgislist[i]
                if (this.real_gis.indexOf(g) < 0) {
                    this.resetChannel(g, 1)
                }
            }
            this.real_gis = realgislist
        },
        resetChannel(mingzi, mode) {
            if (mingzi === undefined || mingzi === null) return false
            const ids = mingzi.split('-')
            if (ids === null || ids.length < 3) return false
            const bdz = parseInt(ids[0])
            const leixing = parseInt(ids[1])
            const shebei = parseInt(ids[2])
            try {
                this.$ajax.reset_channel_mode(bdz, leixing, shebei, mode)
            } catch (error) {
                this.$Message.warning(error.toString())
            }
            return true
        }
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .gragh {
        width: 100%;
        height: 100%;
        margin: 0px;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .toolbar {
            width: 100%;
            min-height: 40px;
            flex: 0;
            overflow: hidden;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            background: lightskyblue;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .el-select {
                flex: 0;
                min-width: 600px;
                overflow: hidden;
                box-sizing: border-box;
                padding: 4px;
                margin: 0px;
            }
        }
        .prps {
            flex: 1;
            width: 100%;
            height: 50%;
            padding: 2px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .item {
                padding: 2px;
                height: 100%;
                width: 33%;
                // flex: 1;
                box-sizing: border-box;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
            .item2 {
                padding: 2px;
                height: 100%;
                width: 33%;
                min-width: 30%;
                // flex: 1;
                box-sizing: border-box;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
        }
        .prps2 {
            flex: 1;
            width: 100%;
            height: 80%;
            padding: 2px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .item {
                padding: 2px;
                height: 100%;
                width: 48%;
                // flex: 1;
                box-sizing: border-box;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }
}
</style>