<template>
<div
class="om_dialog_wrapper"
v-if="showDialog_"
@click.prevent.stop="closeDialog()"
>
    <div class="om_dialog_container" :style="`width:${width}; height:${height};`">
        <div class="om_dialog" @click.stop @keydown.esc.prevent.stop="closeDialog()">
            <div class="om_dialog__header">
                <div class="om_dialog_header_title">
                    <slot name="title" :title="title">{{title}}</slot>
                </div>
                <div class="om_dialog_header_status">
                    <close width="24" height="24" @click.prevent.stop="closeDialog()" />
                </div>
            </div>
            <div class="om_dialog__body">
                <slot/>
            </div>
            <div class="om_dialog__footer">
                <slot name="footer"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { CloseBold as close } from '@element-plus/icons-vue'
export default {
    name: 'om_dialog',
    components: {
        close
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '90%'
        },
        height: {
            type: String,
            default: '90%'
        }
    },
    data() {
        return {
            showDialog_: false
        }
    },
    methods: {
        showDialog() {
            this.showDialog_ = true
            this.$emit('show')
        },
        closeDialog() {
            this.showDialog_ = false
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
$borderColor:#2376b7;
$backgroundColor:#2376b7;
$color: #eeeeee;
.om_dialog_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    border: none;
    overflow: hidden;
    z-index: 500;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    .om_dialog_container {
        width: 90%;
        height: 90%;
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        .om_dialog {
            width: 100%;
            height: 100%;
            background: white;
            box-sizing: border-box;
            border: 4px solid $borderColor;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            display: flex;
            flex-direction: column;
            .om_dialog__header {
                width:100%;
                overflow: hidden;
                font-size: 14px;
                color: $color;
                background: $backgroundColor;
                box-sizing: border-box;
                flex: 0;
                height: 28px;
                min-height: 28px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .om_dialog_header_title {
                    height:100%;
                    box-sizing: border-box;
                    overflow: hidden;
                    padding: 0px 4px 0px 4px;
                    letter-spacing: 1px;
                    flex: 1;
                    flex-wrap: nowrap;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .om_dialog_header_status{
                    height: 100%;
                    padding: 4px 4px 4px 4px;
                    box-sizing: border-box;
                    overflow: hidden;
                    flex: 0;
                    min-width:28px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
            .om_dialog__body {
                overflow: hidden;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .om_dialog__footer {
                overflow: hidden;
                flex: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px;
                margin: 0px;
            }
        }
    }
}
</style>