<template>
<div class="om_table_overview">
    <table
    cellspacing="0"
    cellpadding="0"
    border="1px">
        <tr v-for="(leixing, index) in leixings" :key="index">
            <td
            class="leixing"
            align="center">
                <div class="leixing_title">
                    {{leixing.mingzi}}
                </div>
            </td>
            <td class="shebeis">
                <div
                width="100%"
                height="100%"
                white-space="normal">
                    <div v-for="(shebei, index) in leixing.shebeis" :key="index">
                        <a
                        v-if="shebei.leixing_id!=31 && shebei.leixing_id!=34 && shebei.leixing_id!=35"
                        class="minibutton"
                        :title="shebei.mingzi"
                        @click="handle_button_click(shebei)"
                        >
                            <div>
                                <img :src="device_flag_object(shebei.obj_id).src" alt=""/>
                                <img :src="equip_flag_object(shebei.obj_id).src" alt=""/>
                            </div>
                            <span>
                                {{shebei.mingzi}}
                            </span>
                        </a>
                        <a
                        v-else
                        class="minibutton"
                        :title="shebei.mingzi"
                        @click="handle_button_click(shebei)"
                        style="
                            width:120px;
                            height:32px;
                            margin:4px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow:hidden;"
                        >
                            <div style="height:18px;">
                                <img :src="equip_flag_object(shebei.obj_id).src" alt=""/>
                            </div>
                            <span
                            style="
                                height:14px;
                                font-size:12px;
                                line-height:12px;
                                display:block;
                                text-overflow:ellipsis;
                                white-space:nowrap;
                                overflow:hidden;
                            ">
                                {{shebei.mingzi}}
                            </span>
                        </a>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import { device_flag_object, equip_flag_object } from '@/utils'
export default {
    name:'om_table_overview',
    components: {
    },
    computed: {
        leixings() {
            const leixings = this.$store.getters.leixings
            const leixings_exclude_condition = leixings.filter(
                (leixing) => {
                    if (leixing.leixing_id === 31) {
                        return false
                    }
                    if (leixing.leixing_id === 34) {
                        return false
                    }
                    if (leixing.leixing_id === 35) {
                        return false
                    }
                    return true
                }
            )
            return leixings_exclude_condition
        }
    },
    methods: {
        object(obj_id) {
            return this.$store.getters.object(obj_id)
        },
        leixing_image_url(leixing_id) {
            const leixing_image_url = `/images/leixing/lx_${leixing_id}.gif`
            return leixing_image_url
        },
        sum_flag(obj_id) {
            const canshu = this.$store.getters.canshu(obj_id, 1)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const sum_flag = canshu.data_flag
            if (sum_flag === undefined || sum_flag === null) {
                return 0
            }
            return sum_flag
        },
        device_flag_object(obj_id) {
            const sum_flag = this.sum_flag(obj_id)
            const device_flag = device_flag_object(sum_flag)
            return device_flag
        },
        equip_flag_object(obj_id) {
            const sum_flag = this.sum_flag(obj_id)
            const equip_flag = equip_flag_object(sum_flag)
            return equip_flag
        },
        handle_button_click(shebei) {
            const leixing_id = shebei.leixing_id
            if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) {
                this.$router.push('/condition')
                return
            }
            this.$router.push('/report')
            this.$nextTick(
                () => {
                    this.$store.commit('setSelectedShebeiObject', shebei)
                }
            )
        }
    }
}
</script>

<style scoped lang='scss'>
.om_table_overview {
    width: 100%;
    height: 100%;
    margin: 0px;
    background: white;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 4px;
    box-sizing: border-box;
    border:#8CB2E2 1px solid;
    table {
        border: 1px solid rgb(0, 137, 200);
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        table-layout: fixed;
        word-wrap: break-word;
        tr {
            width: 100%;
            height: 40px;
            td.leixing {
                width:100px;
                height:100%;
                background-color: whitesmoke;
                .leixing_title {
                    padding: 4px;
                    font-weight: bold;
                    width: 91px;
                    height: 70px;
                    box-sizing: border-box;
                    overflow: hidden;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: url(/images/leixing/lx_backgound.png);
                    background-repeat: no-repeat;
                    background-size: 91px 60px;
                    font-size: 12px;
                    font-weight: bold;
                    word-wrap: break-word;
                }
            }
            td.shebeis {
                .minibutton {
                    display: block;
                    background: url(/images/button/button.gif) repeat-x 0px 50%;
                    border: #b5afaf solid 1px;
                    color: #333;
                    border-radius: 2px;
                    text-align: center;
                    cursor: default;
                    width: 120px;
                    height: 32px;
                    margin: 4px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow:hidden;
                    width:128px;
                    word-wrap: break-word;
                    display: block;
                    float: left;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    div {
                        height:18px;
                    }
                    span {
                        height:14px;
                        font-size:12px;
                        line-height:12px;
                        display:block;
                        text-overflow:ellipsis;
                        white-space:nowrap;
                        overflow:hidden;
                    }
                }
                a:hover.minibutton {
                    background: url(/images/button/hover.gif) repeat-x 0px 50%;
                    border-color:#80a4d0;
                }
                a:active.minibutton {
                    background: url(/images/button/pressed.gif) repeat-x 0px 50%;
                    border-color:#80a4f0;
                }
            }
        }
    }
}
</style>
