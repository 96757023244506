<template>
<div class="om_device_history_data">
    <el-tabs type="border-card" tab-position="bottom" v-model="activeName" @tab-click="handle_tab_click_event">
        <el-tab-pane :label="bgsj" name="om_device_history_table">
            <om_device_history_table v-if="activeName==='om_device_history_table'" class="om_device_history_table" ref="om_device_history_table" :object="object"/>
        </el-tab-pane>
        <el-tab-pane :label="txqs" name="om_device_history_chart">
            <om_device_history_chart v-if="activeName==='om_device_history_chart'" class="om_device_history_chart" ref="om_device_history_chart" :object="object"/>
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
import { ref } from 'vue'
import om_device_history_table from './om_device_history_table'
import om_device_history_chart from './om_device_history_chart'
export default {
    name:'om_device_realtime_data',
    components: {
        om_device_history_table,
        om_device_history_chart
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const activeName = 'om_device_history_table'
        const om_device_history_table = ref(null)
        const om_device_history_chart = ref(null)
        return {
            activeName,
            om_device_history_table,
            om_device_history_chart
        }
    },
    computed: {
        bgsj() {
            return this.$Lang.jcsj.ycls.bgsj
        },
        txqs() {
            return this.$Lang.jcsj.ycls.txqs
        }
    },
    methods: {
        handle_tab_click_event(tab) {
            const name = tab.paneName
            this.activeName = name
            if (name === 'om_device_history_table') {
                if (!this.om_device_history_table) return
                // this.om_device_history_table.resize()
            }
            if (name === 'om_device_history_chart') {
                if (!this.om_device_history_chart) return
                this.om_device_history_chart.resize()
            }
        },
        resize() {
            // if (om_device_history_table) {
            //     om_device_history_table.resize()
            // }
            if (this.om_device_history_chart) {
                this.om_device_history_chart.resize()
            }
        }
    }
}
</script>

<style lang="scss">
.om_device_history_data {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    .el-tabs--bottom .el-tabs__header.is-bottom {
        margin-top: 0px !important;
    }
}
</style>