<template>
<div class="om_device_history_table">
    <div class="content_form">
        <div class="time">
            <span>{{rqfw}}</span>
            <el-date-picker
            type="daterange"
            :clearable="false"
            :range-separator="zhi"
            start-placeholder="starttm"
            end-placeholder="endtm"
            v-model="time">
            </el-date-picker>
        </div>
        <div class="canshu_range">
            <el-select
            :placeholder="qxzjcl"
            v-model="canshu_id"
            >
                <el-option
                v-for="(canshu, index) in canshus"
                :label="canshu.shuoming"
                :value="canshu.canshu_id"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
        <div class="data_flag_range">
            <el-select
            :placeholder="qxzzt"
            v-model="data_flag_selected"
            >
                <el-option
                v-for="(data_flag, index) in data_flag_range"
                :label="data_flag.mingzi"
                :value="data_flag.data_flag"
                :key="index"
                >
                </el-option>
            </el-select>
        </div>
        <div class="query">
            <el-button type="primary" round @click="handle_click_event">{{cx}}</el-button>
        </div>
        <div class="export">
            <el-button type="success" round @click="handle_click_export_event">{{dc}}</el-button>
        </div>
    </div>
    <div class="content_container">
        <div style="width:100%; height: 100%; display: flex; justify-content: center; align-items:center;" v-show="isLoading">
            <span style="font-size: 26px;">{{loading}}</span>
        </div>
        <el-table
        ref="content_table"
        :data="tableBody"
        :border="true"
        :show-overflow-tooltip="true"
        stripe
        style="width:100%; height: 100%;"
        >
            <el-table-column
            type="index"
            :label='xh'
            align="center"
            :index=currentIndex
            show-overflow-tooltip
            :width="60"
            >
            </el-table-column>

            <el-table-column
            :label='jcl'
            align="center"
            :show-overflow-tooltip="true"
            :width="200"
            >
                <template #default="">
                    <div>
                        {{canshu_mingzi}}
                    </div>
                </template>
            </el-table-column>

            <el-table-column
            :label='cjsj'
            prop="rtime"
            align="center"
            :show-overflow-tooltip="true"
            :width="160"
            >
            </el-table-column>

            <el-table-column
            :label='cjz'
            prop="data"
            align="center"
            :show-overflow-tooltip="true"
            :width="160"
            >
                <template #default="data">
                    <div>
                        {{yxbianma(data.row.data)}}
                    </div>
                </template>
             </el-table-column>

             <el-table-column
            :label='sjzt'
            prop="data_flag"
            align="center"
            :show-overflow-tooltip="true"
            :width="160"
            >
                <template #default="data">
                    <div>
                        <el-image :src="canshu_flag_object(data.row.data_flag).src"/>
                    </div>
                </template>
             </el-table-column>
        </el-table>
    </div>
    <div class="content_page">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page=pageIndex
        :page-sizes="[30]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total=total>
        </el-pagination>
    </div>
</div>
</template>

<script>
import XLSX from 'xlsx'
import { ref } from 'vue'
import { sheet2blob, openDownloadDialog, device_flag_object, equip_flag_object, canshu_flag_object } from '@/utils'
import moment from 'moment'
export default {
    name:'om_device_history_table',
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        },
        Lang: {
            type: Object,
            default: () => {
                return window.Lang
            }
        }
    },
    setup(props) {
        const systemLang = ref(props.Lang)
        const qxzjcl = systemLang.value.jcsj.yxls.qxzjcl
        const qxzzt = systemLang.value.jcsj.yxls.qxzzt
        const qbzt = systemLang.value.jcsj.yxls.qbzt
        const zzgz = systemLang.value.jcsj.yxls.zzgz
        const sjbj = systemLang.value.jcsj.yxls.sjbj
        const sjyj = systemLang.value.jcsj.yxls.sjyj
        return {
            qxzjcl, qxzzt, qbzt, zzgz, sjbj, sjyj
        }
    },
    data() {
        const normalmask = 0
        const unnormalmask = 0x00ff0000
        const attenmask = 0x01000000
        const alarmmask = 0x02000000
        return {
            canshu_id: null,
            curcanshuId: null,
            tableBody: [],
            total: 0,
            time: [],
            pageIndex: 1,
            pageSize: 30,
            data_flag_range: [
                { data_flag: normalmask, mingzi: this.qbzt },
                { data_flag: unnormalmask, mingzi: this.zzgz },
                { data_flag: alarmmask, mingzi:this.sjbj },
                { data_flag: attenmask, mingzi: this.sjyj }
            ],
            data_flag_selected: 0,
            type_tm_selected: 0,
            isLoading: false
        }
    },
    computed: {
        canshus() {
            const canshus = []
            const obj_canshus = this.object.canshus
            for (const key in obj_canshus) {
                const canshu = obj_canshus[key]
                if (canshu.canshu_id === 1) {
                    continue
                }
                if (canshu.datatype > 3) {
                    continue
                }
                if (canshu.rtutype !== 1) {
                    continue
                }
                canshus.push(obj_canshus[key])
            }
            return canshus
        },
        canshu() {
            const canshus = this.canshus
            if (canshus === undefined || canshus === null) {
                return null
            }
            if (canshus.length <= 0) {
                return null
            }
            const canshu_id = this.curcanshuId
            if (canshu_id === null || canshu_id === undefined) {
                return null
            }
            for (let i = 0; i < canshus.length; i++) {
                const canshu = canshus[i]
                if (canshu.canshu_id === canshu_id) {
                    return canshu
                }
            }
            return null
        },
        canshu_mingzi() {
            const canshu = this.canshu
            if (canshu === null || canshu === undefined) return ''
            return canshu.shuoming
        },
        rqfw() {
            return this.$Lang.jcsj.yxls.rqfw
        },
        zhi() {
            return this.$Lang.jcsj.yxls.zhi
        },
        xh() {
            return this.$Lang.jcsj.yxls.xh
        },
        jcl() {
            return this.$Lang.jcsj.yxls.jcl
        },
        cjsj() {
            return this.$Lang.jcsj.yxls.cjsj
        },
        cjz() {
            return this.$Lang.jcsj.yxls.cjz
        },
        sjzt() {
            return this.$Lang.jcsj.yxls.sjzt
        },
        starttm() {
            return this.$Lang.utils.time.starttm
        },
        endtm() {
            return this.$Lang.utils.time.endtm
        },
        warn_empt() {
            return this.$Lang.jcsj.yxls.warn_empt
        },
        cx() {
            return this.$Lang.button.cx
        },
        dc() {
            return this.$Lang.button.dc
        },
        fiveminute() {
            return this.$Lang.utils.time.fiveminute
        },
        hourtm() {
            return this.$Lang.utils.time.hourtm
        },
        daytm() {
            return this.$Lang.utils.time.daytm
        },
        nonetm() {
            return this.$Lang.utils.time.nonetm
        },
        timeTypetm() {
            return this.$Lang.utils.time.timeTypetm
        },
        loading() {
            return this.$Lang.footer.load
        }
    },
    watch: {
        'object.obj_id': {
            immidiate: true,
            deep: false,
            async handler() {
                if (this.canshus === undefined || this.canshus === null) {
                    this.canshu_id = null
                    this.total = 0
                    this.tableBody = []
                    return
                }
                if (this.canshus.length <= 0) {
                    this.canshu_id = null
                    this.total = 0
                    this.tableBody = []
                    return
                }
                this.canshu_id = this.canshus[0].canshu_id
                await this.reload()
            }
        },
        'canshus': {
            immidiate: true,
            deep: false,
            async handler() {
                if (this.canshus === undefined || this.canshus === null) {
                    this.canshu_id = null
                    this.total = 0
                    this.tableBody = []
                    return
                }
                if (this.canshus.length <= 0) {
                    this.canshu_id = null
                    this.total = 0
                    this.tableBody = []
                    return
                }
                this.canshu_id = this.canshus[0].canshu_id
                await this.reload()
            }
        }
    },
    methods:{
        yxbianma(data_int) {
            const object = this.object
            if (object === undefined || object === null) {
                return data_int
            }
            const leixing_id = object.leixing_id
            if (leixing_id === undefined || leixing_id === null) {
                return data_int
            }
            const canshu_id = this.curcanshuId
            if (canshu_id === undefined || canshu_id === null) {
                return data_int
            }
            const obj_id = `${leixing_id}_${canshu_id}_${data_int}`
            const bianma = this.$store.getters.getYxbianma(obj_id)
            if (bianma === undefined || bianma === null) {
                return data_int
            }
            return `${data_int}(${bianma.shuoming})`
        },
        device_flag_object(data_flag) {
            if (undefined === data_flag) {
                data_flag = 0
            }
            const device_flag = device_flag_object(data_flag)
            return device_flag
        },
        equip_flag_object(data_flag) {
            if (undefined === data_flag) {
                data_flag = 0
            }
            const equip_flag = equip_flag_object(data_flag)
            return equip_flag
        },
        canshu_flag_object(data_flag) {
            if (undefined === data_flag) {
                data_flag = 0
            }
            const status = canshu_flag_object(data_flag)
            return status
        },
        yxdata(item) {
            const shuoming = item.shuoming
            const data = item.data
            if (shuoming === '' || shuoming === undefined || shuoming === null) {
                return `${data}`
            }
            const fmt = `${data}(${shuoming})`
            return fmt
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.handle_click_event()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.handle_click_event()
        },
        currentIndex(index) {
            return this.pageSize * (this.pageIndex - 1) + index + 1
        },
        clear() {
            this.total = 0
            this.tableBody = []
        },
        async reload() {
            if (this.time === undefined || this.time === null || this.time === '' || this.time.length === 0) return this.clear()
            if (this.time[0] === undefined || this.time[0] === null) return this.clear()
            if (this.time[1] === undefined || this.time[1] === null) return this.clear()
            if (this.object === undefined || this.object === null) return this.clear()
            if (this.object.bdz_id === undefined || this.object.bdz_id === null) return this.clear()
            if (this.object.leixing_id === undefined || this.object.leixing_id === null) return this.clear()
            if (this.object.leixing_id <= 0 || this.object.leixing_id === 1 || this.object.leixing_id === 2 || this.object.leixing_id === 21 || this.object.leixing_id === 24) return this.clear()
            if (this.object.shebei_id === undefined || this.object.shebei_id === null) return this.clear()
            if (this.canshu_id === undefined || this.canshu_id === null) return this.clear()
            this.isLoading = true
            const bdz_id = this.object.bdz_id
            const leixing_id = this.object.leixing_id
            const shebei_id = this.object.shebei_id
            const canshu_id = this.canshu_id
            const pageSize = this.pageSize
            const pageIndex = this.pageIndex
            const typetm = this.type_tm_selected
            const timeFrom = this.time[0] ? moment(this.time[0]).format('YYYY-MM-DD 00:00:00') : ''
            const timeTo = this.time[1] ? moment(this.time[1]).format('YYYY-MM-DD 23:59:59') : ''
            const data_flag_selected = this.data_flag_selected
            try {
                const params = {
                    bdz_id: bdz_id,
                    leixing_id: leixing_id,
                    shebei_id: shebei_id,
                    canshu_id: canshu_id,
                    data_flag: data_flag_selected,
                    pageSize: pageSize,
                    pageIndex: pageIndex - 1,
                    type: typetm,
                    timeFrom: timeFrom,
                    timeTo: timeTo
                }
                const resultset = await this.$ajax.p_query_lx_data_yx(params)
                this.isLoading = false
                this.curcanshuId = this.canshu_id
                if (!resultset[1] || resultset[1].length === 0) return this.clear()
                const recordset = resultset[1]
                const total = resultset[2][0].total
                await this.$nextTick()
                this.total = parseInt(total)
                this.tableBody = recordset
            } catch (error) {
                this.isLoading = false
                this.$Message({
                    type:'warning',
                    message:`p_query_lx_data_yx异常, 错误原因：${error}`
                })
            }
        },
        async reload_all() {
            if (this.time === undefined || this.time === null || this.time === '' || this.time.length === 0) return []
            if (this.time[0] === undefined || this.time[0] === null) return []
            if (this.time[1] === undefined || this.time[1] === null) return []
            if (this.object === undefined || this.object === null) return []
            if (this.object.bdz_id === undefined || this.object.bdz_id === null) return []
            if (this.object.leixing_id === undefined || this.object.leixing_id === null) return []
            if (this.object.leixing_id <= 0 || this.object.leixing_id === 1 || this.object.leixing_id === 2 || this.object.leixing_id === 21 || this.object.leixing_id === 24) return []
            if (this.object.shebei_id === undefined || this.object.shebei_id === null) return []
            if (this.canshu_id === undefined || this.canshu_id === null) return []
            const bdz_id = this.object.bdz_id
            const leixing_id = this.object.leixing_id
            const shebei_id = this.object.shebei_id
            const canshu_id = this.canshu_id
            const typetm = this.type_tm_selected
            const timeFrom = this.time[0] ? moment(this.time[0]).format('YYYY-MM-DD 00:00:00') : ''
            const timeTo = this.time[1] ? moment(this.time[1]).format('YYYY-MM-DD 23:59:59') : ''
            const data_flag_selected = this.data_flag_selected
            try {
                const params = {
                    bdz_id: bdz_id,
                    leixing_id: leixing_id,
                    shebei_id: shebei_id,
                    canshu_id: canshu_id,
                    data_flag: data_flag_selected,
                    type: typetm,
                    pageSize: 10000000,
                    pageIndex: 0,
                    timeFrom: timeFrom,
                    timeTo: timeTo
                }
                const resultset = await this.$ajax.p_query_lx_data_yx(params)
                this.curcanshuId = this.canshu_id
                if (!resultset[1] || resultset[1].length === 0) return []
                const recordset = resultset[1]
                return recordset
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_lx_data_yx异常, 错误原因：${error}`
                })
            }
        },
        async handle_click_event() {
            await this.reload()
        },
        async handle_click_export_event() {
            const columns = []
            columns.unshift(
                {
                    bianma: 'data_flag',
                    mingzi: this.sjzt
                }
            )
            columns.unshift(
                {
                    bianma: 'data',
                    mingzi: this.cjz
                }
            )
            columns.unshift(
                {
                    bianma: 'rtime',
                    mingzi: this.cjsj
                }
            )
            columns.unshift(
                {
                    bianma: 'canshu_mingzi',
                    mingzi: this.jcl
                }
            )
            const tableDatas = await this.reload_all()
            const data = JSON.parse(JSON.stringify(tableDatas))
            const records = []
            for (let row = 0; row < data.length; row++) {
                const rowData = data[row]
                const record = {}
                for (let col = 0; col < columns.length; col++) {
                    const column = columns[col]
                    const field = column.bianma
                    const header = column.mingzi
                    if (field === 'canshu_mingzi') {
                        const canshu_mingzi = this.canshu_mingzi
                        record[this.jcl] = canshu_mingzi
                        continue
                    }
                    if (field === 'rtime') {
                        const rtime = rowData['rtime']
                        record[this.cjsj] = rtime
                        continue
                    }
                    if (field === 'data_flag') {
                        const data_flag = rowData['data_flag']
                        const equip_flag_object = this.equip_flag_object(data_flag)
                        record[this.sjzt] = equip_flag_object.mingzi
                        continue
                    }
                    const canshu_data = rowData[field]
                    if (canshu_data === undefined || canshu_data === null) {
                        continue
                    }
                    let data = canshu_data
                    if (data === undefined || data === null) {
                        data = ''
                    }
                    if (data < -12344) {
                        data = ''
                    }
                    record[header] = this.yxbianma(data)
                }
                records.push(record)
            }
            if (records.length <= 0) {
                this.$Message({
                    type:'warning',
                    message: this.warn_empt
                })
                return
            }
            const sheet = XLSX.utils.json_to_sheet(records)
            if (sheet === undefined || sheet === null) {
                this.$Message({
                    type:'warning',
                    message:`json_to_sheet error`
                })
                return
            }
            const blob = sheet2blob(XLSX, sheet)
            if (blob === undefined || blob === null) {
                this.$Message({
                    type:'warning',
                    message:`sheet2blob error`
                })
                return
            }
            const timeFrom = moment(this.time[0]).format('YYYYMMDD')
            const fileName = `${this.object.mingzi.trim()}_${timeFrom}.xls`
            openDownloadDialog(blob, fileName)
        }
    },
    async mounted() {
        this.time = [
            moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().format('YYYY-MM-DD HH:mm:ss')
        ]
        if (this.canshus === undefined || this.canshus === null) {
            return
        }
        if (this.canshus.length <= 0 ) {
            return
        }
        this.canshu_id = this.canshus[0].canshu_id
        await this.reload()
    }
}
</script>

<style lang="scss">
.om_device_history_table {
    width:100%;
    height:100%;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    .content_form {
        width: 100%;
        min-height: 40px;
        flex: 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 4px;
        margin: 0px;
        background: lightskyblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .time {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 420px;
            width: 420px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .canshu_range {
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 150px;
            width: 150px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .data_flag_range {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            min-width: 150px;
            width: 150px;
            margin: 0px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .query {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 0;
            height: 100%;
            margin: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        .export {
            padding: 0px 0px 0px 8px;
            box-sizing: border-box;
            flex: 1;
            height: 100%;
            width: 60px;
            margin: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .content_container {
        overflow: hidden;
        width:100%;
        flex:1;
        .el-table--border {
            width: 100%;
            height: 100%;
            font-size: 12px !important;
            display: flex;
            flex-direction: column;
            .el-table__header-wrapper {
                flex: 0 auto;
                width: 100%;
                box-sizing: border-box;
            }
            .el-table__body-wrapper {
                flex: 1;
                width: 100%;
                overflow: auto;
                box-sizing: border-box;
            }
            td, th {
                padding: 0px;
            }
        }

        .el-image {
            max-width: 16px;
            max-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
    .content_page {
        flex: 0;
        width:100%;
        height: 32px;
        min-height: 32px;
        box-sizing: border-box;
        width:100%;
    }

    .data_flag {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
