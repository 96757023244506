
<template>
<div class="om_condition_view">
    <div class="om_condition_device_title">
        <div class="om_cac_panel">
            <om_container :title="CACdy">
                <el-table
                :data="cacs"
                :current-row-key="current_row_key"
                row-key="obj_id"
                :showHeader="false"
                @current-change="handle_current_change"
                highlight-current-row
                border
                stripe
                >
                    <el-table-column
                    :label="rjmc"
                    align="center"
                    prop="mingzi"
                    width="auto"
                    show-overflow-tooltip
                    >
                        <template #default="{ row }">
                            <div class="custom_tree_node" >
                                <img class="device" :src="device_flag_object(row.obj_id).src"/>
                                <img class="equip" :src="equip_flag_object(row.obj_id).src"/>
                                <span class="mingzi">{{row.mingzi}}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </om_container>
        </div>
        <div class="om_module_panel">
            <om_container :title="rjmk">
                <el-table
                :data="modules"
                :current-row-key="current_row_key"
                row-key="obj_id"
                @current-change="handle_current_change"
                :showHeader="false"
                highlight-current-row
                border
                stripe
                >
                    <el-table-column
                    :label="rjmc"
                    align="center"
                    prop="mingzi"
                    width="auto"
                    show-overflow-tooltip
                    >
                        <template #default="{ row }">
                            <div class="custom_tree_node" >
                                <img class="device" :src="device_flag_object(row.obj_id).src"/>
                                <img class="equip" :src="equip_flag_object(row.obj_id).src"/>
                                <span class="mingzi">{{row.mingzi}}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </om_container>
        </div>
        <div class="om_communit_panel">
            <om_container :title="txdy">
                <el-table
                :data="communits"
                :current-row-key="current_row_key"
                row-key="obj_id"
                @current-change="handle_current_change"
                :showHeader="false"
                highlight-current-row
                border
                stripe
                >
                    <el-table-column
                    :label="rjmc"
                    align="center"
                    prop="mingzi"
                    width="auto"
                    show-overflow-tooltip
                    >
                        <template #default="{ row }">
                            <div class="custom_tree_node" >
                                <img class="device" :src="device_flag_object(row.obj_id).src"/>
                                <img class="equip" :src="equip_flag_object(row.obj_id).src"/>
                                <span class="mingzi">{{row.mingzi}}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </om_container>
        </div>
    </div>
    <div class="om_condition_device_detail">
        <om_report_content ref="om_report_content" :object="object"/>
    </div>
</div>
</template>

<script>
import { device_flag_object, equip_flag_object } from '@/utils'
import om_container from '@/components/om_container'
import om_report_content from '../om_data_view/om_report_content'
export default {
    name: 'om_condition_view',
    components: {
        om_container,
        om_report_content
    },
    data() {
        return {
            current_row_key: null,
            object: null
        }
    },
    computed: {
        cac() {
            const cac = this.$store.getters.cac
            if (cac === undefined || cac === null) return null
            return cac
        },
        cacs() {
            const cac = this.cac
            if (cac === undefined || cac === null) return []
            return [cac]
        },
        modules() {
            const map_object = this.$store.state.station.map_object
            if (map_object === undefined || map_object === null) return []
            const modules = []
            for (const key in map_object) {
                const object = map_object[key]
                if (object === undefined || object === null) continue
                const leixing_id = object.leixing_id
                if (leixing_id !== 35) continue
                const module = object
                modules.push(module)
            }
            return modules
        },
        communits() {
            const map_object = this.$store.state.station.map_object
            if (map_object === undefined || map_object === null) return []
            const communits = []
            for (const key in map_object) {
                const object = map_object[key]
                if (object === undefined || object === null) continue
                const leixing_id = object.leixing_id
                if (leixing_id !== 31) continue
                const communit = object
                communits.push(communit)
            }
            return communits
        },
        CACdy() {
            return this.$Lang.gkgl.CACdy
        },
        rjmc() {
            return this.$Lang.gkgl.rjmc
        },
        rjmk() {
            return this.$Lang.gkgl.rjmk
        },
        txdy() {
            return this.$Lang.gkgl.txdy
        }
    },
    methods: {
        sum_flag(obj_id) {
            const canshu = this.$store.getters.canshu(obj_id, 1)
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const sum_flag = canshu.data_flag
            if (sum_flag === undefined || sum_flag === null) {
                return 0
            }
            return sum_flag
        },
        device_flag_object(obj_id) {
            const sum_flag = this.sum_flag(obj_id)
            const device_flag = device_flag_object(sum_flag)
            return device_flag
        },
        equip_flag_object(obj_id) {
            const sum_flag = this.sum_flag(obj_id)
            const equip_flag = equip_flag_object(sum_flag)
            return equip_flag
        },
        handle_current_change(e) {
            const object = e
            if (e === undefined || e === null) return
            this.current_row_key = object.obj_id
            this.object = object
        }
    },
    created() {
        const cac = this.cac
        if (cac === undefined || cac === null) return
        this.current_row_key = cac.obj_id
        this.object = cac
    },
    mounted() {
        const cac = this.cac
        if (cac === undefined || cac === null) return
        this.current_row_key = cac.obj_id
        this.object = cac
    }
}
</script>

<style lang="scss">
.om_condition_view {
    width:100%;
    height:100%;
    display: flex;
    background-color: white !important;
    padding: 4px;
    box-sizing: border-box;
    .om_condition_device_title {
        flex: 0;
        width: 250px;
        min-width: 250px;
        height: 100%;
        overflow: hidden;
        background: white;
        box-sizing: border-box;
        padding-right: 2px;
        display: flex;
        flex-direction: column;
        .om_cac_panel {
            flex: 0;
            width: 100%;
            min-height: 100px;
            overflow: hidden;
            box-sizing: border-box;
            padding-bottom: 2px;
            .custom_tree_node {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                overflow: hidden;
                box-sizing: border-box;
                .mingzi {
                    flex: 0;
                }
                .device {
                    flex: 0;
                }
                .equip {
                    flex: 0;
                }
            }
        }
        .om_module_panel {
            flex: 0;
            width: 100%;
            min-height: 400px;
            overflow: hidden;
            box-sizing: border-box;
            padding-top: 2px;
            padding-bottom: 2px;
            .custom_tree_node {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                overflow: hidden;
                box-sizing: border-box;
                .mingzi {
                    flex: 0;
                }
                .device {
                    flex: 0;
                }
                .equip {
                    flex: 0;
                }
            }
        }
        .om_communit_panel {
            flex: 1;
            width: 100%;
            min-height: 60px;
            overflow: hidden;
            box-sizing: border-box;
            padding-top: 2px;
            overflow: hidden;
            box-sizing: border-box;
            .custom_tree_node {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                overflow: hidden;
                box-sizing: border-box;
                .mingzi {
                    flex: 0;
                }
                .device {
                    flex: 0;
                }
                .equip {
                    flex: 0;
                }
            }
        }
    }
    .om_condition_device_detail {
        flex: 1;
        min-width: 0px;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding-left: 2px;
    }
    .el-table {
        width: 100%;
        height: 100%;
        font-size: 12px !important;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;
        .el-table__header-wrapper {
            overflow: hidden !important;
            flex: 0 auto;
            width: 100%;
            min-height: 0px;
        }
        .el-table__body-wrapper {
            flex: 1;
            width: 100%;
            overflow-y: auto !important;
            display: block;
        }
        td, th {
            padding: 2px;
        }
        .el-image {
            width: 100%;
            height: 100%;
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            img {
                flex: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
</style>