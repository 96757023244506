import axios from 'axios'
import { loginOut } from '@/utils'
const service = axios.create({
    timeout: 600000,
    headers: {
        'Content-type': 'application/json;charset=UTF-8'
    }
})
service.interceptors.request.use(
    (config) => {
        config.headers['x-access-token'] = sessionStorage.getItem('x-access-token') || ''
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            return response.data
        }
        loginOut()
        Promise.reject(response)
    },
    (error) => {
        Promise.reject(error)
    }
)
export default service
