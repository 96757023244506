export default function (options = {}) {
    const { seriesData, ...rest } = options;
    const defaultOptions = {
        legend: {
            top: 10,
        },
        xAxis: {
            type: "time",
        },
        yAxis: {},
        series:
            seriesData &&
            seriesData.map(item => ({
                ...item,
                type: "scatter",
            })),
    };
    return Object.keys(rest).reduce((acc, key) => {
        if (acc[key]) {
            acc[key] = Object.assign(acc[key], rest[key]);
        } else {
            acc[key] = rest[key];
        }
        return acc;
    }, defaultOptions);
}
