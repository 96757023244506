<template>
<om_dialog
ref="ref_dialog"
class="om_dialog"
width="100%"
height="100%"
:title="qzzl"
>
    <om_overview/>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
import om_overview from './index.vue'
export default {
    name: 'om_overview_maximize',
    components: {
        om_dialog,
        om_overview
    },
    setup() {
        const ref_dialog = ref(null)
        return {
            ref_dialog
        }
    },
    computed: {
        qzzl() {
            return this.$Lang.xtsy.sbzl.qzzl
        }
    },
    methods: {
        async showDialog() {
            const ref_dialog = this.ref_dialog
            if (ref_dialog === undefined || ref_dialog === null) return
            ref_dialog.showDialog()
        },
        async closeDialog() {
            const ref_dialog = this.ref_dialog
            if (ref_dialog === undefined || ref_dialog === null) return
            ref_dialog.closeDialog()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>