<template>
<div class="om_home_view">
    <!-- <div class="left">
        <div class="top">
            <om_container title="设备类型状态分布">
                <om_leixing_equip_health_statics ref="om_leixing_equip_health_statics"/>
            </om_container>
        </div>
        <div class="bottom">
            <om_container title="装置类型状态分布">
                <om_leixing_device_health_statics ref="om_leixing_device_health_statics" />
            </om_container>
        </div>
    </div> -->
    <div class="center">
        <div class="index">
            <om_container :title="sbzl">
                <template v-slot:title="{title}">
                    <div
                    class="title"
                    style="
                        width: 100%;
                        height: 100%;
                        display:flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        box-sizing: border-box;
                    ">
                        <div
                        style="
                            height: 100%;
                            flex: 1;
                            display:flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                            box-sizing: border-box;
                        ">
                            {{bdz_mingzi}}{{title}}
                        </div>
                        <div
                        style="
                            height: 100%;
                            flex: 0;
                            min-width: 32px;
                            display:flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                            box-sizing: border-box;
                        ">
                            <el-tooltip effect="dark" :content="zdh" placement="top">
                                <fullscreen style="width: 24px; height: 24px; border:none; outline: none;" @click.prevent.stop="handle_overview_maximize_click"/>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <div class="overview">
                    <om_overview ref="om_overview"/>
                </div>
            </om_container>
        </div>
        <div v-show="isShowHomeBottom" class="event">
            <div class="shebei_event">
                <om_container :title="sjbjxq">
                    <om_shebei_event_table ref="om_shebei_event_table"/>
                </om_container>
            </div>
            <div class="device_event">
                <om_container :title="zzgzxq">
                    <om_device_event_table ref="om_device_event_table" />
                </om_container>
            </div>
        </div>
    </div>
    <div v-show="isShowHomeRight" class="right">
        <div class="time">
            <om_container :title="yxsj">
                <om_datetime_statics ref="om_datetime_statics"/>
            </om_container>
        </div>
        <div class="item1">
            <om_container :title="qzsjzttj">
                <om_bdz_equip_health_statics ref="om_bdz_equip_health_statics" />
            </om_container>
        </div>
        <div class="item2">
            <om_container :title="qzzzzttj">
                <om_bdz_device_health_statics ref="om_bdz_device_health_statics" />
            </om_container>
        </div>
        <div class="item3">
            <om_container :title="xtyxgk">
                <om_bdz_condition_health_statics ref="om_bdz_condition_health_statics" />
            </om_container>
        </div>
    </div>
    <om_overview_maximize ref="ref_overview_maximize"/>
</div>
</template>

<script>
import { FullScreen as fullscreen } from '@element-plus/icons-vue'
import om_container from '@/components/om_container'
// //
// import om_leixing_equip_health_statics from './om_leixing_equip_health_statics'
// import om_leixing_device_health_statics from './om_leixing_device_health_statics'
//
import om_overview from './om_overview'
import om_overview_maximize from './om_overview/om_overview_maximize.vue'
import om_shebei_event_table from './om_shebei_event_table'
import om_device_event_table from './om_device_event_table'
//
import om_datetime_statics from './om_datetime_statics'
import om_bdz_equip_health_statics from './om_bdz_equip_health_statics'
import om_bdz_device_health_statics from './om_bdz_device_health_statics'
import om_bdz_condition_health_statics from './om_bdz_condition_health_statics'
import { ref } from 'vue'
export default {
    name:'om_home_view',
    components: {
        fullscreen,
        om_container,
        // om_leixing_equip_health_statics,
        // om_leixing_device_health_statics,
        om_overview,
        om_overview_maximize,
        om_shebei_event_table,
        om_device_event_table,
        om_datetime_statics,
        om_bdz_equip_health_statics,
        om_bdz_device_health_statics,
        om_bdz_condition_health_statics
    },
    setup() {
        const om_leixing_equip_health_statics = ref(null)
        const om_leixing_device_health_statics = ref(null)
        const om_overview = ref(null)
        const om_shebei_event_table = ref(null)
        const om_device_event_table = ref(null)
        const om_datetime_statics = ref(null)
        const om_bdz_equip_health_statics = ref(null)
        const om_bdz_device_health_statics = ref(null)
        const om_bdz_condition_health_statics = ref(null)
        const ref_overview_maximize = ref(null)
        return {
            om_leixing_equip_health_statics,
            om_leixing_device_health_statics,
            om_overview,
            om_shebei_event_table,
            om_device_event_table,
            om_datetime_statics,
            om_bdz_equip_health_statics,
            om_bdz_device_health_statics,
            om_bdz_condition_health_statics,
            ref_overview_maximize
        }
    },
    computed: {
        isShowHomeBottom() {
            const settings = this.$store.state.station.settings
            if (settings === undefined || settings === null) return true
            return settings[0].useflag > 0
        },
        isShowHomeRight() {
            const settings = this.$store.state.station.settings
            if (settings === undefined || settings === null) return true
            return settings[1].useflag > 0
        },
        bdz_mingzi() {
            const biandianzhan = this.$store.getters.biandianzhan
            if (biandianzhan === undefined || biandianzhan === null) return ''
            const bdz_mingzi = biandianzhan.mingzi
            if (bdz_mingzi === undefined || bdz_mingzi === null) return ''
            return bdz_mingzi
        },
        shebei_total() {
            const shebei_total = this.$store.getters.shebei_total
            return shebei_total
        },
        shebei_alarm_event_total() {
            const shebei_alarm_event_total = this.$store.getters.shebei_alarm_event_total
            return shebei_alarm_event_total
        },
        shebei_atten_event_total() {
            const shebei_atten_event_total = this.$store.getters.shebei_atten_event_total
            return shebei_atten_event_total
        },
        device_fault_event_total() {
            const device_fault_event_total = this.$store.getters.device_fault_event_total
            return device_fault_event_total
        },
        sbzl() {
            return this.$Lang.xtsy.sbzl.name
        },
        zdh() {
            return this.$Lang.xtsy.sbzl.zdh
        },
        sjbjxq() {
            return this.$Lang.xtsy.sjbjxq.name
        },
        zzgzxq() {
            return this.$Lang.xtsy.zzgzxq.name
        },
        yxsj() {
            return this.$Lang.xtsy.yxsj.name
        },
        qzsjzttj() {
            return this.$Lang.xtsy.qzsjzttj.name
        },
        qzzzzttj() {
            return this.$Lang.xtsy.qzzzzttj.name
        },
        xtyxgk() {
            return this.$Lang.xtsy.xtyxgk.name
        }
    },
    methods: {
        handle_overview_maximize_click() {
            const ref_overview_maximize = this.ref_overview_maximize
            if (ref_overview_maximize === undefined || ref_overview_maximize === null) return
            ref_overview_maximize.showDialog()
        },
        resize() {
            if (this.om_leixing_equip_health_statics) {
                this.om_leixing_equip_health_statics.resize()
            }
            if (this.om_leixing_device_health_statics) {
                this.om_leixing_device_health_statics.resize()
            }
            // if (this.om_overview) {
            //     this.om_overview.resize()
            // }
            // if (this.om_shebei_event_table) {
            //     this.om_shebei_event_table.resize()
            // }
            if (this.om_datetime_statics) {
                this.om_datetime_statics.resize()
            }
            if (this.om_leixing_equip_health_statics) {
                this.om_leixing_equip_health_statics.resize()
            }
            // if (this.om_bdz_equip_health_statics) {
            //     this.om_bdz_equip_health_statics.resize()
            // }
            // if (this.om_bdz_device_health_statics) {
            //     this.om_bdz_device_health_statics.resize()
            // }
            if (this.om_bdz_condition_health_statics) {
                this.om_bdz_condition_health_statics.resize()
            }
        }
    },
    activated() {
        this.resize()
    },
    mounted() {
        this.resize()
    }
}
</script>

<style scoped lang='scss'>
.om_home_view {
    width: 100%;
    height: 100%;
    margin: 0px;
    background: white;
    overflow: hidden;
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    // .left {
    //     display: hidden;
    //     flex: 0;
    //     height: 100%;
    //     width: 300px;
    //     min-width: 300px;
    //     margin: 0px;
    //     padding: 0px;
    //     overflow: hidden;
    //     box-sizing: border-box;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     .time {
    //         flex: 0;
    //         width: 100%;
    //         min-height: 200px;
    //         margin: 0px;
    //         padding: 4px;
    //         padding-right: 2px;
    //         padding-bottom: 2px;
    //         overflow: hidden;
    //         box-sizing: border-box;
    //         border: 0;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //     }
    //     .top {
    //         flex: 1;
    //         width: 100%;
    //         margin: 0px;
    //         padding: 4px;
    //         padding-top: 2px;
    //         padding-right: 2px;
    //         padding-bottom: 2px;
    //         overflow: hidden;
    //         box-sizing: border-box;
    //         border: 0;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //     }
    //     .bottom {
    //         flex: 1;
    //         width: 100%;
    //         margin: 0px;
    //         padding: 4px;
    //         padding-right: 2px;
    //         padding-top: 2px;
    //         overflow: hidden;
    //         box-sizing: border-box;
    //         border: 0;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //     }
    // }
    .center {
        flex: 1;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .index {
            flex: 1;
            width: 100%;
            margin: 0px;
            padding: 0px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 4px;
            // padding-left: 2px;
            padding-right: 2px;
            padding-bottom: 2px;
            .overview {
                height: 100%;
                width: 100%;
                margin: 0px;
                padding: 0px;
                overflow: hidden;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        .event {
            flex: 0;
            width: 100%;
            min-height: 280px;
            margin: 0px;
            padding: 0px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            .shebei_event {
                flex: 1;
                height: 100%;
                margin: 0px;
                padding: 0px;
                overflow: hidden;
                box-sizing: border-box;
                padding: 4px;
                padding-top: 2px;
                padding-left: 4px;
                padding-right: 2px;
            }
            .device_event {
                flex: 1;
                height: 100%;
                margin: 0px;
                padding: 0px;
                overflow: hidden;
                box-sizing: border-box;
                padding: 4px;
                padding-top: 2px;
                padding-left: 2px;
                padding-right: 2px;
            }
        }
    }
    .right {
        flex: 0;
        height: 100%;
        width: 300px;
        min-width: 300px;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .time {
            flex: 0;
            width: 100%;
            min-height: 200px;
            margin: 0px;
            padding: 4px;
            padding-left: 2px;
            padding-bottom: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .item1 {
            flex: 1;
            width: 100%;
            height: 280px;
            margin: 0px;
            padding: 4px;
            padding-left: 2px;
            padding-top: 2px;
            padding-bottom: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .item2 {
            flex: 1;
            width: 100%;
            margin: 0px;
            padding: 4px;
            padding-left: 2px;
            padding-top: 2px;
            padding-bottom: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .item3 {
            flex: 0;
            width: 100%;
            min-height: 280px;
            margin: 0px;
            padding: 4px;
            padding-top: 2px;
            padding-left: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .time {
            flex: 0;
            width: 100%;
            min-height: 250px;
            margin: 0px;
            padding: 4px;
            padding-left: 2px;
            padding-bottom: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .item1 {
            flex: 1;
            width: 100%;
            height: 200px;
            margin: 0px;
            padding: 4px;
            padding-left: 2px;
            padding-top: 2px;
            padding-bottom: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .item2 {
            flex: 1;
            width: 100%;
            margin: 0px;
            padding: 4px;
            padding-left: 2px;
            padding-top: 2px;
            padding-bottom: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .item3 {
            flex: 0;
            width: 100%;
            min-height: 300px;
            margin: 0px;
            padding: 4px;
            padding-top: 2px;
            padding-left: 2px;
            overflow: hidden;
            box-sizing: border-box;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
}
</style>
