<template>
<div class="om_echarts_prps">
    <echartsWrap ref="ref_echarts" class="om_echart_view" :options="option" :xt="xw" :yt="zqdw" :zt="fu" type="bar" />
</div>
</template>

<script>
import { ref } from 'vue'
// import om_echarts from '@/components/om_echarts'
import echartsWrap from '@/components/echartsWrap'
export default {
    name:'om_echarts_prps',
    components: {
        echartsWrap
    },
    props: {
        prps: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            option: null
        }
    },
    setup() {
        const ref_echarts = ref(null)
        return {
            ref_echarts
        }
    },
    computed: {
        xw() {
            return this.$Lang.jcsj.sssj.ssptsj.xw
        },
        zq() {
            return this.$Lang.jcsj.sssj.ssptsj.zq
        },
        fu() {
            return this.$Lang.jcsj.sssj.ssptsj.fu
        },
        zqdw() {
            return this.$Lang.jcsj.sssj.ssptsj.zqdw
        },
        // option() {
        //     let prps = this.prps
        //     if (prps === null || prps === undefined) {
        //         prps = []
        //     }
        //     if (!Array.isArray(prps)) {
        //         prps = []
        //     }
        //     const sin = []
        //     for (let x = 0; x < 360; x += 1.8) {
        //         const value = -40 + 40 * Math.sin(x / 180 * Math.PI)
        //         sin.push([x, 50, value])
        //     }
        //     const prpd = []
        //     const noise = []
        //     for (let i = 0; i < prps.length; i++) {
        //         const value = prps[i]
        //         noise.push([value[0], value[1], -80 - value[2]])
        //         prpd.push([value[0], 50, value[2]])
        //     }
        //     const option = {
        //         backgroundColor:'#b0d5df',
        //         title: {
        //             show: true,
        //             left: 'left',
        //             text: ''
        //         },
        //         legend: {
        //             show: true,
        //             data: [
        //                 {
        //                     name: 'PRPS',
        //                     icon: 'pin'
        //                 },
        //                 {
        //                     name: 'PRPD',
        //                     icon: 'pin'
        //                 }
        //             ],
        //             selected: {
                //         'PRPS': true,
                //         'PRPD': false
                //     }
                // },
                // visualMap: [
                //     {
                //         seriesIndex: 1,
                //         show: false,
                //         right: 0,
                //         dimension: 2,
                //         min: -80,
                //         max: 0,
                //         calculable: false,
                //         align: 'top',
                //         top: 'middle',
                //         formatter: (param) => {
                //             return `${-param - 80}dBm`
                //         },
                //         type: 'piecewise',
                //         itemGap: 4,
                //         pieces: [
                //             { min: -80, max: -70, label: '[-80,-70]', color: 'red' },
                //             { min: -70, max: -60, label: '[-70,-60]', color: 'red' },
                //             { min: -60, max: -50, label: '[-60,-50]', color: 'red' },
                //             { min: -50, max: -40, label: '[-50,-40]', color: 'red' },
                //             { min: -40, max: -30, label: '[-40,-30]', color: 'yellow' },
                //             { min: -30, max: -20, label: '[-30,-20]', color: 'yellow' },
                //             { min: -20, max: -11, label: '[-20,-10]', color: 'yellow' },
                //             { min: -11, max: 0, label: '[-10, 0]', color: 'blue' }
                //         ]
                //     },
                //     {
                //         seriesIndex: 2,
                //         show: true,
                //         right: 0,
                //         dimension: 2,
                //         min: -80,
                //         max: 0,
                //         calculable: false,
                //         align: 'top',
                //         top: 'middle',
                //         formatter: (param) => {
                //             return `${param}dBm`
                //         },
                //         type: 'piecewise',
                //         itemGap: 4,
                //         pieces: [
                //             { min: -80, max: -69, label: '[-80,-70]', color: 'blue' },
                //             { min: -69, max: -60, label: '[-70,-60]', color: 'yellow' },
                //             { min: -60, max: -50, label: '[-60,-50]', color: 'yellow' },
                //             { min: -50, max: -40, label: '[-50,-40]', color: 'yellow' },
                //             { min: -40, max: -30, label: '[-40,-30]', color: 'red' },
                //             { min: -30, max: -20, label: '[-30,-20]', color: 'red' },
                //             { min: -20, max: -10, label: '[-20,-10]', color: 'red' },
                //             { min: -10, max: 0, label: '[-10, 0]', color: 'red' }
                //         ]
                //     },
                //     {
                //         seriesIndex: 3,
                //         show: false,
                //         right: 0,
                //         dimension: 2,
                //         min: -80,
                //         max: 0,
                //         calculable: false,
                //         align: 'top',
                //         top: 'middle',
                //         formatter: (param) => {
                //             return `${param}dBm`
                //         },
                //         type: 'piecewise',
                //         itemGap: 4,
                //         pieces: [
                //             { min: -80, max: -70, label: '[-80,-70]', color: 'red' },
                //             { min: -70, max: -60, label: '[-70,-60]', color: 'red' },
                //             { min: -60, max: -50, label: '[-60,-50]', color: 'red' },
                //             { min: -50, max: -40, label: '[-50,-40]', color: 'red' },
                //             { min: -40, max: -30, label: '[-40,-30]', color: 'red' },
                //             { min: -30, max: -20, label: '[-30,-20]', color: 'red' },
                //             { min: -20, max: -10, label: '[-20,-10]', color: 'red' },
                //             { min: -10, max: 0, label: '[-10, 0]', color: 'red' }
                //         ]
                //     }
                // ],
                // toolbox: {
                //     show: true,
                //     feature: {
                //         restore: {
                //             show: false
                //         },
                //         saveAsImage: {
                //             show: false
                //         }
                //     }
                // },
                // tooltip: {
                //     formatter:(param) => {
                //         return `${this.xw}: ${param.value[0]}°<br>${this.zq}: ${param.value[1]}<br>${this.fu}:${-param.value[2] - 80}dBm`
                //     }
                // },
                // grid3D: {
                //     show: true,
                //     boxWidth: 150,
                //     boxDepth: 100,
                //     boxHeight: 100,
                //     viewControl: {
                //         autoRotate: false,
                //         projection: 'perspective',
                //         distance: 230
                //     },
                //     light: {
                //         main: {
                //             intensity: 1.2,
                //             shadow: true
                //         },
                //         ambient: {
                //             intensity: 0.3
                //         }
                //     }
                // },
                // xAxis3D: {
                //     type: 'value',
                //     name: this.xw,
                //     min:0,
                //     interval:45,
                //     max:360,
                //     axisLabel: { formatter: '{value}°' },
                //     splitArea: {
                //         show: true
                //     }
                // },
                // yAxis3D: {
                //     type: 'value',
                //     name: this.zqdw,
                //     min:0,
                //     max:50,
                //     interval: 5,
                //     axisLabel: { formatter: '{value}' },
                //     splitArea: {
                //         show: true
                //     }
                // },
                // zAxis3D: {
                //     type: 'value',
                //     name: this.fu,
                //     interval: 10,
                //     min: -80,
                //     max: 0,
                //     axisLabel: {
                //         margin: 15,
                //         formatter: '{value}dBm'
                //     }
                // },
                // series: [
                //     {
                //         stack: 'total',
                //         name:'noise',
                //         type: 'bar3D',
                //         barSize: [0.25, 0.5],
                //         shading: 'lambert',
                //         itemStyle: {
                //             opacity: 0
                //         },
                //         data: prps
                //     },
                //     {
                //         stack: 'total',
                //         name:'PRPS',
                //         type: 'bar3D',
                //         barSize: [0.25, 0.5],
                //         shading: 'lambert',
                //         data: noise
                //     },
                //     {
                //         name:'PRPD',
                //         type: 'scatter3D',
                //         symbolSize: 4,
                //         shading: 'lambert',
                //         data: prpd
                //     },
                //     {
                //         name:'sin',
                //         type: 'line3D',
                //         symbolSize: 4,
                //         shading: 'lambert',
                //         data: sin
                //     }
                // ]
        //     }
        //     return option
        // }
    },
    methods: {
        resizeChart() {
            const ref_echarts = this.ref_echarts
            if (ref_echarts === undefined || ref_echarts === null) return
            ref_echarts.resizeChart()
        },
        saveAsImage(imagename) {
            const ref_echarts = this.ref_echarts
            if (ref_echarts === undefined || ref_echarts === null) return
            ref_echarts.saveAsImage(imagename)
        },
        setChart() {
            let prps = this.prps
            if (prps === null || prps === undefined) {
                prps = []
            }
            if (!Array.isArray(prps)) {
                prps = []
            }
            
            const prpd = []
            const noise = []
            for (let i = 0; i < prps.length; i++) {
                const value = prps[i]
                noise.push([value[0], value[1], 80 + value[2]])
                prpd.push([value[0], 50, 80+value[2]])
            }
            this.option.series[0].data = noise
            this.option.series[1].data = prpd
        },
        initChart() {
            // const sin = []
            // for (let x = 0; x < 360; x += 1.8) {
            //     const value = 80-40 + 40 * Math.sin(x / 180 * Math.PI)
            //     sin.push([x, 50, value])
            // }
            const optionex = {
                        title: {
                            show: false,
                            text: "",
                            textStyle: {
                                color: "#eee",
                                fontWeight:400,
                                lineHeight:30,
                                fontSize:14
                            },
                            right:20,
                            top:20
                        },
                        legend: {
                            show: true,
                            data: [
                                {
                                    name: 'PRPS',
                                    icon: 'pin'
                                },
                                {
                                    name: 'PRPD',
                                    icon: 'pin'
                                }
                            ],
                            selected: {
                                'PRPS': true,
                                'PRPD': false
                            }
                        },
                        visualMap: [
                            {
                                seriesIndex: 0,
                                show: false,
                                right: 0,
                                dimension: 2,
                                min: -80,
                                max: 0,
                                calculable: false,
                                align: 'top',
                                top: 'middle',
                                formatter: (param) => {
                                    return `${param}dBm`
                                },
                                type: 'piecewise',
                                itemGap: 4,
                                pieces: [
                                    { min: 0, max: 9.9, label: '[-80,-70]', color: 'blue' },
                                    { min: 10, max: 19.9, label: '[-70,-60]', color: 'yellow' },
                                    { min: 20, max: 29.9, label: '[-60,-50]', color: 'yellow' },
                                    { min: 30, max: 39.9, label: '[-50,-40]', color: 'yellow' },
                                    { min: 40, max: 49.9, label: '[-40,-30]', color: 'red' },
                                    { min: 50, max: 59.5, label: '[-30,-20]', color: 'red' },
                                    { min: 60, max: 69.9, label: '[-20,-10]', color: 'red' },
                                    { min: 70, max: 80, label: '[-10, 0]', color: 'red' }
                                ]
                            },
                            {
                                seriesIndex: 1,
                                show: false,
                                right: 0,
                                dimension: 2,
                                min: 0,
                                max: 80,
                                calculable: false,
                                align: 'top',
                                top: 'middle',
                                formatter: (param) => {
                                    return `${param}dBm`
                                },
                                type: 'piecewise',
                                itemGap: 4,
                                pieces: [
                                    { min: 0, max: 9.9, label: '[-80,-70]', color: 'blue' },
                                    { min: 10, max: 19.9, label: '[-70,-60]', color: 'yellow' },
                                    { min: 20, max: 29.9, label: '[-60,-50]', color: 'yellow' },
                                    { min: 30, max: 39.9, label: '[-50,-40]', color: 'yellow' },
                                    { min: 40, max: 49.9, label: '[-40,-30]', color: 'red' },
                                    { min: 50, max: 59.5, label: '[-30,-20]', color: 'red' },
                                    { min: 60, max: 69.9, label: '[-20,-10]', color: 'red' },
                                    { min: 70, max: 80, label: '[-10, 0]', color: 'red' }
                                ]
                            },
                            {
                                seriesIndex: 2,
                                show: false,
                                right: 0,
                                dimension: 2,
                                min: -80,
                                max: 0,
                                calculable: false,
                                align: 'top',
                                top: 'middle',
                                formatter: (param) => {
                                    return `${param}dBm`
                                },
                                type: 'piecewise',
                                itemGap: 4,
                                pieces: [
                                    { min: 0, max: 9.9, label: '[-80,-70]', color: 'red' },
                                    { min: 10, max: 19.9, label: '[-70,-60]', color: 'red' },
                                    { min: 20, max: 29.9, label: '[-60,-50]', color: 'red' },
                                    { min: 30, max: 39.9, label: '[-50,-40]', color: 'red' },
                                    { min: 40, max: 49.9, label: '[-40,-30]', color: 'red' },
                                    { min: 50, max: 59.5, label: '[-30,-20]', color: 'red' },
                                    { min: 60, max: 69.9, label: '[-20,-10]', color: 'red' },
                                    { min: 70, max: 80, label: '[-10, 0]', color: 'red' }
                                ]
                            }
                        ],
                        toolbox: { // 可视化的工具箱
                            show: false,
                            feature: {
                                dataView: { // 数据视图
                                    show: false
                                },
                                saveAsImage: { // 保存图片
                                    show: true,
                                    backgroundColor: "black",
                                    name: "地电波峰值谱图"
                                }
                            },
                            top: "5%",
                            right: "20%",
                            iconStyle: {
                                color: "#bccbf6"
                            },
                        },
                        tooltip: {
                            show: true,
                            trigger:"item",
                            axisPointer: {type:"cross"},
                            showContent:true,
                            formatter:(param) => {
                                const v = param.value[2]-80
                                return `相位: ${param.value[0]}°<br>周期: ${param.value[1]}<br>幅值:${v.toFixed(1)}dBm`
                            }
                        },
                        grid3D: {
                            left:"0px",
                            top:"0px",
                            bottom:0,
                            viewControl: {
                                autoRotate: false,
                                rotateSensitivity: 0,
                                zoomSensitivity: 0,
                                panSensitivity: 0,
                                projection: 'perspective',//perspective
                                distance: 220,
                                autoRotateAfterStill: 1,
                                beta:0,
                            },
                            splitArea: {
                                show: false
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#eee"
                                },
                                left:'100px',
                                onZero: true
                            },
                            axisLabel: {
                                rotate: 270
                            },
                            axisPointer: {
                                show: false
                            }
                        },
                        xAxis3D: {
                            id: 'x',
                            type: 'value',
                            name: '', //"相位",
                            min:0,
                            interval:90,
                            max:360,
                            axisLabel: { show: false, formatter: '{value}°' },
                            splitArea: {
                                show: true
                            }
                        },
                        yAxis3D: {
                            id: 'y',
                            type: 'value',
                            name: "",
                            min:0,
                            max:50,
                            interval: 10,
                            axisLabel: {show: false,  formatter: (param) => {
                                    return `${param}`
                                } },
                            splitArea: {
                                show: true
                            }
                        },
                        zAxis3D: {
                            id: 'z',
                            type: 'value',
                            name: '',
                            interval: 10,
                            min: 0,
                            max: 80,
                            // inverse: false,
                            animationDuration: 0,
                            animationDurationUpdate: 0,
                            axisLabel: {
                                margin: 10, show:false,
                                formatter: (param) => {
                                    return `${param}`
                                }
                            }
                        },
                series: [
                    {
                        stack: 'total',
                        name:'PRPS',
                        type: 'bar3D',
                        barSize: [0.1, 0.1],
                        shading: 'lambert',
                        data: [],
                        label: {
                                    show: false,
                                    textStyle: { color: 'transparent' },
                                    valueAnimation: true
                                },
                    },
                    {
                        name:'PRPD',
                        type: 'scatter3D',
                        symbolSize: 4,
                        shading: 'lambert',
                        data: [],
                        label: {
                                    show: false,
                                    textStyle: { color: 'transparent' },
                                    valueAnimation: true
                                },
                    }
                    // {
                    //     name:'sin',
                    //     type: 'line3D',
                    //     symbolSize: 4,
                    //     shading: 'lambert',
                    //     data: sin,
                    //     label: {
                    //                 show: false,
                    //                 textStyle: { color: 'transparent' },
                    //                 valueAnimation: true
                    //             },
                    // }
                ],
                        animation: false,
                        animationDuration: 0,
                        animationEasing: 'cubicInOut'
            }
            this.option = optionex
        }
    },
    watch: {
            prps: {
                handler(nv) {
                    window.console.log('prpsdata', JSON.stringify(this.prps))
                    this.setChart();
                    // const p10 = chartInstance.convertToPixel({xAxis3DIndex: 0, yAxis3DIndex: 0, zAxis3DIndex: 0}, [10, -40, 10])
                    // window.console.log('p102t', p10)
                },
                immidiate: true,
                deep: true  
            },
        },
    mounted() {
        // this.$nextTick(() => {
        //     this.initChart()
        // })
        this.initChart()
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.om_echarts_prps {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .om_echart_view {
        width: 100%;
        height: 100%;
    }
}
</style>