<template>
<div class="om_ysp_dwsjx_view">
    <div class="dwsjx_container">
        <div class="dwsjx_container_left">
            <div style="width:500px; height:433px; background: url(/images/dwsjx.png) no-repeat center;background-size:500px 433px;">
                <div style="width:100%; height:100%; position: relative;">
                    <div id="color" style="background-color: red; border-radius: 50%; width: 10px; height: 10px; border:2px solid white; position: absolute;"></div>
                </div>
            </div>
        </div>
        <div class="dwsjx_container_right">
            <table>
                <tr>
                    <td colspan="2" align="center" bgcolor="#c0c0c0">{{spsb}}</td>
                </tr>
                <tr>
                    <td width="10px" bgcolor="#c0c0c0"></td>
                    <td width="auto" align="center" bgcolor="#c0c0c0">PD-{{jbfd}}</td>
                </tr>
                <tr>
                    <td width="10px" bgcolor="#60e30b"></td>
                    <td width="auto" align="center" bgcolor="#c0c0c0">D1-{{dnfd}}</td>
                </tr>
                <tr>
                    <td width="10px" bgcolor="#8ae3b5"></td>
                    <td width="auto" align="center" bgcolor="#c0c0c0">D2-{{gnfd}}</td>
                </tr>
                <tr>
                    <td width="10px" bgcolor="#ff00ff"></td>
                    <td width="auto" align="center" bgcolor="#c0c0c0">T1-{{rgz}}</td>
                </tr>
                <tr>
                    <td width="10px" bgcolor="#d39a0b"></td>
                    <td width="auto" align="center" bgcolor="#c0c0c0">T2-{{rgz}}</td>
                </tr>
                <tr>
                    <td width="10px" bgcolor="#0000ff"></td>
                    <td width="auto" align="center" bgcolor="#c0c0c0">T3-{{rgz}}</td>
                </tr>
                <tr>
                    <td width="10px" bgcolor="#ffff00"></td>
                    <td width="auto" align="center" bgcolor="#c0c0c0">D+T</td>
                </tr>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import { toDecimal } from '@/utils'
export default {
    name:'om_ysp_dwsjx_view',
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    computed: {
        H2() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 2)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        CH4() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 5)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        C2H2() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 6)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        C2H4() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 7)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        C2H6() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 8)
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        canshus() {
            const map_canshu = this.$store.state.station.map_canshu
            if (map_canshu === undefined || map_canshu === null) return []
            const canshus = []
            const obj_canshus = this.object.canshus
            for (const key in obj_canshus) {
                const canshu = map_canshu[key]
                if (canshu.canshu_id === 1) {
                    continue
                }
                if (canshu.datatype === 4) {
                    continue
                }
                if (canshu.datatype === 5) {
                    continue
                }
                canshus.push(canshu)
            }
            return canshus
        },
        dwsjx() {
            const CH4 = this.CH4
            if (CH4 === null) {
                return null
            }
            const C2H2 = this.C2H2
            if (C2H2 === null) {
                return null
            }
            const C2H4 = this.C2H4
            if (C2H4 === null) {
                return null
            }
            try {
                const fCH4 = toDecimal(CH4.data, 1)
                const fC2H2 = toDecimal(C2H2.data, 1)
                const fC2H4 = toDecimal(C2H4.data, 1)
                const sum = fCH4 + fC2H2 + fC2H4
                if (sum < 0.1) {
                    return null
                }
                const fCH4_rate = fCH4 / sum
                const fC2H2_rate = fC2H2 / sum
                const y = ( 1 - fCH4_rate) * 433
                const x = 500 - fC2H2_rate * 500 - Math.tan(3.1416 / 6) * fCH4_rate * 433
                return [x, y]
            } catch (error) {
                // console.log('大卫三角形计算出错：', error)
            }
            return null
        },
        spsb() {
            return this.$Lang.jcsj.sssj.dwsjfx.spsb
        },
        jbfd() {
            return this.$Lang.jcsj.sssj.dwsjfx.jbfd
        },
        dnfd() {
            return this.$Lang.jcsj.sssj.dwsjfx.dnfd
        },
        gnfd() {
            return this.$Lang.jcsj.sssj.dwsjfx.gnfd
        },
        rgz() {
            return this.$Lang.jcsj.sssj.dwsjfx.rgz
        }
    },
    methods: {
        setState() {
            const dom_color = document.getElementById('color')
            if (dom_color === undefined || dom_color === null) {
                return
            }
            const dwsjx = this.dwsjx
            if (dwsjx === null) {
                dom_color.style.display = 'none'
                return
            }
            const x = dwsjx[0]
            const y = dwsjx[1]
            dom_color.style.left = `${x}px`
            dom_color.style.top = `${y}px`
            dom_color.style.display = 'block'
        }
    },
    watch: {
        'dwsjx': {
            imidiate: true,
            deep: true,
            handler() {
                this.setState()
            }
        }
    },
    mounted() {
        this.setState()
    }
}
</script>

<style lang="scss" scoped>
.om_ysp_dwsjx_view {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items:center;
    justify-content:center;
    .dwsjx_container {
        overflow: hidden;
        margin: 0px;
        padding: 0px;
        width: 710px;
        height: 433px;
        min-width: 610px;
        min-height: 433px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        .dwsjx_container_left {
            padding: 0px;
            margin: 0px;
            width: 500px;
            height: 433px;
            min-width: 500px;
            min-height: 433px;
            table {
                padding: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                border: 1px solid #607560;
                font-size: 12px;
                border-collapse: collapse;
            }
            table td {
                border: 1px solid #607560;
            }
        }
        .dwsjx_container_right {
            padding: 0px;
            margin: 0px;
            width: 200px;
            height: 433px;
            min-width: 100px;
            min-height: 433px;
            table {
                padding: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                border: 1px solid #607560;
                font-size: 12px;
                border-collapse: collapse;
            }
            table tr {
                height: 54px
            }
            table td {
                border: 1px solid #607560;
            }
        }
    }
}
</style>