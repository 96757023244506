<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="80%"
height="90%"
:title="title">
    <div class="gragh">
        <!-- <span>{{ packageno }}</span> -->
        <div class="prps">
            <om_prps_view ref="om_prps_view" :prps="prps" :prname="prname"/>
            <!-- <echartsWrap ref="channelChart1" :options="option" type="bar"/> -->
        </div>
        <!-- <div class="prpd">
            <om_echarts_ljprpd ref="om_echarts_ljprpd" :prps="rdata" :prname="prpdname"/>
        </div> -->
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog/index.vue'
import om_prps_view from '@/components/om_prps_view'
// import echartsWrap from '@/components/echartsWrap'
// import om_echarts_ljprpd from '@/components/om_echarts_ljprpd'
export default {
    components:{
        om_dialog,
        om_prps_view,
        // echartsWrap,
        // om_echarts_ljprpd
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        },
        wsuri: {
                type: String,
                default: `ws://${window.location.hostname}:8081/`
            }
    },
    data() {
        return {
            // soonTime: "",packageno: 0,
            option: null,
            // wsClient: null
        }
    },
    setup() {
        // const channelChart1 = ref(null);
        const om_prps_view = ref(null)
            const resizechart = resizeChartChannel()
            function resizeChartChannel() {
                return function() {
                    // window.console.log('onsze', 'sds')
                    om_prps_view.resizeChart()
                }
            }
            return {
                // activeName,
                om_prps_view,
                resizechart
            }
        // const om_prps_view = ref(null)
        // const om_echarts_ljprpd = ref(null)
        // const echartsWrap = ref(null)
        // return {
        //     // om_prps_view,
        //     echartsWrap,
        //     om_echarts_ljprpd
        // }
    },
    // created() {
    //         this.connect()
    //         window.console.log('connect', 'connected')
    //     },
    //     destroyed() {
    //         this.disconnect()
    //     },
    computed: {
        title() {
            const defaultName = this.sspt
            const object = this.object
            if (object === undefined || object === null) return defaultName
            const mingzi = object.mingzi
            if (mingzi === undefined || mingzi === null || mingzi === '') return defaultName
            const title = `${mingzi}${defaultName}`
            return title
        },
        sspt() {
            return this.$Lang.jcsj.sssj.ssptck.sspt
        },
        prps() {
            const object = this.object
            if (object === undefined || object === null) return null
            const canshu = this.$store.getters.canshu(object.obj_id, 21)
            if (canshu === undefined || canshu === null) return null
            const rdata = canshu.rdata
            if (rdata === undefined || rdata === null) return null
            return rdata
        },
        prname() {
            const object = this.object
            if (object === undefined || object === null) return 'prps'
            const mingzi = object.mingzi
            return mingzi + 'prps'
        },
        rdata() {
            const prps = this.prps
            if (prps === undefined || prps === null) return null
            const rdata = prps.rdata
            if (rdata === undefined || rdata === null) return null
            return rdata
        },
        prpdname() {
            const object = this.object
            if (object === undefined || object === null) return 'prpd'
            const mingzi = object.mingzi
            return mingzi + 'prpd'
        }
    },
    methods: {
        showDialog() {
            this.resetChannel(1)
            this.$refs.om_dialog.showDialog()
            const list_gis = this.list_gis
            if (list_gis === undefined || list_gis === null) return
            if (list_gis.length === 0) return
            this.selected_gis_id = list_gis[0].obj_id
            this.resize()
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        afterClose() {
            this.resetChannel(0)
        },
        resize() {
            // if (!this.om_prps_view) return
            // this.om_prps_view.resize()
            // if (!this.om_echarts_ljprpd) return
            // this.om_echarts_ljprpd.resize()
        },
        resetChannel(mode) {
            const object = this.object
            if (object === undefined || object === null) return false
            const bdz = object.bdz_id
            const leixing = object.leixing_id
            const shebei = object.shebei_id
            // this.$Message.warning(bdz + ',' + leixing + ',' + shebei)
            if (bdz === null || leixing === null || shebei === null) return false
            try {
                this.$ajax.reset_channel_mode(bdz, leixing, shebei, mode)
            } catch (error) {
                this.$Message.warning(error.toString())
            }
            return true
        },
        // 初始化websocket,并连接websocket服务端
        // connect() {
        //         this.wsClient = new WebSocket(this.wsuri)
        //         this.wsClient.onmessage = this.websocketonmessage
        //         this.wsClient.onopen = this.websocketonopen
        //         this.wsClient.onerror = this.websocketonerror
        //         this.wsClient.onclose = this.websocketclose
        //     },
        //     reconnect() {
        //         this.disconnect()
        //         this.connect()
        //     },
        //     disconnect() {
        //         if (!this.wsClient) return
        //         // this.resetHeartbeat()
        //         this.wsClient.close()
        //         delete this.wsClient
        //         this.wsClient = null
        //         window.console.log('disconnect', 'disconnected')
        //     },
        //     websocketonopen() {
        //     },
            // 数据接收
            // websocketonmessage({ data }) {
            //     const msgdata = data.split(',')
            //     const curno = parseInt(msgdata[0])
            //     const isold = (curno === this.packageno) || ((curno < this.packageno) && (curno > 20))
            //     if (isold) {
            //         window.console.log('out frame', curno)
            //         return
            //     }
            //     window.console.log('curframe1', isold + ',' + this.packageno + ',' + curno)
            //     this.packageno = curno
                
            //     // const msgdata = JSON.parse(data)
            //     const len = msgdata.length 
            //     let datas = []
            //     for (let i = 1; i < len; i+= 3) {
            //         datas.push([Number(msgdata[i]), parseInt(msgdata[i+1]),Number(msgdata[i+2])])
            //     }
            //     this.option.series[0].data = datas
            //     const dt = new Date()
            //     window.console.log('curtime', dt.getMinutes() + ':' + dt.getSeconds() + ',' + dt.getMilliseconds())
            // },
            // // 连接建立失败重连
            // websocketonerror() {
            //     this.wsClient.close()
            // },
            // // 关闭
            // websocketclose() {
            //     this.reconnect()
            // }
    },
    mounted() {
        this.resize()
        // const optionex = {
        //                 title: {
        //                     show: true,
        //                     text: "UHF\n峰\n值\n谱\n图",
        //                     textStyle: {
        //                         color: "#eee",
        //                         fontWeight:400,
        //                         lineHeight:30,
        //                         fontSize:14
        //                     },
        //                     right:20,
        //                     top:20
        //                 },
        //                 visualMap: [
        //                     {
        //                         textStyle: {
        //                             color: '#e7e7e7'
        //                         },
        //                         seriesIndex: 0,
        //                         show: false,
        //                         top: 10,
        //                         left: 30,
        //                         dimension: 2,
        //                         min: -75,
        //                         max: 0,
        //                         calculable: false,
        //                         align: 'top',
        //                         orient :'horizontal',
        //                         formatter: (param) => {
        //                             return `${param}dBm`
        //                         },
        //                         type: 'piecewise',
        //                         itemGap: 4,
        //                         pieces: [
        //                             { min: 0, max: 25, label: `[0, 24.9]`, color: 'blue' },
        //                             { min: 25, max: 50, label: `[25,49.9]`, color: 'yellow' },
        //                             { min: 50, max: 75, label: `[50,75]`, color: 'red' }
                                    
        //                         ]
        //                     }
        //                 ],
                        // toolbox: { // 可视化的工具箱
                        //     show: true,
                        //     feature: {
                        //         dataView: { // 数据视图
                        //             show: false
                        //         },
                        //         saveAsImage: { // 保存图片
                        //             show: true,
                        //             backgroundColor: "black",
                        //             name: "地电波峰值谱图"
                        //         }
                        //     },
                        //     top: "5%",
                        //     right: "20%",
                        //     iconStyle: {
                        //         color: "#bccbf6"
                        //     },
                        // },
                        // tooltip: {
                        //     show: true,
                        //     trigger:"item",
                        //     axisPointer: {type:"cross"},
                        //     showContent:true,
                        //     formatter:(param) => {
                        //         const v = param.value[2]
                        //         return `相位: ${param.value[0]}°<br>周期: ${param.value[1]}<br>幅值:${v.toFixed(1)}dBm`
                        //     }
                        // },
                        // grid3D: {
                        //     left:"0px",
                        //     top:"0px",
                        //     bottom:0,
                        //     viewControl: {
                        //         autoRotate: false,
                        //         rotateSensitivity: 0,
                        //         zoomSensitivity: 0,
                        //         panSensitivity: 0,
                        //         projection: 'perspective',//perspective
                        //         distance: 220,
                        //         autoRotateAfterStill: 1,
                        //         beta:0,
                        //     },
                        //     splitArea: {
                        //         show: false
                        //     },
                        //     axisLine: {
                        //         show: true,
                        //         lineStyle: {
                        //             color: "#eee"
                        //         },
                        //         left:'100px',
                        //         onZero: true
                        //     },
                        //     axisLabel: {
                        //         rotate: 270
                        //     },
                        //     axisPointer: {
                        //         show: false
                        //     }
                        // },
                        // xAxis3D: {
                        //     id: 'x',
                        //     type: 'value',
                        //     name: '', //"相位",
                        //     min:0,
                        //     interval:90,
                        //     max:360,
                        //     axisLabel: { show: false, formatter: '{value}°' },
                        //     splitArea: {
                        //         show: true
                        //     }
                        // },
                        // yAxis3D: {
                        //     id: 'y',
                        //     type: 'value',
                        //     name: "",
                        //     min:-50,
                        //     max:0,
                        //     interval: 10,
                        //     axisLabel: {show: false, formatter: (param) => {
                        //             return `${-param}`
                        //         } },
                        //     splitArea: {
                        //         show: true
                        //     }
                        // },
                        // zAxis3D: {
                        //     id: 'z',
                        //     type: 'value',
                        //     name: '',
                        //     interval: 10,
                        //     min: 0,
                        //     max: 75,
                        //     inverse: false,
                        //     animationDuration: 0,
                        //     animationDurationUpdate: 0,
                        //     axisLabel: {
                        //         margin: 10, show:false,
                        //         formatter: (param) => {
                        //             return `${-75 + param}`
                        //         }
                        //     }
                        // },
                        // series: [
                        //     {
                        //         name:'PRPS',
                        //         type: 'bar3D',
                        //         barSize: [0.1, 0.1],
                        //         shading: 'lambert',
                        //         data: [],
                    //             label: {
                    //                 show: false,
                    //                 textStyle: { color: 'transparent' },
                    //                 valueAnimation: true
                    //             },
                    //             large: true
                    //         }
                    //     ],
                    //     animation: false,
                    //     animationDuration: 0,
                    //     animationEasing: 'cubicInOut'
                    // }
                    // this.option = optionex
            window.onresize = this.resizechart
    },
    beforeDestroy() {
            window.removeEventListener('resize', this.resizechart)
        }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .gragh {
        width: 100%;
        height: 100%;
        margin: 0px;
        box-sizing: border-box;
        overflow: hidden;
        margin: 2px;
        // background-color: blue;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .prps {
            height: 100%;
            width: 100%;
            // flex: 1;
            overflow: hidden;
            box-sizing: border-box;
            margin: 2px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border: 2px solid #2376b7;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        .prpd {
            flex: 1;
            height: 100%;
            margin: 2px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: 2px solid #2376b7;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}
</style>