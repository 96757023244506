<template>
<om_dialog ref="ref_dialog" :title="yzsz" width="1280px" height="85%">
    <div class="om_device_canshu_view">
        <el-table
        ref="om_device_canshu_view_table"
        :data="canshus"
        :showHeader="true"
        border
        stripe
        >
            <el-table-column
            :label="jcl"
            align="center"
            prop="shuoming"
            width="200"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            :label="zdz"
            align="center"
            width="160"
            prop="data"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <template v-if="scope.row.datatype===1">
                            <el-tag>
                                {{ toDecimal(scope.row.data, 3) }}{{ danwei(scope.row.danwei) }}
                            </el-tag>
                        </template>
                        <template v-if="scope.row.datatype===2">
                            <el-tooltip effect="dark" placement="top">
                                <div slot="content">{{yxbianma(scope.row.leixing_id, scope.row.canshu_id, scope.row.data)}}</div>
                                <el-tag>{{yxbianma(scope.row.leixing_id, scope.row.canshu_id, scope.row.data)}}</el-tag>
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.datatype===3">
                            <div>
                                <el-tag>{{ scope.row.data }}</el-tag>
                            </div>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="yxsx"
            width="auto"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-input-number v-model="scope.row.youxiaoup" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="yxxx"
            width="auto"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-input-number v-model="scope.row.youxiaodn" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="bjsx"
            width="auto"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-input-number v-model="scope.row.alarmup" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="bjxx"
            width="auto"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-input-number v-model="scope.row.alarmdn" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="yjsx"
            width="auto"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-input-number v-model="scope.row.attenup" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            :label="yjxx"
            width="auto"
            align="center"
            show-overflow-tooltip
            >
                <template #default="scope">
                    <div style="width:100%; height:100%; display: flex; justify-content: center; align-items:center;">
                        <el-input-number v-model="scope.row.attendn" size="small" :precision="2" :step="1" :min="-999999" :max="999999" />
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <template v-slot:footer>
        <div style="padding: 8px; width: 100%; height: 100%; display: flex; flex-direction:row; justify-content: flex-end; align-items: center;">
            <el-button type="primary" round @click="handle_click_event">{{qd}}</el-button>
        </div>
    </template>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import { canshu_flag_object, toDateTime, toDecimal } from '@/utils'
import om_dialog from '@/components/om_dialog'
export default {
    name:'om_yuzhi_set_dialog',
    components: {
        om_dialog
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    setup() {
        const ref_dialog = ref(null)
        return {
            ref_dialog
        }
    },
    data() {
        return {
            youxiaoup: 999999,
            youxiaodn: -999999,
            alarmup: 999999,
            alarmdn: -999999,
            attenup: 999999,
            attendn: -999999
        }
    },
    computed: {
        canshus() {
            const map_canshu = this.$store.state.station.map_canshu
            if (map_canshu === undefined || map_canshu === null) return []
            const canshus = []
            const obj_canshus = this.object.canshus
            for (const key in obj_canshus) {
                const canshu = map_canshu[key]
                if (canshu.canshu_id === 1) {
                    continue
                }
                if (canshu.datatype === 4) {
                    continue
                }
                if (canshu.datatype === 5) {
                    continue
                }
                canshus.push(canshu)
            }
            return canshus
        },
        yzsz() {
            return this.$Lang.jcsj.xgyz.yzsz
        },
        jcl() {
            return this.$Lang.jcsj.xgyz.jcl
        },
        zdz() {
            return this.$Lang.jcsj.xgyz.zdz
        },
        yxsx() {
            return this.$Lang.jcsj.xgyz.yxsx
        },
        yxxx() {
            return this.$Lang.jcsj.xgyz.yxxx
        },
        bjsx() {
            return this.$Lang.jcsj.xgyz.bjsx
        },
        bjxx() {
            return this.$Lang.jcsj.xgyz.bjxx
        },
        yjsx() {
            return this.$Lang.jcsj.xgyz.yjsx
        },
        yjxx() {
            return this.$Lang.jcsj.xgyz.yjxx
        },
        qd() {
            return this.$Lang.header.xgmm.qd
        }
    },
    methods: {
        showDialog() {
            const ref_dialog = this.ref_dialog
            if (ref_dialog === undefined || ref_dialog === null) return
            ref_dialog.showDialog()
        },
        closeDialog() {
            const ref_dialog = this.ref_dialog
            if (ref_dialog === undefined || ref_dialog === null) return
            ref_dialog.closeDialog()
        },
        toDecimal(x, fixed) {
            return toDecimal(x, fixed)
        },
        toDateTime(sec) {
            return toDateTime(sec)
        },
        danwei(danwei) {
            if (!danwei) return ''
            if (danwei === '') return ''
            return `(${danwei})`
        },
        imageUrl(data) {
            return `images/devices/${data}`
        },
        yxbianma(leixing_id, canshu_id, data_int) {
            if (leixing_id === undefined || leixing_id === null) {
                return data_int
            }
            if (canshu_id === undefined || canshu_id === null) {
                return data_int
            }
            const obj_id = `${leixing_id}_${canshu_id}_${parseInt(data_int)}`
            const bianma = this.$store.getters.getYxbianma(obj_id)
            if (bianma === undefined || bianma === null) {
                return data_int
            }
            return `${data_int}(${bianma.shuoming})`
        },
        canshu_data_value_format(canshu) {
            const datatype = canshu.datatype
            let data = canshu.data
            let danwei = canshu.danwei
            if ( datatype === 4 ) {
                return `images/devices/${data}`
            }
            if (!data) {
                return ''
            }
            if ( datatype === 1 ) {
                data = Math.round(data * 1000, 3) / 1000
            }
            if (!danwei) {
                return data
            }
            danwei = danwei.trim()
            if (danwei === '') {
                return data
            }
            const value = `${data} (${danwei})`
            return value
        },
        canshu_flag_object(data_flag) {
            if (undefined === data_flag) {
                data_flag = 0
            }
            const status = canshu_flag_object(data_flag)
            return status
        },
        async handle_click_event() {
            const canshus = this.canshus
            if (canshus === undefined || canshus === null) return
            const length = canshus.length
            if (length <= 0) return
            try {
                const resultset = await this.$ajax.p_update_ms_relaalarm_ts(canshus)
                const code = resultset[0][0].code
                if (code === 0) {
                    this.ref_dialog.closeDialog()
                    return
                }
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_update_ms_relaalarm_ts,错误原因：${error}`
                })
                return
            }
        }
    }
}
</script>

<style lang="scss">
.om_yuzhi_set_dialog {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>