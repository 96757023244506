<template>
<div class="om_object_info_view">
    <div class="mingzi">
        <div class="label">{{jczz}}</div>
        <div class="name"><el-tag>{{object.mingzi}}</el-tag></div>
    </div>
    <div class="time">
        <div class="label">{{zdsj}}</div>
        <div class="name"><el-tag>{{ctime}}</el-tag></div>
    </div>
    <!-- <div class="time">
        <div class="label">{{cjsj}}</div>
        <div class="name"><el-tag>{{rtime}}</el-tag></div>
    </div> -->
    <div class="flag">
        <div class="label">{{zzzt}}</div>
        <img class="flag" :src="device_flag_object.src"/>
    </div>
    <div class="flag">
        <div class="label">{{sjzt}}</div>
        <img class="flag" :src="equip_flag_object.src"/>
    </div>
    <div v-if="quanxian>0" style="flex: 1; padding: 4px; display: flex; justify-content: flex-end; align-items: center;">
        <el-button class="more" type="warning" size="small" round @click="handle_click_event">{{xgyz}}</el-button>
    </div>
    <om_yuzhi_set_dialog
        ref="ref_yuzhi_set_dialog"
        :object="object"
        v-if="visibleDialog"
        @close="om_yuzhi_set_dialog_close_handler"
    />
</div>
</template>

<script>
import { ref } from 'vue'
import om_yuzhi_set_dialog from './om_yuzhi_set_dialog'
import { device_flag_object, equip_flag_object } from '@/utils'
export default {
    name:'om_object_info_view',
    components: {
        om_yuzhi_set_dialog
    },
    props: {
        object: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            quanxian: 0,
            visibleDialog: false
        }
    },
    setup() {
        const ref_yuzhi_set_dialog = ref(null)
        return {
            ref_yuzhi_set_dialog
        }
    },
    computed: {
        canshu() {
            const object = this.object
            if (this.object === undefined || object === null) return null
            const obj_id = object.obj_id
            return this.$store.getters.canshu(obj_id, 1)
        },
        data_flag() {
            const canshu = this.canshu
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) {
                return 0
            }
            return data_flag
        },
        ctime() {
            const canshu = this.canshu
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const ctime = canshu.ctime
            if (ctime === undefined || ctime === null) {
                return ''
            }
            return ctime
        },
        rtime() {
            const canshu = this.canshu
            if (canshu === undefined || canshu === null) {
                return 0
            }
            const rtime = canshu.rtime
            if (rtime === undefined || rtime === null) {
                return ''
            }
            return rtime
        },
        device_flag_object() {
            const data_flag = this.data_flag
            const device_flag = device_flag_object(data_flag)
            return device_flag
        },
        equip_flag_object() {
            const data_flag = this.data_flag
            const equip_flag = equip_flag_object(data_flag)
            return equip_flag
        },
        jczz() {
            return this.$Lang.jcsj.jczz
        },
        zdsj() {
            return this.$Lang.jcsj.zdsj
        },
        cjsj() {
            return this.$Lang.jcsj.cjsj
        },
        zzzt() {
            return this.$Lang.jcsj.zzzt
        },
        sjzt() {
            return this.$Lang.jcsj.sjzt
        },
        xgyz() {
            return this.$Lang.jcsj.xgyz.name
        },
        warn_qx() {
            return this.$Lang.jcsj.xgyz.warn_qx
        }
    },
    methods: {
        handle_click_event() {
            const quanxian = sessionStorage.getItem('quanxian')
            if (quanxian === '' || quanxian === null || quanxian === undefined || quanxian === null) {
                this.$Message({
                    type:'warning',
                    message: this.warn_qx
                })
                return
            }
            this.visibleDialog = true
            this.$nextTick(() => {
                const ref_yuzhi_set_dialog = this.ref_yuzhi_set_dialog
                if (ref_yuzhi_set_dialog === undefined || ref_yuzhi_set_dialog === null) return
                ref_yuzhi_set_dialog.showDialog()
            })
        },
        om_yuzhi_set_dialog_close_handler() {
            this.visibleDialog = false
        }
    },
    mounted() {
        this.quanxian = sessionStorage.getItem('quanxian')
    }
}
</script>

<style lang="scss">
.om_object_info_view {
    width: 100%;
    height: 100%;
    user-select: none;
    box-sizing: border-box;
    border: 0px;
    font-size: 14px;
    font-family: 'monospace';
    font-weight: bold;
    color: whitesmoke;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .mingzi {
        box-sizing: border-box;
        margin: 0px;
        flex: 0;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        .label {
            height: 100%;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            flex: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            min-width: 80px;
            white-space:nowrap;
        }
        .name {
            height: 100%;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            flex: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
        }
    }
    .time {
        box-sizing: border-box;
        margin: 0px;
        padding: 4px;
        flex: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .label {
            height: 100%;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            flex: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            white-space:nowrap;
        }
        .name {
            height: 100%;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            flex: 0 auto;
            display: flex;
            width: 150px;
            min-width: 150px;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
        }
    }
    .flag {
        box-sizing: border-box;
        margin: 0px;
        padding: 4px;
        flex: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .label {
            height: 100%;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            flex: 1;
            width: 100px;
            min-width: 100px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            white-space:nowrap;
        }
        .flag {
            height: 100%;
            box-sizing: border-box;
            padding: 4px;
            margin: 0px;
            flex: 0;
            min-width: 24px;
            width: 24px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>