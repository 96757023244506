<template>
<div class="om_data_view">
    <div class="om_report_tree_region">
        <om_container :title="sjzts">
            <om_report_tree ref="om_report_tree" :object="object"/>
        </om_container>
    </div>
    <div class="om_report_content_region">
        <om_report_content ref="om_report_content" :object="object"/>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import om_container from '@/components/om_container'
import om_report_content from './om_report_content'
import om_report_tree from './om_report_tree'
export default {
    name: 'om_data_view',
    components: {
        om_container,
        om_report_content,
        om_report_tree
    },
    setup() {
        const om_report_tree = ref(null)
        const om_report_content = ref(null)
        return {
            om_report_tree,
            om_report_content
        }
    },
    computed: {
        object() {
            return this.$store.state.selectedShebeiObject
        },
        sjzts() {
            return this.$Lang.sjgl.sjzts
        }
    },
    methods: {
        resize() {
            if (this.om_report_content) {
                this.om_report_content.resize()
            }
        }
    },
    activated() {
        this.resize()
    },
    mounted() {
        this.resize()
    }
}
</script>

<style scoped lang="scss">
.om_data_view {
    width:100%;
    height:100%;
    display: flex;
    background-color: white !important;
    padding:4px;
    box-sizing: border-box;
    .om_report_tree_region {
        flex: 0;
        width: 300px;
        min-width: 300px;
        height: 100%;
        overflow: hidden;
        background: white;
        box-sizing: border-box;
        padding-right: 2px;
    }
    .om_report_content_region {
        flex: 1;
        min-width: 0px;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding-left: 2px;
    }
}
</style>