import { createStore } from 'vuex'
// import createVuexAlong from 'vuex-along'
import ajax from '@/api'
import { ElMessage } from 'element-plus'
import { contain_device_flag, contain_equip_flag, contain_equip_alarm_flag, contain_equip_atten_flag, toDecimal, toDateTime } from '@/utils'
export default createStore({
    state: {
        bIsInitState: false,
        station: {
            home_show_mode: 1,
            settings: [],
            map_object: {},
            map_leixing: {},
            map_canshu: {},
            map_leixing_canshu: {},
            map_yxbianma: {},
            biandianzhans: []
        },
        selectedShebeiObject: {},
        zx_data: {}
    },
    mutations: {
        update_zx_data(state) {
            const map_canshu = state.station.map_canshu
            const zx_data = state.zx_data
            for (const key in zx_data) {
                const value = zx_data[key]
                const list_key = key.split('.')
                if (list_key.length !== 3 ) {
                    continue
                }
                const data_orgin = list_key[0]
                if (data_orgin !== 'zx_data_src' && data_orgin !== 'zx_data_calc') {
                    continue
                }
                const data_key = list_key[1]
                if (data_key === undefined || data_key === null) {
                    continue
                }
                const list_data_key = data_key.split('_')
                if (list_data_key.length !== 4) {
                    continue
                }
                const data_attr = list_key[2]
                if (data_attr !== 'rtime' && data_attr !== 'data' && data_attr !== 'data_flag' && data_attr !== 'ctime') {
                    continue
                }
                const obj_id = data_key
                const canshu = map_canshu[obj_id]
                if (canshu === undefined || canshu === null) {
                    continue
                }
                if (data_orgin === 'zx_data_calc') {
                    if (data_attr === 'rtime') continue
                    canshu[data_attr] = value
                    continue
                }
                if (data_orgin === 'zx_data_src') {
                    if (data_attr === 'data') {
                        canshu.rdata = value
                        continue
                    }
                    if (data_attr === 'rtime') {
                        canshu.rtime = value
                        continue
                    }
                }
            }
            // 清空数据
            state.zx_data = {}
        },
        set_zx_data(state, zx_data) {
            Object.assign(state.zx_data, zx_data)
        },
        setSelectedShebeiObject(state, object) {
            if (object === undefined || object === null) {
                return null
            }
            const shebei = JSON.parse(JSON.stringify(object))
            const canshus = this.getters.canshus(shebei.obj_id)
            shebei.canshus = canshus
            state.selectedShebeiObject = shebei
        },
        setDefaultShebeiObject(state) {
            const biandianzhan = this.getters.biandianzhan
            if (biandianzhan === undefined || biandianzhan === null) return null
            const jianges = biandianzhan.jianges
            if (jianges === undefined || biandianzhan === null) return null
            if (jianges.length <= 0) return null
            const jianges_exclude_condition = jianges.filter(
                (jiange) => {
                    if (jiange.jg_id === 1031) {
                        return false
                    }
                    if (jiange.jg_id === 1034) {
                        return false
                    }
                    if (jiange.jg_id === 1035) {
                        return false
                    }
                    return true
                }
            )
            if (jianges_exclude_condition.length <= 0) return null
            const shebeis = jianges_exclude_condition[0].shebeis
            if (shebeis.length <= 0) return null
            const shebei = JSON.parse(JSON.stringify(shebeis[0]))
            const canshus = this.getters.canshus(shebei.obj_id)
            shebei.canshus = canshus
            state.selectedShebeiObject = shebei
        }
    },
    getters: {
        // 当前变电站
        biandianzhan(state) {
            const biandianzhans = state.station.biandianzhans
            if (biandianzhans === undefined || biandianzhans === null) return null
            if (biandianzhans.length <= 0) return null
            const biandianzhan = biandianzhans[0]
            return biandianzhan
        },
        cac(state, getters) {
            const biandianzhan = getters.biandianzhan
            if (biandianzhan === undefined || biandianzhan === null) return 0
            const bdz_id = biandianzhan.bdz_id
            const cac_id = `${bdz_id}_34_1`
            return getters.object(cac_id)
        },
        // 设备总数
        shebei_total(state, getters) {
            const biandianzhan = getters.biandianzhan
            if (biandianzhan === undefined || biandianzhan === null) return 0
            let shebei_total = 0
            const map_object = state.station.map_object
            if (map_object === undefined || map_object === null) return shebei_total
            for (const key in map_object) {
                const object = map_object[key]
                if (object === undefined || object === null) continue
                const bdz_id = object.bdz_id
                const leixing_id = object.leixing_id
                if (bdz_id !== biandianzhan.bdz_id) continue
                if (leixing_id === undefined || leixing_id === null) continue
                if (leixing_id === 1 || leixing_id === 2 || leixing_id === 21 || leixing_id === 24) continue
                if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) continue
                shebei_total++
            }
            return shebei_total
        },
        // 设备按类型排序
        leixings: (state, getters) => {
            const biandianzhan = getters.biandianzhan
            if (biandianzhan === undefined || biandianzhan === null) return []
            return biandianzhan.leixings
        },
        // 设备按间隔排序
        jianges: (state, getters) => {
            const biandianzhan = getters.biandianzhan
            if (biandianzhan === undefined || biandianzhan === null) return []
            return biandianzhan.jianges
        },
        // 根据ID获取对象
        object: (state) => (obj_id) => {
            const map_object = state.station.map_object
            if (map_object === undefined || map_object === null) return null
            const object = map_object[obj_id]
            return object
        },
        // 根据ID获取对象所有监测量
        canshus: (state, getters) => (obj_id) => {
            const map_object = state.station.map_object
            if (map_object === undefined || map_object === null) return null
            const object = map_object[obj_id]
            if (object === undefined || object === null) return null
            const canshus = object.canshus
            if (canshus === undefined || canshus === null) return null
            return canshus
        },
        // 根据ID获取对象指定监测量
        canshu: (state, getters) => (obj_id, canshu_id) => {
            const map_canshu = state.station.map_canshu
            if (canshu_id === undefined || canshu_id === null) {
                const list = obj_id.split('_')
                if (list.length !== 4) {
                    return null
                }
                const canshu = map_canshu[obj_id]
                if (canshu === undefined || canshu === null) return null
                return canshu
            }
            const key = `${obj_id}_${canshu_id}`
            const canshu = map_canshu[key]
            if (canshu === undefined || canshu === null) return null
            return canshu
        },
        rdata: (state, getters) => (obj_id, canshu_id) => {
            const canshu = getters.canshu(obj_id, canshu_id)
            if (canshu === undefined || canshu === null) return ''
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return ''
            if (canshu_id === undefined || canshu_id === null) return ''
            const rdata = canshu.rdata
            if (rdata === null || rdata === undefined) return ''
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return ''
            const rtutype = canshu.rtutype
            if (rtutype === undefined || rtutype === null) return ''
            let danwei = canshu.danwei
            if (danwei === undefined || danwei === null) danwei = ''
            // 遥测
            if (rtutype === 0) {
                // 浮点型
                if (datatype === 1) {
                    const fData = toDecimal(rdata, 2)
                    if (danwei === '') {
                        return fData
                    }
                    return `${fData}(${danwei})`
                }
                // 整型
                if (datatype === 2) {
                    const iData = toDecimal(rdata, 0)
                    const bianma_id = `${leixing_id}_${canshu_id}_${iData}`
                    let bianma = getters.getYxbianma(bianma_id)
                    if (bianma === undefined || bianma === null) bianma = ''
                    if (bianma === '') {
                        return iData
                    }
                    return `${iData}(${bianma})`
                }
                // 日期时间型
                if (datatype === 3) {
                    return toDateTime(rdata)
                }
                return ''
            }
            if (rtutype === 1) {
                // 整型
                if (datatype === 2) {
                    const iData = toDecimal(rdata, 0)
                    const bianma_id = `${leixing_id}_${canshu_id}_${iData}`
                    let bianma = getters.getYxbianma(bianma_id)
                    if (bianma === undefined || bianma === null) bianma = ''
                    if (bianma === '') {
                        return iData
                    }
                    return `${iData}(${bianma})`
                }
                return ''
            }
            return ''
        },
        data: (state, getters) => (obj_id, canshu_id) => {
            const canshu = getters.canshu(obj_id, canshu_id)
            if (canshu === undefined || canshu === null) return ''
            const leixing_id = canshu.leixing_id
            if (leixing_id === undefined || leixing_id === null) return ''
            if (canshu_id === undefined || canshu_id === null) return ''
            const rdata = canshu.data
            if (rdata === null || rdata === undefined) return ''
            const datatype = canshu.datatype
            if (datatype === undefined || datatype === null) return ''
            const rtutype = canshu.rtutype
            if (rtutype === undefined || rtutype === null) return ''
            let danwei = canshu.danwei
            if (danwei === undefined || danwei === null) danwei = ''
            // 遥测
            if (rtutype === 0) {
                // 浮点型
                if (datatype === 1) {
                    const fData = toDecimal(rdata, 2)
                    if (danwei === '') {
                        return fData
                    }
                    return `${fData}(${danwei})`
                }
                // 整型
                if (datatype === 2) {
                    const iData = toDecimal(rdata, 0)
                    const bianma_id = `${leixing_id}_${canshu_id}_${iData}`
                    let bianma = getters.getYxbianma(bianma_id)
                    if (bianma === undefined || bianma === null) bianma = ''
                    if (bianma === '') {
                        return iData
                    }
                    return `${iData}(${bianma})`
                }
                // 日期时间型
                if (datatype === 3) {
                    return toDateTime(rdata)
                }
                return ''
            }
            if (rtutype === 1) {
                // 整型
                if (datatype === 2) {
                    const iData = toDecimal(rdata, 0)
                    const bianma_id = `${leixing_id}_${canshu_id}_${iData}`
                    let bianma = getters.getYxbianma(bianma_id)
                    if (bianma === undefined || bianma === null) bianma = ''
                    if (bianma === '') {
                        return iData
                    }
                    return `${iData}(${bianma})`
                }
                return ''
            }
            return ''
        },
        // 根据ID获取对象遥信编码
        getYxbianma: (state) => (obj_id) => {
            const map_yxbianma = state.station.map_yxbianma
            if (map_yxbianma === undefined || map_yxbianma === null) return null
            if (obj_id === undefined || obj_id === null) return null
            const bianma = map_yxbianma[obj_id]
            return bianma
        },
        // 获取对象报警
        object_sum_flag: (state, getters) => (obj_id) => {
            const canshu = getters.canshu(obj_id, 1)
            if (canshu === undefined || canshu === null) return 0
            const data_flag = canshu.data_flag
            if (data_flag === undefined || data_flag === null) return 0
            return data_flag
        },
        // 设备故障列表
        shebei_fault_event(state, getters) {
            const shebei_fault_event = []
            const map_object = state.station.map_object
            if (map_object === undefined || map_object === null) return shebei_fault_event
            for (const key in map_object) {
                const object = map_object[key]
                if (object === undefined || object === null) continue
                const leixing_id = object.leixing_id
                if (leixing_id === undefined || leixing_id === null) continue
                if (leixing_id === 1 || leixing_id === 2 || leixing_id === 21 || leixing_id === 24) continue
                if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) continue
                const data_flag = getters.object_sum_flag(object.obj_id)
                const contain = contain_equip_flag(data_flag)
                if (!contain) continue
                shebei_fault_event.push(object)
            }
            return shebei_fault_event
        },
        // 设备故障总数
        shebei_fault_event_total(state, getters) {
            const shebei_fault_event = getters.shebei_fault_event
            if (shebei_fault_event === undefined || shebei_fault_event === null) return 0
            return shebei_fault_event.length
        },
        // 数据报警列表
        shebei_alarm_event(state, getters) {
            const shebei_alarm_event = []
            const shebei_fault_event = getters.shebei_fault_event
            if (shebei_fault_event === undefined || shebei_fault_event === null) return shebei_alarm_event
            for (let i = 0; i < shebei_fault_event.length; i++) {
                const shebei_fault = shebei_fault_event[i]
                const data_flag = getters.object_sum_flag(shebei_fault.obj_id)
                const contain = contain_equip_alarm_flag(data_flag)
                if (!contain) continue
                shebei_alarm_event.push(shebei_fault)
            }
            return shebei_alarm_event
        },
        // 数据报警列表总数
        shebei_alarm_event_total(state, getters) {
            const shebei_alarm_event = getters.shebei_alarm_event
            if (shebei_alarm_event === undefined || shebei_alarm_event === null) return 0
            return shebei_alarm_event.length
        },
        // 数据预警列表
        shebei_atten_event(state, getters) {
            const shebei_atten_event = []
            const shebei_fault_event = getters.shebei_fault_event
            if (shebei_fault_event === undefined || shebei_fault_event === null) return shebei_atten_event
            for (let i = 0; i < shebei_fault_event.length; i++) {
                const shebei_fault = shebei_fault_event[i]
                const data_flag = getters.object_sum_flag(shebei_fault.obj_id)
                const contain = contain_equip_atten_flag(data_flag)
                if (!contain) continue
                shebei_atten_event.push(shebei_fault)
            }
            return shebei_atten_event
        },
        // 数据预警列表总数
        shebei_atten_event_total(state, getters) {
            const shebei_atten_event = getters.shebei_atten_event
            if (shebei_atten_event === undefined || shebei_atten_event === null) return 0
            return shebei_atten_event.length
        },
        // 装置故障列表
        device_fault_event(state, getters) {
            const device_fault_event = []
            const map_object = state.station.map_object
            if (map_object === undefined || map_object === null) return device_fault_event
            for (const key in map_object) {
                const object = map_object[key]
                if (object === undefined || object === null) continue
                const leixing_id = object.leixing_id
                if (leixing_id === undefined || leixing_id === null) continue
                if (leixing_id === 1 || leixing_id === 2 || leixing_id === 21 || leixing_id === 24) continue
                if (leixing_id === 31 || leixing_id === 34 || leixing_id === 35) continue
                const data_flag = getters.object_sum_flag(object.obj_id)
                const contain = contain_device_flag(data_flag)
                if (!contain) continue
                device_fault_event.push(object)
            }
            return device_fault_event
        },
        // 装置故障总数
        device_fault_event_total(state, getters) {
            const device_fault_event = getters.device_fault_event
            if (device_fault_event === undefined || device_fault_event === null) return 0
            return device_fault_event.length
        },
        getos() {
            const op = state.station.opsystem
            return op
        }
    },
    actions: {
        async init({ commit, state }) {
            if (state.bIsInitState) return
            try {
                // add web settings
                {
                    const resultset = await ajax.p_query_ms_config('{}')
                    if (resultset[1]) {
                        if (resultset[1].length > 0) {
                            state.station.settings = resultset[1]
                        }
                    }
                }
                {
                    const resultset = await ajax.p_query_ms_svg('{}')
                    if (resultset[1]) {
                        if (resultset[1].length > 0) {
                            const record = resultset[1][0]
                            if (record) {
                                state.station.home_show_mode = record.useflag
                            }
                        }
                    }
                }
                {
                    // 加载遥信编码
                    const map_yxbianma = {}
                    const resultset = await ajax.p_query_ms_leixing_canshu_yx_data_enum({})
                    const yxbianma = resultset[1]
                    for (let i = 0; i < yxbianma.length; i++) {
                        const obj_id = yxbianma[i].obj_id
                        const bianma = yxbianma[i].yxbianma
                        map_yxbianma[obj_id] = bianma
                    }
                    state.station.map_yxbianma = map_yxbianma
                    // console.log('加载遥信编码', JSON.stringify(state.station.map_yxbianma))
                }
                {
                    // 加载类型
                    const map_leixing = {}
                    const resultset = await ajax.p_query_leixing({})
                    const leixings = resultset[1]
                    for (let i = 0; i < leixings.length; i++) {
                        const leixing = leixings[i]
                        map_leixing[leixing.leixing_id] = leixing
                    }
                    state.station.map_leixing = map_leixing
                    // console.log('加载类型', JSON.stringify(state.station.map_leixing))
                }
                {
                    // 加载类型参数
                    const map_leixing_canshu = {}
                    const resultset = await ajax.p_query_leixing_canshu({})
                    const canshus = resultset[1]
                    for (let i = 0; i < canshus.length; i++) {
                        const canshu = canshus[i]
                        const leixing_id = canshu.leixing_id
                        if (map_leixing_canshu[leixing_id] === undefined || map_leixing_canshu[leixing_id] === null) {
                            map_leixing_canshu[leixing_id] = []
                        }
                        map_leixing_canshu[leixing_id].push(canshu)
                    }
                    state.station.map_leixing_canshu = map_leixing_canshu
                    // console.log('加载类型参数', JSON.stringify(state.station.map_leixing_canshu))
                }
                {
                    // 加载对象
                    const map_object = {}
                    const resultset = await ajax.p_query_shebei({})
                    const objects = resultset[1]
                    for (let i = 0; i < objects.length; i++) {
                        const object = objects[i].object
                        const obj_id = object.obj_id
                        const leixing_id = object.leixing_id
                        if (leixing_id !== undefined && leixing_id !== null) {
                            object.leixing = JSON.parse(JSON.stringify(state.station.map_leixing[leixing_id]))
                        }
                        map_object[obj_id] = object
                    }
                    state.station.map_object = map_object
                    // console.log('加载对象', JSON.stringify(state.station.map_object))
                }
                {
                    // 加载对象参数
                    const map_object = state.station.map_object
                    //
                    const map_canshu = {}
                    const resultset = await ajax.p_query_shebei_canshu({})
                    const canshus = resultset[1]
                    //
                    for (let i = 0; i < canshus.length; i++) {
                        const canshu = canshus[i]
                        const bdz_id = canshu.bdz_id
                        const leixing_id = canshu.leixing_id
                        const shebei_id = canshu.shebei_id
                        const obj_id = `${bdz_id}_${leixing_id}_${shebei_id}`
                        const object = map_object[obj_id]
                        if (object === undefined || object === null) {
                            continue
                        }
                        map_canshu[canshu.obj_id] = JSON.parse(JSON.stringify(canshu))
                        if (object.canshus === undefined || map_object.canshus === null) {
                            object.canshus = {}
                        }
                        object.canshus[canshu.obj_id] = JSON.parse(JSON.stringify(canshu))
                    }
                    state.station.map_canshu = map_canshu
                    // console.log('加载对象参数', JSON.stringify(state.station.map_object))
                }
                {
                    // 将数据按装置类型、间隔分组
                    const map_object = state.station.map_object
                    const map_leixing = state.station.map_leixing
                    const paramset = {
                        lang: localStorage.getItem('lang')
                    }
                    const biandianzhans = []
                    const resultset = await ajax.p_query_shebei_by_leixing_and_jiange(paramset)
                    const bdz_id = resultset[1][0].bdz_id
                    const list_leixing_id = resultset[1][0].leixings
                    const list_jg_id = resultset[1][0].jianges
                    //
                    const biandianzhan = map_object[`${bdz_id}_2_${bdz_id}`]
                    //
                    {
                        const leixings = []
                        for (let i = 0; i < list_leixing_id.length; i++) {
                            const leixing_id = list_leixing_id[i]
                            const leixing = JSON.parse(JSON.stringify(map_leixing[leixing_id]))
                            leixing.shebeis = []
                            for (const key in map_object) {
                                const object = map_object[key]
                                if (object.bdz_id !== bdz_id) {
                                    continue
                                }
                                if (object.leixing_id !== leixing_id) {
                                    continue
                                }
                                const shebei = JSON.parse(JSON.stringify(object))
                                delete shebei.canshus
                                leixing.shebeis.push(shebei)
                            }
                            leixings.push(leixing)
                        }
                        biandianzhan.leixings = leixings
                        // console.log('按类型分组:', JSON.stringify(biandianzhan.leixings))
                    }
                    //
                    {
                        const jianges = []
                        for (let i = 0; i < list_jg_id.length; i++) {
                            const jg_id = list_jg_id[i]
                            const jiange = map_object[`${bdz_id}_21_${jg_id}`]
                            jiange.shebeis = []
                            for (const key in map_object) {
                                const object = map_object[key]
                                if (object.bdz_id !== bdz_id) {
                                    continue
                                }
                                if (object.leixing_id === 1 || object.leixing_id === 24 || object.leixing_id === 2 || object.leixing_id === 21) {
                                    continue
                                }
                                if (object.jg_id !== jg_id) {
                                    continue
                                }
                                const shebei = JSON.parse(JSON.stringify(object))
                                delete shebei.canshus
                                jiange.shebeis.push(shebei)
                            }
                            jianges.push(jiange)
                        }
                        biandianzhan.jianges = jianges
                        // console.log('按间隔分组:', JSON.stringify(biandianzhan.jianges))
                    }
                    biandianzhans.push(biandianzhan)
                    // 生成组织结构
                    state.station.biandianzhans = biandianzhans
                    // console.log('组织结构：', JSON.stringify(state.station.biandianzhans))
                }
                // 加载对象参数数据
                {
                    const resultset = await ajax.p_query_zx_data({})
                    const zx_data = resultset[1][0].zx_data
                    commit('set_zx_data', zx_data)
                    commit('update_zx_data')
                    // console.log('加载参数数据：', JSON.stringify(state.station.map_canshu))
                }
                // 设置默认值
                commit('setDefaultShebeiObject')
            } catch (error) {
                ElMessage({
                    type:'warning',
                    message:`加载对象全部信息异常,错误原因：${error}`
                })
                return
            }
            state.bIsInitState = true
        }
    },
    modules: {
    },
    plugins: [
        // createVuexAlong(
        //     {
        //         // 设置保存的集合名字，避免同站点下的多项目数据冲突
        //         name: 'om-a300-view'
        //     }
        // )
    ]
})
