<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="300px"
height="280px"
:title="xgmm">
    <div class="om_dialog__body">
        <el-form :model="paramset" :rules="rules" ref="login">
            <el-form-item prop="username">
                <el-input v-model="paramset.username" placeholder="" disabled>
                    <template v-slot:prepend>
                        <el-button>{{zh}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" placeholder="" v-model="paramset.password" autocomplete="off" auto-complete="new-password">
                    <template v-slot:prepend>
                        <el-button>{{mm}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="password_new">
                <el-input type="password" placeholder="" v-model="paramset.password_new" autocomplete="off" auto-complete="new-password">
                    <template v-slot:prepend>
                        <el-button>{{xmm}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="password_new_confirm">
                <el-input type="password" placeholder="" v-model="paramset.password_new_confirm" autocomplete="off" auto-complete="new-password">
                    <template v-slot:prepend>
                        <el-button>{{xmm_conf}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
    <template v-slot:footer>
        <div class="om_dialog__footer">
            <el-button type="primary" @click="closeDialog()">{{qx}}</el-button>
            <el-button type="primary" @click="handle_pwd_reset_button_click()">{{qd}}</el-button>
        </div>
    </template>
</om_dialog>
</template>

<script>
import om_dialog from '@/components/om_dialog/index.vue'
import md5 from 'js-md5'
export default {
    components:{
        om_dialog
    },
    data: function() {
        const username = sessionStorage.getItem('username') || ''
        return {
            paramset: {
                username: username,
                password: '',
                password_new: '',
                password_new_confirm: ''
            },
            rules: {
                username: [{ required: true, message: this.warn_zh, trigger: 'blur' }],
                password: [{ required: true, message: this.warn_mm, trigger: 'blur' }],
                password_new: [{ required: true, message: this.warn_xmm, trigger: 'blur' }],
                password_new_confirm: [{ required: true, message: this.warn_xmm_conf, trigger: 'blur' }]
            }
        }
    },
    computed: {
        xgmm() {
            return this.$Lang.header.xgmm.name
        },
        zh() {
            return this.$Lang.header.xgmm.zh
        },
        mm() {
            return this.$Lang.header.xgmm.mm
        },
        xmm() {
            return this.$Lang.header.xgmm.xmm
        },
        xmm_conf() {
            return this.$Lang.header.xgmm.xmm_conf
        },
        qx() {
            return this.$Lang.header.xgmm.qx
        },
        qd() {
            return this.$Lang.header.xgmm.qd
        },
        warn_zh() {
            return this.$Lang.header.xgmm.warn_zh
        },
        warn_mm() {
            return this.$Lang.header.xgmm.warn_mm
        },
        warn_xmm() {
            return this.$Lang.header.xgmm.warn_xmm
        },
        warn_xmm_conf() {
            return this.$Lang.header.xgmm.warn_xmm_conf
        },
        warn_mmcw() {
            return this.$Lang.header.xgmm.warn_mmcw
        },
        warn_mmbpp() {
            return this.$Lang.header.xgmm.warn_mmbpp
        },
        warn_mmcheck() {
            return this.$Lang.header.xgmm.warn_mmcheck
        }
    },
    methods: {
        showDialog() {
            this.$refs.om_dialog.showDialog()
        },
        closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        handle_pwd_reset_button_click() {
            this.$refs.login.validate( async(valid) => {
                // 对账号或者密码进行校验
                if (!valid) {
                    this.$message.warning(this.warn_mmcw)
                    return false
                }
                if (this.paramset.password_new !== this.paramset.password_new_confirm) {
                    this.$message.warning(this.warn_mmbpp)
                    return false
                }
                const mutchstr = (/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{6,}$/.exec(this.paramset.password_new))
                if (mutchstr === undefined || mutchstr === null) {
                    this.$message.warning(this.warn_mmcheck)
                    return false
                }
                // 用户名、密码发送到后台进行登陆校验
                let dataset = null
                try {
                    dataset = await this.$ajax.p_user_password_reset({
                        user_id: this.paramset.username,
                        user_pwd_old: md5(this.paramset.password),
                        user_pwd_new: md5(this.paramset.password_new)
                    })
                    const error = dataset[0][0]
                    if (error.code !== 0) {
                        this.$message.warning(`p_user_password_reset request failed, error code:${error.code}, error text:${error.text}`)
                        return false
                    }
                } catch (e) {
                    this.$message.warning(`p_user_password_reset request failed, error message:${e}!`)
                    return false
                }
                this.closeDialog()
                return true
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .om_dialog__body {
        box-sizing: border-box;
        padding: 4px;
    }
    .om_dialog__footer {
        box-sizing: border-box;
        padding: 4px;
    }
}
</style>