<template>
<div class="om_container">
    <div class="om_container_header">
        <slot name="title" :title="title">{{title}}</slot>
    </div>
    <div class="om_container_body">
        <slot />
    </div>
</div>
</template>

<script>
export default {
    name: 'om_container',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
$borderColor:#338dff;
$backgroundColor: #2376b7; //#338dd0;
$color: white;
.om_container {
    width:100%;
    height:100%;
    user-select: none;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    // border: 4px solid $borderColor;
    font-family: monospace;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .om_container_header {
        flex: 0;
        height: 28px;
        min-height: 28px;
        width:100%;
        overflow: hidden;
        font-size: 14px;
        background: $backgroundColor;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $color;
        font-weight: bold;
        padding: 4px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .om_container_body {
        flex: 1;
        width:100%;
        box-sizing: border-box;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 4px solid $backgroundColor;
        border-top: 2px solid $backgroundColor;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
</style>