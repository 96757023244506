<template>
<om_dialog
ref="om_dialog"
class="om_dialog"
width="95%"
height="95%"
:title="dtbj"
@close="handle_close_event">
    <div class="om_dialog__body">
        <div class="toolbar">
            <el-button-group>
                <el-button type="success" @click.prevent.stop="upload">{{scbjt}}</el-button>
                <el-button type="success" @click.prevent.stop="showSelectedObjectDialog">{{xz}}</el-button>
                <el-button type="danger" @click.prevent.stop="deleteItem">{{sc}}</el-button>
                <el-button type="danger" @click.prevent.stop="refresh">{{sx}}</el-button>
                <el-button type="primary" @click.prevent.stop="save">{{bc}}</el-button>
            </el-button-group>
        </div>
        <div class="canvas">
            <om_canvas ref="ref_canvas" :detail="detail"/>
        </div>
        <om_upload_dialog class="om_upload_dialog" ref="ref_upload_dialog" @commit="handle_commit_click"/>
        <om_device_dialog class="om_device_dialog" ref="ref_device_dialog" @commit="handle_device_commit_click"/>
    </div>
</om_dialog>
</template>

<script>
import { ref } from 'vue'
import om_dialog from '@/components/om_dialog'
import om_canvas from '@/components/om_canvas'
import detail from '@/components/om_canvas/test.js'
import om_upload_dialog from './om_upload_dialog'
import om_device_dialog from './om_device_dialog'
export default {
    name: 'om_svg_edit_dialog',
    components: {
        om_dialog,
        om_canvas,
        om_upload_dialog,
        om_device_dialog
    },
    data() {
        return {
            detail: detail
        }
    },
    setup() {
        const ref_canvas = ref(null)
        const ref_upload_dialog = ref(null)
        const ref_device_dialog = ref(null)
        return {
            ref_canvas,
            ref_upload_dialog,
            ref_device_dialog
        }
    },
    computed: {
        dtbj() {
            return this.$Lang.header.dtbj.name
        },
        scbjt() {
            return this.$Lang.header.dtbj.scbjt.name
        },
        xz() {
            return this.$Lang.header.dtbj.xz.name
        },
        sc() {
            return this.$Lang.header.dtbj.sc
        },
        sx() {
            return this.$Lang.header.dtbj.sx
        },
        bc() {
            return this.$Lang.header.dtbj.bc
        },
        xsjxt() {
            return this.$Lang.header.dtbj.xsjxt
        },
        ycjxt() {
            return this.$Lang.header.dtbj.ycjxt
        }
    },
    methods: {
        async showDialog() {
            this.$refs.om_dialog.showDialog()
            await this.p_query_ms_svg()
        },
        async closeDialog() {
            this.$refs.om_dialog.closeDialog()
        },
        async handle_close_event() {
            try {
                await this.$MessageBox.confirm(
                    'Do you want to save the sld map?',
                    'prompt',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'info'
                    }
                )
                await this.p_update_ms_svg()
                this.$router.go(0)
            } catch (error) {
                return
            }
        },
        // -----------------------------------------------------------------
        async getImageSize(src) {
            return new Promise(
                (resolve, reject) => {
                    const image = new Image()
                    image.onload = () => {
                        const size = {
                            width: image.width,
                            height: image.height
                        }
                        resolve(size)
                    }
                    image.src = src
                }
            )
        },
        // -----------------------------------------------------------------
        async p_query_ms_svg() {
            const detail = {
                scene: {
                    background: '/images/svg/station.jpg',
                    viewBox : {
                        x: 0,
                        y: 0,
                        width: 1666,
                        height: 831
                    }
                },
                items: []
            }
            try {
                const resultset = await this.$ajax.p_query_ms_svg('{}')
                if (resultset[1] === undefined || resultset[1] === null) return
                if (resultset[1].length === 0) {
                    this.detail = detail
                    return
                }
                const record = resultset[1][0]
                if (record === undefined || record === null) {
                    this.detail = detail
                    return
                }
                this.home_show_mode = record.useflag
                detail.scene.viewBox.x = record.x
                detail.scene.viewBox.y = record.y
                detail.scene.viewBox.width = record.width
                detail.scene.viewBox.height = record.height
                detail.items = record.items
                const src = '/images/svg/station.jpg'
                const size = await this.getImageSize(src)
                if (size === undefined || size === null) return
                detail.scene.background = `${src}?${Math.random()}`
                const viewBox = {
                    x: 0,
                    y: 0,
                    width: size.width,
                    height: size.height
                }
                detail.scene.viewBox = viewBox
                this.$nextTick(
                    () => {
                        this.detail = detail
                    }
                )
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_query_ms_svg异常,错误原因:${error}`
                })
            }
        },
        async p_update_ms_svg() {
            const paramset = {
                background: '/images/svg/station.jpg',
                useflag: 1,
                x: 0,
                y: 0,
                width: this.detail.scene.viewBox.width,
                height: this.detail.scene.viewBox.height,
                items: this.detail.items
            }
            try {
                await this.$ajax.p_update_ms_svg(paramset)
            } catch (error) {
                this.$Message({
                    type:'warning',
                    message:`p_update_ms_svg异常,错误原因:${error}`
                })
            }
        },
        // -----------------------------------------------------------------
        upload() {
            const ref_upload_dialog = this.ref_upload_dialog
            if (ref_upload_dialog === undefined || ref_upload_dialog === null) return
            ref_upload_dialog.showDialog()
        },
        showSelectedObjectDialog() {
            const ref_device_dialog = this.ref_device_dialog
            if (ref_device_dialog === undefined || ref_device_dialog === null) return
            ref_device_dialog.showDialog()
        },
        insertItem(object) {
            if (object === undefined || object === null) return
            const ref_canvas = this.ref_canvas
            if (ref_canvas === undefined || ref_canvas === null) return
            const item = {
                obj_id: object.obj_id,
                mingzi: object.mingzi
            }
            ref_canvas.addItem(item)
        },
        deleteItem() {
            const ref_canvas = this.ref_canvas
            if (ref_canvas === undefined || ref_canvas === null) return
            ref_canvas.removeActiveItem()
        },
        async save() {
            await this.p_update_ms_svg()
        },
        async refresh() {
            await this.p_query_ms_svg()
        },
        async handle_commit_click() {
            const detail = this.detail
            if (detail === undefined || detail === null) return
            const scene = detail.scene
            if (scene === undefined || scene === null) return
            const src = '/images/svg/station.jpg'
            const size = await this.getImageSize(src)
            if (size === undefined || size === null) return
            scene.background = `${src}?${Math.random()}`
            const viewBox = {
                x: 0,
                y: 0,
                width: size.width,
                height: size.height
            }
            scene.viewBox = viewBox
        },
        handle_device_commit_click(object) {
            this.insertItem(object)
        }
    },
    async mounted() {
        await this.p_query_ms_svg()
    }
}
</script>

<style lang="scss" scoped>
.om_dialog {
    .om_dialog__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .toolbar {
            flex: 0;
            width: 100%;
            min-height: 40px;
            box-sizing: border-box;
            overflow: hidden;
            padding-left: 4px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: #619ac3;
        }
        .canvas {
            flex: 1;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0px;
            justify-content: center;
            align-items: center;
        }
        .om_upload_dialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,0.5);
            overflow: hidden;
            z-index: 600;
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }
        .om_device_dialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,0.5);
            overflow: hidden;
            z-index: 600;
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }
    }
}
</style>