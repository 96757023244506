<template>
<div class="om_event_view" >
    <div class="top">
        <om_shebei_event_view class="om_shebei_event_view" ref="om_shebei_event_view"/>
    </div>
    <div class="bottom">
        <om_device_event_view class="om_device_event_view" ref="om_device_event_view"/>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import om_shebei_event_view from './om_shebei_event_view'
import om_device_event_view from './om_device_event_view'
export default {
    name: 'om_event_view',
    components: {
        om_shebei_event_view,
        om_device_event_view
    },
    setup() {
        const om_shebei_event_view = ref(null)
        const om_device_event_view = ref(null)
        return {
            om_shebei_event_view,
            om_device_event_view
        }
    },
    methods: {
        resize() {
            if (this.om_shebei_event_view) {
                this.om_shebei_event_view.resize()
            }
            if (this.om_device_event_view) {
                this.om_device_event_view.resize()
            }
        }
    },
    activated() {
        this.resize()
    },
    mounted() {
        this.resize()
    }
}
</script>

<style lang='scss' scoped>
.om_event_view {
    width: 100%;
    height: 100%;
    background: white;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;
    .top {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding-bottom: 2px;
    }
    .bottom {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding-top: 2px;
    }
}
</style>