<template >
<g id="controller" class="controller">
    <rect
    pointer-events="all"
    :x="x"
    :y="y"
    :width="width"
    :height="height"
    data-handlertype="drag"
    data-itemtype="controller"
    fill="none"
    vector-effect="non-scaling-stroke"
    :stroke="option.resizeBorderHandler.stroke"
    :stroke-width="option.resizeBorderHandler.strokeWidth"
    cursor="move"
    />
    <rect
    v-for="(resizeBoxHandler, index) in option.resizeBoxHandlers"
    :key="index"
    data-itemtype="controller"
    :data-handlertype="`resize_${resizeBoxHandler.type}`"
    vector-effect="non-scaling-stroke"
    :x="resizeBoxHandler.x"
    :y="resizeBoxHandler.y"
    width="0.1"
    height="0.1"
    :stroke-width="resizeBoxHandler.strokeWidth"
    :stroke="resizeBoxHandler.stroke"
    fill="blue"
    :cursor="resizeBoxHandler.cursor"
    />
</g>
</template>
<script>
const config = {
    resizeBorderHandler: {
        strokeWidth: '1px', // 可调边框固定1个像素
        stroke: '#4F80FF' // 可调边框颜色
    },
    resizeBoxHandler: {
        strokeWidth: '8px', // 可调盒子大小固定9个像素
        stroke: '#4F80FF' // 可调盒子颜色
    }
}
export default {
    name: 'om_controller',
    props: {
        item: {
            type: Object,
            default: () => {
                return null
            }
        },
        activable: {
            type: Boolean,
            default: true
        },
        resizable: {
            type: Boolean,
            default: true
        },
        rotatable: {
            type: Boolean,
            default: true
        },
        draggable: {
            type: Boolean,
            default: true
        },
        acceptRatio: {
            type: Boolean,
            default: false
        },
        resizeBorderHandler: {
            type: Object,
            default: () => {
                return {
                    strokeWidth: config.resizeBorderHandler.strokeWidth,
                    stroke: config.resizeBorderHandler.stroke
                }
            }
        },
        resizeBoxHandler: {
            type: Object,
            default: () => {
                return {
                    strokeWidth: config.resizeBoxHandler.strokeWidth,
                    stroke: config.resizeBoxHandler.stroke
                }
            }
        }
    },
    computed: {
        x() {
            const item = this.item
            if (item === undefined || item === null) return 0
            return item.x
        },
        y() {
            const item = this.item
            if (item === undefined || item === null) return 0
            return item.y
        },
        width() {
            const item = this.item
            if (item === undefined || item === null) return 0
            return item.width
        },
        height() {
            const item = this.item
            if (item === undefined || item === null) return 0
            return item.height
        },
        option() {
            const option = {
                resizeBorderHandler: {},
                resizeBoxHandlers: []
            }
            option.resizeBorderHandler = {
                strokeWidth: this.resizeBorderHandler.strokeWidth,
                stroke: this.resizeBorderHandler.stroke
            }
            option.resizeBoxHandlers = [
                {
                    type: 'tl',
                    cursor:'nw-resize',
                    x: this.x,
                    y: this.y,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                },
                {
                    type: 'tm',
                    cursor:'n-resize',
                    x: this.x + this.width / 2,
                    y: this.y,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                },
                {
                    type: 'tr',
                    cursor:'ne-resize',
                    x: this.x + this.width,
                    y: this.y,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                },
                {
                    type: 'r',
                    cursor:'e-resize',
                    x: this.x + this.width,
                    y: this.y + this.height / 2,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                },
                {
                    type: 'br',
                    cursor:'se-resize',
                    x: this.x + this.width,
                    y: this.y + this.height,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                },
                {
                    type: 'bm',
                    cursor:'s-resize',
                    x: this.x + this.width / 2,
                    y: this.y + this.height,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                },
                {
                    type: 'bl',
                    cursor:'sw-resize',
                    x: this.x,
                    y: this.y + this.height,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                },
                {
                    type: 'l',
                    cursor:'w-resize',
                    x: this.x,
                    y: this.y + this.height / 2,
                    strokeWidth: this.resizeBoxHandler.strokeWidth,
                    stroke: this.resizeBoxHandler.stroke
                }
            ]
            return option
        }
    },
    methods: {
        getItem() {
            return this.item
        }
    }
}
</script>
<style>
</style>
